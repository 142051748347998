
import React, { useContext, useEffect, useRef, useState } from "react";
import { isEmpty, assignIn, clone, find, filter, forIn } from "lodash";
import { Tag, Input, Tooltip } from 'antd';
import { Button } from 'antd';
import "./style.scss";


const FilterTags = (props) => {
    const { data, onRemoveTag } = props;

    const onHandleRemoveTag = (item) => {
        if (onRemoveTag) {
            onRemoveTag(item);
        }
    }

    return (
        <div className="FilterTags-wp">
            {!isEmpty(data) && data.map((tItem, index) => (
                <Tag
                    closable
                    className="f-tag-item"
                    key={`${tItem.key}_${index}`}
                    onClose={() => onHandleRemoveTag(tItem)}
                >
                    {tItem.value}
                </Tag>
            ))}
        </div>
    )
}

export default FilterTags;
