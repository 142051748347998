import React, { useState, useEffect } from "react";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { Dropdown, Menu, Radio, DatePicker, Button } from "antd";
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import Cookies from "universal-cookie";
import "./style.scss";
import moment from "moment";

import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();

var lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
var currentDate = new Date();

if (
  cookies.get("ms_startDate") &&
  cookies.get("ms_startDate") !== "" &&
  cookies.get("ms_startDate") !== "undefined"
) {
  currentDate = cookies.get("ms_startDate");
}
if (
  cookies.get("ms_endDate") &&
  cookies.get("ms_endDate") !== "" &&
  cookies.get("ms_endDate") !== "undefined"
) {
  lastDate = cookies.get("ms_endDate");
}

const MyStoresOverview = (props) => {
  // const [data, setData] = useState([]);
  // const [tableConfigs, setTableConfigs] = useState([]);
  const [periodFilter, setPeriodFilter] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [sDate, setSDate] = useState([]);
  const [eDate, setEDate] = useState([]);

  useEffect(() => {
    if (cookies.get("email") == null) {
      document.location.href = "/";
    }
    cookies.set("pr_brand", "");
    cookies.set("retailer", "");

    if (
      cookies.get("ms_includePromoProducts") === undefined ||
      cookies.get("ms_includePromoProducts") === "" ||
      cookies.get("ms_includePromoProducts") === "undefined"
    ) {
      cookies.set("ms_includePromoProducts", true);
    }

    if (
      cookies.get("ms_includeSecondaryDisplay") === undefined ||
      cookies.get("ms_includeSecondaryDisplay") === "" ||
      cookies.get("ms_includeSecondaryDisplay") === "undefined"
    ) {
      cookies.set("ms_includeSecondaryDisplay", false);
    }

    cookies.set("pc_retailer", "");
    cookies.set("pc_product", "");
    cookies.set("ig_startDate", "");
    cookies.set("ig_startDate", "");
    cookies.set("ig_placement", "");
    cookies.set("ig_retailer", "");

    //get all countries & regions
    /*if (
      cookies.get("ms_country") === null ||
      cookies.get("ms_country") === "undefined" ||
      cookies.get("ms_country") === undefined
    ) {
      let strCountry = "";
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
      cookies.set("ms_country", strCountry);
    }

    if (
      cookies.get("ms_region") === null ||
      cookies.get("ms_region") === "undefined" ||
      cookies.get("ms_region") === undefined
    ) {
      let strRegion = "";
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
      cookies.set("ms_region", strRegion);
    }

    if (
      cookies.get("ms_retailer") === null ||
      cookies.get("ms_retailer") === "undefined" ||
      cookies.get("ms_retailer") === undefined
    ) {
      let strRetailer = "";
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
      cookies.set("ms_retailer", strRetailer);
    }*/
    //end get all countries & regions

    setSDate();
  }, []);

  const exportMenus = (
    <Menu>
      <Menu.Item
        key="pdf"
        onClick={() => {
          exportPDF();
        }}
      >
        PDF
      </Menu.Item>
      <Menu.Item key="1">Excel</Menu.Item>
    </Menu>
  );

  const exportPDF = () => {
    //console.log(filterData);
    //alert(filterData.STORES_LIST.value.length);
  };

  const handlePeriodFilterChange = (e) => {
    setPeriodFilter(e.target.value);
  };

  const onHandleFilterChanged = (data) => {
    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("ms_retailer", strRetailer);

    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("ms_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    cookies.set("ms_region", strRegion);

if (
  data.PROMOTION_LIST.value &&
  data.PROMOTION_LIST.value !== "null" &&
  data.PROMOTION_LIST.value !== "undefined" &&
  data.PROMOTION_LIST.value.length > 0
) {
  const promotion = data.PROMOTION_LIST.value;
  cookies.set("ms_includePromoProducts", !promotion[0].isChecked);
} else cookies.set("ms_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== undefined &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      cookies.set("ms_includeSecondaryDisplay", secondary[0].isChecked);
    } else cookies.set("ms_includeSecondaryDisplay", false);

    setFilterData(data);
    convertDataForTags(data);
    document.location.href = "my-stores";
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);
  };

  return (
    <div className="Team-Activity-Overview-wp">
      <div className="title-wp">
        <div className="title-wp">My Stores</div>
      </div>
      <div className="condition-values-wp">
        <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
      </div>
      <div className="control-btns">
        <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
          <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
          <RangePicker
            showTime
            format={"YYYY-MM-DD"}
            onChange={(range) => {}}
            onCalendarChange={async (range, dateString, info) => {
              if (range[0] && range[1]) {
                let startDate = moment(range[0]).format("YYYY-MM-DD");
                let endDate = moment(range[1]).format("YYYY-MM-DD");
                cookies.set("ms_startDate", startDate);
                cookies.set("ms_endDate", endDate);
                document.location.href = "my-stores";
                //}
              }
            }}
            defaultValue={[
              moment(lastDate, dateFormat),
              moment(currentDate, dateFormat),
            ]}
            allowClear={false}
            suffixIcon={
              <img alt="" style={{ marginTop: "3px" }} src={ICON_ARROW_DOWN} />
            }
          />
        </div>
        <CustomFilter
          useFilterBtnForOverview={true}
          selectedData={filterData}
          filterKeys={[
            CUSTOM_FILTER_KEYS.COUNTRY_LIST,
            CUSTOM_FILTER_KEYS.REGION_LIST,
            CUSTOM_FILTER_KEYS.RETAILER_LIST,
            //CUSTOM_FILTER_KEYS.MERCHANDISER_LIST,
            CUSTOM_FILTER_KEYS.PROMOTION_LIST,
            CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
          ]}
          onChange={(data) => {
            onHandleFilterChanged(data);
          }}
        />
      </div>
    </div>
  );
};

export default MyStoresOverview;
