import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";

const columns = [
  {
    title: "Retailer",
    dataIndex: "retailer",
    width: "33.33%",
    key: "retailer",
    sorter: (a, b) => a.retailer.localeCompare(b.retailer),
    showSorterTooltip: false,
  },
  {
    title: "Avg. OSA ( %)",
    dataIndex: "avgOsa",
    width: "33.33%",
    align: "center",
    key: "avgOsa",
    render: (avgOsa) => (
      <>
        <div>{avgOsa}%</div>
      </>
    ),
    sorter: (a, b) => parseInt(a.avgOsa) - parseInt(b.avgOsa),
  },
  /*{
    title: "Sales ($, Milion)",
    dataIndex: "overdueBy",
    key: "overdueBy",
    align: "center",
    width: "33.33%",
  },*/
];

const auditData = [
    /*{
        auditor: 'Sally',
        store: 'Store A',
        overdueBy: 5,
    },
    {
        auditor: 'John',
        store: 'Store B',
        overdueBy: 4,
    },
    {
        auditor: 'Adrian',
        store: 'Store C',
        overdueBy: 3,
    },
    {
        auditor: 'Gary',
        store: 'Store D',
        overdueBy: 2,
    },*/
];

const OSAByRetailer = ({osaData}) => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader title="OSA by Retailer" hideControlButtons={true} />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={osaData.table}
        isPagination={true}
      />
      <Modal
        title="OSA by Retailer"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={osaData.table}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div>
    </div>
  );
};

export default OSAByRetailer;
