
import React from "react";
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import Cookies from "universal-cookie";
import "./style.scss";

import navConfig from "./navConfigs.json";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");

const cookies = new Cookies();

const Header = (props) => {
    const history = useHistory();

    const exportProfileMenus = (
        <Menu>
            <Menu.Item key="pdf" onClick={() => history.push("/logout")}>Sign out</Menu.Item>
        </Menu>
    );

    const isMenuSelected = (key) => {
        const pathName = window.location && window.location.pathname ? window.location.pathname : '';
        if (key === '/' && pathName === key && pathName.length === key.length) {
            return 'active';
        } else if (key !== '/') {
            return pathName.indexOf(key) !== -1 ? 'active' : '';
        }
        return '';
    }

    return (
      <div className="Header-wp">
        <Link className="logo-wp" to="/">
          <img
            alt="Milky Way AI logo"
            className="logo-img"
            src={require("../../assets/img/milky-way-ai-logo.svg")}
          />
          <span className="logo-text">Milky Way AI</span>
        </Link>
        <div className="nav-items-wp">
          {navConfig.map((navItem) => (
            <Link
              to={navItem.link}
              className={`item__name ${isMenuSelected(navItem.link)}`}
              key={navItem.id}
            >
              {navItem.name}
            </Link>
          ))}
        </div>
        <div className="profile-wp">
          <Dropdown
            className="btn-item profile-dropdown"
            overlay={exportProfileMenus}
            trigger={["click"]}
          >
            <div>
              <img
                alt="icon-btn"
                className="arrow-icon"
                src={require("../../assets/img//default-avatar.svg")}
              />
              <div className="info-wp">
                <div className="username">{cookies.get('user')}</div>
                <div className="role-wp">{cookies.get('role')}</div>
              </div>
              <img alt="" className="arrow-down-ic" src={ICON_ARROW_DOWN} />
            </div>
          </Dropdown>
        </div>
      </div>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
}

export default Header;
