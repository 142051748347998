import React from 'react';
import {Spin} from "antd";
import "./style.scss";

const Loading = () => {
    return (
        <div className="loading-spinner-wp">
            <Spin size="large" />
        </div>
    )
};

export default Loading;
