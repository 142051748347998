import React, { useState, useEffect } from "react";
import moment from "moment";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import Cookies from "universal-cookie";
import { Column, GroupedColumn } from "@ant-design/charts";
import { message, DatePicker } from "antd";
import HeaderStoreAuditsPeriodChart from "../../componentsShared/HeaderStoreAuditsPeriodChart";
import {
  BACKEND_URL,
  CUSTOM_FILTER_KEYS,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
import FilterTags from "../../componentsShared/FilterTags";
import CustomFilter from "../../componentsShared/CustomFilter";
import "./style.scss";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 12);
lastDate = moment(lastDate).format(dateFormat);
let currentDate = new Date();
currentDate = moment(currentDate).format(dateFormat);

const TotalAvgShareOfShelfChart = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [manufacturer, setManufacturer] = useState();

  if (
    cookies.get("ta_startDate") &&
    cookies.get("ta_startDate") !== "" &&
    cookies.get("ta_startDate") !== "undefined"
  ) {
    lastDate = cookies.get("ta_startDate");
  }
  if (
    cookies.get("ta_endDate") &&
    cookies.get("ta_endDate") !== "" &&
    cookies.get("ta_endDate") !== "undefined"
  ) {
    currentDate = cookies.get("ta_endDate");
  }

  if (!cookies.get("ta_startDate") || !cookies.get("ta_endDate")) {
    cookies.set("ta_startDate", lastDate);
    cookies.set("ta_endDate", currentDate);
  }

  const fetchSos = () => {
    const loaderId = "ca_fetchsosavgsoschart";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getsosformanufacturerandcompetitor?manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("ta_retailer")}&regions=${cookies.get(
      "ta_region"
    )}&countries=${cookies.get("ta_country")}&subcategory=${cookies.get(
      "ta_subcategory"
    )}&includePromoProducts=${cookies.get(
      "ta_includePromoProducts"
    )}&competitor=5f0e5790d6c8cf2b8efc4b9c&includeSecondaryDisplay=${cookies.get(
      "ta_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        const reversedResult = [...result.message].reverse();
        removeLoader(loaderId);
        setData(reversedResult);
      })
      .catch((err) => console.log("------- Error -------", err));
  };

  const onHandleFilterChanged = (data) => {
    console.log(data);
    var strBrand = "";
    if (
      data.BRAND_LIST &&
      data.BRAND_LIST.value &&
      data.BRAND_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.BRAND_LIST.value.length; i++) {
        strBrand = strBrand + data.BRAND_LIST.value[i].id + ",";
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    } else {
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    }
    cookies.set("ta_brand", strBrand);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("ta_retailer", strRetailer);

    var strSubCategory = "";
    if (
      data.SUB_CATEGORY_LIST &&
      data.SUB_CATEGORY_LIST.value &&
      data.SUB_CATEGORY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.SUB_CATEGORY_LIST.value.length; i++) {
        strSubCategory =
          strSubCategory + data.SUB_CATEGORY_LIST.value[i].id + ",";
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);
    } else {
      /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
      if (subcategories) {
        forEach(subcategories, (item) => {
          strSubCategory = strSubCategory + item.subcategory_id + ",";
        });
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
      strSubCategory = "";
    }
    cookies.set("ta_subcategory", strSubCategory);

    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("ta_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    cookies.set("ta_region", strRegion);
    if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      cookies.set("ta_includePromoProducts", !promotion[0].isChecked);
    } else cookies.set("ta_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      cookies.set("ta_includeSecondaryDisplay", secondary[0].isChecked);
    } else cookies.set("ta_includeSecondaryDisplay", false);

    setFilterData(data);
    convertDataForTags(data);
    fetchSos();
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "BRAND_LIST") {
      let brand = cookies.get("ta_brand").split(",");
      brand = brand.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("ta_brand", brand.join());
      if (brand && brand.length <= 0) {
        let strBrand = "";
        const brands = JSON.parse(localStorage.getItem("brands"));
        if (brands) {
          forEach(brands, (item) => {
            strBrand = strBrand + item.brand_id + ",";
          });
        }
        strBrand = strBrand.substring(0, strBrand.length - 1);
        cookies.set("ta_brand", strBrand);
      }
    }
    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("ta_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("ta_retailer", retailer.join());
      if (retailer && retailer.length <= 0) {
        let strRetailer = "";
        const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
        cookies.set("ta_retailer", strRetailer);
      }
    }
    if (item.key === "COUNTRY_LIST") {
      let country = cookies.get("ta_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("ta_country", country.join());
      if (country && country.length <= 0) {
        let strCountry = "";
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
        cookies.set("ta_country", strCountry);
      }
    }
    if (item.key === "SUB_CATEGORY_LIST") {
      let subcategory = cookies.get("ta_subcategory").split(",");
      subcategory = subcategory.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("ta_subcategory", subcategory.join());
      if (subcategory && subcategory.length <= 0) {
        let strSubCategory = "";
        /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
        if (subcategories) {
          forEach(subcategories, (item) => {
            strSubCategory = strSubCategory + item.subcategory_id + ",";
          });
        }
        strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
        cookies.set("ta_subcategory", strSubCategory);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = cookies.get("ta_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("ta_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("brands"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        cookies.set("ta_region", strRegion);
      }
    }
    if (item.key === "PROMO_SELECTION") {
      cookies.set("ta_includePromoProducts", true);
    }
    if (item.key === "SECONDARY_DISPLAY_SELECTION") {
      cookies.set("ta_includeSecondaryDisplay", false);
    }

    fetchSos();
  };

  //get data
  useEffect(() => {
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    cookies.set("ta_country", strCountry);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    cookies.set("ta_region", strRegion);

    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    cookies.set("ta_retailer", strRetailer);

    let strSubCategory = "";
    /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
    if (subcategories) {
      forEach(subcategories, (item) => {
        strSubCategory = strSubCategory + item.subcategory_id + ",";
      });
    }
    strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
    cookies.set("ta_subcategory", strSubCategory);
    //end get all countries & regions
    if (
      cookies.get("ta_includePromoProducts") === undefined ||
      cookies.get("ta_includePromoProducts") === "" ||
      cookies.get("ta_includePromoProducts") === "undefined"
    ) {
      cookies.set("ta_includePromoProducts", true);
    }
    if (
      cookies.get("ta_includeSecondaryDisplay") === undefined ||
      cookies.get("ta_includeSecondaryDisplay") === "" ||
      cookies.get("ta_includeSecondaryDisplay") === "undefined"
    ) {
      cookies.set("ta_includeSecondaryDisplay", false);
    }

    setManufacturer(cookies.get("manufacturer_name"));
    fetchSos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data_sa = [
    {
      month: "Jan",
      sos: 0,
    },
    {
      month: "Feb",
      sos: 0,
    },
    {
      month: "Mar",
      sos: 0,
    },
    {
      month: "Apr",
      sos: 0,
    },
    {
      month: "May",
      sos: 0,
    },
    {
      month: "Jun",
      sos: 0,
    },
    {
      month: "Jul",
      sos: 0,
    },
    {
      month: "Aug",
      sos: 0,
    },
    {
      month: "Sep",
      sos: 0,
    },
    {
      month: "Oct",
      sos: 0,
    },
    {
      month: "Nov",
      sos: 0,
    },
    {
      month: "Dec",
      sos: 0,
    },
  ];

  const config_sa = {
    title: {
      visible: false,
      text: "SOS",
    },
    forceFit: true,
    data: data.length > 0 ? data : data_sa,
    xField: "month",
    //yField: "SOS (%)",
    yField: "sos",
    //yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    //color: ["#4A5DE8", "#A4AEF3", "#A4AEF3", "#4F5058"],
    color: [
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
    ],
    yAxis: {
      title: {
        text: "SOS (%)",
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      min: 0,
    },
    xAxis: {
      title: {
        text: "Month",
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      label: {
        style: {
          fill: "#000",
          fontSize: 14,
          fontWeight: "bold",
        },
      },
    },
  };

  return (
    <div className="Completed-Store-Audits-Chart-wp">
      {/* <HeaderStoreAuditsPeriodChart
        title="Total Avg Share of Shelf - Kellogg’s Vs competitor"
        filterKeys={[
          CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN,
          CUSTOM_FILTER_KEYS.RETAILER_LIST,
        ]}
        onFilterChanged={(filters) => {
          setFilterData(filters);
          console.log("TeamActivity: onFilterChanged:", filters);
        }}
      /> */}

      <div className="HeaderStoreAuditsChart">
        <div className="hd-left-col">
          <div className="title-value">
            Total Avg SOS - {manufacturer} Vs competitor
          </div>
        </div>
        <div className="hd-middle-col">
          <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
        </div>
        <div className="hd-right-col">
          {/*<div className="custom-calendar-wp">
            <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
            <RangePicker
              showTime
              format={"YYYY-MM-DD"}
              onCalendarChange={(range, dateString, info) => {
                let startDate = moment(range[0]).format("YYYY-MM-DD");
                let endDate = moment(range[1]).format("YYYY-MM-DD");

                //if (info.range === "end") {
                cookies.set("ta_startDate", startDate);
                cookies.set("ta_endDate", endDate);

                // message.loading("Loading data...", 0);
                fetchSos();
                //}
              }}
              defaultValue={[
                moment(lastDate, dateFormat),
                moment(currentDate, dateFormat),
              ]}
              allowClear={false}
              suffixIcon={
                <img
                  alt=""
                  style={{ marginTop: "3px" }}
                  src={ICON_ARROW_DOWN}
                />
              }
            />
            </div>*/}
          <CustomFilter
            // useFilterBtnForOverview={true}
            selectedData={filterData}
            filterKeys={[
              CUSTOM_FILTER_KEYS.COUNTRY_LIST,
              CUSTOM_FILTER_KEYS.REGION_LIST,
              CUSTOM_FILTER_KEYS.RETAILER_LIST,
              CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST,
              CUSTOM_FILTER_KEYS.BRAND_LIST,
              CUSTOM_FILTER_KEYS.PROMOTION_LIST,
              CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
            ]}
            onChange={(data) => {
              onHandleFilterChanged(data);
            }}
          />
        </div>
      </div>
      <div className="separate-line" />
      <GroupedColumn {...config_sa} />
    </div>
  );
};

export default TotalAvgShareOfShelfChart;
