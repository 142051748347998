import React, { useState } from "react";
import { Progress } from "antd";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import { CUSTOM_FILTER_KEYS, BACKEND_URL } from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";

const ICON_DECREASE = require("../../assets/img/icons/ic-decrease-red.svg");
const ICON_INCREASE = require("../../assets/img/icons/ic-increase-green.svg");

const cookies = new Cookies();

const MyStoresShareOfShelfChart = (props) => {
  const [data, setData] = useState({});

  return (
    <div className="Total-Audits-Completed-wp">
      <div className="row">
        <div className="col-25">
          <Progress
            type="circle"
            percent={props.sos}
            strokeWidth={10}
            strokeColor={props.sos > 80 ? "#87d068" : "#E5305B"}
            format={() =>
              props.sos > 80 ? (
                <div style={{ color: "#87d068" }}>{props.sos}%</div>
              ) : (
                <div style={{ color: "#E5305B" }}>{props.sos}%</div>
              )
            }
            width={80}
          />
        </div>
        <div className="col-75">
          <div className="summary-completed-stores">Share of Shelf (SOS)</div>
          {/*<div className="summary-detail">
            {data.isIncrease ? (
              <img alt="" className="period-icon" src={ICON_INCREASE} />
            ) : (
              <img alt="" className="period-icon" src={ICON_DECREASE} />
            )}
            <div className="last-period">
              {`${data.isIncrease ? "+" : "-"}${
                data.increasePercent
              }% vs last week`}
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default MyStoresShareOfShelfChart;
