import React from "react";
import { Progress } from "antd";
import Cookies from "universal-cookie";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import { BACKEND_URL } from "../../constants/constants";
import "./style.scss";

const ICON_DECREASE = require("../../assets/img/icons/ic-decrease-red.svg");
const ICON_INCREASE = require("../../assets/img/icons/ic-increase-green.svg");
const cookies = new Cookies();

const MyStoresPlanogramComplianceChart = (props) => {
  return (
    <div className="Total-Audits-Completed-wp">
      <div className="row">
        <div className="col-25">
          <Progress
            type="circle"
            percent={props.planogram}
            strokeWidth={10}
            strokeColor={props.planogram > 80 ? "#87d068" : "#E5305B"}
            format={() =>
              props.planogram > 80 ? (
                <div style={{ color: "#87d068" }}>{props.planogram}%</div>
              ) : (
                <div style={{ color: "#E5305B" }}>{props.planogram}%</div>
              )
            }
            width={80}
          />
        </div>
        <div className="col-75">
          <div className="summary-completed-stores">Planogram Compliance</div>
          {/*<div className="summary-detail">
            {data.isIncrease ? (
              <img alt="" className="period-icon" src={ICON_INCREASE} />
            ) : (
              <img alt="" className="period-icon" src={ICON_DECREASE} />
            )}
            <div className="last-period">
              {`${data.isIncrease ? "+" : "-"}${
                data.increasePercent
              }% vs last week`}
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default MyStoresPlanogramComplianceChart;
