import React, { useState, useEffect } from "react";
import MyStoreSummary from "../../components/MyStoreSummary";
import MyStoreProfile from "../../components/MyStoreProfile";
import MyStoresOverview from "../../components/MyStoresOverview";
import MyStoresOnShelfAvailability from "../../components/MyStoresOnShelfAvailability";
import MyStoresShareOfShelf from "../../components/MyStoresShareOfShelf";
import MyStoresPlanogramCompliance from "../../components/MyStoresPlanogramCompliance";
import PrimaryBays from "../../components/PrimaryBays";
import EndcapBays from "../../components/EndcapBays";
import OtherBays from "../../components/OtherBays";
import IslandBays from "../../components/IslandBays";
import PriceScans from "../../components/PriceScans";
import Cookies from "universal-cookie";
import LoaderComponent from "../../components/LoaderComponent";
import { BACKEND_URL, BACKEND_URL_AZURE } from "../../constants/constants";
import "./style.scss";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const MyStores = () => {
  const [name] = useState([]);
  const [auditResult, setAuditResult] = useState([]);
  const [priceScan, setPriceScan] = useState([]);
  const [storeName, setStoreName] = useState([]);
  const [address, setAddress] = useState([]);
  const [sale, setSale] = useState([]);
  const [lastaudit, setLastaudit] = useState([]);
  const [osaData, setOsaData] = useState({});
  const [sosData, setSosData] = useState({});
  const [planogramData, setPlanogramData] = useState([]);
  // store active loading state of the child components
  const [loaders, setLoaders] = useState([]);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const removeLoader = (id) => {
    setLoaders((prevState) => prevState.filter((state) => state !== id));
  };

  const addLoader = (id) => {
    setLoaders((prevState) => [...prevState, id]);
  };

  const fetchAllAudits = async () => {
    const loaderId = "ms_fetchprimarybays";
    addLoader(loaderId);

    if (
      cookies.get("store") &&
      cookies.get("store") !== "" &&
      cookies.get("store") !== "undefined"
    ) {
      if (
        cookies.get("ms_startDate") === undefined ||
        cookies.get("ms_startDate") === "" ||
        cookies.get("ms_startDate") === "undefined"
      ) {
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ms_endDate") === undefined ||
        cookies.get("ms_endDate") === "" ||
        cookies.get("ms_endDate") === "undefined"
      ) {
        cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
      }
      const url =
        BACKEND_URL_AZURE +
        "/getallauditsbystore?store=" +
        cookies.get("store") +
        "&client=" +
        cookies.get("client") +
        "&startDate=" +
        cookies.get("ms_startDate") +
        "&endDate=" +
        cookies.get("ms_endDate");

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          removeLoader(loaderId);
          setAuditResult(result.message);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    }
  };

  const fetchPriceScans = async () => {
    const loaderId = "ms_fetchpricescans";
    addLoader(loaderId);

    if (
      cookies.get("store") &&
      cookies.get("store") !== "" &&
      cookies.get("store") !== "undefined"
    ) {
      if (
        cookies.get("ms_startDate") === undefined ||
        cookies.get("ms_startDate") === "" ||
        cookies.get("ms_startDate") === "undefined"
      ) {
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ms_endDate") === undefined ||
        cookies.get("ms_endDate") === "" ||
        cookies.get("ms_endDate") === "undefined"
      ) {
        cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
      }
      let url =
        BACKEND_URL_AZURE +
        "/getallpricescansbystore?store=" +
        cookies.get("store") +
        "&client=" +
        cookies.get("client") +
        "&startDate=" +
        cookies.get("ms_startDate") +
        "&endDate=" +
        cookies.get("ms_endDate");

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          if (result && result.message.length > 0) setPriceScan(result.message);
          else setPriceScan([]);
          removeLoader(loaderId);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    }
  };

  const fetchMyStoreProfile = async () => {
    const loaderId = "ms_mystoreprofile";
    addLoader(loaderId);

    if (
      cookies.get("store") &&
      cookies.get("store") !== "" &&
      cookies.get("store") !== "undefined"
    ) {
      var url =
        BACKEND_URL_AZURE + "/getstorebyaudit?store=" + cookies.get("store");
      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          setStoreName(result.name);
          setAddress(result.address);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
      if (
        cookies.get("ms_startDate") === undefined ||
        cookies.get("ms_startDate") === "" ||
        cookies.get("ms_startDate") === "undefined"
      ) {
        var startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ms_endDate") === undefined ||
        cookies.get("ms_endDate") === "" ||
        cookies.get("ms_endDate") === "undefined"
      ) {
        cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
      }
      var url1 =
        BACKEND_URL_AZURE +
        "/getlastauditbystore?store=" +
        cookies.get("store") +
        "&client=" +
        cookies.get("client") +
        "&startDate=" +
        cookies.get("ms_startDate") +
        "&endDate=" +
        cookies.get("ms_endDate");

      fetch(url1, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result1) => {
          removeLoader(loaderId);
          if (result1) {
            setSale(result1.sale);
            setLastaudit(
              result1.dateEnd.split("T")[0].split("-")[2] +
                " " +
                monthNames[
                  parseInt(result1.dateEnd.split("T")[0].split("-")[1]) - 1
                ] +
                " " +
                result1.dateEnd.split("T")[0].split("-")[0].substr(2, 2)
            );
          }
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    }
  };

  const fetchOsa = () => {
    if (
      cookies.get("store") &&
      cookies.get("store") !== "" &&
      cookies.get("store") !== "undefined"
    ) {
      const loaderId = "ms_mystoreosa";
      addLoader(loaderId);
      var url =
        BACKEND_URL_AZURE +
        "/getonshelfavailabilitybystore?store=" +
        cookies.get("store") +
        "&startDate=" +
        cookies.get("ms_startDate") +
        "&endDate=" +
        cookies.get("ms_endDate") +
        "&manufacturer=" +
        cookies.get("manufacturer") +
        `&includePromoProducts=${cookies.get(
          "ms_includePromoProducts"
        )}&includeSecondaryDisplay=${cookies.get(
          "ms_includeSecondaryDisplay"
        )}`;

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then((result) => {
          removeLoader(loaderId);
          setOsaData(result);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    }
  };

  const fetchSos = () => {
    if (
      cookies.get("store") &&
      cookies.get("store") !== "" &&
      cookies.get("store") !== "undefined"
    ) {
      const loaderId = "ms_fetchsos";
      addLoader(loaderId);
      var url =
        BACKEND_URL_AZURE +
        "/getshareofshelfbystore?store=" +
        cookies.get("store") +
        "&startDate=" +
        cookies.get("ms_startDate") +
        "&endDate=" +
        cookies.get("ms_endDate") +
        "&manufacturer=" +
        cookies.get("manufacturer") +
        `&includePromoProducts=${cookies.get(
          "ms_includePromoProducts"
        )}&includeSecondaryDisplay=${cookies.get(
          "ms_includeSecondaryDisplay"
        )}`;

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then((result) => {
          removeLoader(loaderId);
          setSosData(result);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    }
  };

  const fetchPlanogram = () => {
    if (
      cookies.get("store") &&
      cookies.get("store") !== "" &&
      cookies.get("store") !== "undefined"
    ) {
      const loaderId = "ms_fetchplanogram";
      addLoader(loaderId);
      const store = cookies.get("store");

      if (
        cookies.get("ms_startDate") === undefined ||
        cookies.get("ms_startDate") === "" ||
        cookies.get("ms_startDate") === "undefined"
      ) {
        var startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ms_endDate") === undefined ||
        cookies.get("ms_endDate") === "" ||
        cookies.get("ms_endDate") === "undefined"
      ) {
        cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
      }

      const url = `${BACKEND_URL_AZURE}/getplanogrambystore?store=${store}&manufacturer=${cookies.get(
        "manufacturer"
      )}&startDate=${cookies.get("ms_startDate")}&endDate=${cookies.get(
        "ms_endDate"
      )}&includePromoProducts=${cookies.get(
        "ms_includePromoProducts"
      )}&includeSecondaryDisplay=${cookies.get("ms_includeSecondaryDisplay")}`;

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          removeLoader(loaderId);
          setPlanogramData(result);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    }
  };

  useEffect(() => {
    if (
      cookies.get("ms_startDate") === undefined ||
      cookies.get("ms_startDate") === "" ||
      cookies.get("ms_startDate") === "undefined"
    ) {
      let startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
    }

    if (
      cookies.get("ms_endDate") === undefined ||
      cookies.get("ms_endDate") === "" ||
      cookies.get("ms_endDate") === "undefined"
    ) {
      cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
    }

    fetchAllAudits();
    fetchPriceScans();
    fetchMyStoreProfile();
    fetchOsa();
    fetchSos();
    fetchPlanogram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="team-av">
      <LoaderComponent show={loaders.length > 0} />
      <MyStoresOverview
      //functionCallFromParent={parentFunction.bind(this)}
      />
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-70">
          <MyStoreSummary
            fetchAllAudits={fetchAllAudits}
            fetchPriceScans={fetchPriceScans}
            fetchMyStoreProfile={fetchMyStoreProfile}
            fetchOsa={fetchOsa}
            fetchSos={fetchSos}
            fetchPlanogram={fetchPlanogram}
            addLoader={addLoader}
            removeLoader={removeLoader}
          />
        </div>
        <div className="col-30">
          <MyStoreProfile
            name={storeName}
            address={address}
            sale={sale}
            lastaudit={lastaudit}
          />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="title-wp">
        <div className="title-wp">Performance {name}</div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-33">
          <MyStoresOnShelfAvailability data={osaData} />
        </div>
        <div className="col-33">
          <MyStoresShareOfShelf data={sosData} />
        </div>
        <div className="col-33">
          <MyStoresPlanogramCompliance data={planogramData} />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="title-wp">
        <div className="title-wp">Store Audit Image Gallery</div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <PrimaryBays data={auditResult} />
        </div>
      </div>
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <EndcapBays data={auditResult} />
        </div>
      </div>
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <IslandBays data={auditResult} />
        </div>
      </div>
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <OtherBays data={auditResult} />
        </div>
      </div>
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <PriceScans data={priceScan} />
        </div>
      </div>
    </div>
  );
};
export default MyStores;
