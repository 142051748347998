import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import moment from "moment";
import { BACKEND_URL, BACKEND_URL_AZURE } from "../../constants/constants";
import BaseKPI from "../BaseKPI";
import BonusKPI from "../BonusKPI";
import PlanogramCompliance from "../PlanogramCompliance";
import SpaceBuy from "../SpaceBuy";
import StoreExecutionOverview from "../StoreExecutionOverview";
import NoOfNonCompliantStoresByKeyRetailer from "../NoOfNonCompliantStoresByKeyRetailer";
import NoOfNonCompliantStoresByAccount from "../NoOfNonCompliantStoresByAccount";
import { message } from "antd";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

// let lastDate = new Date();
// lastDate.setMonth(lastDate.getMonth() - 1);
// lastDate = moment(lastDate).format("YYYY-MM-DD");
// let currentDate = new Date();
// currentDate = moment(currentDate).format("YYYY-MM-DD");

const StoreExecutionComponent = ({ addLoader, removeLoader }) => {
  const [planogramData, setPlanogramData] = useState({});
  const [baseKpi, setBaseKpi] = useState({ baseKpi: 0, sku: [] });
  const [bonusKpi, setBonusKpi] = useState({ bonusKpi: 0, sku: [] });
  const [nonCompliantRetailer, setNonCompliantRetailer] = useState([]);
  const [nonCompliantAccount, setNonCompliantAccount] = useState([]);

  const fetchPlanogram = () => {
    const loaderId = "se_fetchplanogram";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getplanogramcompliance?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("se_startDate")}&endDate=${cookies.get(
      "se_endDate"
    )}&countries=${cookies.get("pr_country")}&regions=${cookies.get(
      "pr_region"
    )}&includePromoProducts=${cookies.get(
      "pr_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("pr_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET", headers: headers })
      .then(function (response) {
        return response.json();
      })
      .then(async (result) => {
        removeLoader(loaderId);
        setPlanogramData({
          complianceScore: result.complianceScore,
          targetCompliance: result.targetCompliance,
        });
        setBaseKpi({
          baseKpi: result.basekpi,
          targetCompliance: result.targetCompliance,
        });
        setBonusKpi({
          bonusKpi: result.bonuskpi,
          targetCompliance: result.targetCompliance,
        });
        setNonCompliantRetailer(result.non_compliant_retailers);
        setNonCompliantAccount(result.non_compliant_stores_by_account);
      })
      .catch(function (error) {
        console.log("-------- error ------- " + error);
      });
  };

  {
    /*const fetchBaseKpi = () => {
    const loaderId = "se_fetchbasekpi";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getbasekpi?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("se_startDate")}&endDate=${cookies.get(
      "se_endDate"
    )}&country=${cookies.get("country")}&brand=${cookies.get(
      "pr_brand"
    )}&includePromoProducts=${cookies.get(
      "pr_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("pr_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setBaseKpi(result);
      })
      .catch((err) => console.log("------ Error ------", err));
  };

  const fetchBonusKpi = () => {
    const loaderId = "se_fetchbonuskpi";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getbonuskpi?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("se_startDate")}&endDate=${cookies.get(
      "se_endDate"
    )}&country=${cookies.get("country")}&brand=${cookies.get(
      "pr_brand"
    )}&includePromoProducts=${cookies.get(
      "pr_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("pr_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setBonusKpi(result);
      })
      .catch((err) => console.log("------ Error ------", err));
  };

  const fetchNonComplianceRetailer = () => {
    const loaderId = "se_fetchnoncompliantretailer";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getnoncompliantstoresbykeyretailer?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("se_startDate")}&endDate=${cookies.get(
      "se_endDate"
    )}&country=${cookies.get("country")}&brand=${cookies.get(
      "pr_brand"
    )}&includePromoProducts=${cookies.get(
      "pr_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("pr_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setNonCompliantRetailer(result);
      })
      .catch((err) => console.log("------ Error ------", err));
  };

  const fetchNonComplianceAccount = () => {
    const loaderId = "se_fetchnoncompliantaccount";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getnoncompliantstoresbyaccount?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("se_startDate")}&endDate=${cookies.get(
      "se_endDate"
    )}&country=${cookies.get("country")}&brand=${cookies.get(
      "pr_brand"
    )}&includePromoProducts=${cookies.get(
      "pr_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("pr_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        result = result.filter((resultData) => resultData.stores.length > 0);
        removeLoader(loaderId);
        setNonCompliantAccount(result);
        // message.destroy();
      })
      .catch((err) => console.log("------ Error ------", err));
  };*/
  }

  useEffect(() => {
    if (
      cookies.get("se_startDate") === undefined ||
      cookies.get("se_startDate") === "" ||
      cookies.get("se_startDate") === "undefined"
    ) {
      var startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      cookies.set("se_startDate", startDate);
    }
    if (
      cookies.get("se_endDate") === undefined ||
      cookies.get("se_endDate") === "" ||
      cookies.get("se_endDate") === "undefined"
    ) {
      cookies.set("se_endDate", new Date());
    }
    if (
      cookies.get("pr_includePromoProducts") === undefined ||
      cookies.get("pr_includePromoProducts") === "" ||
      cookies.get("pr_includePromoProducts") === "undefined"
    ) {
      cookies.set("pr_includePromoProducts", true);
    }
    if (
      cookies.get("pr_includeSecondaryDisplay") === undefined ||
      cookies.get("pr_includeSecondaryDisplay") === "" ||
      cookies.get("pr_includeSecondaryDisplay") === "undefined"
    ) {
      cookies.set("pr_includeSecondaryDisplay", false);
    }

    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    cookies.set("pr_country", strCountry);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    cookies.set("pr_region", strRegion);
    //end get all countries & regions

    fetchPlanogram();
    //fetchBaseKpi();
    //fetchBonusKpi();
    //fetchNonComplianceRetailer();
    //fetchNonComplianceAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StoreExecutionOverview
        fetchPlanogram={fetchPlanogram}
        //fetchBaseKpi={fetchBaseKpi}
        //fetchBonusKpi={fetchBonusKpi}
        //fetchNonComplianceAccount={fetchNonComplianceAccount}
        //fetchNonComplianceRetailer={fetchNonComplianceRetailer}
      />
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-33">
          <PlanogramCompliance planogramData={planogramData} />
        </div>
        <div className="col-33">
          <BaseKPI
            baseKpi={baseKpi}
            sku={[]}
            //sku={baseKpi.sku}
          />
        </div>
        <div className="col-33">
          <BonusKPI
            bonusKpi={bonusKpi}
            sku={[]}
            //sku={bonusKpi.sku}
          />
        </div>
        {/*<div className="col-20">
          <SpaceBuy />
        </div>*/}
      </div>
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-40">
          <NoOfNonCompliantStoresByKeyRetailer
            nonCompliant={nonCompliantRetailer}
          />
        </div>
        <div className="col-60">
          <NoOfNonCompliantStoresByAccount nonCompliant={nonCompliantAccount} />
        </div>
      </div>
    </>
  );
};

export default StoreExecutionComponent;
