import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "../screens/Login";
import Logout from "../screens/Logout";
import Main from "../screens/Main";
import Demo from "../screens/Demo";
import TeamActivityPage from "../screens/TeamActivity/TeamActivity";
import StoreExecutionPage from "../screens/StoreExecution/StoreExecution";
import CompetitorActivityPage from "../screens/CompetitorActivity/CompetitorActivity";
import MyStoresPage from "../screens/MyStores/MyStores";
import ActionListPage from "../screens/ActionList/ActionList";
import NoneCompliance from "../screens/NoneCompliance";
import SecondaryDisplayPage from "../screens/SecondaryDisplay/SecondaryDisplay";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import MainLayout from './layoutTemplates/MainLayout';
import LoginLayout from './layoutTemplates/LoginLayout';

export default function App() {
    return (
      <div className={`app-container`}>
        <Switch>
          <PublicRoute path="/login" layout={LoginLayout} component={Login} />
          <PublicRoute path="/logout" layout={LoginLayout} component={Logout} />
          <Route path="/demo" component={Demo}></Route>
          {/* <Route path="/news-layout" component={TeamActivityPage}></Route> */}
          <Route path="/nonecompliance" component={NoneCompliance}></Route>
          <PrivateRoute
            path="/store-execution"
            layout={MainLayout}
            component={StoreExecutionPage}
          />
          <PrivateRoute
            path="/competitor-activity"
            layout={MainLayout}
            component={CompetitorActivityPage}
          />
          <PrivateRoute
            path="/my-stores"
            layout={MainLayout}
            component={MyStoresPage}
          />
          <PrivateRoute
            path="/secondary-display"
            layout={MainLayout}
            component={SecondaryDisplayPage}
          />
          <PrivateRoute
            path="/action-list"
            layout={MainLayout}
            component={ActionListPage}
          />
          {/* <PrivateRoute path="/new_layout" layout={MainLayout} component={TeamActivityPage} /> */}
          <PrivateRoute
            path="/team-activity"
            layout={MainLayout}
            component={TeamActivityPage}
          />
          <Route path="/main" component={Main}></Route>
          {/* <PrivateRoute path="/" layout={MainLayout} component={TeamActivityPage} /> */}
          <Route path="/" component={Login}></Route>
        </Switch>
      </div>
    );
}
