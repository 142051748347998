import React, { useState, useEffect } from "react";
import { Table, Modal } from "antd";
import moment from "moment";
import Cookies from "universal-cookie";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { BACKEND_URL, BACKEND_URL_AZURE, CUSTOM_FILTER_KEYS } from "../../constants/constants";
import "./style.scss";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { encode } from "base-64";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const columns = [
  {
    title: "SKU",
    dataIndex: "name",
    width: "50%",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    showSorterTooltip: false,
  },
  {
    title: "Count of Promoted Weeks",
    dataIndex: "promotionFrequency",
    width: "50%",
    align: "center",
    key: "promotionFrequency",
    sorter: (a, b) => Number(b.promotionFrequency) - Number(a.promotionFrequency),
    showSorterTooltip: false,
  },
];

const TopPromotedProduct = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tableConfigs, setTableConfigs] = useState([]);

  const fetchToPromotedProducts = () => {
    const loaderId = "ca_toppromotedproducts";
    addLoader(loaderId);

    const manufacturer = cookies.get("manufacturer");
    const startDate = moment().startOf("year").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    const url = `${BACKEND_URL_AZURE}/gettoppromotedproducts?manufacturer=${manufacturer}&countries=${cookies.get(
      "ca_country"
    )}&regions=${cookies.get("ca_region")}&retailers=${cookies.get(
      "ca_retailer"
    )}&startDate=${startDate}&endDate=${endDate}`;

    fetch(url, { method: "GET", headers:headers })
      .then((res) => res.json())
      .then((data) => {
        removeLoader(loaderId);
        setData(data.message);
      })
      .catch((error) => console.log("------- Error ------", error));
  };

  useEffect(() => {
    setTableConfigs(columns);
    fetchToPromotedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="Top Promoted Product"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={data}
        isPagination={true}
      />
      <Modal
        title="Top Promoted Product"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
        width={650}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={data}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div>
    </div>
  );
};

export default TopPromotedProduct;
