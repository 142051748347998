import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import HeaderComponent from "../../componentsShared/Header";
import '../layoutStyles/mainLayout.scss';

export default function MainLayout(props) {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history.location.pathname]);

    return (
        <div className="route-content-wp">
            <HeaderComponent />
            <div className="app-content">
                <div className="app-inner-content-wp">
                    {props.children}
                </div>
            </div>
        </div>
    );
}