import React, { View, useState } from "react";
// import styles from "../assets/css/App.css";
import { Helmet } from "react-helmet";
import {
  Card,
  Tabs,
  Menu,
  Dropdown,
  Drawer,
  DatePicker,
  Form,
  Button,
  Layout,
  Collapse,
  Divider,
  Table,
  Space,
  Select,
  Checkbox,
  Tag,
  Progress,
} from "antd";
import { Row, Col } from "antd";
import {
  DownOutlined,
  MailOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FilterOutlined,
  DownloadOutlined,ArrowUpOutlined,ArrowDownOutlined,StarOutlined
} from "@ant-design/icons";
import moment from "moment";
import { Line, Column, GroupedColumn } from "@ant-design/charts";
const { Option } = Select;
const { RangePicker } = DatePicker;

const Main = () => {
  const { Header, Footer, Content } = Layout;
  const { TabPane } = Tabs;
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a rel="noopener noreferrer" href="/logout">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  function callback(key) {
    console.log(key);
  }

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const [visible, setVisible] = useState(false);

  const { Panel } = Collapse;

  const style = {
    maincontent: {
      backgroundColor: "#ffffff",
      borderRadius: 10,
      padding: 20,
      margin: 10,
      boxShadow: "1px 1px 5px 5px #eee",
    },
  };

  const dataSource = [
    {
      key: "1",
      region: "Central",
      actual: "89% (5/10)",
      last: "+5%",
    },
    {
      key: "2",
      region: "South",
      actual: "89% (5/10)",
      last: "+5%",
    },
    {
      key: "3",
      region: "North",
      actual: "89% (5/10)",
      last: "+5%",
    },
    {
      key: "4",
      region: "East",
      actual: "89% (5/10)",
      last: "+5%",
    },
  ];

  const columns = [
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "Actual Vs Plan ( %, Stores)",
      dataIndex: "actual",
      key: "actual",
    },
    {
      title: "Vs Last Period (%)",
      dataIndex: "last",
      key: "last",
    },
  ];

  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  const data = [
    { Month: "W1", "Shelf Price": 13, category: "Kelloggs" },
    { Month: "W2", "Shelf Price": 14, category: "Kelloggs" },
    { Month: "W3", "Shelf Price": 15, category: "Kelloggs" },
    { Month: "W4", "Shelf Price": 15, category: "Kelloggs" },
    {
      Month: "W5",
      "Shelf Price": 12,
      category: "Kelloggs",
    },
    { Month: "W6", "Shelf Price": 6, category: "Kelloggs" },
    { Month: "W7", "Shelf Price": 7, category: "Kelloggs" },
    { Month: "W7", "Shelf Price": 9, category: "Kelloggs" },
    {
      Month: "W9",
      "Shelf Price": 113,
      category: "Kelloggs",
    },
    {
      Month: "W10",
      "Shelf Price": 80,
      category: "Kelloggs",
    },
    {
      Month: "W11",
      "Shelf Price": 70,
      category: "Kelloggs",
    },
    {
      Month: "W12",
      "Shelf Price": 174,
      category: "Kelloggs",
    },
    { Month: "W13", "Shelf Price": 3, category: "Kelloggs" },
    { Month: "W14", "Shelf Price": 4, category: "Kelloggs" },
    { Month: "W15", "Shelf Price": 5, category: "Kelloggs" },
    { Month: "W16", "Shelf Price": 5, category: "Kelloggs" },
    {
      Month: "W17",
      "Shelf Price": 112,
      category: "Kelloggs",
    },
    { Month: "W18", "Shelf Price": 6, category: "Kelloggs" },
    { Month: "W19", "Shelf Price": 7, category: "Kelloggs" },
    { Month: "W20", "Shelf Price": 9, category: "Kelloggs" },
    {
      Month: "W21",
      "Shelf Price": 113,
      category: "Kelloggs",
    },
    {
      Month: "W22",
      "Shelf Price": 140,
      category: "Kelloggs",
    },
    {
      Month: "W23",
      "Shelf Price": 130,
      category: "Kelloggs",
    },
    {
      Month: "W24",
      "Shelf Price": 174,
      category: "Kelloggs",
    },
     { Month: "W1", "Shelf Price": 3, category: "Nestle" },
    { Month: "W2", "Shelf Price": 4, category: "Nestle" },
    { Month: "W3", "Shelf Price": 5, category: "Nestle" },
    { Month: "W4", "Shelf Price": 5, category: "Nestle" },
    {
      Month: "W5",
      "Shelf Price": 12,
      category: "Nestle",
    },
    { Month: "W6", "Shelf Price": 6, category: "Nestle" },
    { Month: "W7", "Shelf Price": 7, category: "Nestle" },
    { Month: "W7", "Shelf Price": 9, category: "Nestle" },
    {
      Month: "W9",
      "Shelf Price": 13,
      category: "Nestle",
    },
    {
      Month: "W10",
      "Shelf Price": 40,
      category: "Nestle",
    },
    {
      Month: "W11",
      "Shelf Price": 30,
      category: "Nestle",
    },
    {
      Month: "W12",
      "Shelf Price": 74,
      category: "Nestle",
    },
    { Month: "W13", "Shelf Price": 3, category: "Nestle" },
    { Month: "W14", "Shelf Price": 4, category: "Nestle" },
    { Month: "W15", "Shelf Price": 5, category: "Nestle" },
    { Month: "W16", "Shelf Price": 5, category: "Nestle" },
    {
      Month: "W17",
      "Shelf Price": 12,
      category: "Nestle",
    },
    { Month: "W18", "Shelf Price": 6, category: "Nestle" },
    { Month: "W19", "Shelf Price": 7, category: "Nestle" },
    { Month: "W20", "Shelf Price": 9, category: "Nestle" },
    {
      Month: "W21",
      "Shelf Price": 13,
      category: "Nestle",
    },
    {
      Month: "W22",
      "Shelf Price": 40,
      category: "Nestle",
    },
    {
      Month: "W23",
      "Shelf Price": 30,
      category: "Nestle",
    },
    {
      Month: "W24",
      "Shelf Price": 74,
      category: "Nestle",
    },
  ];
 
  const config = {
    data,
    title: {
      visible: true,
      text: "Shelf Price",
      style: {
        fontSize: 12,
        fontWeight:"bold"
      },
    },
    legend: {
      position: "top",
      visible: true,
    },
    xField: "Month",
    yField: "Shelf Price",
    seriesField: "category",
    //smooth: "true",
    responsive: true,
    color: ["#0f4c75", "#D62A0D", "#FAA219", "#3282b8"],
    label: {
      visible: true,
      style: {
        fill: "#000",
        fontSize: 12,
        fontWeight: 600,
        //opacity: 0.6,
      },
    },
  };

    const data_sa = [
    { Month: "Jan", "# of stores audited": 3, category: "# of stores audited" },
    { Month: "Feb", "# of stores audited": 4, category: "# of stores audited" },
    { Month: "Mar", "# of stores audited": 5, category: "# of stores audited" },
    { Month: "Apr", "# of stores audited": 5, category: "# of stores audited" },
    {
      Month: "May",
      "# of stores audited": 12,
      category: "# of stores audited",
    },
    { Month: "Jun", "# of stores audited": 6, category: "# of stores audited" },
    { Month: "Jul", "# of stores audited": 7, category: "# of stores audited" },
    { Month: "Aug", "# of stores audited": 9, category: "# of stores audited" },
    {
      Month: "Sep",
      "# of stores audited": 13,
      category: "# of stores audited",
    },
    {
      Month: "Oct",
      "# of stores audited": 40,
      category: "# of stores audited",
    },
    {
      Month: "Nov",
      "# of stores audited": 30,
      category: "# of stores audited",
    },
    {
      Month: "Dec",
      "# of stores audited": 74,
      category: "# of stores audited",
    },
 
  ];
 
  const config_sa = {
    data: data_sa,
    title: {
      visible: false,
      text: "Promotion Frequency",
      style: {
        fontSize: 15,
      },
    },
    legend: {
      position: "top",
      visible: true,
    },
    xField: "Month",
    yField: "# of stores audited",
    seriesField: "category",
    smooth: "false",
    responsive: true,
    color: ["#0f4c75"],
    label: {
      visible: true,
      style: {
        fill: "#000",
        fontSize: 12,
        fontWeight: 600,
        //opacity: 0.6,
      },
    },
  };

 const data_m = [
   { Month: "Jan", "Shelf Price": 3, category: "MSRP" },
   { Month: "Feb", "Shelf Price": 4, category: "MSRP" },
   { Month: "Mar", "Shelf Price": 5, category: "MSRP" },
   { Month: "Apr", "Shelf Price": 5, category: "MSRP" },
   {
     Month: "May",
     "Shelf Price": 12,
     category: "MSRP",
   },
   { Month: "Jun", "Shelf Price": 6, category: "MSRP" },
   { Month: "Jul", "Shelf Price": 7, category: "MSRP" },
   { Month: "Aug", "Shelf Price": 9, category: "MSRP" },
   {
     Month: "Sep",
     "Shelf Price": 13,
     category: "MSRP",
   },
   {
     Month: "Oct",
     "Shelf Price": 40,
     category: "MSRP",
   },
   {
     Month: "Nov",
     "Shelf Price": 30,
     category: "MSRP",
   },
   {
     Month: "Dec",
     "Shelf Price": 74,
     category: "MSRP",
   },
   { Month: "Jan", "Shelf Price": 13, category: "Min Price" },
   { Month: "Feb", "Shelf Price": 14, category: "Min Price" },
   { Month: "Mar", "Shelf Price": 15, category: "Min Price" },
   { Month: "Apr", "Shelf Price": 15, category: "Min Price" },
   {
     Month: "May",
     "Shelf Price": 112,
     category: "Min Price",
   },
   { Month: "Jun", "Shelf Price": 16, category: "Min Price" },
   { Month: "Jul", "Shelf Price": 17, category: "Min Price" },
   { Month: "Aug", "Shelf Price": 19, category: "Min Price" },
   {
     Month: "Sep",
     "Shelf Price": 113,
     category: "Min Price",
   },
   {
     Month: "Oct",
     "Shelf Price": 140,
     category: "Min Price",
   },
   {
     Month: "Nov",
     "Shelf Price": 130,
     category: "Min Price",
   },
   {
     Month: "Dec",
     "Shelf Price": 174,
     category: "Min Price",
   },
   { Month: "Jan", "Shelf Price": 23, category: "Max Price" },
   { Month: "Feb", "Shelf Price": 24, category: "Max Price" },
   { Month: "Mar", "Shelf Price": 25, category: "Max Price" },
   { Month: "Apr", "Shelf Price": 25, category: "Max Price" },
   {
     Month: "May",
     "Shelf Price": 212,
     category: "Max Price",
   },
   { Month: "Jun", "Shelf Price": 26, category: "Max Price" },
   { Month: "Jul", "Shelf Price": 27, category: "Max Price" },
   { Month: "Aug", "Shelf Price": 29, category: "Max Price" },
   {
     Month: "Sep",
     "Shelf Price": 213,
     category: "Max Price",
   },
   {
     Month: "Oct",
     "Shelf Price": 240,
     category: "Max Price",
   },
   {
     Month: "Nov",
     "Shelf Price": 230,
     category: "Max Price",
   },
   {
     Month: "Dec",
     "Shelf Price": 274,
     category: "Max Price",
   },
 ];

const config_m = {
    data: data_m,
    title: {
      visible: true,
      text: "Shelf Price",
      style: {
        fontSize: 12,
        fontWeight:"bold"
      },
    },
    legend: {
      position: "top",
      visible: true,
    },
    xField: "Month",
    yField: "Shelf Price",
    seriesField: "category",
    //smooth: "false",
    responsive: true,
   color: ["#0f4c75", "#D62A0D", "#FAA219", "#3282b8"],
    label: {
      visible: true,
      style: {
        fill: "#000",
        fontSize: 12,
        fontWeight: 600,
        //opacity: 0.6,
      },
    },
  };

  const data_group_1 = [
    {
      name: "1 facings",
      Month: "Jan",
      "# of stores": 18,
    },
    {
      name: "1 facings",
      Month: "Feb",
      "# of stores": 28,
    },
    {
      name: "1 facings",
      Month: "Mar",
      "# of stores": 39,
    },
    {
      name: "1 facings",
      Month: "Apr",
      "# of stores": 81,
    },
    {
      name: "1 facings",
      Month: "May",
      "# of stores": 47,
    },
    {
      name: "1 facings",
      Month: "Jun",
      "# of stores": 20,
    },
    {
      name: "1 facings",
      Month: "Jul",
      "# of stores": 24,
    },
    {
      name: "1 facings",
      Month: "Aug",
      "# of stores": 35,
    },
    {
      name: "1 facings",
      Month: "Sep",
      "# of stores": 45,
    },
    {
      name: "1 facings",
      Month: "Oct",
      "# of stores": 56,
    },
    {
      name: "1 facings",
      Month: "Nov",
      "# of stores": 36,
    },
    {
      name: "1 facings",
      Month: "Dec",
      "# of stores": 25,
    },
    {
      name: "2 facings",
      Month: "Jan",
      "# of stores": 12,
    },
    {
      name: "2 facings",
      Month: "Feb",
      "# of stores": 23,
    },
    {
      name: "2 facings",
      Month: "Mar",
      "# of stores": 39,
    },
    {
      name: "2 facings",
      Month: "Apr",
      "# of stores": 99,
    },
    {
      name: "2 facings",
      Month: "May",
      "# of stores": 52,
    },
    {
      name: "2 facings",
      Month: "Jun",
      "# of stores": 35,
    },
    {
      name: "2 facings",
      Month: "Jul",
      "# of stores": 37,
    },
    {
      name: "2 facings",
      Month: "Aug",
      "# of stores": 42,
    },
    {
      name: "2 facings",
      Month: "Sep",
      "# of stores": 35,
    },
    {
      name: "2 facings",
      Month: "Oct",
      "# of stores": 37,
    },
    {
      name: "2 facings",
      Month: "Nov",
      "# of stores": 42,
    },
    {
      name: "2 facings",
      Month: "Dec",
      "# of stores": 19,
    },
    {
      name: "3 facings",
      Month: "Jan",
      "# of stores": 18,
    },
    {
      name: "3 facings",
      Month: "Feb",
      "# of stores": 28,
    },
    {
      name: "3 facings",
      Month: "Mar",
      "# of stores": 39,
    },
    {
      name: "3 facings",
      Month: "Apr",
      "# of stores": 81,
    },
    {
      name: "3 facings",
      Month: "May",
      "# of stores": 47,
    },
    {
      name: "3 facings",
      Month: "Jun",
      "# of stores": 20,
    },
    {
      name: "3 facings",
      Month: "Jul",
      "# of stores": 24,
    },
    {
      name: "3 facings",
      Month: "Aug",
      "# of stores": 35,
    },
    {
      name: "3 facings",
      Month: "Sep",
      "# of stores": 45,
    },
    {
      name: "3 facings",
      Month: "Oct",
      "# of stores": 56,
    },
    {
      name: "3 facings",
      Month: "Nov",
      "# of stores": 36,
    },
    {
      name: "3 facings",
      Month: "Dec",
      "# of stores": 25,
    },
    {
      name: "4+ facings",
      Month: "Jan",
      "# of stores": 12,
    },
    {
      name: "4+ facings",
      Month: "Feb",
      "# of stores": 23,
    },
    {
      name: "4+ facings",
      Month: "Mar",
      "# of stores": 39,
    },
    {
      name: "4+ facings",
      Month: "Apr",
      "# of stores": 99,
    },
    {
      name: "4+ facings",
      Month: "May",
      "# of stores": 52,
    },
    {
      name: "4+ facings",
      Month: "Jun",
      "# of stores": 35,
    },
    {
      name: "4+ facings",
      Month: "Jul",
      "# of stores": 37,
    },
    {
      name: "4+ facings",
      Month: "Aug",
      "# of stores": 42,
    },
    {
      name: "4+ facings",
      Month: "Sep",
      "# of stores": 35,
    },
    {
      name: "4+ facings",
      Month: "Oct",
      "# of stores": 37,
    },
    {
      name: "4+ facings",
      Month: "Nov",
      "# of stores": 42,
    },
    {
      name: "4+ facings",
      Month: "Dec",
      "# of stores": 19,
    },
  ];
  const config_group_1 = {
    title: {
      visible: false,
      text: "# of Stores by Facings",
    },
    forceFit: true,
    data: data_group_1,
    xField: "Month",
    yField: "# of stores",
    yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    color: ["#1b262c", "#0f4c75", "#3282b8", "#bbe1fa"],
  };

  const data_group_2 = [
    {
      name: "Retailer A",
      Month: "Jan",
      "# of Facings": 1,
    },
    {
      name: "Retailer A",
      Month: "Feb",
      "# of Facings": 2,
    },
    {
      name: "Retailer A",
      Month: "Mar",
      "# of Facings": 9,
    },
    {
      name: "Retailer A",
      Month: "Apr",
      "# of Facings": 8,
    },
    {
      name: "Retailer A",
      Month: "May",
      "# of Facings": 7,
    },
    {
      name: "Retailer A",
      Month: "Jun",
      "# of Facings": 2,
    },
    {
      name: "Retailer A",
      Month: "Jul",
      "# of Facings": 9,
    },
    {
      name: "Retailer A",
      Month: "Aug",
      "# of Facings": 5,
    },
    {
      name: "Retailer A",
      Month: "Sep",
      "# of Facings": 4,
    },
    {
      name: "Retailer A",
      Month: "Oct",
      "# of Facings": 5,
    },
    {
      name: "Retailer A",
      Month: "Nov",
      "# of Facings": 6,
    },
    {
      name: "Retailer A",
      Month: "Dec",
      "# of Facings": 5,
    },
    {
      name: "Retailer B",
      Month: "Jan",
      "# of Facings": 12,
    },
    {
      name: "Retailer B",
      Month: "Feb",
      "# of Facings": 2,
    },
    {
      name: "Retailer B",
      Month: "Mar",
      "# of Facings": 3,
    },
    {
      name: "Retailer B",
      Month: "Apr",
      "# of Facings": 9,
    },
    {
      name: "Retailer B",
      Month: "May",
      "# of Facings": 12,
    },
    {
      name: "Retailer B",
      Month: "Jun",
      "# of Facings": 3,
    },
    {
      name: "Retailer B",
      Month: "Jul",
      "# of Facings": 7,
    },
    {
      name: "Retailer B",
      Month: "Aug",
      "# of Facings": 4,
    },
    {
      name: "Retailer B",
      Month: "Sep",
      "# of Facings": 3,
    },
    {
      name: "Retailer B",
      Month: "Oct",
      "# of Facings": 7,
    },
    {
      name: "Retailer B",
      Month: "Nov",
      "# of Facings": 4,
    },
    {
      name: "Retailer B",
      Month: "Dec",
      "# of Facings": 9,
    },
    {
      name: "Retailer C",
      Month: "Jan",
      "# of Facings": 1,
    },
    {
      name: "Retailer C",
      Month: "Feb",
      "# of Facings": 2,
    },
    {
      name: "Retailer C",
      Month: "Mar",
      "# of Facings": 9,
    },
    {
      name: "Retailer C",
      Month: "Apr",
      "# of Facings": 8,
    },
    {
      name: "Retailer C",
      Month: "May",
      "# of Facings": 4,
    },
    {
      name: "Retailer C",
      Month: "Jun",
      "# of Facings": 3,
    },
    {
      name: "Retailer C",
      Month: "Jul",
      "# of Facings": 8,
    },
    {
      name: "Retailer C",
      Month: "Aug",
      "# of Facings": 5,
    },
    {
      name: "Retailer C",
      Month: "Sep",
      "# of Facings": 4,
    },
    {
      name: "Retailer C",
      Month: "Oct",
      "# of Facings": 5,
    },
    {
      name: "Retailer C",
      Month: "Nov",
      "# of Facings": 6,
    },
    {
      name: "Retailer C",
      Month: "Dec",
      "# of Facings": 5,
    },
    {
      name: "Retailer D",
      Month: "Jan",
      "# of Facings": 12,
    },
    {
      name: "Retailer D",
      Month: "Feb",
      "# of Facings": 2,
    },
    {
      name: "Retailer D",
      Month: "Mar",
      "# of Facings": 3,
    },
    {
       name: "Retailer D",
      Month: "Apr",
      "# of Facings": 6,
    },
    {
       name: "Retailer D",
      Month: "May",
      "# of Facings": 5,
    },
    {
       name: "Retailer D",
      Month: "Jun",
      "# of Facings": 3,
    },
    {
      name: "Retailer D",
      Month: "Jul",
      "# of Facings": 3,
    },
    {
       name: "Retailer D",
      Month: "Aug",
      "# of Facings": 2,
    },
    {
       name: "Retailer D",
      Month: "Sep",
      "# of Facings": 3,
    },
    {
       name: "Retailer D",
      Month: "Oct",
      "# of Facings": 1,
    },
    {
       name: "Retailer D",
      Month: "Nov",
      "# of Facings": 2,
    },
    {
       name: "Retailer D",
      Month: "Dec",
      "# of Facings": 4,
    },
  ];

  const config_group_2 = {
    title: {
      visible: false,
      text: "# of Stores by Facings",
    },
    forceFit: true,
    data: data_group_2,
    xField: "Month",
    yField: "# of Facings",
    yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    color: ["#1b262c", "#0f4c75", "#3282b8", "#bbe1fa"],
  };

  const data_group_3 = [
    {
      name: "Brand A",
      Month: "Jan",
      "# of Facings": 18,
    },
    {
      name: "Brand A",
      Month: "Feb",
      "# of Facings": 28,
    },
    {
      name: "Brand A",
      Month: "Mar",
      "# of Facings": 39,
    },
    {
      name: "Brand A",
      Month: "Apr",
      "# of Facings": 81,
    },
    {
      name: "Brand A",
      Month: "May",
      "# of Facings": 47,
    },
    {
      name: "Brand A",
      Month: "Jun",
      "# of Facings": 20,
    },
    {
      name: "Brand A",
      Month: "Jul",
      "# of Facings": 24,
    },
    {
      name: "Brand A",
      Month: "Aug",
      "# of Facings": 35,
    },
    {
      name: "Brand A",
      Month: "Sep",
      "# of Facings": 45,
    },
    {
      name: "Brand A",
      Month: "Oct",
      "# of Facings": 56,
    },
    {
      name: "Brand A",
      Month: "Nov",
      "# of Facings": 36,
    },
    {
      name: "Brand A",
      Month: "Dec",
      "# of Facings": 25,
    },
    {
      name: "Brand B",
      Month: "Jan",
      "# of Facings": 12,
    },
    {
      name: "Brand B",
      Month: "Feb",
      "# of Facings": 23,
    },
    {
      name: "Brand B",
      Month: "Mar",
      "# of Facings": 39,
    },
    {
      name: "Brand B",
      Month: "Apr",
      "# of Facings": 99,
    },
    {
      name: "Brand B",
      Month: "May",
      "# of Facings": 52,
    },
    {
      name: "Brand B",
      Month: "Jun",
      "# of Facings": 35,
    },
    {
      name: "Brand B",
      Month: "Jul",
      "# of Facings": 37,
    },
    {
      name: "Brand B",
      Month: "Aug",
      "# of Facings": 42,
    },
    {
      name: "Brand B",
      Month: "Sep",
      "# of Facings": 35,
    },
    {
      name: "Brand B",
      Month: "Oct",
      "# of Facings": 37,
    },
    {
      name: "Brand B",
      Month: "Nov",
      "# of Facings": 42,
    },
    {
      name: "Brand B",
      Month: "Dec",
      "# of Facings": 19,
    },
    {
      name: "Brand C",
      Month: "Jan",
      "# of Facings": 18,
    },
    {
      name: "Brand C",
      Month: "Feb",
      "# of Facings": 28,
    },
    {
      name: "Brand C",
      Month: "Mar",
      "# of Facings": 39,
    },
    {
      name: "Brand C",
      Month: "Apr",
      "# of Facings": 81,
    },
    {
      name: "Brand C",
      Month: "May",
      "# of Facings": 47,
    },
    {
      name: "Brand C",
      Month: "Jun",
      "# of Facings": 20,
    },
    {
      name: "Brand C",
      Month: "Jul",
      "# of Facings": 24,
    },
    {
      name: "Brand C",
      Month: "Aug",
      "# of Facings": 35,
    },
    {
      name: "Brand C",
      Month: "Sep",
      "# of Facings": 45,
    },
    {
      name: "Brand C",
      Month: "Oct",
      "# of Facings": 56,
    },
    {
      name: "Brand C",
      Month: "Nov",
      "# of Facings": 36,
    },
    {
      name: "Brand C",
      Month: "Dec",
      "# of Facings": 25,
    },
    {
      name: "Brand D",
      Month: "Jan",
      "# of Facings": 12,
    },
    {
      name: "Brand D",
      Month: "Feb",
      "# of Facings": 23,
    },
    {
      name: "Brand D",
      Month: "Mar",
      "# of Facings": 39,
    },
    {
       name: "Brand D",
      Month: "Apr",
      "# of Facings": 99,
    },
    {
       name: "Brand D",
      Month: "May",
      "# of Facings": 52,
    },
    {
       name: "Brand D",
      Month: "Jun",
      "# of Facings": 35,
    },
    {
      name: "Brand D",
      Month: "Jul",
      "# of Facings": 37,
    },
    {
       name: "Brand D",
      Month: "Aug",
      "# of Facings": 42,
    },
    {
       name: "Brand D",
      Month: "Sep",
      "# of Facings": 35,
    },
    {
       name: "Brand D",
      Month: "Oct",
      "# of Facings": 37,
    },
    {
       name: "Brand D",
      Month: "Nov",
      "# of Facings": 42,
    },
    {
       name: "Brand D",
      Month: "Dec",
      "# of Facings": 19,
    },
  ];

  const config_group_3 = {
    title: {
      visible: false,
      text: "# of Stores by Facings",
    },
    forceFit: true,
    data: data_group_3,
    xField: "Month",
    yField: "# of Facings",
    yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    color: ["#1b262c", "#0f4c75", "#3282b8", "#bbe1fa"],
  };

  const data_group_4 = [
    {
      name: "Nestle",
      Month: "Jan",
      "OSA(%)": 18,
    },
    {
      name: "Nestle",
      Month: "Feb",
      "OSA(%)": 28,
    },
    {
      name: "Nestle",
      Month: "Mar",
      "OSA(%)": 39,
    },
    {
      name: "Nestle",
      Month: "Apr",
      "OSA(%)": 81,
    },
    {
      name: "Nestle",
      Month: "May",
      "OSA(%)": 47,
    },
    {
      name: "Nestle",
      Month: "Jun",
      "OSA(%)": 20,
    },
    {
      name: "Nestle",
      Month: "Jul",
      "OSA(%)": 24,
    },
    {
      name: "Nestle",
      Month: "Aug",
      "OSA(%)": 35,
    },
    {
      name: "Nestle",
      Month: "Sep",
      "OSA(%)": 45,
    },
    {
      name: "Nestle",
      Month: "Oct",
      "OSA(%)": 56,
    },
    {
      name: "Nestle",
      Month: "Nov",
      "OSA(%)": 36,
    },
    {
      name: "Nestle",
      Month: "Dec",
      "OSA(%)": 25,
    },
    {
      name: "Kelloggs",
      Month: "Jan",
      "OSA(%)": 12,
    },
    {
      name: "Kelloggs",
      Month: "Feb",
      "OSA(%)": 23,
    },
    {
      name: "Kelloggs",
      Month: "Mar",
      "OSA(%)": 39,
    },
    {
      name: "Kelloggs",
      Month: "Apr",
      "OSA(%)": 99,
    },
    {
      name: "Kelloggs",
      Month: "May",
      "OSA(%)": 52,
    },
    {
      name: "Kelloggs",
      Month: "Jun",
      "OSA(%)": 35,
    },
    {
      name: "Kelloggs",
      Month: "Jul",
      "OSA(%)": 37,
    },
    {
      name: "Kelloggs",
      Month: "Aug",
      "OSA(%)": 42,
    },
    {
      name: "Kelloggs",
      Month: "Sep",
      "OSA(%)": 35,
    },
    {
      name: "Kelloggs",
      Month: "Oct",
      "OSA(%)": 37,
    },
    {
      name: "Kelloggs",
      Month: "Nov",
      "OSA(%)": 42,
    },
    {
      name: "Kelloggs",
      Month: "Dec",
      "OSA(%)": 19,
    },
  ];

  const config_group_4 = {
    title: {
      visible: false,
      text: "# of Stores by Facings",
    },
    forceFit: true,
    data: data_group_4,
    xField: "Month",
    yField: "OSA(%)",
    yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    color: ["#1b262c", "#0f4c75", "#3282b8", "#bbe1fa"],
  };

const data_group_5 = [
    {
      name: "Nestle",
      Month: "Jan",
      "FSOS": 18,
    },
    {
      name: "Nestle",
      Month: "Feb",
      "FSOS": 28,
    },
    {
      name: "Nestle",
      Month: "Mar",
      "FOS": 39,
    },
    {
      name: "Nestle",
      Month: "Apr",
      "FSOS": 81,
    },
    {
      name: "Nestle",
      Month: "May",
      "FSOS": 47,
    },
    {
      name: "Nestle",
      Month: "Jun",
      "FSOS": 20,
    },
    {
      name: "Nestle",
      Month: "Jul",
      "FSOS": 24,
    },
    {
      name: "Nestle",
      Month: "Aug",
      "FSOS": 35,
    },
    {
      name: "Nestle",
      Month: "Sep",
      "FSOS": 45,
    },
    {
      name: "Nestle",
      Month: "Oct",
      "FSOS": 56,
    },
    {
      name: "Nestle",
      Month: "Nov",
      "FSOS": 36,
    },
    {
      name: "Nestle",
      Month: "Dec",
      "FSOS": 25,
    },
    {
      name: "Kelloggs",
      Month: "Jan",
      "FSOS": 12,
    },
    {
      name: "Kelloggs",
      Month: "Feb",
      "FSOS": 23,
    },
    {
      name: "Kelloggs",
      Month: "Mar",
      "FSOS": 39,
    },
    {
      name: "Kelloggs",
      Month: "Apr",
      "FSOS": 99,
    },
    {
      name: "Kelloggs",
      Month: "May",
      "FSOS": 52,
    },
    {
      name: "Kelloggs",
      Month: "Jun",
      "FSOS": 35,
    },
    {
      name: "Kelloggs",
      Month: "Jul",
      "FSOS": 37,
    },
    {
      name: "Kelloggs",
      Month: "Aug",
      "FSOS": 42,
    },
    {
      name: "Kelloggs",
      Month: "Sep",
      "FSOS": 35,
    },
    {
      name: "Kelloggs",
      Month: "Oct",
      "FSOS": 37,
    },
    {
      name: "Kelloggs",
      Month: "Nov",
      "FSOS": 42,
    },
    {
      name: "Kelloggs",
      Month: "Dec",
      "FSOS": 19,
    },
  ];

  const config_group_5 = {
    title: {
      visible: false,
      text: "# of Stores by Facings",
    },
    forceFit: true,
    data: data_group_5,
    xField: "Month",
    yField: "FSOS",
    yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    color: ["#1b262c", "#0f4c75", "#3282b8", "#bbe1fa"],
  };

const data_group_6 = [
    {
      name: "Nestle",
      Month: "Jan",
      "SOS(%)": 18,
    },
    {
      name: "Nestle",
      Month: "Feb",
      "SOS(%)": 28,
    },
    {
      name: "Nestle",
      Month: "Mar",
      "SOS(%)": 39,
    },
    {
      name: "Nestle",
      Month: "Apr",
      "SOS(%)": 81,
    },
    {
      name: "Nestle",
      Month: "May",
      "SOS(%)": 47,
    },
    {
      name: "Nestle",
      Month: "Jun",
      "SOS(%)": 20,
    },
    {
      name: "Nestle",
      Month: "Jul",
      "SOS(%)": 24,
    },
    {
      name: "Nestle",
      Month: "Aug",
      "SOS(%)": 35,
    },
    {
      name: "Nestle",
      Month: "Sep",
      "SOS(%)": 45,
    },
    {
      name: "Nestle",
      Month: "Oct",
      "SOS(%)": 56,
    },
    {
      name: "Nestle",
      Month: "Nov",
      "SOS(%)": 36,
    },
    {
      name: "Nestle",
      Month: "Dec",
      "SOS(%)": 25,
    },
    {
      name: "Kelloggs",
      Month: "Jan",
      "SOS(%)": 12,
    },
    {
      name: "Kelloggs",
      Month: "Feb",
      "SOS(%)": 23,
    },
    {
      name: "Kelloggs",
      Month: "Mar",
      "SOS(%)": 39,
    },
    {
      name: "Kelloggs",
      Month: "Apr",
      "SOS(%)": 99,
    },
    {
      name: "Kelloggs",
      Month: "May",
      "SOS(%)": 52,
    },
    {
      name: "Kelloggs",
      Month: "Jun",
      "SOS(%)": 35,
    },
    {
      name: "Kelloggs",
      Month: "Jul",
      "SOS(%)": 37,
    },
    {
      name: "Kelloggs",
      Month: "Aug",
      "SOS(%)": 42,
    },
    {
      name: "Kelloggs",
      Month: "Sep",
      "SOS(%)": 35,
    },
    {
      name: "Kelloggs",
      Month: "Oct",
      "SOS(%)": 37,
    },
    {
      name: "Kelloggs",
      Month: "Nov",
      "SOS(%)": 42,
    },
    {
      name: "Kelloggs",
      Month: "Dec",
      "SOS(%)": 19,
    },
  ];

  const config_group_6 = {
    title: {
      visible: false,
      text: "# of Stores by Facings",
    },
    forceFit: true,
    data: data_group_6,
    xField: "Month",
    yField: "SOS(%)",
    yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    color: ["#1b262c", "#0f4c75", "#3282b8", "#bbe1fa"],
  };

  return (
    <Layout>
      <Drawer
        title="Filters"
        placement="left"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={350}
        footer={
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Button style={{ width: "100%" }} onClick={onClose} type="primary">
              Apply
            </Button>
          </div>
        }
      >
        <Collapse ghost onChange={callback} expandIconPosition="right">
          <Panel header="Country" key="1">
            <Select
              showSearch
              style={{ width: "100%" }}
              showArrow={true}
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Hongkong">Hongkong</Option>
              <Option value="Malaysia">Malaysia</Option>
              <Option value="Singapore">Singapore</Option>
            </Select>
          </Panel>
          <Panel header="Region" key="2">
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Central
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              South
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              North
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              East
            </Checkbox>
          </Panel>
          <Panel header="Channel" key="3">
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Channel 1
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Channel 2
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Channel 3
            </Checkbox>
          </Panel>
          <Panel header="Manufacturer" key="4"></Panel>
          <Panel header="Retailer" key="5">
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Retailer 1
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Retailer 2
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Retailer 3
            </Checkbox>
          </Panel>
          <Panel header="Store" key="6">
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              FairPrice Xtra Jurong Point
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              FairPrice Xtra Ang Mo Kio Hub
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              FairPrice Finest Bukit Timah Plaza
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              FairPrice Finest Waterway Point
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              FairPrice Eastpoint
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Sheng Siong Tanglin Halt BLK 88
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Sheng Siong Clementi 720
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Giant Hypermarket - Tampines
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Giant Hyper - IMM
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Cold Storage Great World City
            </Checkbox>
          </Panel>
          <Panel header="Sales rep" key="7">
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Adrian Kang
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Gary Chee
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Hazel Chen
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              Vickki Tan
            </Checkbox>
            <br />
            <Checkbox
              onChange={onChange}
              style={{ padding: 10, paddingLeft: 0, paddingTop: 0 }}
            >
              George Tan
            </Checkbox>
          </Panel>
          <Panel header="Product Category" key="8"></Panel>
          <Panel header="Sub-Category" key="9"></Panel>
          <Panel header="Brand" key="10"></Panel>
          <Panel header="Pack Size" key="11"></Panel>
          <Panel header="Flavour" key="12"></Panel>
          <Panel header="Product" key="13"></Panel>
          <Panel header="Promotion" key="14"></Panel>
          <Panel header="Placement Type" key="15"></Panel>
        </Collapse>
      </Drawer>
      <Helmet>
        <title>MilkywayAI - Dashboard</title>
      </Helmet>
      <Header>
        <Row>
          <Col span={12}>
            <div className="logo">
              <img
                style={{ width: "96px" }}
                src={require("../assets/img/logo.png")}
                alt="logo"
              ></img>
              <font style={{marginLeft:30}}>Dashboard</font>
            </div>
          </Col>
          <Col span={9}></Col>
          <Col span={3} style={{float:"right"}}>
            <Dropdown overlay={menu} style={{float:"right"}}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Demo InstaShelf <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row>
          <Col span={24} style={{ padding: 10 }}>
            <Tabs
              centered={false}
              //tabBarGutter={35}
              defaultActiveKey="1"
              onChange={callback}
            >
              <TabPane
                tab={
                  <div style={{ width: window.innerWidth / 6 }}>
                    Team Activity
                  </div>
                }
                key="1"
              >
                <Row style={{ marginTop: 10 }}>
                  <Col span={22}></Col>
                  <Col span={2}>
                    <div style={{ float: "right", marginRight: 10 }}>
                      <MailOutlined style={{ fontSize: "16px" }} />
                      <FilePdfOutlined
                        style={{ marginLeft: 10, fontSize: "16px" }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Overview
                    </font>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      <Space size={"large"}>
                        <div>
                          <font
                            style={{
                              color: "#391EAC",
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            MTD
                          </font>
                        </div>
                        <div>QTD</div>
                        <div>YTD</div>
                      </Space>
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingTop: 10,
                    paddingLeft: 10,
                  }}
                >
                  <Col span={24}>
                    <Tag color="processing" closable>
                      Singapore
                    </Tag>
                    <Tag color="processing" closable>
                      South
                    </Tag>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col
                          span={6}
                          style={{ textAlign: "center", padding: 5 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#4B5DE8",
                            }}
                          >
                            Total Audits Completed
                          </font>
                          <br /> <br />
                          <Progress
                            strokeColor={{
                              "100%": "#E20D0D",
                            }}
                            type="circle"
                            percent={30}
                            width={100}
                          />
                          <br /> <br />
                          9/10 Store Audits Completed
                          <br />
                          <font style={{ color: "#09BF3C" }}>
                            +10% vs last week
                          </font>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={17}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <Row>
                            <Col span={20}>
                              <font
                                style={{
                                  color: "#4B5DE8",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              >
                                Audits Completed by Region
                              </font>
                            </Col>
                            <Col span={4}>
                              <div style={{ float: "right", marginRight: 15 }}>
                                <FileExcelOutlined
                                  style={{ fontSize: "16px" }}
                                />
                                <FilterOutlined
                                  onClick={showDrawer}
                                  style={{ fontSize: "16px", marginLeft: 10 }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Region</Col>
                            <Col span={8}>Actual Vs Plan ( %, Stores)</Col>
                            <Col span={8}>Vs Last Period (%)</Col>
                          </Row>
                          <Row style={{ padding: 10, paddingLeft: 0 }}>
                            <Col span={8}>Central</Col>
                            <Col span={8}>89% (5/10)</Col>
                            <Col span={8} style={{ color: "#09BF3C" }}>
                              +5%
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>South</Col>
                            <Col span={8}>79% (5/10)</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              -10%
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>North</Col>
                            <Col span={8}>100% (8/10)</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              -10%
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>East</Col>
                            <Col span={8}>99% (9/10)</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              -10%
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={20}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Sales Rep Performance Ranking
                          </font>
                        </Col>
                        <Col span={4}>
                          <div style={{ float: "right", marginRight: 15 }}>
                            <FileExcelOutlined style={{ fontSize: "16px" }} />
                            <FilterOutlined
                              onClick={showDrawer}
                              style={{ fontSize: "16px", marginLeft: 10 }}
                            />
                          </div>
                        </Col>
                      </Row>

                      {/*<Table
                        tableLayout="fixed"
                        pagination={false}
                        bordered={false}
                        dataSource={dataSource}
                        columns={columns}
                        style={{ marginTop: 20 }}
                      />*/}
                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={8}>Sales Rep</Col>
                        <Col span={8}>Actual Vs Plan ( %, Stores)</Col>
                        <Col span={8}>Vs last period ( %)</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Sally</Col>
                        <Col span={8} style={{ color: "#09BF3C" }}>
                          100% ( 10/10)
                        </Col>
                        <Col span={8} style={{ color: "#09BF3C" }}>
                          +5%
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>John</Col>
                        <Col span={8} style={{ color: "#09BF3C" }}>
                          90% ( 9/10)
                        </Col>
                        <Col span={8} style={{ color: "#09BF3C" }}>
                          {" "}
                          +5%
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Michael</Col>
                        <Col span={8} style={{ color: "#09BF3C" }}>
                          80% ( 8/10)
                        </Col>
                        <Col span={8} style={{ color: "#09BF3C" }}>
                          {" "}
                          +5%
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Peter</Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          70% ( 7/10)
                        </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          {" "}
                          -5%
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Ali</Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          70% ( 7/10)
                        </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          {" "}
                          -5%
                        </Col>
                      </Row>
                      <br />
                      <a
                        href="#"
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        View all
                      </a>
                      <br />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={20}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Overdue Store Audits
                          </font>
                        </Col>
                        <Col span={4}>
                          <div style={{ float: "right", marginRight: 15 }}>
                            <FileExcelOutlined style={{ fontSize: "16px" }} />
                            <FilterOutlined
                              onClick={showDrawer}
                              style={{ fontSize: "16px", marginLeft: 10 }}
                            />
                          </div>
                        </Col>
                      </Row>

                      {/*<Table
                        tableLayout="fixed"
                        pagination={false}
                        bordered={false}
                        dataSource={dataSource}
                        columns={columns}
                        style={{ marginTop: 20 }}
                      />*/}
                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={8}>Store Name</Col>
                        <Col span={8}>Sales Reps</Col>
                        <Col span={8}>Overdue by</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Store A</Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          Sally
                        </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          23 days
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Store B </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          John
                        </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          14 days
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Store C</Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          Chester
                        </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          12 days
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Store D</Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          Adrian
                        </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          10 days
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Store E</Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          Li Ming
                        </Col>
                        <Col span={8} style={{ color: "#E20D0D" }}>
                          1 day
                        </Col>
                      </Row>
                      <br />
                      <a
                        href="#"
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        View all
                      </a>
                      <br />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      # of Completed Store Audits, %
                    </font>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingTop: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  <Col span={20}>
                    <Tag color="processing" closable>
                      Sally
                    </Tag>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Year
                      <DatePicker
                        defaultValue={moment(new Date())}
                        style={{
                          marginLeft: 20,
                          fontWeight: "bold",
                          color: "#391EAC",
                        }}
                        onChange={onChange}
                        picker="year"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Column {...config_sa} />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <div style={{ width: window.innerWidth / 6 }}>
                    Store Execution
                  </div>
                }
                key="2"
              >
                <Row style={{ marginTop: 10 }}>
                  <Col span={22}></Col>
                  <Col span={2}>
                    <div style={{ float: "right", marginRight: 10 }}>
                      <MailOutlined style={{ fontSize: "16px" }} />
                      <FilePdfOutlined
                        style={{ fontSize: "16px", marginLeft: 10 }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Overview
                    </font>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      <Space size={"large"}>
                        <div>
                          <font
                            style={{
                              color: "#391EAC",
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            MTD
                          </font>
                        </div>
                        <div>QTD</div>
                        <div>YTD</div>
                      </Space>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={24}>
                    <Tag color="processing" closable>
                      Singapore
                    </Tag>
                    <Tag color="processing" closable>
                      All Kelloggs
                    </Tag>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <Progress
                            strokeColor={{
                              "100%": "#E20D0D",
                            }}
                            type="circle"
                            percent={30}
                            width={70}
                          />
                        </Col>
                        <Col
                          span={5}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "#4B5DE8",
                            }}
                          >
                            Planogram Compliance
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30% vs last week
                          </font>
                        </Col>
                        <Col
                          span={4}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Primary Placement
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30%
                          </font>
                        </Col>
                        <Col
                          span={4}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Secondary Placement
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#09BF3C" }}>
                            70%
                          </font>
                        </Col>
                        <Col
                          span={4}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Space Buy ($)
                          </font>
                          <br />
                          <font style={{ fontSize: 14 }}>$20 Million</font>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={10}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            # of Non-Compliant Stores by Key Retailer
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Retailer</Col>
                            <Col span={8}>%, # of Stores</Col>
                            <Col span={8}>Space Buy ($)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer A</Col>
                            <Col span={8}>5% (5/100)</Col>
                            <Col span={8}>$200,000</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer B</Col>
                            <Col span={8}>19% (19/100)</Col>
                            <Col span={8}>$100,000</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer C</Col>
                            <Col span={8}>20% (20/100)</Col>
                            <Col span={8}>$50,000</Col>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={6}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            # of Non-Compliant Stores by Account
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Region</Col>
                            <Col span={16}>%, # of Stores</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Central</Col>
                            <Col span={16}>5% (5/100)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>South</Col>
                            <Col span={16}>19% (19/100)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>North</Col>
                            <Col span={16}>20% (20/100)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>East</Col>
                            <Col span={16}>29% (29/100))</Col>
                          </Row>
                          <Row
                            style={{
                              padding: 10,
                              paddingLeft: 0,
                              float: "right",
                            }}
                          >
                            <a
                              href="#"
                              style={{ fontWeight: "bold", float: "right" }}
                            >
                              View all
                            </a>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={6}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Planogram Non-Compliance Store List
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={12}>Store</Col>
                            <Col span={12}>Compliance Score (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store A</Col>
                            <Col span={12}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store B</Col>
                            <Col span={12}>30%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store C</Col>
                            <Col span={12}>20%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store D</Col>
                            <Col span={12}>10%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store E</Col>
                            <Col span={12}>10%</Col>
                          </Row>
                          <Row
                            style={{
                              padding: 10,
                              paddingLeft: 0,
                              float: "right",
                            }}
                          >
                            <a
                              href="/nonecompliance"
                              style={{ fontWeight: "bold", float: "right" }}
                            >
                              View all
                            </a>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={24}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                       // marginLeft: 10,
                      }}
                    />
                    <Tag color="processing" closable>
                      Singapore
                    </Tag>
                    <Tag color="processing" closable>
                      All Kelloggs
                    </Tag>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <Progress
                            strokeColor={{
                              "100%": "#E20D0D",
                            }}
                            type="circle"
                            percent={30}
                            width={70}
                          />
                        </Col>
                        <Col
                          span={5}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "#4B5DE8",
                            }}
                          >
                            On-Shelf Availability
                          </font>
                          <br />
                          <font style={{ color: "#E20D0D" }}>
                            -30% vs last audit
                          </font>
                        </Col>
                        <Col
                          span={4}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Primary Placement
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30%
                          </font>
                        </Col>
                        <Col
                          span={4}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Secondary Placement
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#09BF3C" }}>
                            70%
                          </font>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={10}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            OSA by Retailer
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Retailer</Col>
                            <Col span={8}>Avg. OSA ( %)</Col>
                            <Col span={8}>Sales ($, Milion)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer A</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              29%
                            </Col>
                            <Col span={8}>$ 50.5 M</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer B</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              19%
                            </Col>
                            <Col span={8}>$ 10.5 M</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer C</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              10%
                            </Col>
                            <Col span={8}>$ 10.5 M</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer D</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              29%
                            </Col>
                            <Col span={8}>$ 10.5 M</Col>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={6}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Stores with Zero Facing ( 10 Stores)
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={12}>Store</Col>
                            <Col span={12}>Product</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store A</Col>
                            <Col span={12}>Product A</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store B</Col>
                            <Col span={12}>Product B</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store C</Col>
                            <Col span={12}>Product C</Col>
                          </Row>
                          <Row
                            style={{
                              padding: 10,
                              paddingLeft: 0,
                              float: "right",
                            }}
                          >
                            <a
                              href="#"
                              style={{ fontWeight: "bold", float: "right" }}
                            >
                              View all
                            </a>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={6}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Products with Zero Facings
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={12}>Product</Col>
                            <Col span={12}>OOS (# stores)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product A</Col>
                            <Col span={12}>50</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product B</Col>
                            <Col span={12}>20</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product C</Col>
                            <Col span={12}>10</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product D</Col>
                            <Col span={12}>5</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product E</Col>
                            <Col span={12}>3</Col>
                          </Row>
                          <Row
                            style={{
                              padding: 10,
                              paddingLeft: 0,
                              float: "right",
                            }}
                          >
                            <a
                              href="#"
                              style={{ fontWeight: "bold", float: "right" }}
                            >
                              View all
                            </a>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      # of Stores by Facings
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Product A
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 120, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="1">1 facings</Option>
                      <Option value="2">2 facings</Option>
                      <Option value="3">3 facings</Option>
                      <Option value="4+">4+ facings</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <div style={style.maincontent}>
                      <GroupedColumn {...config_group_1} />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={16}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            # of Stores by Facing
                          </font>
                        </Col>
                        <Col span={8}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontWeight: "bold",
                              float: "right",
                            }}
                          >
                            Sort <ArrowUpOutlined />
                          </font>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={12}>Store</Col>
                        <Col span={12}># of Products</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Store A</Col>
                        <Col span={12}>10</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Store B</Col>
                        <Col span={12}>8</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Store C</Col>
                        <Col span={12}>6</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Avg # of Facings by Retailer
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Malaysia
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 120, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Retailer A">Retailer A</Option>
                      <Option value="Retailer B">Retailer B</Option>
                      <Option value="Retailer C">Retailer C</Option>
                      <Option value="Retailer D">Retailer D</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <div style={style.maincontent}>
                      <GroupedColumn {...config_group_2} />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={16}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            # of Facings by Retailer
                          </font>
                        </Col>
                        <Col span={8}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontWeight: "bold",
                              float: "right",
                            }}
                          >
                            Sort <ArrowUpOutlined />
                          </font>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={12}>Retailer</Col>
                        <Col span={12}># of Products</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Retailer A</Col>
                        <Col span={12}>10</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Retailer B</Col>
                        <Col span={12}>8</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Retailer C</Col>
                        <Col span={12}>6</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Avg # of Facings by Brand
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Malaysia
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 120, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Brand A">Brand A</Option>
                      <Option value="Brand B">Brand B</Option>
                      <Option value="Brand C">Brand C</Option>
                      <Option value="Brand D">Brand D</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <div style={style.maincontent}>
                      <GroupedColumn {...config_group_3} />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={16}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Avg # of Facings by Brand / SKURetailer
                          </font>
                        </Col>
                        <Col span={8}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontWeight: "bold",
                              float: "right",
                            }}
                          >
                            Sort <ArrowUpOutlined />
                          </font>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={12}>Retailer</Col>
                        <Col span={12}># of Products</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Retailer A</Col>
                        <Col span={12}>10</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Retailer B</Col>
                        <Col span={12}>8</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Retailer C</Col>
                        <Col span={12}>6</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      OSA - Kellogg’s Vs Competitor
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={24}>
                    <Tag color="processing" closable>
                      Cold Storage, Marina One
                    </Tag>
                    <Tag color="processing" closable>
                      Cereal
                    </Tag>
                    <Tag color="processing" closable>
                      Primary Display
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 150, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Manufacturer A">Manufacturer A</Option>
                      <Option value="Manufacturer B">Manufacturer B</Option>
                      <Option value="Manufacturer C">Manufacturer C</Option>
                      <Option value="Manufacturer D">Manufacturer D</Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <GroupedColumn {...config_group_4} />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Total # of Facings
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Brand A
                    </Tag>
                    <Tag color="processing" closable>
                      Cold Storage, Marina One
                    </Tag>
                    <Tag color="processing" closable>
                      Cereal
                    </Tag>
                    <Tag color="processing" closable>
                      Primary Display
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 150, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Manufacturer A">Manufacturer A</Option>
                      <Option value="Manufacturer B">Manufacturer B</Option>
                      <Option value="Manufacturer C">Manufacturer C</Option>
                      <Option value="Manufacturer D">Manufacturer D</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <GroupedColumn {...config_group_2} />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <div style={{ width: window.innerWidth / 6 }}>
                    Competitor Activity
                  </div>
                }
                key="3"
              >
                <Row style={{ marginTop: 10 }}>
                  <Col span={22}></Col>
                  <Col span={2}>
                    <div style={{ float: "right", marginRight: 10 }}>
                      <MailOutlined style={{ fontSize: "16px" }} />
                      <FilePdfOutlined
                        style={{ fontSize: "16px", marginLeft: 10 }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Overview
                    </font>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      <Space size={"large"}>
                        <div>
                          <font
                            style={{
                              color: "#391EAC",
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            MTD
                          </font>
                        </div>
                        <div>QTD</div>
                        <div>YTD</div>
                      </Space>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={24}>
                    <Tag color="processing" closable>
                      Singapore
                    </Tag>
                    <Tag color="processing" closable>
                      All Kelloggs
                    </Tag>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={3} style={{ textAlign: "center" }}>
                          <Progress
                            strokeColor={{
                              "100%": "#E20D0D",
                            }}
                            type="circle"
                            percent={30}
                            width={70}
                          />
                        </Col>
                        <Col
                          span={5}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "#4B5DE8",
                            }}
                          >
                            Share of Shelf (SOS)
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30% vs last week
                          </font>
                        </Col>
                        <Col
                          span={4}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Primary Placement
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30%
                          </font>
                        </Col>
                        <Col
                          span={4}
                          style={{ textAlign: "center", padding: 15 }}
                        >
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Secondary Placement
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#09BF3C" }}>
                            70%
                          </font>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col span={6}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            SOS by Region
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Region</Col>
                            <Col span={16}>Actual (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Central</Col>
                            <Col span={16}>90% (100 stores)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>South</Col>
                            <Col span={16}>85% (100 stores)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>North</Col>
                            <Col span={16}>75% (100 stores)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>East</Col>
                            <Col span={16}>65% (100 stores)</Col>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={10}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            SOS Key Retailer
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Retailer</Col>
                            <Col span={8}>Channel</Col>
                            <Col span={8}>Market Share vs SOS (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer A</Col>
                            <Col span={8}>Hypermarket</Col>
                            <Col span={8}>35% vs 40%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer B</Col>
                            <Col span={8}>Supermarket</Col>
                            <Col span={8}>35% vs 40%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Retailer C</Col>
                            <Col span={8}>Convenience</Col>
                            <Col span={8}>35% vs 40%</Col>
                          </Row>
                          <Row
                            style={{
                              padding: 10,
                              paddingLeft: 0,
                              float: "right",
                            }}
                          >
                            <a
                              href="#"
                              style={{ fontWeight: "bold", float: "right" }}
                            >
                              View all
                            </a>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>

                        <Col span={6}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Avg Share of Shelf Ranking by Store
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={12}>Store</Col>
                            <Col span={12}>Compliance Score (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store A</Col>
                            <Col span={12}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store B</Col>
                            <Col span={12}>30%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store C</Col>
                            <Col span={12}>20%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store D</Col>
                            <Col span={12}>10%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Store E</Col>
                            <Col span={12}>10%</Col>
                          </Row>
                          <Row
                            style={{
                              padding: 10,
                              paddingLeft: 0,
                              float: "right",
                            }}
                          >
                            <a
                              href="#"
                              style={{ fontWeight: "bold", float: "right" }}
                            >
                              View all
                            </a>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={24}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <Tag color="processing" closable>
                      Singapore
                    </Tag>
                    <Tag color="processing" closable>
                      Cereal
                    </Tag>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={8}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Promotion Depth
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}></Col>
                            <Col span={16}>Avg Var from Regular Price (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8} style={{ fontWeight: "bold" }}>
                              Kelloggs
                            </Col>
                            <Col span={8}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8} style={{ fontWeight: "bold" }}>
                              Nestle
                            </Col>
                            <Col span={8}>20%</Col>
                          </Row>
                          <br />
                          <br />
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Top Promoted Product by Depth
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Product</Col>
                            <Col span={16}>Var from Regular price (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Product A</Col>
                            <Col span={8}>30%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Product B</Col>
                            <Col span={8}>17.9%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Product C</Col>
                            <Col span={8}>17.0%</Col>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={7}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Promotion Frequency
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}></Col>
                            <Col span={16}>Avg Promotion Frequency</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8} style={{ fontWeight: "bold" }}>
                              Kelloggs
                            </Col>
                            <Col span={8}>YTD: 3 weeks</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8} style={{ fontWeight: "bold" }}>
                              Nestle
                            </Col>
                            <Col span={8}>YTD: 5 weeks</Col>
                          </Row>
                          <br />
                          <br />
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Top Promoted Product by Frequency
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={8}>Product</Col>
                            <Col span={16}>Promotion Frequency</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Product A</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              30%
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Product B</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              17.9%
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={8}>Product C</Col>
                            <Col span={8} style={{ color: "#E20D0D" }}>
                              17.0%
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={7}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            New SKU Detected
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={12}>Product</Col>
                            <Col span={12}># stores</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product A</Col>
                            <Col span={12}>50</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product B</Col>
                            <Col span={12}>20</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product C</Col>
                            <Col span={12}>10</Col>
                          </Row>
                          <br />
                          <br />
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Delisted SKU
                          </font>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={12}>Product</Col>
                            <Col span={12}># stores</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product A</Col>
                            <Col span={12}>50</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}>Product B</Col>
                            <Col span={12}>20</Col>
                          </Row>
                          <Row
                            style={{
                              padding: 10,
                              paddingLeft: 0,
                              float: "right",
                            }}
                          >
                            <a
                              href="#"
                              style={{ fontWeight: "bold", float: "right" }}
                            >
                              View all
                            </a>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Fair Share of Shelf (FSOS)
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Brand A
                    </Tag>
                    <Tag color="processing" closable>
                      Brand B
                    </Tag>
                    <Tag color="processing" closable>
                      Cereal
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 120, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Kelloggs">Kelloggs</Option>
                      <Option value="Nestle">Nestle</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <GroupedColumn {...config_group_5} />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Total Avg Share of Shelf - Kellogg’s Vs competitor
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Malaysia
                    </Tag>
                    <Tag color="processing" closable>
                      Cereal
                    </Tag>
                    <Tag color="processing" closable>
                      Brand A
                    </Tag>
                    <Tag color="processing" closable>
                      Product A
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 120, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Kelloggs">Kelloggs</Option>
                      <Option value="Nestle">Nestle</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <div style={style.maincontent}>
                      <GroupedColumn {...config_group_6} />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={16}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Share of Shelf by Store
                          </font>
                        </Col>
                        <Col span={8}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontWeight: "bold",
                              float: "right",
                            }}
                          >
                            Sort <ArrowUpOutlined />
                          </font>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={12}>Store</Col>
                        <Col span={12}>SOS (%)</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Store A</Col>
                        <Col span={12}>10%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Store B</Col>
                        <Col span={12}>8%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Store C</Col>
                        <Col span={12}>6%</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Kelloggs Vs Competitor Promotion Frequency
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Malaysia
                    </Tag>
                    <Tag color="processing" closable>
                      Brand A
                    </Tag>
                    <Tag color="processing" closable>
                      Brand B
                    </Tag>
                    <Tag color="processing" closable>
                      Product A
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 120, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Kelloggs">Kelloggs</Option>
                      <Option value="Nestle">Nestle</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <div style={style.maincontent}>
                      <Line {...config} />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={16}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Top Promoted Product
                          </font>
                        </Col>
                        <Col span={8}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontWeight: "bold",
                              float: "right",
                            }}
                          >
                            Sort <ArrowUpOutlined />
                          </font>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={12}>Product</Col>
                        <Col span={12}>Count of promoted weeks</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Product A</Col>
                        <Col span={12} style={{ color: "#E20D0D" }}>
                          3W
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Product B</Col>
                        <Col span={12} style={{ color: "#E20D0D" }}>
                          4W
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={12}>Product C</Col>
                        <Col span={12} style={{ color: "#E20D0D" }}>
                          2W
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Variance from MSRP
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Singapore
                    </Tag>
                    <Tag color="processing" closable>
                      Retailer A
                    </Tag>
                    <Tag color="processing" closable>
                      Product A
                    </Tag>
                    <Select
                      //showSearch
                      style={{ width: 150, color: "#000", fontWeight: "bold" }}
                      bordered={false}
                      showArrow={true}
                      mode="multiple"
                      placeholder="Dimension"
                      optionFilterProp="children"
                      onChange={onChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="MSRP">MSRP</Option>
                      <Option value="Min Price">Min Price</Option>
                      <Option value="Max Price">Max Price</Option>
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Line {...config_m} />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Promtion Frequency and Depth
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Singapore
                    </Tag>
                    <Tag color="processing" closable>
                      Nestle Koko Krunch 100g
                    </Tag>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <div style={style.maincontent}>
                  <Row
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      padding: 5,
                      paddingLeft: 0,
                      marginTop: 15,
                      borderBottom: "1px solid #D8D8D8",
                    }}
                  >
                    <Col span={6}>Product</Col>
                    <Col span={6}>Promotions</Col>
                    <Col span={6}>Frequency</Col>
                    <Col span={6}>Depth(var from MSRP,%)</Col>
                  </Row>
                  <Row
                    style={{
                      fontWeight: "bold",
                      padding: 5,
                      paddingLeft: 0,
                      backgroundColor: "#DAE4F3",
                    }}
                  >
                    <Col span={6}>Nestle Koko Krunch 100g</Col>
                    <Col span={6}>Price discount</Col>
                    <Col span={6}>
                      Q2: W 14, 15
                      <br />
                      Q3: W 40, 45
                    </Col>
                    <Col span={6}>-10% from MSRP</Col>
                  </Row>
                  <Row
                    style={{ fontWeight: "bold", padding: 5, paddingLeft: 0 }}
                  >
                    <Col span={6}></Col>
                    <Col span={6}>Price discount</Col>
                    <Col span={6}>
                      Q2: W 14, 15
                      <br />
                      Q3: W 40, 45
                    </Col>
                    <Col span={6}>-20% from MSRP</Col>
                  </Row>
                  <Row
                    style={{
                      fontWeight: "bold",
                      padding: 5,
                      paddingLeft: 0,
                      backgroundColor: "#DAE4F3",
                    }}
                  >
                    <Col span={6}></Col>
                    <Col span={6}>Free Gift - Disney Frozen Bowl</Col>
                    <Col span={6}>W 3,4,5</Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row
                    style={{ fontWeight: "bold", padding: 5, paddingLeft: 0 }}
                  >
                    <Col span={6}></Col>
                    <Col span={6}>Twin Pack</Col>
                    <Col span={6}>Q3: W 40, 45</Col>
                    <Col span={6}></Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane
                tab={
                  <div style={{ width: window.innerWidth / 6 }}>My Stores</div>
                }
                key="4"
              >
                <Row style={{ marginTop: 10 }}>
                  <Col span={22}></Col>
                  <Col span={2}>
                    <div style={{ float: "right", marginRight: 10 }}>
                      <MailOutlined style={{ fontSize: "16px" }} />
                      <FilePdfOutlined
                        style={{ marginLeft: 10, fontSize: "16px" }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Overview
                    </font>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      <Space size={"large"}>
                        <div>
                          <font
                            style={{
                              color: "#391EAC",
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            MTD
                          </font>
                        </div>
                        <div>QTD</div>
                        <div>YTD</div>
                      </Space>
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingTop: 10,
                    paddingLeft: 10,
                  }}
                >
                  <Col span={24}>
                    <Tag color="processing" closable>
                      Sally
                    </Tag>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={15}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <Row>
                            <Col span={20}>
                              <font
                                style={{
                                  color: "#4B5DE8",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              >
                                My Stores
                              </font>
                            </Col>
                            <Col span={4}>
                              <font
                                style={{
                                  color: "#4B5DE8",
                                  fontWeight: "bold",
                                  float: "right",
                                }}
                              >
                                Sort <ArrowUpOutlined />
                              </font>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={6}>Store</Col>
                            <Col span={6}>Planogram Compliance (%)</Col>
                            <Col span={6}>On-Shelf Availability (%)</Col>
                            <Col span={6}>Share of Shelf (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store A</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store B</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store C</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store D</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store E</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={7} style={{ textAlign: "let", padding: 5 }}>
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#4B5DE8",
                            }}
                          >
                            Store Profile
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            FairPrice Xtra Jurong Point
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            #03-01, 63, Jurong West Central 3, Jurong Point,
                            648331
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            Sales Rep : Sally
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            Last Audit : 10:30 am, 12 Aug 20
                          </font>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={24}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            Performance: Store A
                          </font>
                          <br />
                          <br />
                          <Progress
                            strokeColor={{
                              "100%": "#E20D0D",
                            }}
                            type="circle"
                            percent={30}
                            width={70}
                          />
                          <br />
                          <br />
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            On-Shelf Availability
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30% vs last audit
                          </font>
                          <br />
                          <br />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={12}>Product</Col>
                        <Col span={12}>OSA (%)</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product A</Col>
                        <Col span={12}>50%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product A</Col>
                        <Col span={12}>50%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product B</Col>
                        <Col span={12}>30%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product C</Col>
                        <Col span={12}>20%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product D</Col>
                        <Col span={12}>10%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product E</Col>
                        <Col span={12}>10%</Col>
                      </Row>
                      <br />
                      <a
                        href="#"
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        View all
                      </a>
                      <br />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={24}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          ></font>
                          <br />
                          <br />
                          <Progress
                            strokeColor={{
                              "100%": "#E20D0D",
                            }}
                            type="circle"
                            percent={30}
                            width={70}
                          />
                          <br />
                          <br />
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Share of Shelf (SOS)
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30% vs last audit
                          </font>
                          <br />
                          <br />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={12}>Product</Col>
                        <Col span={12}>SOS (%)</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product A</Col>
                        <Col span={12}>50%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product A</Col>
                        <Col span={12}>50%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product B</Col>
                        <Col span={12}>30%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product C</Col>
                        <Col span={12}>20%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product D</Col>
                        <Col span={12}>10%</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={8}>Product E</Col>
                        <Col span={12}>10%</Col>
                      </Row>
                      <br />
                      <a
                        href="#"
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        View all
                      </a>
                      <br />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={24}>
                          <font
                            style={{
                              color: "#4B5DE8",
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          ></font>
                          <br />
                          <br />
                          <Progress
                            strokeColor={{
                              "100%": "#E20D0D",
                            }}
                            type="circle"
                            percent={30}
                            width={70}
                          />
                          <br />
                          <br />
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#2E2E2E",
                            }}
                          >
                            Planogram Compliance
                          </font>
                          <br />
                          <font style={{ fontSize: 14, color: "#E20D0D" }}>
                            -30% vs last audit
                          </font>
                          <br />
                          <br />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          padding: 5,
                          paddingLeft: 0,
                          marginTop: 15,
                          borderBottom: "1px solid #D8D8D8",
                        }}
                      >
                        <Col span={24}>Area of Non-Compliance</Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={24}>
                          1. Incorrect product placement
                          <br /> - Product X is misplaced
                        </Col>
                      </Row>
                      <Row style={{ padding: 5, paddingLeft: 0 }}>
                        <Col span={24}>
                          2. Unapproved SKU
                          <br /> - Product X detected
                        </Col>
                      </Row>
                      <br />
                      <a
                        href="#"
                        style={{ fontWeight: "bold", float: "right" }}
                      >
                        View all
                      </a>
                      <br />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Store Audit Image Gallery
                    </font>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 10 }}>
                  <Col span={16}>
                    <Tag color="processing" closable>
                      Retailer A
                    </Tag>
                    <Tag color="processing" closable>
                      Primary Placement
                    </Tag>
                    <Tag color="processing" closable>
                      End Cap
                    </Tag>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10, paddingLeft: 15 }}>
                  <Col span={24}>
                    <div
                      style={{
                        fontSize: 13,
                        fontWeight: "bold",
                        color: "#000",
                      }}
                    >
                      Audit Date: 11 Aug 2020
                      <br />
                      Auditor: Sally
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        Primary Bays
                        <br />
                        <br />
                        <br />
                      </div>
                      <Row>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                      </Row>
                      <br />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        End-Caps Bays
                        <br />
                        <br />
                        <br />
                      </div>
                      <Row>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                        <Col span={6}>
                          <img src={require("../assets/img/bay.png")} />
                          <br />
                          <br />
                          <font style={{ fontWeight: "bold" }}>
                            Primary Placement, Bay 1
                          </font>
                          <br />
                          Time: 12:40 AM
                        </Col>
                      </Row>
                      <br />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <div style={{ width: window.innerWidth / 6 }}>
                    Action List
                  </div>
                }
                key="5"
              >
                <Row style={{ marginTop: 10 }}>
                  <Col span={22}></Col>
                  <Col span={2}>
                    <div style={{ float: "right", marginRight: 10 }}>
                      <MailOutlined style={{ fontSize: "16px" }} />
                      <FilePdfOutlined
                        style={{ marginLeft: 10, fontSize: "16px" }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                        marginLeft: 10,
                      }}
                    >
                      Starred
                    </font>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      <Space size={"large"}>
                        <div>
                          <font
                            style={{
                              color: "#391EAC",
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            MTD
                          </font>
                        </div>
                        <div>QTD</div>
                        <div>YTD</div>
                      </Space>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={14}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <Row>
                            <Col span={20}>
                              <font
                                style={{
                                  color: "#4B5DE8",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              ></font>
                            </Col>
                            <Col span={4}>
                              <font
                                style={{
                                  color: "#4B5DE8",
                                  fontWeight: "bold",
                                  float: "right",
                                }}
                              >
                                Sort <ArrowUpOutlined />
                              </font>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={6}>Store</Col>
                            <Col span={6}>Planogram Compliance (%)</Col>
                            <Col span={6}>On-Shelf Availability (%)</Col>
                            <Col span={6}>Share of Shelf (%)</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>
                              <StarOutlined style={{ fontSize: 16 }} /> Store A
                            </Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>
                              <StarOutlined style={{ fontSize: 16 }} /> Store B
                            </Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>
                              <StarOutlined style={{ fontSize: 16 }} /> Store C
                            </Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>
                              <StarOutlined style={{ fontSize: 16 }} /> Store D
                            </Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>
                              <StarOutlined style={{ fontSize: 16 }} /> Store E
                            </Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                            <Col span={6}>50%</Col>
                          </Row>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={7} style={{ textAlign: "let", padding: 5 }}>
                          <font
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#4B5DE8",
                            }}
                          >
                            Store Profile
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            FairPrice Xtra Jurong Point
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            #03-01, 63, Jurong West Central 3, Jurong Point,
                            648331
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            Sales Rep : Sally
                          </font>
                          <br />
                          <br />
                          <font style={{ color: "#000" }}>
                            Last Audit : 10:30 am, 12 Aug 20
                          </font>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={16}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Priorities by Sales Impact
                    </font>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        fontSize: 13,
                        float: "right",
                        marginRight: 10,
                      }}
                    >
                      Audit Period
                      <RangePicker style={{ marginLeft: 20 }} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={11}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <Row>
                            <Col span={24}>
                              <font
                                style={{
                                  color: "#000",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              >
                                Distribution Gap - by Product
                              </font>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              //fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={6}>Product</Col>
                            <Col span={18}>Avg. # of Facings</Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              //smarginTop: 5,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={6}></Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W1
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W2
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W3
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W4
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W5
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W6
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Product A</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Product B</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Product C</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Product D</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Product E</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                          </Row>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                          <Divider type="vertical" style={{ height: "100%" }} />
                        </Col>
                        <Col span={11} style={{ textAlign: "let", padding: 5 }}>
                          <Row>
                            <Col span={24}>
                              <font
                                style={{
                                  color: "#000",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              >
                                Distribution Gap - by Store
                              </font>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              //fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={6}>Store</Col>
                            <Col span={18}>Avg. Total # of facings</Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              //smarginTop: 5,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={6}></Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W1
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W2
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W3
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W4
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W5
                            </Col>
                            <Col span={3} style={{ fontWeight: "bold" }}>
                              W6
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store A</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store B</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store C</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store D</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                            <Col span={3}>0</Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={6}>Store E</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                            <Col span={3}>1</Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <FilterOutlined
                      onClick={showDrawer}
                      style={{
                        fontSize: "16px",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    />
                    <font
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#4B5DE8",
                      }}
                    >
                      Planogram Compliance
                    </font>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={style.maincontent}>
                      <Row>
                        <Col span={11}>
                          {/*<Table
                            tableLayout="fixed"
                            pagination={false}
                            bordered={false}
                            dataSource={dataSource}
                            columns={columns}
                            style={{padding:0}}
                          />*/}
                          <Row>
                            <Col span={24}>
                              <font
                                style={{
                                  color: "#000",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              >
                                Primary Placement
                              </font>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              color: "#000000",
                              fontWeight: "bold",
                              padding: 5,
                              paddingLeft: 0,
                              marginTop: 15,
                              borderBottom: "1px solid #D8D8D8",
                            }}
                          >
                            <Col span={12}></Col>
                            <Col span={12}>Area of Non-Compliance</Col>
                          </Row>

                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}></Col>
                            <Col span={12}>
                              1. Incorrect product placement
                              <br />- Product X is misplaced
                            </Col>
                          </Row>
                          <Row style={{ padding: 5, paddingLeft: 0 }}>
                            <Col span={12}></Col>
                            <Col span={12}>
                              2. Unapproved SKU
                              <br />- Product X detected
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Main;
