import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { BACKEND_URL, BACKEND_URL_AZURE } from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const MyStoreSummary = ({
  addLoader,
  removeLoader,
  fetchAllAudits,
  fetchPriceScans,
  fetchMyStoreProfile,
  fetchOsa,
  fetchSos,
  fetchPlanogram,
}) => {
  const [data, setData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);
  const [visible, setVisible] = useState(false);

  const fetchData = async () => {
    const loaderId = "ms_datastoresummary";
    addLoader(loaderId);

    //get all countries & regions
    if (
      cookies.get("ms_country") === null ||
      cookies.get("ms_country") === "" ||
      cookies.get("ms_country") === "undefined" ||
      cookies.get("ms_country") === undefined
    ) {
      let strCountry = "";
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
      cookies.set("ms_country", strCountry);
    }

    if (
      cookies.get("ms_region") === null ||
      cookies.get("ms_region") === "" ||
      cookies.get("ms_region") === "undefined" ||
      cookies.get("ms_region") === undefined
    ) {
      let strRegion = "";
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
      cookies.set("ms_region", strRegion);
    }

    if (
      cookies.get("ms_retailer") === null ||
      cookies.get("ms_retailer") === "" ||
      cookies.get("ms_retailer") === "undefined" ||
      cookies.get("ms_retailer") === undefined
    ) {
      let strRetailer = "";
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
      cookies.set("ms_retailer", strRetailer);
    }
    //end get all countries & regions

    if (
      cookies.get("ms_startDate") === undefined ||
      cookies.get("ms_startDate") === "" ||
      cookies.get("ms_startDate") === "undefined"
    ) {
      let startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
    }
    if (
      cookies.get("ms_endDate") === undefined ||
      cookies.get("ms_endDate") === "" ||
      cookies.get("ms_endDate") === "undefined"
    ) {
      cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
    }

    let url = `${BACKEND_URL_AZURE}/getstoresbyclient?client=${cookies.get(
      "client"
    )}&retailers=${cookies.get("ms_retailer")}&regions=${cookies.get(
      "ms_region"
    )}&countries=${cookies.get("ms_country")}&startDate=${cookies.get(
      "ms_startDate"
    )}&endDate=${cookies.get("ms_endDate")}&includePromoProducts=${cookies.get(
      "ms_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("ms_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET", headers: headers })
      .then(function (response) {
        return response.json();
      })
      .then((result) => {
        removeLoader(loaderId);
        setTableConfigs(altColumns);
        setData(result.message);
      })
      .catch(function (error) {
        console.log("-------- error ------- " + error);
      });
  };

  const onClick = async (e) => {
    cookies.set("store", e.currentTarget.dataset.id);

    await Promise.all([
      fetchAllAudits(),
      fetchPriceScans(),
      fetchMyStoreProfile(),
      fetchOsa(),
      fetchSos(),
      fetchPlanogram(),
    ]);
  };

  const altColumns = [
    {
      title: "Store",
      dataIndex: "store",
      width: "20%",
      key: "store",
      render: (text, record) => (
        <a onClick={onClick} data-id={text.split("###")[0]}>
          {text.split("###")[1]}
        </a>
      ),
    },
    {
      title: "Planogram Compliance (%)",
      dataIndex: "planogram",
      width: "30%",
      align: "center",
      key: "planogram",
      sorter: (a, b) =>
        a.planogram.toString().localeCompare(b.planogram.toString()),
      showSorterTooltip: false,
    },
    {
      title: "On-Shelf Availability (%)",
      dataIndex: "osa",
      key: "osa",
      align: "center",
      width: "25%",
      sorter: (a, b) => a.osa.toString().localeCompare(b.osa.toString()),
      showSorterTooltip: false,
    },
    {
      title: "Share of Shelf (%)",
      dataIndex: "sos",
      key: "sos",
      align: "center",
      width: "25%",
      sorter: (a, b) => a.sos.toString().localeCompare(b.sos.toString()),
      showSorterTooltip: false,
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={data}
        isPagination={true}
      />
      <Modal
        title="My Stores"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={data}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton onClick={() => setVisible(true)} title="View all" />
      </div>
    </div>
  );
};

export default MyStoreSummary;
