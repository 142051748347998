import { isEmpty, clone, isObject, isUndefined, isNumber as _isNumber, find, assignIn, isString } from "lodash";

function parseJwt(token) {
    if (isEmpty(token)) return '';

    const parts = token.split('.');
    if (parts.length < 2) return '';

    let base64Url = parts[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return !isEmpty(base64) ? JSON.parse(window.atob(base64)) : '';
}

export function isTimeExpired(token) {
    let decodedToken = parseJwt(token);
    //check token expired or not
    if (!decodedToken
        || (decodedToken && !_isNumber(decodedToken.exp))
        || (decodedToken && _isNumber(decodedToken.exp) && decodedToken.exp < (Date.now() / 1000))
    ) {
        return true;
    }
    return false;
}