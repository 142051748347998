import React, { useState, useEffect } from "react";

import ImageGalleryProfile from "../../components/ImageGalleryProfile";
import ImageGalleryOverview from "../../components/ImageGalleryOverview";
import ImageGalleryEndcapBays from "../../components/ImageGalleryEndcapBays";
import ImageGalleryOtherBays from "../../components/ImageGalleryOtherBays";
import ImageGalleryIslandBays from "../../components/ImageGalleryIslandBays";
import ImageGalleryPriceScans from "../../components/ImageGalleryPriceScans";
import Cookies from "universal-cookie";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import "./style.scss";
import { BACKEND_URL, BACKEND_URL_AZURE } from "../../constants/constants";
import LoaderComponent from "../../components/LoaderComponent";
import { encode } from "base-64";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Divider,Dropdown, Menu, Radio, DatePicker, Button, message } from "antd";
import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import SecondaryPlacementOverview from "../../components/SecondaryPlacementOverview";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

var lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
var currentDate = new Date();

if (
  cookies.get("ig_startDate") &&
  cookies.get("ig_startDate") !== "" &&
  cookies.get("ig_startDate") !== "undefined"
) {
  currentDate = cookies.get("ig_startDate");
}
if (
  cookies.get("ig_endDate") &&
  cookies.get("ig_endDate") !== "" &&
  cookies.get("ig_endDate") !== "undefined"
) {
  lastDate = cookies.get("ig_endDate");
}

function printDocument() {
  message.loading("Exporting PDF...", 0);
  const input = document.querySelector("#divToPrint");
  html2canvas(
    input,
    { useCORS: true },
    {
      width: document.getElementById("divToPrint").scrollWidth,
      height: document.getElementById("divToPrint").scrollHeight,
    }
  ).then((canvas) => {
    canvas.crossOrigin = "Anonymous";
    const imgData = canvas.toDataURL("image/png");
    //document.querySelector("#divToPrint").appendChild(canvas);
    const pdf = new jsPDF();
    pdf.addImage(
      imgData,
      "png",
      0,
      0,
      document.getElementById("divToPrint").offsetWidth / 9,
      document.getElementById("divToPrint").offsetHeight / 9
    );
    pdf.save(new Date().valueOf() + ".pdf");
    message.destroy();
  });
}

const SecondaryDisplay = () => {
  const [name, setName] = useState([]);
  const [auditResult, setAuditResult] = useState([]);
  const [loaders, setLoaders] = useState([]);
  const [periodFilter, setPeriodFilter] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [sDate, setSDate] = useState([]);
  const [eDate, setEDate] = useState([]);
const [secondaryPlacementOverview, setSecondaryPlacementOverview] = useState(
    []
  );

  const removeLoader = (id) => {
    setLoaders((prevState) => prevState.filter((state) => state !== id));
  };

  const addLoader = (id) => {
    setLoaders((prevState) => [...prevState, id]);
  };

  if (
    cookies.get("ig_startDate") === undefined ||
    cookies.get("ig_startDate") === "" ||
    cookies.get("ig_startDate") === "undefined"
  ) {
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    cookies.set("ig_startDate", startDate.toISOString().split("T")[0]);
  }

  if (
    cookies.get("ig_endDate") === undefined ||
    cookies.get("ig_endDate") === "" ||
    cookies.get("ig_endDate") === "undefined"
  ) {
    cookies.set("ig_endDate", new Date().toISOString().split("T")[0]);
  }

  const fetchLastAuditsByRetailer = async () => {
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    //end get all countries & regions
    if (
      //cookies.get("ig_retailer") &&
      //cookies.get("ig_retailer") !== "" &&
      cookies.get("ig_retailer") !== "undefined" && cookies.get("ig_retailer") !== undefined
    ) {
      const loaderId = "sd_lastauditretailer";
      addLoader(loaderId);
      if (
        cookies.get("ig_startDate") === undefined ||
        cookies.get("ig_startDate") === "" ||
        cookies.get("ig_startDate") === "undefined"
      ) {
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ig_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ig_endDate") === undefined ||
        cookies.get("ig_endDate") === "" ||
        cookies.get("ig_endDate") === "undefined"
      ) {
        cookies.set("ig_endDate", new Date().toISOString().split("T")[0]);
      }
      let url =
        BACKEND_URL_AZURE +
        "/getlastauditsbyretailer?retailers=" +
        cookies.get("ig_retailer") +
        "&client=" +
        cookies.get("client") +
        "&countries=" +
        strCountry +
        "&regions=" +
        strRegion +
        "&startDate=" +
        cookies.get("ig_startDate") +
        "&endDate=" +
        cookies.get("ig_endDate");

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          removeLoader(loaderId);
          setAuditResult(result.lastAudit);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    }
  };

  useEffect(() => {
    if (cookies.get("email") == null) {
      document.location.href = "/";
    }
    cookies.set("pr_brand", "");
    cookies.set("retailer", "");
    cookies.set("pc_retailer", "");
    cookies.set("pc_product", "");
    setSDate();
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    cookies.set("ig_country", strCountry);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    cookies.set("ig_region", strRegion);
    //end get all countries & regions
    fetchSecondaryPlacementOverview();
    const fetchMyStoreProfile = async () => {
      if (
        cookies.get("ig_retailer") &&
        cookies.get("ig_retailer") !== "" &&
        cookies.get("ig_retailer") !== "," &&
        cookies.get("ig_retailer") !== undefined &&
        cookies.get("ig_retailer") !== "undefined"
      ) {
        if (
          cookies.get("ig_startDate") === undefined ||
          cookies.get("ig_startDate") === "" ||
          cookies.get("ig_startDate") === "undefined"
        ) {
          var startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          cookies.set("ig_startDate", startDate.toISOString().split("T")[0]);
        }
        if (
          cookies.get("ig_endDate") === undefined ||
          cookies.get("ig_endDate") === "" ||
          cookies.get("ig_endDate") === "undefined"
        ) {
          cookies.set("ig_endDate", new Date().toISOString().split("T")[0]);
        }
      }
    };
    fetchMyStoreProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const exportMenus = (
    <Menu>
      <Menu.Item key="pdf" onClick={printDocument}>
        PDF
      </Menu.Item>
      {/*<Menu.Item key="1">Excel</Menu.Item>*/}
    </Menu>
  );

  const onHandleFilterChanged = (data) => {
    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("ig_retailer", strRetailer);
    /*if (
      data.STORES_LIST.value &&
      data.STORES_LIST.value.length > 0 &&
      data.STORES_LIST.value !== undefined &&
      data.STORES_LIST.value !== "undefined"
    )
      cookies.set("ig_store", data.STORES_LIST.value[0].id);*/
    setFilterData(data);
    convertDataForTags(data);
    /*cookies.set(
      "ig_pricescans",
      document.getElementById("chkPriceScan").checked
    );*/
    fetchLastAuditsByRetailer();
    fetchSecondaryPlacementOverview();
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);
    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("ig_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("ig_retailer", retailer.join());
      if (retailer && retailer.length <= 0) {
        let strRetailer = "";
        /*const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);*/
        cookies.set("ig_retailer", strRetailer);
      }
    }
    fetchLastAuditsByRetailer();
    fetchSecondaryPlacementOverview();
  };

  const fetchSecondaryPlacementOverview = () => {
    
    if (
      //cookies.get("ig_retailer") &&
      //cookies.get("ig_retailer") !== "" &&
      //cookies.get("ig_retailer") !== "," &&
      cookies.get("ig_retailer") !== undefined &&
      cookies.get("ig_retailer") !== "undefined"
      )
      {
      const loaderId = 'sd_secondaryplacementoverview';
      addLoader(loaderId);
      const url = `${BACKEND_URL_AZURE}/getsecondaryplacementoverview?manufacturer=${cookies.get(
        "manufacturer"
      )}&startDate=${cookies.get("ig_startDate")}&endDate=${cookies.get(
        "ig_endDate"
      )}&retailers=${cookies.get("ig_retailer")}&countries=${cookies.get(
        "ig_country"
      )}&regions=${cookies.get("ig_region")}`;
    fetch(url, { method: "GET", headers:headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setSecondaryPlacementOverview(result.data);
      })
      .catch((err) => console.log("------- Error ------", err));
    }
  };

  return (
    <div className="team-av" id="divToPrint">
      <LoaderComponent show={loaders.length > 0} />
      <div className="Team-Activity-Overview-wp">
      <div className="title-wp" style={{ width: "100px" }}>
        <div className="title-wp">Secondary Display</div>
      </div>
      <div className="condition-values-wp">
        <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
      </div>
      <div className="control-btns" style={{ width: "700px" }}>
        {/*<Radio.Group
            className="period-filter-wp"
            value={periodFilter}
            onChange={handlePeriodFilterChange}
          >
            <Radio.Button value="monthly">Monthly</Radio.Button>
            <Radio.Button value="quarterly">Quarterly</Radio.Button>
            <Radio.Button value="yearly">Yearly</Radio.Button>
          </Radio.Group>
          <CustomFilter
            useFilterBtnForOverview={true}
            selectedData={filterData}
            filterKeys={[
              CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN,
              CUSTOM_FILTER_KEYS.STORES_LIST,
              CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST
            ]}
            onChange={(data) => {
              console.log("TeamActivityOverview ####", data);
              onHandleFilterChanged(data);
            }}
          />*/}
        <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
          <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
          {/*<DatePicker
              allowClear={false}
              onChange={(data) => {}}
              
              suffixIcon={<img alt="" src={ICON_ARROW_DOWN} />}
              disabledDate={(d) => !d || d.isAfter("2021")}
            />*/}
          <RangePicker
            showTime
            format={"YYYY-MM-DD"}
            onChange={(range) => {
              //console.log(new Date(range[0]._d).getMonth());
            }}
            /*renderExtraFooter={() => (
                <Button
                  onClick={() => {
                    document.location.href = "my-stores";
                  }}
                  style={{ float: "right", margin: 10 }}
                >
                  OK
                </Button>
                )}*/
            onCalendarChange={(range) => {
              if (range[0] && range[1]) {
                var startDate = new Date(range[0]._d + "EDT")
                  .toISOString()
                  .split("T")[0];
                var endDate = new Date(range[1]._d + "EDT")
                  .toISOString()
                  .split("T")[0];
                //if (
                //  startDate !== cookies.get("startDate") &&
                //  endDate !== cookies.get("endDate")
                //) {
                cookies.set("ig_startDate", startDate);
                cookies.set("ig_endDate", endDate);
                //cookies.set("startDate", startDate);
                //cookies.set("endDate", endDate);
                fetchLastAuditsByRetailer();
                fetchSecondaryPlacementOverview();
                //document.location.href = "secondary-display";
                //}
              }
            }}
            //picker="year"
            defaultValue={[
              moment(lastDate, dateFormat),
              moment(currentDate, dateFormat),
            ]}
            allowClear={false}
            suffixIcon={
              <img alt="" style={{ marginTop: "3px" }} src={ICON_ARROW_DOWN} />
            }
          />
        </div>
        <CustomFilter
          useFilterBtnForOverview={true}
          selectedData={filterData}
          filterKeys={[
            //CUSTOM_FILTER_KEYS.STORES_LIST,
            CUSTOM_FILTER_KEYS.RETAILER_LIST,
            //CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST,
          ]}
          onChange={(data) => {
            onHandleFilterChanged(data);
          }}
        />
        <Dropdown
          className="btn-item export-dropdown"
          overlay={exportMenus}
          trigger={["click"]}
        >
          <div>
            <img
              alt="icon-btn"
              className="arrow-icon"
              src={ICON_EXPORT_EXCEL}
            />
            <span>Export As</span>
            <img alt="" className="arrow-down-ic" src={ICON_ARROW_DOWN} />
          </div>
        </Dropdown>
      </div>
    </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <div className="OverdueStoreAudits-wp">
      <ReportTableHeader title="Summary" hideControlButtons={true} />
      <div className="separate-line" />
      <div style={{ fontSize: 14, fontWeight: 500, color: "#000" }}>
        Date :{" "}
        {cookies.get("ig_startDate")
          ? cookies.get("ig_startDate").split("-")[2] +
            " " +
            monthNames[
              parseInt(cookies.get("ig_startDate").split("-")[1]) - 1
            ] +
            " " +
            cookies.get("ig_startDate").split("-")[0].substr(2, 2)
          : ""}{" "}
        -{" "}
        {cookies.get("ig_endDate")
          ? cookies.get("ig_endDate").split("-")[2] +
            " " +
            monthNames[parseInt(cookies.get("ig_endDate").split("-")[1]) - 1] +
            " " +
            cookies.get("ig_endDate").split("-")[0].substr(2, 2)
          : ""}
        <Divider />
        <SecondaryPlacementOverview
          data={secondaryPlacementOverview}
          pagination={false}
        />
      </div>
    </div>
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <ImageGalleryEndcapBays data={auditResult} />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <ImageGalleryIslandBays data={auditResult} />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <ImageGalleryOtherBays data={auditResult} />
        </div>
      </div>
    </div>
  );
};
export default SecondaryDisplay;
