import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Radio, DatePicker, Button, message } from "antd";
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import Cookies from "universal-cookie";
import "./style.scss";
import moment from "moment";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format("YYYY-MM-DD");
let currentDate = new Date();
currentDate = moment(currentDate).format("YYYY-MM-DD");

const StoreExecutionOverview = ({
  fetchPlanogram,
  //fetchBaseKpi,
  //fetchBonusKpi,
  //fetchNonComplianceAccount,
  //fetchNonComplianceRetailer,
}) => {
  // const [data, setData] = useState([]);
  // const [tableConfigs, setTableConfigs] = useState([]);
  const [periodFilter, setPeriodFilter] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);

  function getDataByFilter(brand) {
    var strBrand = "";
    if (brand) {
      for (var i = 0; i < brand.length; i++) {
        strBrand = strBrand + brand[i].id + ",";
      }
    }
    cookies.set("seo_brand", strBrand);
  }
  if (
    cookies.get("se_startDate") &&
    cookies.get("se_startDate") !== "" &&
    cookies.get("se_startDate") !== "undefined"
  ) {
    lastDate = cookies.get("se_startDate");
  } else {
    //cookies.set("startDate", lastDate.toISOString().split("T")[0]);
    let lastDate = new Date();
    lastDate.setMonth(lastDate.getMonth() - 1);
    cookies.set("se_startDate", lastDate.toISOString().split("T")[0]);
  }
  if (
    cookies.get("se_endDate") &&
    cookies.get("se_endDate") !== "" &&
    cookies.get("se_endDate") !== "undefined"
  ) {
    currentDate = cookies.get("se_endDate");
  } else {
    //cookies.set("endDate", currentDate.toISOString().split("T")[0]);
    let currentDate = new Date();
    cookies.set("se_endDate", currentDate.toISOString().split("T")[0]);
  }

  useEffect(() => {
    if (cookies.get("email") == null) {
      document.location.href = "/";
    }
    cookies.set("ms_retailer", "");
    cookies.set("ms_merchandiser", "");
    cookies.set("ms_country", "");
    cookies.set("retailer", "");
    cookies.set("pc_retailer", "");
    cookies.set("pc_product", "");
    cookies.set("ig_startDate", "");
    cookies.set("ig_startDate", "");
    cookies.set("ig_placement", "");
    cookies.set("ig_retailer", "");
  }, []);

  const exportMenus = (
    <Menu>
      <Menu.Item key="pdf">PDF</Menu.Item>
      <Menu.Item key="1">Excel</Menu.Item>
    </Menu>
  );

  const handlePeriodFilterChange = (e) => {
    setPeriodFilter(e.target.value);
  };

  const onHandleFilterChanged = async (data) => {
    var strCountry = "";
    if (
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value !== "null" &&
      data.COUNTRY_LIST.value !== "undefined" &&
      data.COUNTRY_LIST.value !== undefined &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("pr_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST.value &&
      data.REGION_LIST.value !== "null" &&
      data.REGION_LIST.value !== "undefined" &&
      data.REGION_LIST.value !== undefined &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    cookies.set("pr_region", strRegion);

if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      cookies.set("pr_includePromoProducts", !promotion[0].isChecked);
    } else cookies.set("pr_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0 &&
      data.SECONDARY_DISPLAY_SELECTION.value !== undefined
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      cookies.set("pr_includeSecondaryDisplay", secondary[0].isChecked);
    } else cookies.set("pr_includeSecondaryDisplay", false);

    setFilterData(data);
    convertDataForTags(data);

    // message.loading('Loading data...', 0);
    await Promise.all([
      fetchPlanogram(),
      //fetchBaseKpi(),
      //fetchBonusKpi(),
      //fetchNonComplianceAccount(),
      //fetchNonComplianceRetailer(),
    ]);
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = async (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "COUNTRY_LIST") {
      let country = cookies.get("pr_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("pr_country", country.join());
      if (country && country.length <= 0) {
        let strCountry = "";
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
        cookies.set("pr_country", strCountry);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = cookies.get("pr_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("pr_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("regions"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        cookies.set("pr_region", strRegion);
      }
    }
    if (item.key === "PROMOTION_LIST") {
      cookies.set("pr_includePromoProducts", true);
    }
    if (item.key === "SECONDARY_DISPLAY_SELECTION") {
      cookies.set("br_includeSecondaryDisplay", false);
    }

    await Promise.all([
      fetchPlanogram(),
      //fetchBaseKpi(),
      //fetchBonusKpi(),
      //fetchNonComplianceAccount(),
      //fetchNonComplianceRetailer(),
    ]);
  };

  const handleOK = (e) => {};
  return (
    <div className="Team-Activity-Overview-wp">
      <div className="title-wp">
        <div className="title-wp">Planogram Compliance</div>
      </div>
      <div className="condition-values-wp">
        <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
      </div>
      <div className="control-btns">
        <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
          <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
          <RangePicker
            showTime
            format={"YYYY-MM-DD"}
            onOk={handleOK}
            onCalendarChange={async (range, dateString, info) => {
              let startDate = moment(range[0]).format("YYYY-MM-DD");
              let endDate = moment(range[1]).format("YYYY-MM-DD");

              //if (info.range === "end") {
              //cookies.set("startDate", startDate);
              //cookies.set("endDate", endDate);
              cookies.set("se_startDate", startDate);
              cookies.set("se_endDate", endDate);

              // message.loading('Loading data...', 0);
              await Promise.all([
                fetchPlanogram(),
                //fetchBaseKpi(),
                //fetchBonusKpi(),
                //fetchNonComplianceAccount(),
                //fetchNonComplianceRetailer(),
              ]);
              //}
            }}
            defaultValue={[
              moment(lastDate, dateFormat),
              moment(currentDate, dateFormat),
            ]}
            allowClear={false}
            suffixIcon={
              <img alt="" style={{ marginTop: "3px" }} src={ICON_ARROW_DOWN} />
            }
          />
        </div>
        <CustomFilter
          useFilterBtnForOverview={true}
          selectedData={filterData}
          filterKeys={[
            CUSTOM_FILTER_KEYS.COUNTRY_LIST,
            CUSTOM_FILTER_KEYS.REGION_LIST,
            CUSTOM_FILTER_KEYS.PROMOTION_LIST,
            CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
          ]}
          onChange={(data) => {
            onHandleFilterChanged(data);
          }}
          isPlanogram={true}
        />
        {/*<Dropdown
          className="btn-item export-dropdown"
          overlay={exportMenus}
          trigger={["click"]}
        >
          <div>
            <img
              alt="icon-btn"
              className="arrow-icon"
              src={ICON_EXPORT_EXCEL}
            />
            <span>Export As</span>
            <img alt="" className="arrow-down-ic" src={ICON_ARROW_DOWN} />
          </div>
        </Dropdown>*/}
      </div>
    </div>
  );
};

export default StoreExecutionOverview;
