import React, { useState, useEffect } from 'react';
import { isEmpty, forEach, cloneDeep, find, filter } from 'lodash';
import storesList from "./data.json";
import MultiCheckboxSelection from "../MultiCheckboxSelection";
import { Tag, Input, Tooltip, Checkbox } from 'antd';
import './style.scss';

const StoresSelection = (props) => {
    const { defaultValue, onChange, } = props;
    const [storeData, setStoresData] = useState([]);
    const stores = JSON.parse(localStorage.getItem("stores"));

    useEffect(() => {
        generateStoresData(stores);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        combineStoresWithOutsideData(defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const generateStoresData = (data) => {
        let Regions = [];
        forEach(data, (sItem) => {
            Regions.push({
              id: sItem.store_id,
              label: sItem.store_name,
              isChecked: false,
            });
        })
        setStoresData(Regions);
    }

    const combineStoresWithOutsideData = (items) => {

        let Regions = [];
        forEach(stores, (sItem) => {
            Regions.push({
              id: sItem.store_id,
              label: sItem.store_name,
              isChecked: false,
            });
        })

        let clonedData = cloneDeep(Regions);

        forEach(clonedData, (sItem) => {
            const matched = find(items, { id: sItem.id });
            sItem.isChecked = !isEmpty(matched) ? matched.isChecked : false;
        })

        setStoresData(clonedData);
    }

    const onSelectStore = (mItem, isChecked) => {
        let clonedData = cloneDeep(storeData);

        let matched = find(clonedData, { id: mItem.id });
        if (matched) {
            matched.isChecked = isChecked;
        }
        forEach(clonedData, (sItem) => {
            if (sItem.id !== matched.id)
              sItem.isChecked = false;
        });
        setStoresData(clonedData);

        const selectedItems = filter(clonedData, { isChecked: true });
        onChange(selectedItems);
    };

    const onSelectAll = (isChecked) => {
        if (isChecked===true) {
          let clonedData = cloneDeep(storeData);
          forEach(clonedData, (sItem) => {
            sItem.isChecked = isChecked;
          });
          setStoresData(clonedData);
          onChange(clonedData);
        }
        else{
          let clonedData = cloneDeep(null);
          onChange(clonedData);
        }
      };

    return (
        <div className="custom-table-StoresSelection">
            <div className="StoresSelection-wp">
                <MultiCheckboxSelection
                    showSelectAll={false}
                    data={storeData}
                    onCheckItem={onSelectStore}
                    onCheckAll={onSelectAll}
                />
            </div>
        </div>
    );
}

StoresSelection.defaultProps = {
    selectedItems: [{ id: '', label: '', isChecked: false }],
    onChange: (value) => { }
}

export default StoresSelection;