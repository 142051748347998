import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import "./style.scss";

const ICON_DECREASE = require("../../assets/img/icons/ic-decrease-red.svg");
const ICON_INCREASE = require("../../assets/img/icons/ic-increase-green.svg");

const SpaceBuy = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      percent: 30,
      stores: 10,
      completedStores: 9,
      isIncrease: false,
      increasePercent: 10,
    });
  }, []);

  return (
    <div className="Total-Audits-Completed-wp">
      <div className="row">
        <div className="col-100">
          <div className="summary-completed-stores">Space Buy ($)</div>
          <div className="summary-detail">-</div>
        </div>
      </div>
    </div>
  );
};

export default SpaceBuy;
