import React from "react";
import { GroupedColumn } from "@ant-design/charts";
import "./style.scss";

const TotalOfFacingsChart = (props) => {
  const data = props.facingData?[...props.facingData].reverse():[];
  const data_sa = [
    {
      month: "January",
      facings: 0,
    },
    {
      month: "February",
      facings: 0,
    },
    {
      month: "March",
      facings: 0,
    },
    {
      month: "April",
      facings: 0,
    },
    {
      month: "May",
      facings: 0,
    },
    {
      month: "June",
      facings: 0,
    },
    {
      month: "July",
      facings: 0,
    },
    {
      month: "August",
      facings: 0,
    },
    {
      month: "September",
      facings: 0,
    },
    {
      month: "October",
      facings: 0,
    },
    {
      month: "November",
      facings: 0,
    },
    {
      month: "December",
      facings: 0,
    },
  ];
  
   const config_sa = {
     title: {
       visible: false,
       text: "No. of facing",
     },
     forceFit: true,
     data: data.length > 0 ? data : data_sa,
     xField: "month",
     yField: "facings",
     label: { visible: false },
     groupField: "name",
     //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
     //color: ["#4A5DE8", "#A4AEF3", "#CDD2FB", "#4F5058"],
     color: [
       "#2b9ddb",
       "#7cfac3",
       "#00ab84",
       "#394856",
       "#e57364",
       "#4e79a7",
       "#59a14f",
       "#9c755f",
       "#f28e2b",
       "#edc948",
       "#bab0ac",
       "#e15759",
       "#b07aa1",
       "#76b7b2",
       "#ff9da7",
       "#2b9ddb",
       "#7cfac3",
       "#00ab84",
       "#394856",
       "#e57364",
       "#4e79a7",
       "#59a14f",
       "#9c755f",
       "#f28e2b",
       "#edc948",
       "#bab0ac",
       "#e15759",
       "#b07aa1",
       "#76b7b2",
       "#ff9da7",
       "#2b9ddb",
       "#7cfac3",
       "#00ab84",
       "#394856",
       "#e57364",
       "#4e79a7",
       "#59a14f",
       "#9c755f",
       "#f28e2b",
       "#edc948",
       "#bab0ac",
       "#e15759",
       "#b07aa1",
       "#76b7b2",
       "#ff9da7",
       "#2b9ddb",
       "#7cfac3",
       "#00ab84",
       "#394856",
       "#e57364",
       "#4e79a7",
       "#59a14f",
       "#9c755f",
       "#f28e2b",
       "#edc948",
       "#bab0ac",
       "#e15759",
       "#b07aa1",
       "#76b7b2",
       "#ff9da7",
       "#2b9ddb",
       "#7cfac3",
       "#00ab84",
       "#394856",
       "#e57364",
       "#4e79a7",
       "#59a14f",
       "#9c755f",
       "#f28e2b",
       "#edc948",
       "#bab0ac",
       "#e15759",
       "#b07aa1",
       "#76b7b2",
       "#ff9da7",
     ],
     yAxis: {
       title: {
         text: "No. of facings",
         visible: true,
         style: {
           fill: "#4A5DE8",
           fontSize: 13,
           fontWeight: "bold",
         },
       },
       min: 0,
     },
     xAxis: {
       title: {
         text: "Month",
         visible: true,
         style: {
           fill: "#4A5DE8",
           fontSize: 13,
           fontWeight: "bold",
         },
       },
       label: {
         style: {
           fill: "#000",
           fontSize: 14,
           fontWeight: "bold",
         },
       },
     },
   };

  return (
    <div className="Completed-Store-Audits-Chart-wp">
      
      
      <div className="separate-line" />
      <GroupedColumn {...config_sa} />
    </div>
  );
};

export default TotalOfFacingsChart;
