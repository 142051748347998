import React, { useEffect, useRef } from "react";

const CustomCheckbox = (props) => {
  const inputEl = useRef(null);
  const { data, onCheck, onCheckAll, isChecked } = props;

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.checked = data.isChecked;
    }
  }, [data]);

  const onHandleCheckboxClicked = (e) => {
    if (inputEl && inputEl.current) {
      data.isChecked === true
        ? (data.isChecked = false)
        : (data.isChecked = true);
      onCheck(data, data.isChecked);
    }
  };

  return (
    <div className="customCheckbox-wp">
      <label class="custom-checkbox-item" className={data.className}>
        <input
          ref={inputEl}
          type="checkbox"
          value={data.id}
          checked={data.isChecked}
          onClick={(e) => {
            onHandleCheckboxClicked();
          }}
        />
        <span />
        <div>{data.label}</div>
      </label>
    </div>
  );
};

export default CustomCheckbox;
