/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Pagination } from "antd";
import "./style.scss";

const PriceScans = ({ data }) => {
  const [img, setImg] = useState([]);
  const [visible, setVisible] = useState(false);
  const [displayPriceScans, setDisplayPriceScans] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleData = (result) => {
    const arrImg = [];
    for (let i = 0; i < result.length; i++) {
      if (result[i].images) {
        for (let j = 0; j < result[i].images.length; j++) {
          arrImg.push({
            img: result[i].images[j],
            auditor: result[i].auditor,
            date: result[i].dateEnd,
          });
        }
      }
    }
    // removeLoader(loaderId);
    if (arrImg.length <= 0) setDisplayPriceScans("none");
    else setDisplayPriceScans("");
    setImg(arrImg);
  };

  useEffect(() => {
    handleData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = img.slice(indexOfFirstItem, indexOfLastItem);

  const renderItems = currentItems.map((item, index) => {
    return (
      <Col span={4} style={{ padding: 10 }} key={index}>
        <img
          src={item.img}
          style={{ width: 200, borderRadius: 7 }}
          onClick={() => showModal(item.img)}
        />
        <br />
        <br />
        Audit Date: {item.date}
        <br />
        Auditor: {item.auditor}
      </Col>
    );
  });

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(img.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    let classActive = "";
    if (number === currentPage) {
      classActive = "active";
    }
    return (
      <li
        className={classActive}
        key={number}
        id={number}
        onClick={handleClick}
      >
        {number}
      </li>
    );
  });

  const showModal = (img) => {
    setImageURL(img);
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <div
      className="OverdueStoreAudits-wp"
      style={{ display: displayPriceScans }}
    >
      <Modal
        title="Image"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1080}
        bodyStyle={{ height: 1000 }}
        style={{ marginTop: -90 }}
      >
        <img src={imageURL} style={{ borderRadius: 7 }} width="1024" />
      </Modal>
      <Row>
        <Col span={24}>
          <div>
            <div
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Price Scans
              <br />
              <br />
            </div>
            <Row>{renderItems}</Row>
            <Row style={{ marginTop: 10 }}>
              <Col
                span={24}
                style={{
                  padding: 10,
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Pagination
                  onChange={handleChange}
                  defaultCurrent={1}
                  total={img.length}
                  showSizeChanger={false}
                  pageSize={6}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PriceScans;
