/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Progress, Row, Col, Button } from "antd";
import "./style.scss";
import Cookies from "universal-cookie";
import { BACKEND_URL } from "../../constants/constants";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-increase-green.svg");
const cookies = new Cookies();

const OnShelfAvailability = ({ data, dataCompetitor }) => {
  const [osa, setOsa] = useState();
  useEffect(() => {}, []);

  function sortResults(prop, asc) {
    const clone = data.data.slice(0);
    clone.sort(function (a, b) {
      if (asc) {
        return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
      } else {
        return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
      }
    });
    setOsa(clone);
  }

  function upData() {
    sortResults("avgOsa", true);
  }

  function downData() {
    sortResults("avgOsa", false);
  }

  return (
    <div className="Total-Audits-Completed-wp">
      <Row gutter={[24, 16]}>
        <Col span={11}>
          <div className="hd-left-col"></div>
        </Col>
        <Col span={11}>
          <div className="hd-middle-col"></div>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <div className="ReportTableHeader">
            <div className="hd-right-col">
              <Button
                className="hd-btn"
                //loading={showFilterLoading}
                onClick={upData}
              >
                <UpCircleOutlined style={{ fontSize: 20 }} />
              </Button>
              <Button
                className="hd-btn"
                //loading={showFilterLoading}
                onClick={downData}
              >
                <DownCircleOutlined style={{ fontSize: 20 }} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        {osa === undefined &&
          data &&
          data.data.map((item) => (
            <Col span={4}>
              <div className="summary-detail">
                <Progress
                  type="circle"
                  percent={item.avgOsa}
                  strokeWidth={10}
                  strokeColor={
                    item.avgOsa > data.target ? "#87d068" : "#E5305B"
                  }
                  format={() =>
                    item.avgOsa > data.target ? (
                      <div style={{ color: "#87d068" }}>{item.avgOsa}%</div>
                    ) : (
                      <div style={{ color: "#E5305B" }}>{item.avgOsa}%</div>
                    )
                  }
                  width={80}
                />
              </div>
              <div className="summary-completed-stores">
                {item.manufacturer}
              </div>
            </Col>
          ))}
        {osa &&
          osa.map((item) => (
            <Col span={4}>
              <div className="summary-detail">
                <Progress
                  type="circle"
                  percent={item.avgOsa}
                  strokeWidth={10}
                  strokeColor={
                    item.avgOsa > data.target ? "#87d068" : "#E5305B"
                  }
                  format={() =>
                    item.avgOsa > data.target ? (
                      <div style={{ color: "#87d068" }}>{item.avgOsa}%</div>
                    ) : (
                      <div style={{ color: "#E5305B" }}>{item.avgOsa}%</div>
                    )
                  }
                  width={80}
                />
              </div>
              <div className="summary-completed-stores">
                {item.manufacturer}
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default OnShelfAvailability;
