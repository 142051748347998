import {
  isEmpty,
  clone,
  isObject,
  isUndefined,
  isNumber as _isNumber,
  find,
  assignIn,
  isString,
  forIn,
  forEach,
  remove,
} from "lodash";
import { CUSTOM_FILTER_KEYS } from "../constants/constants";

export function convertDataForTags(data) {
  let tags = [];
  forIn(data, (filterItem, key) => {
    if (!isEmpty(filterItem.value)) {
      if (filterItem.key === CUSTOM_FILTER_KEYS.REGION_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.STORES_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.CHANNEL_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.MANUFACTURER_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.COUNTRY_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (
        filterItem.key === CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION
      ) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.RETAILER_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.SALES_REP_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.MERCHANDISER_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.BRAND_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.OSA_BRAND_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.PACK_SIZE_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.FLAVOUR_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.PRODUCT_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (
        filterItem.key === CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION
      ) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
        /*} else if (filterItem.key === CUSTOM_FILTER_KEYS.PROMOTION_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });*/
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (
        filterItem.key === CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST
      ) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.PROMOTION_LIST) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      } else if (filterItem.key === CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION) {
        forEach(filterItem.value, (fValue) => {
          tags.push({
            key: filterItem.key,
            value: fValue.label,
            checkboxId: fValue.id,
          });
        });
      }
      // else if (
      //   filterItem.key === CUSTOM_FILTER_KEYS.PROMO_SELECTION
      // ) {
      //   forEach(filterItem.value, (fValue) => {
      //     tags.push({
      //       key: filterItem.key,
      //       value: fValue.label,
      //       checkboxId: fValue.id,
      //     });
      //   });
      // }
    }
  });
  return tags;
}

export function removeTag(data, removedItem) {
  let matched = data[removedItem.key];
  if (matched) {
    if (removedItem.key === CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN) {
      remove(matched.value, (rItem) => {
        return rItem.label === removedItem.value;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.REGION_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.label === removedItem.value;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.STORES_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.CHANNEL_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.MANUFACTURER_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.RETAILER_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.SALES_REP_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.MERCHANDISER_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.BRAND_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.OSA_BRAND_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.PACK_SIZE_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.FLAVOUR_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.PRODUCT_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.PROMOTION_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
    if (removedItem.key === CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST) {
      remove(matched.value, (rItem) => {
        return rItem.id === removedItem.checkboxId;
      });
    }
     if (removedItem.key === CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION) {
       remove(matched.value, (rItem) => {
         return rItem.id === removedItem.checkboxId;
       });
     }
    // if (removedItem.key === CUSTOM_FILTER_KEYS.PROMO_SELECTION) {
    //   remove(matched.value, (rItem) => {
    //     return rItem.id === removedItem.checkboxId;
    //   });
    // }
  }
  return data;
}
