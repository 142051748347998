import React, { useState } from "react";
import { Modal } from 'antd'
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";

const BarChart = ({ data, colors }) => {
  return <div style={{marginBottom: 50}}>
    {
      data.map((dataItem, index) => {
        
        return <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', width: '100%', marginTop: 21 }}>
          <div>
            <span style={{ display: 'inline-block', width: `${dataItem.avgPercentage}%`, backgroundColor: colors[index] || '#4A5DE8', height: 20, transition: 'width 2s' }}></span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '0.875rem', fontWeight: 500, }}>{dataItem.avgPercentage}%</span>
            <span style={{ fontSize: '0.875rem', fontWeight: 600, }}>{dataItem.name}</span>
          </div>
        </div>
      })
    }
  </div>;
};

const PromotionDepth = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const colors = ["#2b9ddb", "#7cfac3", "#00ab84", "#394856", "#e57364", "#4e79a7", "#59a14f", "#9c755f", "#f28e2b", "#edc948", "#bab0ac", "#e15759", "#b07aa1", "#76b7b2", "#ff9da7", "#2b9ddb", "#7cfac3", "#00ab84", "#394856", "#e57364", "#4e79a7", "#59a14f", "#9c755f", "#f28e2b", "#edc948", "#bab0ac", "#e15759", "#b07aa1", "#76b7b2", "#ff9da7", "#2b9ddb", "#7cfac3", "#00ab84", "#394856", "#e57364", "#4e79a7", "#59a14f", "#9c755f", "#f28e2b", "#edc948", "#bab0ac", "#e15759", "#b07aa1", "#76b7b2", "#ff9da7", "#2b9ddb", "#7cfac3", "#00ab84", "#394856", "#e57364", "#4e79a7", "#59a14f", "#9c755f", "#f28e2b", "#edc948", "#bab0ac", "#e15759", "#b07aa1", "#76b7b2", "#ff9da7", "#2b9ddb", "#7cfac3", "#00ab84", "#394856", "#e57364", "#4e79a7", "#59a14f", "#9c755f", "#f28e2b", "#edc948", "#bab0ac", "#e15759", "#b07aa1", "#76b7b2", "#ff9da7"];

  const modalData = data;
  data = data.slice(0, 5);

  return (
    <div className="OverdueStoreAudits-wp" style={{ minHeight: 300 }}>
      <ReportTableHeader
        title="Promotion Depth"
        subTitle="Avg. Variance from Regular Price (%)"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <BarChart data={data} colors={colors} />
      <Modal
        title="Promotion Depth"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <BarChart data={modalData} colors={colors} />
      </Modal>
      <div className="view-all-btn-wp abs-position">
        <ViewAllButton onClick={() => setVisible(true)} title="View all" />
      </div>
    </div>
  );
};

export default PromotionDepth;
