import React, { useState, useEffect } from "react";
import moment from "moment";
import HeaderStoreAuditsChart from "../../componentsShared/HeaderStoreAuditsChart";
import { Column, GroupedColumn } from "@ant-design/charts";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";
import { Table, message, DatePicker } from "antd";
import CustomFilter from "../../componentsShared/CustomFilter";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import FilterTags from "../../componentsShared/FilterTags";
import * as filterDataHanler from "../../utils/filterDataHanler";
import { encode } from "base-64";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const dateFormat = "YYYY-MM-DD";

const OSAKelloggVsCompetitorChart = ({ addLoader, removeLoader }) => {
  const [data_, setData_] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  // const [tableConfigs, setTableConfigs] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [osaVsData, setOsaVsData] = useState({ chart: [] });
  let [brand, setBrand] = useState(cookies.get("osa_vs_brand"));
  let [retailer, setRetailer] = useState(cookies.get("osa_vs_retailer"));
  let [startDate, setStartDate] = useState(cookies.get("se_startDate"));
  let [endDate, setEndDate] = useState(cookies.get("se_endDate"));
  const [manufacturer, setManufacturer] = useState(
    cookies.get("manufacturer_name")
  );
  const [tagsData, setTagsData] = useState([]);

  const { RangePicker } = DatePicker;

  var lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - 1);
  var currentDate = new Date();

  async function refreshOsaVsCompetitor() {
    // message.loading("Loading data...", 0);
    const loaderId = "se_refreshosavscompetitor";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getosavscompetitor?manufacturer=${cookies.get(
      "manufacturer"
    )}&categories=${cookies.get("osa_vs_category")}&countries=${cookies.get(
      "osa_vs_country"
    )}&regions=${cookies.get("osa_vs_region")}&retailers=${cookies.get(
      "osa_vs_retailer"
    )}&brands=${cookies.get("osa_vs_brand")}&includePromoProducts=${cookies.get(
      "osa_vs_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "osa_vs_includeSecondaryDisplay"
    )}`;

    await fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then(async (result) => {
        removeLoader(loaderId);
        setOsaVsData(result.table.reverse());
        // message.destroy();
        //setOsaVsData([result.table].reverse());
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  }

  const fetchOsaVs = () => {
    const loaderId = "se_fetchosavs";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getosavscompetitor?startDate=${cookies.get(
      "se_startDate"
    )}&endDate=${cookies.get("se_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&category=${cookies.get("osa_vs_category")}&brand=${cookies.get(
      "osa_vs_brand"
    )}&country=${cookies.get("osa_vs_country")}&retailer=${cookies.get(
      "osa_vs_retailer"
    )}&includePromoProducts=${cookies.get(
      "osa_vs_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "osa_vs_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setOsaVsData(result.table.reverse());
        //setOsaVsData([result.table].reverse());
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "BRAND_LIST") {
      let brand = cookies.get("osa_vs_brand").split(",");
      brand = brand.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("osa_vs_brand", brand.join());
      if (brand && brand.length <= 0) {
        let strBrand = "";
        const brands = JSON.parse(localStorage.getItem("brands"));
        if (brands) {
          forEach(brands, (item) => {
            strBrand = strBrand + item.brand_id + ",";
          });
        }
        strBrand = strBrand.substring(0, strBrand.length - 1);
        cookies.set("osa_vs_brand", strBrand);
      }
    }
    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("osa_vs_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("osa_vs_retailer", retailer.join());
      if (retailer && retailer.length <= 0) {
        let strRetailer = "";
        const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
        cookies.set("osa_vs_retailer", strRetailer);
      }
    }
    if (item.key === "COUNTRY_LIST") {
      let country = cookies.get("osa_vs_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("osa_vs_country", country.join());
      if (country && country.length <= 0) {
        let strCountry = "";
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
        cookies.set("osa_vs_country", strCountry);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = cookies.get("osa_vs_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("osa_vs_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("brands"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        cookies.set("osa_vs_region", strRegion);
      }
    }
    if (item.key === "PRODUCT_CATEGORY_LIST") {
      let category = cookies.get("osa_vs_category").split(",");
      category = category.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("osa_vs_category", category.join());
      if (category && category.length <= 0) {
        let strCategory = "";
        const categories = JSON.parse(localStorage.getItem("categories"));
        if (categories) {
          forEach(categories, (item) => {
            strCategory = strCategory + item.retailer_id + ",";
          });
        }
        strCategory = strCategory.substring(0, strCategory.length - 1);
        cookies.set("osa_vs_category", strCategory);
      }
    }
    if (item.key === "PROMO_SELECTION") {
      cookies.set("osa_vs_includePromoProducts", true);
    }
    if (item.key === "PROMO_SELECTION") {
      cookies.set("osa_vs_includePromoProducts", false);
    }
    refreshOsaVsCompetitor();
  };

  function getDataByFilterOsaVsCompetitor(
    country,
    region,
    retailer,
    brand,
    includePromoProducts,
    includeSecondaryDisplay
  ) {
    var strCountry = "";
    if (country && country.length > 0) {
      for (var i = 0; i < country.length; i++) {
        strCountry = strCountry + country[i].id + ",";
      }
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);

    var strRegion = "";
    if (region) {
      for (var i = 0; i < region.length; i++) {
        strRegion = strRegion + region[i].id + ",";
      }
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);

    var strRetailer = "";
    if (retailer) {
      for (var i = 0; i < retailer.length; i++) {
        strRetailer = strRetailer + retailer[i].id + ",";
      }
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);

    var strBrand = "";
    if (brand) {
      for (var i = 0; i < brand.length; i++) {
        strBrand = strBrand + brand[i].id + ",";
      }
    } else {
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
    }
    strBrand = strBrand.substring(0, strBrand.length - 1);

    const isChecked = includePromoProducts[0]
      ? includePromoProducts[0].isChecked
      : true;

    const isCheckedSecondary = includeSecondaryDisplay[0]
      ? includeSecondaryDisplay[0].isChecked
      : false;

    cookies.set("osa_vs_country", strCountry);
    cookies.set("osa_vs_region", strRegion);
    cookies.set("osa_vs_retailer", strRetailer);
    cookies.set("osa_vs_brand", strBrand);
    cookies.set("osa_vs_includePromoProducts", isChecked);
    cookies.set("osa_vs_includeSecondaryDisplay", isCheckedSecondary);
  }

  const onHandleFilterVsCompetitorChanged = (data) => {
    var strBrand = "";
    if (
      data.BRAND_LIST &&
      data.BRAND_LIST.value &&
      data.BRAND_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.BRAND_LIST.value.length; i++) {
        strBrand = strBrand + data.BRAND_LIST.value[i].id + ",";
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    } else {
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    }
    cookies.set("osa_vs_brand", strBrand);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("osa_vs_retailer", strRetailer);

    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("osa_vs_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    cookies.set("osa_vs_region", strRegion);

    var strCategory = "";
    if (
      data.PRODUCT_CATEGORY_LIST &&
      data.PRODUCT_CATEGORY_LIST.value &&
      data.PRODUCT_CATEGORY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.PRODUCT_CATEGORY_LIST.value.length; i++) {
        strCategory =
          strCategory + data.PRODUCT_CATEGORY_LIST.value[i].id + ",";
      }
      strCategory = strCategory.substring(0, strCategory.length - 1);
    } else {
      const categories = JSON.parse(localStorage.getItem("categories"));
      if (categories) {
        forEach(categories, (item) => {
          strCategory = strCategory + item.category_id + ",";
        });
      }
      strCategory = strCategory.substring(0, strCategory.length - 1);
    }
    cookies.set("osa_vs_category", strCategory);

if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      cookies.set("osa_vs_includePromoProducts", !promotion[0].isChecked);
    } else cookies.set("osa_vs_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== undefined &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      cookies.set("osa_vs_includeSecondaryDisplay", secondary[0].isChecked);
    } else cookies.set("osa_vs_includeSecondaryDisplay", false);
    refreshOsaVsCompetitor();
    setFilterData(data);
    convertDataForTags(data);
  };

  function timer() {
    //refreshOsaVsCompetitor();
  }

  useEffect(() => {
    const loaderId = "se_manufuredeail";
    addLoader(loaderId);
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);

    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    //end get all countries & regions
    const url = `${BACKEND_URL_AZURE}/getosavscompetitor?manufacturer=${cookies.get(
      "manufacturer"
    )}&countries=${strCountry}&retailers=${strRetailer}&regions=${strRegion}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setOsaVsData(result.message.reverse());
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data_osa = [
    {
      month: "January",
      "OSA %": 0,
    },
    {
      month: "February",
      "OSA %": 0,
    },
    {
      month: "March",
      "OSA %": 0,
    },
    {
      month: "April",
      "OSA %": 0,
    },
    {
      month: "May",
      "OSA %": 0,
    },
    {
      month: "June",
      "OSA %": 0,
    },
    {
      month: "July",
      "OSA %": 0,
    },
    {
      month: "August",
      "OSA %": 0,
    },
    {
      month: "September",
      "OSA %": 0,
    },
    {
      month: "October",
      "OSA %": 0,
    },
    {
      month: "November",
      "OSA %": 0,
    },
    {
      month: "December",
      "OSA %": 0,
    },
  ];

  const config_osa = {
    title: {
      visible: false,
      text: "OSA %",
    },
    forceFit: true,
    data: osaVsData.length > 0 ? osaVsData : data_osa,
    xField: "month",
    yField: "OSA %",
    //yAxis: { min: 0 },
    label: { visible: false },
    groupField: "name",
    //color: ["#4472C4", "#ED7D31", "#558236", "#7131A0"],
    //color: ["#4A5DE8", "#A4AEF3", "#A4AEF3", "#4F5058"],
    color: [
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
    ],
    yAxis: {
      title: {
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      min: 0,
    },
    xAxis: {
      title: {
        text: "Month",
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      label: {
        style: {
          fill: "#000",
          fontSize: 14,
          fontWeight: "bold",
        },
      },
    },
  };

  return (
    <>
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <div className="Team-Activity-Overview-wp">
            <div className="title-wp">
              <div className="title-wp">OSA - {manufacturer} Vs Competitor</div>
            </div>
            <div className="condition-values-wp">
              <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
            </div>
            <div className="control-btns">
              <CustomFilter
                useFilterBtnForOverview={true}
                selectedData={filterData}
                filterKeys={[
                  CUSTOM_FILTER_KEYS.COUNTRY_LIST,
                  CUSTOM_FILTER_KEYS.REGION_LIST,
                  CUSTOM_FILTER_KEYS.RETAILER_LIST,
                  CUSTOM_FILTER_KEYS.BRAND_LIST,
                  CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST,
                  CUSTOM_FILTER_KEYS.PROMOTION_LIST,
                  CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
                ]}
                isPlanogram={false}
                onChange={(data) => {
                  onHandleFilterVsCompetitorChanged(data);
                }}
                onFilterChanged={(filters) => {
                  getDataByFilterOsaVsCompetitor(
                    filters.COUNTRY_LIST.value,
                    filters.REGION_LIST.value,
                    filters.RETAILER_LIST.value,
                    filters.BRAND_LIST.value,
                    filters.PRODUCT_CATEGORY_LIST.value,
                    filters.PROMOTION_LIST.value,
                    filters.SECONDARY_DISPLAY_SELECTION.value
                  );
                  //setFilterDataOsaVsCompetitor(filters);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="separate-line mb-30" />
      <div className="Completed-Store-Audits-Chart-wp">
        <div className="separate-line" />
        <GroupedColumn {...config_osa} />
      </div>
    </>
  );
};

export default OSAKelloggVsCompetitorChart;
