import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import countriesList from "./countriesData.json";
import Select from 'react-select'
import './style.scss';

const CountryCodesDropdown = (props) => {
    const { defaultValue, onChange, } = props;
    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        if (isEmpty(defaultValue)) {
            setSelectedCountry([]);
        } else {
            setSelectedCountry(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const onChangeCountry = (mItem) => {
        onChange(mItem);
        setSelectedCountry(mItem);
    };

    return (
        <div className="custom-table-countryCodeFilter">
            <div className="countryCodeDropdown-wp">
                <Select
                    isMulti={true}
                    menuIsOpen={true}
                    value={selectedCountry}
                    options={countriesList}
                    onChange={onChangeCountry}
                />
            </div>
        </div>
    );
}

CountryCodesDropdown.defaultProps = {
    defaultValue: [{ key: '', name: '' }],
    onChange: (value) => { }
}

export default CountryCodesDropdown;