import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, message } from "antd";
import Cookies from "universal-cookie";
import FilterTags from "../../componentsShared/FilterTags";
import AvgOfFacingBySku from "../AvgOfFacingBySku";
import AvgOfFacingsByBrandChart from "../AvgOfFacingsByBrandChart";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import {
  BACKEND_URL,
  CUSTOM_FILTER_KEYS,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
import CustomFilter from "../../componentsShared/CustomFilter";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format("YYYY-MM-DD");
let currentDate = new Date();
currentDate = moment(currentDate).format("YYYY-MM-DD");

const AvgOfFacingsByBrand = ({ addLoader, removeLoader }) => {
  const [tagsData, setTagsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [facingData, setFacingData] = useState({ chart: [], table: [] });

  if (
    localStorage.getItem("br_startDate") &&
    localStorage.getItem("br_startDate") !== "" &&
    localStorage.getItem("br_startDate") !== "undefined"
  ) {
    lastDate = localStorage.getItem("br_startDate");
  }
  if (
    localStorage.getItem("br_endDate") &&
    localStorage.getItem("br_endDate") !== "" &&
    localStorage.getItem("br_endDate") !== "undefined"
  ) {
    currentDate = localStorage.getItem("br_endDate");
  }

  if (
    !localStorage.getItem("br_startDate") ||
    !localStorage.getItem("br_endDate")
  ) {
    localStorage.setItem("br_startDate", lastDate);
    localStorage.setItem("br_endDate", currentDate);
  }

  const fetchFacing = () => {
    const loaderId = "se_avgfacingbrand";
    addLoader(loaderId);

    if (
      localStorage.getItem("br_category") === undefined ||
      localStorage.getItem("br_category") === "undefined" ||
      localStorage.getItem("br_category") === "" ||
      localStorage.getItem("br_category") === null
    ) {
      /*var strCategory = "";
      const categories = JSON.parse(localStorage.getItem("categories"));
      if (categories) {
        forEach(categories, (item) => {
          strCategory = strCategory + item.category_id + ",";
        });
      }
      strCategory = strCategory.substring(0, strCategory.length - 1);
      localStorage.setItem("br_category", strCategory);*/
      localStorage.setItem("br_category", "");
    }

    if (
      localStorage.getItem("br_brand") === undefined ||
      localStorage.getItem("br_brand") === "undefined" ||
      localStorage.getItem("br_brand") === "" ||
      localStorage.getItem("br_brand") === null
    ) {
      var strBrand = "";
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
      localStorage.setItem("br_brand", strBrand);
    }

    if (
      localStorage.getItem("br_country") === undefined ||
      localStorage.getItem("br_country") === "undefined" ||
      localStorage.getItem("br_country") === "" ||
      localStorage.getItem("br_country") === null
    ) {
      var strCountry = "";
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
      localStorage.setItem("br_country", strCountry);
    }

    if (
      localStorage.getItem("br_region") === undefined ||
      localStorage.getItem("br_region") === "undefined" ||
      localStorage.getItem("br_region") === "" ||
      localStorage.getItem("br_region") === null
    ) {
      var strRegion = "";
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
      localStorage.setItem("br_region", strRegion);
    }

    if (
      localStorage.getItem("br_retailer") === undefined ||
      localStorage.getItem("br_retailer") === "undefined" ||
      localStorage.getItem("br_retailer") === "" ||
      localStorage.getItem("br_retailer") === null
    ) {
      var strRetailer = "";
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
      localStorage.setItem("br_retailer", strRetailer);
    }

    if (
      localStorage.getItem("br_product") === undefined ||
      localStorage.getItem("br_product") === "undefined" ||
      localStorage.getItem("br_product") === "" ||
      localStorage.getItem("br_product") === null
    ) {
      var strProduct = "";
      /*const products = JSON.parse(localStorage.getItem("products"));
      if (products) {
        forEach(products, (item) => {
          strProduct = strProduct + item.product_id + ",";
        });
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);*/
      localStorage.setItem("br_product", strProduct);
    }

    if (
      localStorage.getItem("br_includePromoProducts") === undefined ||
      localStorage.getItem("br_includePromoProducts") === "undefined" ||
      localStorage.getItem("br_includePromoProducts") === "" ||
      localStorage.getItem("br_includePromoProducts") === null
    ) {
      localStorage.setItem("br_includePromoProducts", true);
    }

    if (
      localStorage.getItem("br_includeSecondaryDisplay") === undefined ||
      localStorage.getItem("br_includeSecondaryDisplay") === "undefined" ||
      localStorage.getItem("br_includeSecondaryDisplay") === "" ||
      localStorage.getItem("br_includeSecondaryDisplay") === null
    ) {
      localStorage.setItem("br_includeSecondaryDisplay", false);
    }

    const url = `${BACKEND_URL_AZURE}/getfacingbybrand?startDate=${localStorage.getItem(
      "br_startDate"
    )}&endDate=${localStorage.getItem("br_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&categories=${localStorage.getItem(
      "br_category"
    )}&brands=${localStorage.getItem(
      "br_brand"
    )}&countries=${localStorage.getItem(
      "br_country"
    )}&regions=${localStorage.getItem(
      "br_region"
    )}&retailers=${localStorage.getItem(
      "br_retailer"
    )}&subcategory=${localStorage.getItem(
      "br_subcategory"
    )}&products=${localStorage.getItem(
      "br_product"
    )}&includePromoProducts=${localStorage.getItem(
      "br_includePromoProducts"
    )}&includeSecondaryDisplay=${localStorage.getItem(
      "br_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setFacingData(result);
        // message.destroy();
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const onHandleFilterChanged = (data) => {
    var strCategory = "";
    if (
      data.PRODUCT_CATEGORY_LIST &&
      data.PRODUCT_CATEGORY_LIST.value &&
      data.PRODUCT_CATEGORY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.PRODUCT_CATEGORY_LIST.value.length; i++) {
        strCategory =
          strCategory + data.PRODUCT_CATEGORY_LIST.value[i].id + ",";
      }
      strCategory = strCategory.substring(0, strCategory.length - 1);
      //localStorage.setItem("br_category", strCategory);
    } else {
      const categories = JSON.parse(localStorage.getItem("categories"));
      if (categories) {
        forEach(categories, (item) => {
          strCategory = strCategory + item.category_id + ",";
        });
      }
      strCategory = strCategory.substring(0, strCategory.length - 1);
      
      //localStorage.setItem("br_category", "");
    }
    localStorage.setItem("br_category", strCategory);

    var strSubCategory = "";
    if (
      data.SUB_CATEGORY_LIST &&
      data.SUB_CATEGORY_LIST.value &&
      data.SUB_CATEGORY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.SUB_CATEGORY_LIST.value.length; i++) {
        strSubCategory =
          strSubCategory + data.SUB_CATEGORY_LIST.value[i].id + ",";
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);
    } else {
      /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
      if (subcategories) {
        forEach(subcategories, (item) => {
          strSubCategory = strSubCategory + item.subcategory_id + ",";
        });
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
      strSubCategory = "";
    }
     localStorage.setItem("br_subcategory", strSubCategory);

    var strBrand = "";
    if (
      data.BRAND_LIST &&
      data.BRAND_LIST.value &&
      data.BRAND_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.BRAND_LIST.value.length; i++) {
        strBrand = strBrand + data.BRAND_LIST.value[i].id + ",";
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    } else {
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    }
    localStorage.setItem("br_brand", strBrand);

    var strProduct = "";
    if (
      data.PRODUCT_LIST &&
      data.PRODUCT_LIST.value &&
      data.PRODUCT_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.PRODUCT_LIST.value.length; i++) {
        strProduct = strProduct + data.PRODUCT_LIST.value[i].id + ",";
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);
    } else {
      /*const products = JSON.parse(localStorage.getItem("products"));
      if (products) {
        forEach(products, (item) => {
          strBrand = strBrand + item.product_id + ",";
        });
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);*/
      strProduct = "";
    }
    localStorage.setItem("br_product", strProduct);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    localStorage.setItem("br_retailer", strRetailer);

    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    localStorage.setItem("br_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    localStorage.setItem("br_region", strRegion);

    if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      localStorage.setItem("br_includePromoProducts", !promotion[0].isChecked);
    } else localStorage.setItem("br_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== undefined &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      localStorage.setItem(
        "br_includeSecondaryDisplay",
        secondary[0].isChecked
      );
    } else localStorage.setItem("br_includeSecondaryDisplay", false);
    setFilterData(data);
    convertDataForTags(data);
    fetchFacing();
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    // message.destroy();
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "BRAND_LIST") {
      let brand = localStorage.getItem("br_brand").split(",");
      brand = brand.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("br_brand", brand.join());
      if (brand && brand.length <= 0) {
        let strBrand = "";
        const brands = JSON.parse(localStorage.getItem("brands"));
        if (brands) {
          forEach(brands, (item) => {
            strBrand = strBrand + item.brand_id + ",";
          });
        }
        strBrand = strBrand.substring(0, strBrand.length - 1);
        localStorage.setItem("br_brand", strBrand);
      }
    }
    if (item.key === "PRODUCT_CATEGORY_LIST") {
      let category = localStorage.getItem("br_category").split(",");
      category = category.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      localStorage.setItem("br_category", category.join());
      if (category && category.length <= 0) {
        let strCategory = "";
        const categories = JSON.parse(localStorage.getItem("categories"));
        if (categories) {
          forEach(categories, (item) => {
            strCategory = strCategory + item.retailer_id + ",";
          });
        }
        strCategory = strCategory.substring(0, strCategory.length - 1);
        localStorage.setItem("br_category", strCategory);
        //localStorage.setItem("br_category", "");
      }
    }
    if (item.key === "SUB_CATEGORY_LIST") {
      let subcategory = localStorage.getItem("br_subcategory").split(",");
      subcategory = subcategory.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      localStorage.setItem("br_subcategory", subcategory.join());
      if (subcategory && subcategory.length <= 0) {
        let strSubCategory = "";
        /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
        if (subcategories) {
          forEach(subcategories, (item) => {
            strSubCategory = strSubCategory + item.subcategory_id + ",";
          });
        }
        strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
        localStorage.setItem("br_subcategory", strSubCategory);
      }
    }
    if (item.key === "RETAILER_LIST") {
      let retailer = localStorage.getItem("br_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      localStorage.setItem("br_retailer", retailer.join());
      if (retailer && retailer.length <= 0) {
        let strRetailer = "";
        const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
        localStorage.setItem("br_retailer", strRetailer);
      }
    }
    if (item.key === "PRODUCT_LIST") {
      let product = localStorage.getItem("br_product").split(",");
      product = product.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("br_product", product.join());
      if (product && product.length <= 0) {
        let strProduct = "";
        /*const products = JSON.parse(localStorage.getItem("products"));
        if (products) {
          forEach(products, (item) => {
            strProduct = strProduct + item.product_id + ",";
          });
        }
        strProduct = strProduct.substring(0, strProduct.length - 1);*/
        localStorage.setItem("br_product", strProduct);
      }
    }
    if (item.key === "COUNTRY_LIST") {
      let country = localStorage.getItem("br_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("br_country", country.join());
      if (country && country.length <= 0) {
        let strCountry = "";
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
        localStorage.setItem("br_country", strCountry);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = localStorage.getItem("br_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("br_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("brands"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        localStorage.setItem("br_region", strRegion);
      }
    }
    if (item.key === "PROMO_SELECTION") {
      localStorage.setItem("br_includePromoProducts", true);
    }
    if (item.key === "SECONDARY_DISPLAY_SELECTION") {
      localStorage.setItem("br_includeSecondaryDisplay", false);
    }
    fetchFacing();
  };

  useEffect(() => {
    const loaderId = "se_avgfacingbrand";
    addLoader(loaderId);
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);

    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);

    let strBrand = "";
    let ind = 0;
    const brands = JSON.parse(localStorage.getItem("brands"));
    if (brands) {
      forEach(brands, (item) => {
        if (ind === 5) return;
        strBrand = strBrand + item.brand_id + ",";
        ind++;
      });
    }
    strBrand = strBrand.substring(0, strBrand.length - 1);
    //end get all countries & regions
    const url = `${BACKEND_URL_AZURE}/getfacingbybrand?startDate=${localStorage.getItem(
      "br_startDate"
    )}&endDate=${localStorage.getItem("br_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&brands=${strBrand}&countries=${strCountry}&retailers=${strRetailer}&regions=${strRegion}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setFacingData(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-100">
          <div className="Team-Activity-Overview-wp">
            <div className="title-wp">
              <div className="title-wp">Avg No. of Facings by Brand</div>
            </div>
            <div className="condition-values-wp">
              <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
            </div>
            <div className="control-btns">
              <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
                <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
                <RangePicker
                  showTime
                  format={"YYYY-MM-DD"}
                  onCalendarChange={(range, dateString, info) => {
                    let startDate = moment(range[0]).format("YYYY-MM-DD");
                    let endDate = moment(range[1]).format("YYYY-MM-DD");

                    //if (info.range === "end") {
                    localStorage.setItem("br_startDate", startDate);
                    localStorage.setItem("br_endDate", endDate);

                    // message.loading("loading data...", 0);
                    fetchFacing();
                    //}
                  }}
                  defaultValue={[
                    moment(lastDate, dateFormat),
                    moment(currentDate, dateFormat),
                  ]}
                  allowClear={false}
                  suffixIcon={
                    <img
                      alt=""
                      style={{ marginTop: "3px" }}
                      src={ICON_ARROW_DOWN}
                    />
                  }
                />
              </div>
              <CustomFilter
                useFilterBtnForOverview={true}
                selectedData={filterData}
                filterKeys={[
                  CUSTOM_FILTER_KEYS.COUNTRY_LIST,
                  CUSTOM_FILTER_KEYS.REGION_LIST,
                  CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST,
                  CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST,
                  CUSTOM_FILTER_KEYS.RETAILER_LIST,
                  CUSTOM_FILTER_KEYS.BRAND_LIST,
                  CUSTOM_FILTER_KEYS.PRODUCT_LIST,
                  CUSTOM_FILTER_KEYS.PROMOTION_LIST,
                  CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
                ]}
                onChange={(data) => {
                  onHandleFilterChanged(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-75">
          <AvgOfFacingsByBrandChart facingData={facingData.chart} />
        </div>
        <div className="col-25">
          <AvgOfFacingBySku data={facingData.table} />
        </div>
      </div>
    </>
  );
};

export default AvgOfFacingsByBrand;
