import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";
import ExportJsonExcel from "js-export-excel";

const columns = [
  {
    title: "SKU",
    dataIndex: "product",
    width: "40%",
    key: "product",
    sorter: (a, b) => a.product.localeCompare(b.product),
    showSorterTooltip: false,
  },
  {
    title: "OOS (No. of stores)",
    dataIndex: "store",
    width: "40%",
    align: "left",
    key: "store",
    render: (stores) => {
      stores = stores.slice(0, 2);
      return stores.map((store, index) => (
        <div style={{ marginTop: 10 }}>
          {index + 1}. {store}
        </div>
      ));
    },
  },
  {
    title: "Total of stores",
    dataIndex: "store",
    width: "20%",
    align: "center",
    key: "store",
    sorter: (a, b) => Number(a.store.length) - Number(b.store.length),
    showSorterTooltip: false,
    render: (stores) => {
      return stores.length;
    },
  },
];

const modalColumns = [
  {
    title: "SKU",
    dataIndex: "product",
    width: "50%",
    key: "product",
    sorter: (a, b) => a.product.localeCompare(b.product),
    showSorterTooltip: false,
  },
  {
    title: "OOS (No. of stores)",
    dataIndex: "store",
    width: "50%",
    align: "left",
    key: "store",
    render: (store) => (
      <>
        {store.map((item, index) => (
          <div style={{ marginTop: 10 }}>
            {index + 1}. {item}
          </div>
        ))}
      </>
    ),
  },
];

const SkuWithZeroFacings = ({ skuZeroFacingData }) => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [modalConfigs, setModalConfigs] = useState([]);
  const [visible, setVisible] = useState(false);

  function exportToExcel() {
    var option = {};
    option.fileName = "SKU with Zero Facings";
    var arrData = [];
    for (var i = 0; i < skuZeroFacingData.data.length; i++) {
      for (var j = 0; j < skuZeroFacingData.data[i].store.length; j++) {
        if (
          j>0 
        ) {
          arrData.push({
            product: "",
            oss: "",
            store: skuZeroFacingData.data[i].store[j],
            date: skuZeroFacingData.data[i].audit_date[j].replace("GMT",""),
          });
        }
        else
        {
          arrData.push({
            product: skuZeroFacingData.data[i].product,
            oss: skuZeroFacingData.data[i].num_store,
            store: skuZeroFacingData.data[i].store[j],
            date: skuZeroFacingData.data[i].audit_date[j].replace("GMT", ""),
          });
        }
      }
    }
    option.datas = [
      {
        sheetData: arrData,
        sheetName: "sheet",
        sheetHeader: ["OSS SKU", "No. of OSS stores", "Store Name", "Audit Date"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  useEffect(() => {
    setTableConfigs(columns);
    setModalConfigs(modalColumns);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title={`SKU with Zero Facings (${skuZeroFacingData.skuLength} SKUs)`}
        hideControlButtons={false}
        hideFilters={true}
        onClickExport={() => {
          exportToExcel();
        }}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={skuZeroFacingData.data}
        isPagination={true}
      />
      <Modal
        title="SKU with Zero Facings"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={modalConfigs}
          dataSource={skuZeroFacingData.data}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div>
    </div>
  );
};

export default SkuWithZeroFacings;
