import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import MyStoresShareOfShelfChart from "../MyStoresShareOfShelfChart";
import "./style.scss";

const columns = [
  {
    title: "Brand",
    dataIndex: "brand",
    width: "50%",
    key: "brand",
    sorter: (a, b) => a.brand.localeCompare(b.brand),
    showSorterTooltip: false,
  },
  {
    title: "SOS (%)",
    dataIndex: "sos",
    width: "50%",
    align: "center",
    key: "sos",
    sorter: (a, b) => a.sos.split(" ")[0] - b.sos.split(" ")[0],
    showSorterTooltip: false,
  },
];

const MyStoresShareOfShelf = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [tableConfigs, setTableConfigs] = useState([]);

  useEffect(() => {
    setTableConfigs(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <MyStoresShareOfShelfChart sos={data.totalSos} />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={data.current}
        isPagination={true}
      />
      <Modal
        title="Share of Shelf"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={data.current}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton onClick={() => setVisible(true)} title="View all" />
      </div>
    </div>
  );
};

export default MyStoresShareOfShelf;
