import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Table, Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import "./style.scss";

const columns = [
  {
    title: "Retailer",
    dataIndex: "retailer",
    width: "33.33%",
    key: "retailer",
    sorter: (a, b) => a.retailer.localeCompare(b.retailer),
    showSorterTooltip: false,
  },
  {
    title: "Type",
    dataIndex: "bays",
    width: "33.33%",
    key: "bays",
    render: (bays) => (
      <>
        {bays.map((bay) => (
          <div>{bay.bayType}</div>
        ))}
      </>
    ),
  },
  {
    title: "Count of Display",
    align: "center",
    width: "33.33%",
    children: [
      {
        title: "Paid",
        dataIndex: "bays",
        key: "bays",
        //width: "50%",
        align: "center",
        render: (bays) => (
          <>
            {bays.map((bay) => (
              <div>{bay.paid}</div>
            ))}
          </>
        ),
      },
      {
        title: "Free",
        dataIndex: "bays",
        key: "bays",
        //width: "50%",
        align: "center",
        render: (bays) => (
          <>
            {bays.map((bay) => (
              <div>{bay.free}</div>
            ))}
          </>
        ),
      },
    ],
  },
];

const SOSBySecondaryPlacementType = (props) => {
  // const [filterData, setFilterData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);
  // const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="Secondary Placement Overview"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={props.data}
        isPagination={props.pagination}
        count={3}
      />
      {/* <Modal
        title="Secondary Placement Overview"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={data.current}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div> */}
    </div>
  );
};

export default SOSBySecondaryPlacementType;
