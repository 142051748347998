import React, { useState, useEffect } from "react";
import { message } from "antd";
import AuditsCompleted from "../../components/AuditsCompleted";
import SalesRepPerformanceRanking from "../../components/SalesRepPerformanceRanking";
import OverdueStoreAudits from "../../components/OverdueStoreAudits";
import TotalAuditsCompleted from "../../components/TotalAuditsCompleted";
import CompletedStoreAuditsChart from "../../components/CompletedStoreAuditsChart";
import TeamActivityOverview from "../../components/TeamActivityOverview";
import "./style.scss";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import LoaderComponent from "../../components/LoaderComponent";

function printDocument() {
  const input = document.querySelector("divToPrint");
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    document.appendChild(canvas);
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 0, 0);
    // pdf.output('dataurlnewwindow');
    pdf.save("download.pdf");
  });
}

const TeamActivity = () => {
  // store active loading state of the child components
  const [loaders, setLoaders] = useState([]);

  const removeLoader = (id) => {
    setLoaders((prevState) => prevState.filter((state) => state !== id));
  };

  const addLoader = (id) => {
    setLoaders((prevState) => [...prevState, id]);
  };

  // useEffect(() => message.open({
  //   type: 'null',
  //   content: <div style={{width: 200, height: 20}}>
  //     <div className="loader" />
  //   </div>,
  //   duration: 0,
  // }), []);

  // useEffect(() => {
  //   // remove loading indicator if nothing is loading
  //   if (loaders.length === 0) {
  //     // message.destroy();
  //   }
  // });

  return (
    <div className="team-av" id="divToPrint">
      <LoaderComponent show={loaders.length > 0} />
      <TeamActivityOverview />
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-75">
          <AuditsCompleted removeLoader={removeLoader} addLoader={addLoader} />
        </div>
        <div className="col-25">
          <TotalAuditsCompleted
            removeLoader={removeLoader}
            addLoader={addLoader}
          />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        {/*<div className="col-50">
          <SalesRepPerformanceRanking />
        </div>*/}
        <div className="col-100">
          <OverdueStoreAudits
            removeLoader={removeLoader}
            addLoader={addLoader}
          />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <CompletedStoreAuditsChart
        removeLoader={removeLoader}
        addLoader={addLoader}
      />
    </div>
  );
};
export default TeamActivity;
