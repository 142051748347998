import React, { useState, useEffect } from "react";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { Dropdown, Menu, Radio, DatePicker, Button, message } from "antd";
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import Cookies from "universal-cookie";
import "./style.scss";
import moment from "moment";

import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
import { triggerFocus } from "antd/lib/input/Input";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format("YYYY-MM-DD");
let currentDate = new Date();
currentDate = moment(currentDate).format("YYYY-MM-DD");

const CompetitorActivityOverview = ({
  fetchSos,
  //fetchPrimarySos,
  //fetchSecondarySos,
  //fetchAvgSosRanking,
  //fetchSosKeyRetailer,
  fetchSecondaryPlacementOverview,
  fetchPromotionDepth,
  getPromotionFrequency,
}) => {
  // const [data, setData] = useState([]);
  // const [tableConfigs, setTableConfigs] = useState([]);
  const [periodFilter, setPeriodFilter] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);

  if (
    cookies.get("startDate") &&
    cookies.get("startDate") !== "" &&
    cookies.get("startDate") !== "undefined"
  ) {
    lastDate = cookies.get("startDate");
  }
  if (
    cookies.get("endDate") &&
    cookies.get("endDate") !== "" &&
    cookies.get("endDate") !== "undefined"
  ) {
    currentDate = cookies.get("endDate");
  }

  useEffect(() => {
    if (cookies.get("email") == null) {
      document.location.href = "/";
    }
    cookies.set("ms_retailer", "");
    cookies.set("ms_merchandiser", "");
    cookies.set("ms_country", "");
    cookies.set("pr_brand", "");
    cookies.set("ig_startDate", "");
    cookies.set("ig_startDate", "");
    cookies.set("ig_placement", "");
    cookies.set("ig_retailer", "");
  }, []);

  const exportMenus = (
    <Menu>
      <Menu.Item key="pdf">PDF</Menu.Item>
      <Menu.Item key="1">Excel</Menu.Item>
    </Menu>
  );

  const handlePeriodFilterChange = (e) => {
    setPeriodFilter(e.target.value);
  };

  const onHandleFilterChanged = async (data) => {
    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("ca_country", strCountry);

    var strSubCategory = "";
    if (
      data.SUB_CATEGORY_LIST &&
      data.SUB_CATEGORY_LIST.value &&
      data.SUB_CATEGORY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.SUB_CATEGORY_LIST.value.length; i++) {
        strSubCategory =
          strSubCategory + data.SUB_CATEGORY_LIST.value[i].id + ",";
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);
    } else {
      /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
      if (subcategories) {
        forEach(subcategories, (item) => {
          strSubCategory = strSubCategory + item.subcategory_id + ",";
        });
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
      strSubCategory = "";
    }
    cookies.set("ca_subcategory", strSubCategory);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    cookies.set("ca_region", strRegion);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("ca_retailer", strRetailer);

    if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      cookies.set("ca_includePromoProducts", !promotion[0].isChecked);
    } else cookies.set("ca_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== undefined &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      cookies.set("ca_includeSecondaryDisplay", secondary[0].isChecked);
    } else cookies.set("ca_includeSecondaryDisplay", false);

    setFilterData(data);
    convertDataForTags(data);

    await Promise.all([
      fetchSos(),
      //fetchPrimarySos(),
      //fetchSecondarySos(),
      //fetchAvgSosRanking(),
      //fetchSosKeyRetailer(),
      fetchSecondaryPlacementOverview(),
      fetchPromotionDepth(),
      getPromotionFrequency(),
    ]);
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = async (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "COUNTRY_LIST") {
      let country = cookies.get("ca_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("ca_country", country.join());
      if (country && country.length <= 0) {
        let strCountry = "";
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
        cookies.set("ca_country", strCountry);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = cookies.get("ca_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("ca_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("brands"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        cookies.set("ca_region", strRegion);
      }
    }
    if (item.key === "SUB_CATEGORY_LIST") {
      let subcategory = cookies.get("ca_subcategory").split(",");
      subcategory = subcategory.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("ca_subcategory", subcategory.join());
      if (subcategory && subcategory.length <= 0) {
        let strSubCategory = "";
        /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
        if (subcategories) {
          forEach(subcategories, (item) => {
            strSubCategory = strSubCategory + item.subcategory_id + ",";
          });
        }
        strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
        cookies.set("ca_subcategory", strSubCategory);
      }
    }
    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("ca_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("ca_retailer", retailer.join());
      if (retailer && retailer.length <= 0) {
        let strRetailer = "";
        const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
        cookies.set("ca_retailer", strRetailer);
      }
    }
    if (item.key === "PROMO_SELECTION") {
      cookies.set("ca_includePromoProducts", true);
    }
    if (item.key === "SECONDARY_DISPLAY_SELECTION") {
      cookies.set("ca_includeSecondaryDisplay", false);
    }
    // message.loading('Loading data...', 0);
    await Promise.all([
      fetchSos(),
      //fetchPrimarySos(),
      //fetchSecondarySos(),
      //fetchAvgSosRanking(),
      //fetchSosKeyRetailer(),
      fetchSecondaryPlacementOverview(),
      fetchPromotionDepth(),
      getPromotionFrequency(),
    ]);
  };

  return (
    <div className="Team-Activity-Overview-wp">
      <div className="title-wp">
        <div className="title-wp">Competitor Activity</div>
      </div>
      <div className="condition-values-wp">
        <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
      </div>
      <div className="control-btns">
        <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
          <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
          <RangePicker
            showTime
            format={"YYYY-MM-DD"}
            onCalendarChange={async (range, dateString, info) => {
              let startDate = moment(range[0]).format("YYYY-MM-DD");
              let endDate = moment(range[1]).format("YYYY-MM-DD");

              //if (info.range === "end") {
              cookies.set("ca_startDate", startDate);
              cookies.set("ca_endDate", endDate);

              // message.loading('Loading data...', 0);
              await Promise.all([
                fetchSos(),
                //fetchPrimarySos(),
                //fetchSecondarySos(),
                //fetchAvgSosRanking(),
                //fetchSosKeyRetailer(),
                fetchSecondaryPlacementOverview(),
                fetchPromotionDepth(),
                getPromotionFrequency(),
              ]);
              //}
            }}
            defaultValue={[
              moment(lastDate, dateFormat),
              moment(currentDate, dateFormat),
            ]}
            allowClear={false}
            suffixIcon={
              <img alt="" style={{ marginTop: "3px" }} src={ICON_ARROW_DOWN} />
            }
          />
        </div>
        <CustomFilter
          useFilterBtnForOverview={true}
          selectedData={filterData}
          filterKeys={[
            CUSTOM_FILTER_KEYS.COUNTRY_LIST,
            CUSTOM_FILTER_KEYS.REGION_LIST,
            CUSTOM_FILTER_KEYS.RETAILER_LIST,
            CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST,
            CUSTOM_FILTER_KEYS.PROMOTION_LIST,
            CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
          ]}
          onChange={(data) => {
            onHandleFilterChanged(data);
          }}
        />
        {/*<Dropdown
          className="btn-item export-dropdown"
          overlay={exportMenus}
          trigger={["click"]}
        >
          <div>
            <img
              alt="icon-btn"
              className="arrow-icon"
              src={ICON_EXPORT_EXCEL}
            />
            <span>Export As</span>
            <img alt="" className="arrow-down-ic" src={ICON_ARROW_DOWN} />
          </div>
        </Dropdown>*/}
      </div>
    </div>
  );
};

export default CompetitorActivityOverview;
