import React, { useState } from "react";

import AuditsCompleted from '../../components/AuditsCompleted';
import SalesRepPerformanceRanking from '../../components/SalesRepPerformanceRanking';
import OverdueStoreAudits from '../../components/OverdueStoreAudits';
import TotalAuditsCompleted from '../../components/TotalAuditsCompleted';
import CompletedStoreAuditsChart from '../../components/CompletedStoreAuditsChart';
import ActionListOverview from '../../components/ActionListOverview';
import "./style.scss";

const ActionList = () => {
    return (
        <div className="team-av">
            <ActionListOverview/>
            <div className="separate-line mb-30" />

            <div className="audits-and-summary">
                <div className="audit-col">
                    <AuditsCompleted />
                </div>
                <TotalAuditsCompleted />
            </div>

            <div className="separate-line mb-30" />

            <div className="sale-and-overdue">
                <SalesRepPerformanceRanking />
                <OverdueStoreAudits />
            </div>

            <div className="separate-line mb-30" />
            
            <CompletedStoreAuditsChart />
        </div>
    );
};
export default ActionList;
