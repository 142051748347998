import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import "./style.scss";

const ICON_DECREASE = require("../../assets/img/icons/ic-decrease-red.svg");
const ICON_INCREASE = require("../../assets/img/icons/ic-increase-green.svg");

const ShareOfShelf = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      percent: 0,
      stores: 0,
      completedStores: 0,
      isIncrease: false,
      increasePercent: 0,
    });
  }, []);

  return (
    <div className="Total-Audits-Completed-wp">
      <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
        <div className="col-25">
          <Progress
            type="circle"
            percent={props.data}
            strokeWidth={10}
            strokeColor={props.data > 80 ? "#87d068" : "#E5305B"}
            format={() =>
              props.data > 80 ? (
                <div style={{ color: "#87d068" }}>
                  {props.data > 0 ? Math.round(props.data) : "-"}%
                </div>
              ) : (
                <div style={{ color: "#E5305B" }}>
                  {props.data > 0 ? Math.round(props.data) : "-"}%
                </div>
              )
            }
            width={80}
          />
        </div>
        <div className="col-75">
          <div className="summary-completed-stores">Share of Shelf (SOS)</div>
          <div className="summary-detail">
            {/*{data.isIncrease ? (
              <img alt="" className="period-icon" src={ICON_INCREASE} />
            ) : (
              <img alt="" className="period-icon" src={ICON_DECREASE} />
            )}
            <div className="last-period">
              {`${data.isIncrease ? "+" : "-"}${
                data.increasePercent
              }% vs last week`}
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareOfShelf;
