import React, { useState, useEffect } from "react";
import { Progress, Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";

const columns = [
  {
    title: "SKU Names",
    dataIndex: "requirement",
    width: "60%",
    key: "requirement",
    render: (val, obj) => {
      if (obj.SequenceData) {
        return obj.SequenceData.map(sequence => (
          <div style={{marginTop: 5}}>{sequence}</div>
        ));
      }
      if (obj.AdjacencyData) {
        return obj.AdjacencyData.map(adjacency => (
          <div style={{marginTop: 5}}>{adjacency}</div>
        ));
      }
      return obj.skuName;
    },
  },
  {
    title: "Requirement",
    dataIndex: "requirement",
    width: "40%",
    key: "requirement",
    sorter: (a, b) => a.requirement.localeCompare(b.requirement),
    showSorterTooltip: false,
  },
];

const BonusKPI = ({ bonusKpi, sku }) => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
  }, []);

  return (
    <div className="Total-Audits-Completed-wp">
      <div
        className="row"
        style={{ marginTop: 10, marginBottom: 10, position: "relative" }}
      >
        <div className="col-25">
          <Progress
            type="circle"
            percent={bonusKpi.bonusKpi}
            strokeWidth={10}
            strokeColor={
              bonusKpi.bonusKpi > bonusKpi.targetCompliance
                ? "#87d068"
                : "#E5305B"
            }
            format={() =>
              bonusKpi.bonusKpi > bonusKpi.targetCompliance ? (
                <div style={{ color: "#87d068" }}>
                  {bonusKpi.bonusKpi > 0 ? Math.round(bonusKpi.bonusKpi) : "-"}%
                </div>
              ) : (
                <div style={{ color: "#E5305B" }}>
                  {bonusKpi.bonusKpi > 0 ? Math.round(bonusKpi.bonusKpi) : "-"}%
                </div>
              )
            }
            width={80}
          />
        </div>
        <div className="col-75">
          <div className="summary-completed-stores">Bonus KPI - NPD</div>
        </div>

        <Modal
          title="Bonus KPI"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          onOk={() => setShowModal(false)}
          footer={null}
          width={700}
        >
          <ReportTable
            columns={tableConfigs}
            dataSource={sku}
            isPagination={false}
          />
        </Modal>

        <div style={{ position: "absolute", bottom: "-5px", right: 10, display:"none" }}>
          <ViewAllButton onClick={() => setShowModal(true)} title="View List" />
        </div>
      </div>
    </div>
  );
};

export default BonusKPI;
