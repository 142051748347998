import React, { View, useState } from "react";
import styles from "../assets/css/App.css";
import {
  Card,
  Tabs,
  Menu,
  Dropdown,
  Drawer,
  DatePicker,
  Form,
  Button,
  Layout,
  Collapse,
  Divider,
  Table,
  Space,
  Select,
  Checkbox,
  Tag,
  Progress,
} from "antd";
import { Row, Col } from "antd";

const style = {
  maincontent: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    padding: 20,
    margin: 15,
    boxShadow: "1px 1px 5px 5px #eee",
  },
};

//class NoneCompliance extends React.Component {
  //render() {
//export default function NoneCompliance() {
  const NoneCompliance = () => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Col span={6}>
          <div style={style.maincontent}>...</div>
        </Col>
      </Row>
    );
  };
//}
export default NoneCompliance;
//export default withRouter(NoneCompliance);