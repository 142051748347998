import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useAuthState } from "../providers/authProvider";
import * as AuthDataManager from "../services/authDataManager";
// import { isTimeExpired } from '../utils/utilitiesService';

function PrivateRoute({ component: Component, layout: Layout,  ...rest }) {
    const history = useHistory();
    const { isAuthenticated } = useAuthState();

    useEffect(() => {
        console.log("----------------------PrivateRoute isAuthenticated", new Date(), isAuthenticated);

        const { token } = AuthDataManager.getAuthData();
        // if (isTimeExpired(token)) {
            // history.push("./welcome");
            // const appDomain = window ? window.location.origin : apisConstants.appDomain;
            // window.location.href = `${appDomain}/welcome`;
            // return;
        // }
    }, []);

    return (
        <Route {...rest} render={(props) => (
            <Layout>
                {isAuthenticated
                    ? <Component {...props} />
                    : <Redirect to="/login" />
                }
            </Layout>)}
        />
    );
}

export default PrivateRoute;
