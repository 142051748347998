import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import ReactGA from "react-ga";

import { AuthProvider } from "./providers/authProvider.js";
import * as serviceWorker from './serviceWorker';
import './assets/css/index.css';
import authentication from 'react-azure-b2c';

ReactGA.initialize("UA-181789574-1");
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.pageview("/team-activity");
ReactGA.pageview("/store-execution");
ReactGA.pageview("/competitor-activity");
ReactGA.pageview("/my-stores");

authentication.initialize({
  // optional, will default to this
  instance: process.env.REACT_APP_instance,
  // your B2C tenant
  tenant: process.env.REACT_APP_tenant,
  // the policy to use to sign in, can also be a sign up or sign in policy
  signInPolicy: process.env.REACT_APP_signInPolicy,
  // the policy to use for password reset
  resetPolicy: process.env.REACT_APP_resetPolicy,
  // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
  //applicationId: process.env.REACT_APP_,
  clientId: process.env.REACT_APP_clientId,
  // where MSAL will store state - localStorage or sessionStorage
  cacheLocation: "sessionStorage",
  // the scopes you want included in the access token
  scopes: [process.env.REACT_APP_scopes],
  // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
  redirectUri: process.env.REACT_APP_redirectUri,
  //validateAuthority: false,
  // optional, the URI to redirect to after logout
  postLogoutRedirectUri: window.location.origin,
});

const token = authentication.getAccessToken();
if (token===null) authentication.run(() => {
      ReactDOM.render(
        <React.StrictMode>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </React.StrictMode>,
        document.getElementById("root")
      );
});
    //const token = authentication.getAccessToken();
    //console.log(token);
    //if (token)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();