import React, { useState, useEffect } from "react";
import { message } from "antd";
import FairShareOfShelfChart from "../../components/FairShareOfShelfChart";
import TotalAvgShareOfShelfChart from "../../components/TotalAvgShareOfShelfChart";
import KelloggsVsCompetitorPromotionFrequencyChart from "../../components/KelloggsVsCompetitorPromotionFrequencyChart";
import TopPromotedProduct from "../../components/TopPromotedProduct";
import VarianceFromMSRPChart from "../../components/VarianceFromMSRPChart";
import PromotionFrequencyAndDepth from "../../components/PromotionFrequencyAndDepth";
import CompetitorActivityComponent from "../../components/CompetitorActivity";
import Cookies from "universal-cookie";

import "./style.scss";
import LoaderComponent from "../../components/LoaderComponent";
const cookies = new Cookies();

const CompetitorActivity = () => {
  // store active loading state of the child components
  const [loaders, setLoaders] = useState([]);

  const removeLoader = (id) => {
    setLoaders((prevState) => prevState.filter((state) => state !== id));
  };

  const addLoader = (id) => {
    setLoaders((prevState) => [...prevState, id]);
  };

  // useEffect(() => message.loading("Loading data...", 0), []);

  // useEffect(() => {
  //   // remove loading indicator if nothing is loading
  //   if (loaders.length === 0) {
  //     message.destroy();
  //   }
  // });

  return (
    <div className="team-av">
      <LoaderComponent show={loaders.length > 0} />
      <CompetitorActivityComponent
        addLoader={addLoader}
        removeLoader={removeLoader}
      />

      {/*<div className="separate-line mb-30" />

      <div className="row">
        <div className="col-33">
          <TopPromotedProductByDepth />
        </div>
        <div className="col-33">
          <TopPromotedProductByFrequency />
        </div>
        <div className="col-33">
          <DelistedSKU />
        </div>
  </div>*/}

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <FairShareOfShelfChart
            addLoader={addLoader}
            removeLoader={removeLoader}
          />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <TotalAvgShareOfShelfChart
            addLoader={addLoader}
            removeLoader={removeLoader}
          />
        </div>
        {/*<div className="col-25">
          <ShareOfShelfByStores />
  </div>*/}
      </div>
      <div className="separate-line mb-30" />

      <div className="row">
        <div
          className="col-70"
          style={{
            display:
              cookies.get("uses_pricing_module") === true ||
              cookies.get("uses_pricing_module") === "true"
                ? "block"
                : "none",
          }}
        >
          <KelloggsVsCompetitorPromotionFrequencyChart
            addLoader={addLoader}
            removeLoader={removeLoader}
          />
        </div>
        <div
          className="col-30"
          style={{
            display:
              cookies.get("uses_pricing_module") === true ||
              cookies.get("uses_pricing_module") === "true"
                ? "block"
                : "none",
          }}
        >
          <TopPromotedProduct
            addLoader={addLoader}
            removeLoader={removeLoader}
          />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <VarianceFromMSRPChart
        addLoader={addLoader}
        removeLoader={removeLoader}
      />

      <div className="separate-line mb-30" />

      <div
        className="row"
        style={{
          display:
            cookies.get("uses_pricing_module") === true ||
            cookies.get("uses_pricing_module") === "true"
              ? "block"
              : "none",
        }}
      >
        <div className="col-100">
          <PromotionFrequencyAndDepth
            addLoader={addLoader}
            removeLoader={removeLoader}
          />
        </div>
      </div>
    </div>
  );
};
export default CompetitorActivity;
