import React from "react";
import AppRoutes from './routes';

export default function App() {
    return (
        <div className={`app-container`}>
            <AppRoutes/>
        </div>
    );
}
