import React, { useState } from "react";
import {
  Button,
  Upload,
  Spin,
} from "antd";
import { Row, Col } from "antd";
import { isEmpty, find, filter, assignIn } from "lodash";
import productListData from "../assets/mockData/productList.json";
import productDetectionData from "../assets/mockData/productDetection.json";

const IMGS_HIGHLIGHTED_DATA = new Map([
  ["a.png", require("../assets/img/productImgsDemo/a.png")],
  ["b.png", require("../assets/img/productImgsDemo/b.png")],
  ["c.png", require("../assets/img/productImgsDemo/c.png")],
  ["d.png", require("../assets/img/productImgsDemo/d.png")],
  ["e.png", require("../assets/img/productImgsDemo/e.png")],
  ["f.png", require("../assets/img/productImgsDemo/f.png")],
]);

const style = {
  maincontent: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    margin: 10,
    boxShadow: "0px 0px 30px rgba(76, 147, 230, 0.1)",
    minHeight: 300,
    padding: '60px 50px 0',
  },
  faBlock: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    margin: 10,
    boxShadow: "0px 0px 30px rgba(76, 147, 230, 0.1)",
    padding: '50px 25px 25px',
    minHeight: 300,
    maxHeight: 300,
  },
  headerLlb: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: 24
  },
  priceLbl: {
    color: '#E20D0D',
    fontWeight: 'bold',
    fontSize: 32,
    margin: '25px 0',
    minHeight: 50,
  },
  contentWp: {
    width: 170,
    textAlign: 'center',
  },
  noteLbl: {
    color: '#000000',
    fontSize: 20,
    fontWeight: 500,
  },
  formulaLbl: {
    color: '#4B5DE8',
    fontSize: 14,
    marginTop: 15
  },
  formulaValue: {
    color: '#000000',
    fontSize: 18,
  },
  facingProductLbl: {
    color: '#4B5DE8',
    fontSize: 18,
    fontWeight: 600,
  },
  facingProductList: {
    height: 160,
    overflow: 'scroll'
  },
  fcProLbl: {
    color: '#000000',
    fontSize: 12,
    wordBreak: 'break-all'
  },
  fcValueLbl: {
    color: '#000000',
    fontSize: 12,
    paddingLeft: 45,
  },
  uploadBtn: {
    width: 280,
    height: 56,
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: '#391EAC'
  },
  previewBlock: {
    marginTop: 20,
    marginBottom: 30,
    paddingLeft: 20,
    backgroundColor: "#ffffff",
    minHeight: 400,
    padding: '50px 25px 25px',
    boxShadow: "0px 0px 30px rgba(76, 147, 230, 0.1)",
  },
  imgUloaded: {
    width: 800,
    objectFit: 'cover',
    marginLeft: 40,
  },
};

const ProductDetection = () => {
  const [productInfo, setProductInfo] = useState({});
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isProgressingData, setIsProgressingData] = useState(false);

  const beforeUpload = (info) => {
    setIsProgressingData(true);
    setUploadedImage(null);
    setProductInfo({});
  }

  const handleUploadChange = (info) => {
    // const reader = new FileReader();
    // reader.addEventListener('load', () => {
    //   setUploadedImage(reader.result);
    //   setTimeout(() => {
    //     setIsProgressingData(false);
    //   }, 1200);
    // });
    // reader.readAsDataURL(info.file.originFileObj);
    if (info.file.status !== 'uploading') {
      setTimeout(() => {
        getProductInfo(info.file.name);
        setIsProgressingData(false);

        const matched = find(productDetectionData, { img1: info.file.name });
        if (matched) {
          setUploadedImage(matched.img2);
        }
      }, 800);
    }
  }

  const getProductInfo = (imgName) => {
    if (!imgName || isEmpty(productDetectionData)) return;

    const matched = find(productDetectionData, { img1: imgName });
    if (!isEmpty(matched)) {
      const relatedProducts = getRelevantProducts(matched.img2);
      setProductInfo(assignIn({}, matched, { products: relatedProducts }));
    }
  }

  const getRelevantProducts = (img2) => {
    if (!img2 || isEmpty(productListData)) return [];

    const matchedProducts = filter(productListData, { img2: img2 });

    if (!isEmpty(matchedProducts)) {
      return matchedProducts.map((item) => (
        {
          productName: item.productName,
          facing: item.facing,
        }
      ))
    }
    return [];
  }

  const getImageByImg2Name = (img) => {
    return <img
      style={style.imgUloaded} alt=""
      src={IMGS_HIGHLIGHTED_DATA.get(img)}
    />
  }

  return (
    <Row>
      <Spin spinning={isProgressingData}>
        <Row style={{ width: '100%', marginTop: 30, paddingLeft: 30, paddingRight: 30 }}>
          <Col span={17}>
            <div style={style.maincontent}>
              <Row style={{ padding: 5, paddingLeft: 0 }}>
                <Col span={8}>
                  <Row style={style.headerLlb}>
                    <div style={style.contentWp}>
                      Share of Shelf
                    </div>
                  </Row>
                  <Row style={style.priceLbl}>
                    <div style={style.contentWp}>
                      {productInfo.shelfManufacturer1 || ''}
                    </div>
                  </Row>
                  {!isEmpty(productInfo.manufacturer1)
                    ? <Row style={style.noteLbl}>
                      <div style={style.contentWp}>
                        {productInfo.manufacturer1}
                      </div>
                    </Row>
                    : < Row style={style.noteLbl}>
                      <div style={style.contentWp}>
                        &lt;Manufacturer1&gt;
                        </div>
                    </Row>
                  }
                </Col>
                <Col span={8}>
                  <Row style={style.headerLlb}>
                    <div style={style.contentWp}>
                      Share of Shelf
                    </div>
                  </Row>
                  <Row style={style.priceLbl}>
                    <div style={style.contentWp}>
                      {productInfo.shelfManufacturer2 || ''}
                    </div>
                  </Row>
                  {!isEmpty(productInfo.manufacturer2)
                    ? < Row style={style.noteLbl}>

                      <div style={style.contentWp}>
                        {productInfo.manufacturer2}
                      </div>
                    </Row>
                    : < Row style={style.noteLbl}>
                      <div style={style.contentWp}>
                        &lt;Manufacturer2&gt;
                    </div>
                    </Row>
                  }
                </Col>
                <Col span={8}>
                  <Row style={style.formulaLbl}>Formula</Row>
                  <Row style={style.formulaValue}>= Count of Total FC products/</Row>
                  <Row style={style.formulaValue}>count of Total Tracked Facings</Row>
                </Col>
              </Row>

            </div>
          </Col>
          <Col span={7}>
            <div style={style.faBlock}>
              <Row style={style.facingProductLbl}># of Facings by Product</Row>
              <Row
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                  padding: 5,
                  paddingLeft: 0,
                  marginTop: 15,
                  borderBottom: "1px solid #D8D8D8",
                }}
              >
                <Col span={16}>SKU Name</Col>
                <Col span={8} style={{ paddingLeft: 20 }}>Facing Count</Col>
              </Row>
              <div style={style.facingProductList}>
                {productInfo && !isEmpty(productInfo.products) &&
                  productInfo.products.map((pItem, pIndex) => (
                    <Row style={{ padding: 5, paddingLeft: 0 }} key={pIndex}>
                      <Col span={16} style={style.fcProLbl}>{`${pIndex + 1}. ${pItem.productName}`}</Col>
                      <Col span={8} style={style.fcValueLbl}>{pItem.facing}</Col>
                    </Row>
                  ))
                }
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
      <Row style={{ marginTop: 70 }} />
      <Row style={{ width: '100%', marginTop: 40, paddingLeft: 30, paddingRight: 30, display: 'flex', flexDirection: 'column' }}>
        <Row style={{ marginTop: 20, paddingLeft: 10 }}>
          <Upload
            accept="jpg, jpeg, png, image/png, image/jpeg, image/jpg"
            name="imgdemo"
            listType="picture-card"
            className="imguload-demo"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleUploadChange}
          >
            <Button style={style.uploadBtn} disabled={isProgressingData}>
              Upload Image
            </Button>
          </Upload>
        </Row>

        <Spin spinning={isProgressingData}>
          <Row style={style.previewBlock}>
            {uploadedImage && getImageByImg2Name(uploadedImage)}
          </Row>
        </Spin>
      </Row>
    </Row >
  );
};

export default ProductDetection;