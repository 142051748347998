
import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import { Dropdown, Menu, Radio } from 'antd';
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import "./style.scss";

import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");

const ActionListOverview = (props) => {
    // const [data, setData] = useState([]);
    // const [tableConfigs, setTableConfigs] = useState([]);
    const [periodFilter, setPeriodFilter] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [tagsData, setTagsData] = useState([]);

    useEffect(() => {
        // setTableConfigs(columns);
        // setData(auditData);
    }, []);

    const exportMenus = (
        <Menu>
            <Menu.Item key="pdf">PDF</Menu.Item>
            <Menu.Item key="1">Excel</Menu.Item>
        </Menu>
    );

    const handlePeriodFilterChange = (e) => {
        setPeriodFilter(e.target.value);
    }

    const onHandleFilterChanged = (data) => {
        setFilterData(data);
        convertDataForTags(data);
    }

    const convertDataForTags = (data) => {
        setTagsData(filterDataHanler.convertDataForTags(data));
    }

    const handleRemoveTag = (item) => {
        const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
        setFilterData(data);
        convertDataForTags(data);
    }

    return (
        <div className="Team-Activity-Overview-wp">
            <div className="title-wp">
                <div className="title-wp">Action List Overview</div>
            </div>
            <div className="condition-values-wp">
                <FilterTags
                    data={tagsData}
                    onRemoveTag={handleRemoveTag}
                />
            </div>
            <div className="control-btns">
                <Radio.Group
                    className="period-filter-wp"
                    value={periodFilter}
                    onChange={handlePeriodFilterChange}
                >
                    <Radio.Button value="monthy">Monthy</Radio.Button>
                    <Radio.Button value="quarterly">Quarterly</Radio.Button>
                    <Radio.Button value="yearly">Yearly</Radio.Button>
                </Radio.Group>
                <CustomFilter
                    useFilterBtnForOverview={true}
                    selectedData={filterData}
                    filterKeys={[
                        CUSTOM_FILTER_KEYS.REGION_LIST,
                        CUSTOM_FILTER_KEYS.STORES_LIST,
                    ]}
                    onChange={(data) => {
                        console.log("TeamActivityOverview ####", data);
                        onHandleFilterChanged(data);
                    }}
                />
                <Dropdown className="btn-item export-dropdown" overlay={exportMenus} trigger={['click']}>
                    <div>
                        <img alt="icon-btn"
                            className="arrow-icon"
                            src={ICON_EXPORT_EXCEL}
                        />
                        <span>Export As</span>
                        <img alt=""
                            className="arrow-down-ic"
                            src={ICON_ARROW_DOWN}
                        />

                    </div>
                </Dropdown>
            </div>
        </div>
    )
}

export default ActionListOverview;
