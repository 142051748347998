import React, { useState, useEffect } from "react";
import { Progress, Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";

const columns = [
  {
    title: "SKU Names",
    dataIndex: "skuName",
    width: "100%",
    key: "skuName",
    sorter: (a, b) => a.skuName.localeCompare(b.skuName),
    showSorterTooltip: false,
  },
];

const BaseKPI = ({ baseKpi, sku }) => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
  }, []);

  return (
    <div className="Total-Audits-Completed-wp">
      <div
        className="row"
        style={{ marginTop: 10, marginBottom: 10, position: "relative" }}
      >
        <div className="col-25">
          <Progress
            type="circle"
            percent={baseKpi.baseKpi}
            strokeWidth={10}
            strokeColor={
              baseKpi.baseKpi > baseKpi.targetCompliance ? "#87d068" : "#E5305B"
            }
            format={() =>
              baseKpi.baseKpi > baseKpi.targetCompliance ? (
                <div style={{ color: "#87d068" }}>
                  {baseKpi.baseKpi > 0 ? Math.round(baseKpi.baseKpi) : "-"}%
                </div>
              ) : (
                <div style={{ color: "#E5305B" }}>
                  {baseKpi.baseKpi > 0 ? Math.round(baseKpi.baseKpi) : "-"}%
                </div>
              )
            }
            width={80}
          />
        </div>
        <div className="col-75">
          <div className="summary-completed-stores">
            Base KPI - Must Carry SKU
          </div>
        </div>

        <Modal
          title="Base KPI (Min. 1 Facing)"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          onOk={() => setShowModal(false)}
          footer={null}
        >
          <ReportTable
            columns={tableConfigs}
            dataSource={sku}
            isPagination={false}
          />
        </Modal>

        <div style={{ position: "absolute", bottom: "-5px", right: 10, display:"none" }}>
          <ViewAllButton onClick={() => setShowModal(true)} title="View List" />
        </div>
      </div>
    </div>
  );
};

export default BaseKPI;
