
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Button } from 'antd';
import "./style.scss";
import { cloneDeep } from "lodash";

import CustomFilter from "../CustomFilter";
import FilterTags from "../FilterTags";

import * as filterDataHanler from "../../utils/filterDataHanler";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/export-excel-icon.svg")

const ReportTableHeader = (props) => {
    const {
        title, subTitle, onClickFilter, onClickExport,
        showFilterLoading, showExportLoading,
        hideControlButtons, hideFilters,
        filterKeys, onFilterChanged
    } = props;
    const [filterData, setFilterData] = useState([]);
    const [tagsData, setTagsData] = useState([]);


    const onHandleFilterChanged = (data) => {
      onFilterChanged(data);
        setFilterData(data);
        convertDataForTags(data);
    }

    const convertDataForTags = (data) => {
        setTagsData(filterDataHanler.convertDataForTags(data));
    }

    const handleRemoveTag = (item) => {
        const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
        
        onFilterChanged(data);
        setFilterData(data);
        convertDataForTags(data);
    }

    return (
      <div className="ReportTableHeader">
        <div className="hd-left-col">
          <div className="title-value">{title || ""}</div>
          <div className="subtitle-value">{subTitle || ""}</div>
        </div>
        <div className="hd-middle-col">
          <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
        </div>
        {!hideControlButtons && (
          <div className="hd-right-col">
            <Button
              className="hd-btn"
              loading={showFilterLoading}
              onClick={onClickExport}
            >
              <img
                alt="icon-btn"
                className="icon-btn export-icon"
                src={ICON_EXPORT_EXCEL}
              />
            </Button>
            {!hideFilters && (
              <CustomFilter
                selectedData={filterData}
                filterKeys={filterKeys}
                onChange={(data) => {
                  console.log("ReportTableHeader ####", data);
                  onHandleFilterChanged(data);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
}

ReportTableHeader.propTypes = {
  title: PropTypes.string,
  onClickFilter: PropTypes.func,
  onClickExport: PropTypes.func,
  showFilterLoading: PropTypes.bool,
  showExportLoading: PropTypes.bool,
  hideControlButtons: PropTypes.bool,
  hideFilters: PropTypes.bool,
};

export default ReportTableHeader;
