import React, { useState, useEffect } from "react";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import MultiCheckboxSelection from "../MultiCheckboxSelection";
import "./style.scss";

const SingleProductSelection = (props) => {
  const { defaultValue, onChange } = props;
  const [regionData, setRegionsData] = useState([]);
  let trackedProducts = JSON.parse(localStorage.getItem("products"));
  /*trackedProducts = trackedProducts.sort((a, b) =>
    a.name.localeCompare(b.name)
  );*/

  useEffect(() => {
    generateRegionsData(trackedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    combineRegionsWithOutsideData(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const generateRegionsData = (data) => {
    let Products = [];
    forEach(data, (sItem) => {
      Products.push({
        id: sItem.product_id,
        label: sItem.product_name,
        isChecked: false,
      });
    });
    setRegionsData(Products);
  };

  const combineRegionsWithOutsideData = (items) => {
    let Products = [];
    forEach(trackedProducts, (sItem) => {
      Products.push({
        id: sItem.product_id,
        label: sItem.product_name,
        isChecked: false,
      });
    });

    let clonedData = cloneDeep(Products);

    forEach(clonedData, (sItem) => {
      const matched = find(items, { id: sItem.id });
      sItem.isChecked = !isEmpty(matched) ? matched.isChecked : false;
    });

    setRegionsData(clonedData);
  };

  const onSelectRegion = (mItem, isChecked) => {
    let clonedData = cloneDeep(regionData);

    let matched = find(clonedData, { id: mItem.id });
    if (matched) {
      matched.isChecked = isChecked;
    }
    forEach(clonedData, (sItem) => {
      if (sItem.id !== matched.id) sItem.isChecked = false;
    });
    setRegionsData(clonedData);

    const selectedItems = filter(clonedData, { isChecked: true });
    onChange(selectedItems);
  };

  const onSelectAll = (isChecked) => {
    if (isChecked === true) {
      let clonedData = cloneDeep(regionData);
      forEach(clonedData, (sItem) => {
        sItem.isChecked = isChecked;
      });
      setRegionsData(clonedData);
      onChange(clonedData);
    } else {
      let clonedData = cloneDeep(null);
      onChange(clonedData);
    }
  };

  return (
    <div className="custom-table-StoresSelection">
      <div className="StoresSelection-wp">
        <MultiCheckboxSelection
          data={regionData}
          onCheckItem={onSelectRegion}
          onCheckAll={onSelectAll}
          showSelectAll={false}
        />
      </div>
    </div>
  );
};

SingleProductSelection.defaultProps = {
  selectedItems: [{ id: "", label: "", isChecked: false }],
  onChange: (value) => {},
};

export default SingleProductSelection;
