import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Table, Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import "./style.scss";

const columns = [
  {
    title: "Store",
    dataIndex: "store",
    width: "50%",
    key: "store",
    sorter: (a, b) => a.store.localeCompare(b.store),
    showSorterTooltip: false,
  },
  {
    title: "SOS (%)",
    dataIndex: "sos",
    width: "50%",
    align: "center",
    key: "sos",
    sorter: (a, b) => a.sos - b.sos,
    showSorterTooltip: false,
    render: (sos) => <div>{sos}%</div>,
  },
];

const auditData = [
  /*{
        auditor: 'Sally',
        store: 'Store A',
        overdueBy: 5,
    },
    {
        auditor: 'John',
        store: 'Store B',
        overdueBy: 4,
    },
    {
        auditor: 'Adrian',
        store: 'Store C',
        overdueBy: 3,
    },
    {
        auditor: 'Gary',
        store: 'Store D',
        overdueBy: 2,
    },*/
];

const AvgShareOfShelfRankingByStore = (props) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
    setData(auditData);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="Avg SOS Ranking by Store"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={props.data}
        isPagination={true}
        //count={3}
      />
      {/* <Modal
        title="Avg Share of Shelf Ranking by Store"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={props.data}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div> */}
    </div>
  );
};

export default AvgShareOfShelfRankingByStore;
