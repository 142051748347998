import React, { useState, useEffect } from "react";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const cookies = new Cookies();
const headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const columns = [
  {
    title: "Merchandiser",
    dataIndex: "auditor",
    width: "33.33%",
    key: "auditor",
    sorter: (a, b) => a.auditor.localeCompare(b.auditor),
    showSorterTooltip: false,
  },
  {
    title: "Store Audit Completed (audits)",
    dataIndex: "actualPlanStore",
    width: "33.33%",
    align: "center",
    key: "actualPlanStore",
    sorter: (a, b) =>
      a.actualPlanStore.split("%")[0] - b.actualPlanStore.split("%")[0],
    showSorterTooltip: false,
  },
  {
    title: "Price Scans (stores, products)",
    dataIndex: "priceScan",
    width: "33.33%",
    align: "center",
    key: "priceScan",
    sorter: (a, b) => a.priceScan.localeCompare(b.priceScan),
    showSorterTooltip: false,
  },
];

const auditData = [
  {
    auditor: cookies.get("user"),
    actualPlanStore: "0% (0/0)",
    actualPlan: "0% (0/0)",
  },
];

const AuditsCompleted = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);

  function exportToExcel() {
    var option = {};
    option.fileName = "Audits Completed";
    option.datas = [
      {
        sheetData: data,
        sheetName: "sheet",
        sheetHeader: [
          "Merchandiser",
          "Price Scans (stores, products)",
          "Store Audit Completed (audits)",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  function getNumWeeksFromDateRange(startDate, endDate) {
    var lastSunday = new Date(endDate);
    if (lastSunday.getDay() > 0) {
      lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());
    }
    var firstMonday = new Date(startDate);
    if (firstMonday.getDay() != 1) {
      firstMonday.setDate(firstMonday.getDate() + firstMonday.getDay());
    }
    return Math.round(datediff(firstMonday, lastSunday) / 7);
  }

  const onHandleFilterChanged = async (data) => {
    const loaderId = "ta_audit_completed";
    addLoader(loaderId);
    let strCountry = "";
    if (
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value !== "null" &&
      data.COUNTRY_LIST.value !== "undefined" &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      strCountry = data.COUNTRY_LIST.value.map((list) => list.id);
    }
    let strRegion = "";
    if (
      data.REGION_LIST.value &&
      data.REGION_LIST.value !== "null" &&
      data.REGION_LIST.value !== "undefined" &&
      data.REGION_LIST.value.length > 0
    ) {
      strRegion = data.REGION_LIST.value.map((list) => list.id);
    }
    if (strCountry === "") {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    if (strRegion === "") {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    var url =
      BACKEND_URL_AZURE +
      "/getauditscompletedbydate?client=" +
      cookies.get("client") +
      "&start=" +
      cookies.get("ta_startDate") +
      "&end=" +
      cookies.get("ta_endDate") +
      "&countries=" +
      strCountry +
      "&regions=" +
      strRegion;

    fetch(url, { method: "GET", headers: headers })
      .then(function (response) {
        return response.json();
      })
      .then(async (result) => {
        const results = result.message;
        var auditCompleted = [];
        for (var i = 0; i < results.length; i++) {
          var auditsCompleted = results[i].auditsCompleted + 0;
          if (results[i].auditsExpected > 0) {
            auditCompleted.push({
              auditor: results[i].name,
              priceScan: results[i].priceScan,
              actualPlanStore:
                Math.round(
                  (auditsCompleted / results[i].auditsExpected) * 100
                ) +
                "% (" +
                auditsCompleted +
                "/" +
                results[i].auditsExpected +
                ")",
            });
          }
        }
        removeLoader(loaderId);
        setData(auditCompleted);
      })
      .catch(function (error) {
        console.log("-------- error ------- " + error);
      });
  };

  useEffect(() => {
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    //end get all countries & regions
    const fetchData = async () => {
      const loaderId = "ta_audit_completed";
      addLoader(loaderId);
      setTableConfigs(columns);
      //get data
      if (
        cookies.get("ta_startDate") === undefined ||
        cookies.get("ta_startDate") === "" ||
        cookies.get("ta_startDate") === "undefined"
      ) {
        var startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ta_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ta_endDate") === undefined ||
        cookies.get("ta_endDate") === "" ||
        cookies.get("ta_endDate") === "undefined"
      ) {
        cookies.set("ta_endDate", new Date().toISOString().split("T")[0]);
      }
      var numWeek = getNumWeeksFromDateRange(
        cookies.get("ta_startDate"),
        cookies.get("ta_endDate")
      );
      if (numWeek < 0) numWeek = 0;
      var url =
        BACKEND_URL_AZURE +
        "/getauditscompletedbydate?client=" +
        cookies.get("client") +
        "&start=" +
        cookies.get("ta_startDate") +
        "&end=" +
        cookies.get("ta_endDate") +
        "&countries=" +
        strCountry +
        "&regions=" +
        strRegion;

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          //setData(result);
          const results = result.message;
          var auditCompleted = [];
          if (results) {
            for (var i = 0; i < results.length; i++) {
              var auditsCompleted = results[i].auditsCompleted + 0;
              if (results[i].auditsExpected > 0) {
                auditCompleted.push({
                  auditor: results[i].name,
                  priceScan: results[i].priceScan,
                  actualPlanStore:
                    Math.round(
                      (auditsCompleted / results[i].auditsExpected) * 100
                    ) +
                    "% (" +
                    auditsCompleted +
                    "/" +
                    results[i].auditsExpected +
                    ")",
                  // actualPlan: "0% (0/0)",
                });
              } else {
                auditCompleted.push({
                  auditor: results[i].name,
                  priceScan: results[i].priceScan,
                  actualPlanStore: "0% (" + auditsCompleted + "/" + 0 + ")",
                  // actualPlan: "0% (0/0)",
                });
              }
            }
          }
          removeLoader(loaderId);
          setData(auditCompleted);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
      //end get data
      //setData(auditData);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Audits-completed-wp">
      <ReportTableHeader
        title="Audits Completed"
        filterKeys={[
          CUSTOM_FILTER_KEYS.COUNTRY_LIST,
          CUSTOM_FILTER_KEYS.REGION_LIST,
        ]}
        onFilterChanged={(filters) => {
          onHandleFilterChanged(filters);
        }}
        onClickExport={() => {
          exportToExcel();
        }}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={data}
        isPagination={true}
      />
    </div>
  );
};

export default AuditsCompleted;
