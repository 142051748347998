import React, { useState, useEffect } from "react";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { Divider, Table, message } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import SecondaryPlacementOverview from "../SecondaryPlacementOverview";
import Cookies from "universal-cookie";
import "./style.scss";
import { useParams } from "react-router-dom";
import { encode } from "base-64";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const ImageGalleryProfile = ({ addLoader, removeLoader }) => {
  const [name, setName] = useState([]);
  const [address, setAddress] = useState([]);
  const [sale, setSale] = useState([]);
  const [lastaudit, setLastaudit] = useState([]);
  let [store, setStore] = useState(cookies.get("ig_store"));
  const [secondaryPlacementOverview, setSecondaryPlacementOverview] = useState(
    []
  );

  const fetchSecondaryPlacementOverview = () => {
    
    if (
      cookies.get("ig_retailer") &&
      cookies.get("ig_retailer") !== "" &&
      cookies.get("ig_retailer") !== "," &&
      cookies.get("ig_retailer") !== undefined &&
      cookies.get("ig_retailer") !== "undefined"
      )
      {
      const loaderId = 'sd_secondaryplacementoverview';
      addLoader(loaderId);
      const url = `${BACKEND_URL_AZURE}/getsecondaryplacementoverview?manufacturer=${cookies.get(
        "manufacturer"
      )}&startDate=${cookies.get("ig_startDate")}&endDate=${cookies.get(
        "ig_endDate"
      )}&retailers=${cookies.get("ig_retailer")}&countries=${cookies.get(
        "ig_country"
      )}&regions=${cookies.get("ig_region")}`;
    fetch(url, { method: "GET", headers:headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setSecondaryPlacementOverview(result.data);
      })
      .catch((err) => console.log("------- Error ------", err));
    }
  };

  useEffect(() => {
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    cookies.set("ig_country", strCountry);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    cookies.set("ig_region", strRegion);
    //end get all countries & regions
    fetchSecondaryPlacementOverview();
    const fetchMyStoreProfile = async () => {
      if (
        cookies.get("ig_retailer") &&
        cookies.get("ig_retailer") !== "" &&
        cookies.get("ig_retailer") !== "," &&
        cookies.get("ig_retailer") !== undefined &&
        cookies.get("ig_retailer") !== "undefined"
      ) {
        if (
          cookies.get("ig_startDate") === undefined ||
          cookies.get("ig_startDate") === "" ||
          cookies.get("ig_startDate") === "undefined"
        ) {
          var startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          cookies.set("ig_startDate", startDate.toISOString().split("T")[0]);
        }
        if (
          cookies.get("ig_endDate") === undefined ||
          cookies.get("ig_endDate") === "" ||
          cookies.get("ig_endDate") === "undefined"
        ) {
          cookies.set("ig_endDate", new Date().toISOString().split("T")[0]);
        }
      }
    };
    fetchMyStoreProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader title="Summary" hideControlButtons={true} />
      <div className="separate-line" />
      <div style={{ fontSize: 14, fontWeight: 500, color: "#000" }}>
        Date :{" "}
        {cookies.get("ig_startDate")
          ? cookies.get("ig_startDate").split("-")[2] +
            " " +
            monthNames[
              parseInt(cookies.get("ig_startDate").split("-")[1]) - 1
            ] +
            " " +
            cookies.get("ig_startDate").split("-")[0].substr(2, 2)
          : ""}{" "}
        -{" "}
        {cookies.get("ig_endDate")
          ? cookies.get("ig_endDate").split("-")[2] +
            " " +
            monthNames[parseInt(cookies.get("ig_endDate").split("-")[1]) - 1] +
            " " +
            cookies.get("ig_endDate").split("-")[0].substr(2, 2)
          : ""}
        <Divider />
        <SecondaryPlacementOverview
          data={secondaryPlacementOverview}
          pagination={false}
        />
        {/*<Divider />
        Store: {name}
        <Divider />
        Store Address: {address}
        <Divider />
        Merchandiser : {sale}
        <Divider />*/}
      </div>
    </div>
  );
};

export default ImageGalleryProfile;
