import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import MyStoresOnShelfAvailabilityChart from "../../components/MyStoresOnShelfAvailabilityChart";
import "./style.scss";

const columns = [
  {
    title: "Product",
    dataIndex: "name",
    width: "50%",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    showSorterTooltip: false,
  },
  {
    title: "Average OSA",
    dataIndex: "numOfFacing",
    width: "50%",
    align: "center",
    key: "numOfFacing",
    //sorter: (a, b) => a.numOfFacing.localeCompare(b.numOfFacing),
    sorter: (a, b) => a.numOfFacing.split(" ")[0] - b.numOfFacing.split(" ")[0],
    showSorterTooltip: false,
  },
];

const MyStoresOnShelfAvailability = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [tableConfigs, setTableConfigs] = useState([]);

  useEffect(() => {
    setTableConfigs(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <MyStoresOnShelfAvailabilityChart osa={data.totalOsa} />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={data.current}
        isPagination={true}
        //showHeader={false}
      />
      <Modal
        title="On Shelf Availability"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={data.current}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div>
    </div>
  );
};

export default MyStoresOnShelfAvailability;
