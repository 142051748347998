import { message } from "antd";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import moment from 'moment';
import { BACKEND_URL, BACKEND_URL_AZURE } from "../../constants/constants";
import AvgShareOfShelfRankingByStore from "../AvgShareOfShelfRankingByStore";
import CompetitorActivityOverview from "../CompetitorActivityOverview";
import PrimaryPlacement from "../PrimaryPlacement";
import SecondaryPlacement from "../SecondaryPlacement";
import ShareOfShelf from "../ShareOfShelf";
import SOSByKeyRetailer from "../SOSByKeyRetailer";
import SecondaryPlacementOverview from "../SecondaryPlacementOverview";
import SpaceBuy from "../SpaceBuy";
import PromotionDepth from "../PromotionDepth";
import PromotionFrequency from "../PromotionFrequency";
import NewSKUDetected from "../NewSKUDetected";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { encode } from "base-64";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const CompetitorActivityComponent = ({ addLoader, removeLoader }) => {
  const [sos, setSos] = useState(0);
  const [primarySos, setPrimarySos] = useState(0);
  const [secondarySos, setSecondarySos] = useState(0);
  const [sosKeyRetailer, setSosKeyRetailer] = useState([]);
  const [avgSosRanking, setAvgSosRanking] = useState([]);
  const [secondaryPlacementOverview, setSecondaryPlacementOverview] = useState(
    []
  );
  const [promoDepth, setPromoDepth] = useState([]);
  const [promoFrequency, setPromoFrequency] = useState([]);
  const [skuDetected, setSkuDetected] = useState([]);

  const fetchSos = () => {
    const loaderId = "ca_fetchsoscacomponent";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getshareofshelf?startDate=${cookies.get(
      "ca_startDate"
    )}&endDate=${cookies.get("ca_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&countries=${cookies.get("ca_country")}&regions=${cookies.get(
      "ca_region"
    )}&retailers=${cookies.get("ca_retailer")}&subcategory=${cookies.get(
      "ca_subcategory"
    )}&includePromoProducts=${cookies.get(
      "ca_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("ca_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET", headers:headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setSos(result.total_sos);
        setPrimarySos(result.primary_sos);
        setSecondarySos(result.secondary_sos);
        setSosKeyRetailer(result.sos_by_retailers);
        setAvgSosRanking(result.sos_by_stores);
      })
      .catch((err) => console.log("------ Error -----", err));
  };

  const fetchPrimarySos = () => {
    const loaderId = "ca_fetchprimarysoscacomponent";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getshareofshelfbyplacementtype?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("ca_startDate")}&endDate=${cookies.get(
      "ca_endDate"
    )}&retailer=${cookies.get("retailer")}&country=${cookies.get(
      "country"
    )}&placementType=PRIMARY&includePromoProducts=${cookies.get(
      "ca_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("ca_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setPrimarySos(result.totalSos);
      })
      .catch((err) => console.log("------- Error ------", err));
  };

  const fetchSecondarySos = () => {
    const loaderId = "ca_fetchsecondarysoscacomponent";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getshareofshelfbyplacementtype?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("ca_startDate")}&endDate=${cookies.get(
      "ca_endDate"
    )}&retailer=${cookies.get("retailer")}&country=${cookies.get(
      "country"
    )}&placementType=SECONDARY&includePromoProducts=${cookies.get(
      "ca_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("ca_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setSecondarySos(result.totalSos);
      })
      .catch((err) => console.log("------- Error ------", err));
  };

  const fetchSosKeyRetailer = () => {
    const loaderId = "ca_fetchsoskeyretailercacomponent";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getshareofshelfbykeyretailer?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("ca_startDate")}&endDate=${cookies.get(
      "ca_endDate"
    )}&retailers=${cookies.get("retailer")}&country=${cookies.get(
      "country"
    )}&includePromoProducts=${cookies.get(
      "ca_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("ca_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setSosKeyRetailer(result.current);
      })
      .catch((err) => console.log("------- Error ------", err));
  };

  const fetchAvgSosRanking = () => {
    const loaderId = "ca_fetchavgsosrankingcacomponent";
    addLoader(loaderId);

    const url = `${BACKEND_URL}/getavgsosrankingbystore?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("ca_startDate")}&endDate=${cookies.get(
      "ca_endDate"
    )}&retailer=${cookies.get("retailer")}&country=${cookies.get(
      "country"
    )}&includePromoProducts=${cookies.get(
      "ca_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("ca_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setAvgSosRanking(result);
      })
      .catch((err) => console.log("------- Error ------", err));
  };

  const fetchSecondaryPlacementOverview = () => {
    const loaderId = "ca_fetchsecondaryplacementoverviewcacomponent";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getsecondaryplacementoverview?manufacturer=${cookies.get(
      "manufacturer"
    )}&startDate=${cookies.get("ca_startDate")}&endDate=${cookies.get(
      "ca_endDate"
    )}&retailers=${cookies.get("ca_retailer")}&regions=${cookies.get(
      "ca_region"
    )}&countries=${cookies.get("ca_country")}&subcategory=${cookies.get(
      "ca_subcategory"
    )}&includePromoProducts=${cookies.get(
      "ca_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get("ca_includeSecondaryDisplay")}`;

    fetch(url, { method: "GET", headers:headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setSecondaryPlacementOverview(result.data);
      })
      .catch((err) => console.log("------- Error ------", err));
  };

  const fetchPromotionDepth = () => {
    const loaderId = "ca_fetchpromodepthcacomponent";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getpromotiondepth?manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("ca_retailer")}&regions=${cookies.get(
      "ca_region"
    )}&countries=${cookies.get("ca_country")}&subcategory=${cookies.get(
      "ca_subcategory"
    )}&startDate=${cookies.get("ca_startDate")}&endDate=${cookies.get(
      "ca_endDate"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setPromoDepth(result.message);
      })
      .catch((err) => console.log("-------- Error -------", err));
  };

  const getPromotionFrequency = () => {
    const loaderId = 'ca_fetchpromotionfrequency';
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getpromotionfrequency?manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("ca_retailer")}&regions=${cookies.get(
      "ca_region"
    )}&subcategory=${cookies.get("ca_subcategory")}&countries=${cookies.get(
      "ca_country"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setPromoFrequency(result.message);
      })
      .catch((err) => console.log("--------- Error --------", err));
  }

  const getSkuChangesDetected = () => {
    const loaderId = 'ca_getskuchangesdetected';
    addLoader(loaderId);
    const manufacturer = cookies.get('manufacturer');
    const startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const url = `${BACKEND_URL_AZURE}/getskuchangesdetected?manufacturer=${manufacturer}&startDate=${startDate}&endDate=${endDate}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setSkuDetected(result.data);
      })
      .catch((error) => console.log("-------- Error -------", error));
  }

  useEffect(() => {
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    cookies.set("ca_country", strCountry);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    cookies.set("ca_region", strRegion);

    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    cookies.set("ca_retailer", strRetailer);

    let strSubCategory = "";
    /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
    if (subcategories) {
      forEach(subcategories, (item) => {
        strSubCategory = strSubCategory + item.subcategory_id + ",";
      });
    }
    strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
    cookies.set("ca_subcategory", strSubCategory);
    //end get all countries & regions
    if (
      cookies.get("ca_startDate") === undefined ||
      cookies.get("ca_startDate") === "" ||
      cookies.get("ca_startDate") === "undefined"
    ) {
      let startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      cookies.set("ca_startDate", startDate.toISOString().split("T")[0]);
    }

    if (
      cookies.get("ca_endDate") === undefined ||
      cookies.get("ca_endDate") === "" ||
      cookies.get("ca_endDate") === "undefined"
    ) {
      cookies.set("ca_endDate", new Date().toISOString().split("T")[0]);
    }

    if (
      cookies.get("ca_includePromoProducts") === undefined ||
      cookies.get("ca_includePromoProducts") === "" ||
      cookies.get("ca_includePromoProducts") === "undefined"
    ) {
      cookies.set("ca_includePromoProducts", true);
    }

    if (
      cookies.get("ca_includeSecondaryDisplay") === undefined ||
      cookies.get("ca_includeSecondaryDisplay") === "" ||
      cookies.get("ca_includeSecondaryDisplay") === "undefined"
    ) {
      cookies.set("ca_includeSecondaryDisplay", false);
    }

    fetchSos();
    //fetchPrimarySos();
    //fetchSecondarySos();
    //fetchAvgSosRanking();
    //fetchSosKeyRetailer();
    fetchSecondaryPlacementOverview();
    fetchPromotionDepth();
    getPromotionFrequency();
    getSkuChangesDetected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CompetitorActivityOverview
        fetchSos={fetchSos}
        //fetchPrimarySos={fetchPrimarySos}
        //fetchSecondarySos={fetchSecondarySos}
        //fetchAvgSosRanking={fetchAvgSosRanking}
        //fetchSosKeyRetailer={fetchSosKeyRetailer}
        fetchSecondaryPlacementOverview={fetchSecondaryPlacementOverview}
        fetchPromotionDepth={fetchPromotionDepth}
        getPromotionFrequency={getPromotionFrequency}
      />
      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-33">
          <ShareOfShelf data={sos} />
        </div>
        <div className="col-33">
          <PrimaryPlacement data={primarySos} />
        </div>
        <div className="col-33">
          <SecondaryPlacement data={secondarySos} />
        </div>
        {/*<div className="col-20">
          <SpaceBuy />
        </div>*/}
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-33">
          <SecondaryPlacementOverview
            data={secondaryPlacementOverview}
            pagination={true}
          />
        </div>
        <div className="col-33">
          <SOSByKeyRetailer data={sosKeyRetailer} />
        </div>
        <div className="col-33">
          <AvgShareOfShelfRankingByStore data={avgSosRanking} />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div
          className="col-33"
          style={{
            display:
              cookies.get("uses_pricing_module") === true ||
              cookies.get("uses_pricing_module") === "true"
                ? "block"
                : "none",
          }}
        >
          <PromotionDepth data={promoDepth} />
        </div>
        <div
          className="col-33"
          style={{
            display:
              cookies.get("uses_pricing_module") === true ||
              cookies.get("uses_pricing_module") === "true"
                ? "block"
                : "none",
          }}
        >
          <PromotionFrequency data={promoFrequency} />
        </div>
        <div className="col-33">
          <NewSKUDetected data={skuDetected} />
        </div>
      </div>
    </>
  );
};

export default CompetitorActivityComponent;
