import { getItem, setItem, removeItem } from "../utils/localStorageHandler";
import { LOGGED_USER, TOKEN, REFRESS_TOKEN, } from "../constants/constants";

export function getAuthData() {
    const loggedUser = getItem(LOGGED_USER);
    const token = getItem(TOKEN);
    const refreshToken = getItem(REFRESS_TOKEN);
    return { user: loggedUser, token, refreshToken };
}

export function storeAuthData(user, token, refreshToken, timeStamp) {
    setItem(LOGGED_USER, user);
    setItem(TOKEN, token);
    setItem(REFRESS_TOKEN, refreshToken);
}

export function updateCurrentUser(user) {
    setItem(LOGGED_USER, user);
}

export function clearAuthData() {
    removeItem(LOGGED_USER);
    removeItem(TOKEN);
    removeItem(REFRESS_TOKEN);
}
