import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Table, Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import "./style.scss";

const columns = [
  {
    title: "Retailer",
    dataIndex: "retailer",
    width: "33.33%",
    key: "retailer",
    sorter: (a, b) => a.retailer.localeCompare(b.retailer),
    showSorterTooltip: false,
  },
  {
    title: "Channel",
    dataIndex: "channel",
    width: "33.33%",
    key: "channel",
    sorter: (a, b) => a.channel.localeCompare(b.channel),
    showSorterTooltip: false,
  },
  {
    title: "Market Share vs SOS (%)",
    dataIndex: "sos",
    key: "sos",
    align: "center",
    width: "33.33%",
  },
];

const auditData = [
  /*{
        auditor: 'Sally',
        store: 'Store A',
        overdueBy: 5,
    },
    {
        auditor: 'John',
        store: 'Store B',
        overdueBy: 4,
    },
    {
        auditor: 'Adrian',
        store: 'Store C',
        overdueBy: 3,
    },
    {
        auditor: 'Gary',
        store: 'Store D',
        overdueBy: 2,
    },*/
];

const SOSByKeyRetailer = (props) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
    setData(auditData);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="SOS by Key Retailer"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={props.data}
        isPagination={true}
        //count={3}
      />
      {/* <Modal
        title="SOS by Key Retailer"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={props.data}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div> */}
    </div>
  );
};

export default SOSByKeyRetailer;
