import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

function PublicRoute({ component: Component, layout: Layout,  ...rest }) {
    return (
        <Route {...rest} render={(props) => (
            <Layout>
                <Component {...props} />
            </Layout>)}
        />
    );
}

export default PublicRoute;
