import React, { useState, useEffect } from "react";
import moment from "moment";
import Cookies from "universal-cookie";
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import OnShelfAvailability from "../OnShelfAvailability";
import OSAByRetailer from "../OSAByRetailer";
import SkuWithZeroFacings from "../SkuWithZeroFacings";
import StoresWithZeroFacing from "../StoresWithZeroFacing";
import { message, DatePicker } from "antd";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import {
  BACKEND_URL,
  CUSTOM_FILTER_KEYS,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const cookies = new Cookies();
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const OSAComponent = ({ removeLoader, addLoader }) => {
  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [totalOsa, setTotalOsa] = useState(0);
  const [dataCompetitor, setDataCompetitor] = useState([]);
  const [osaData, setOsaData] = useState({ table: [] });
  const [skuZeroFacingData, setSKUZeroFacingData] = useState({
    data: [],
    skuLength: 0,
  });
  const [osaZeroFacingData, setZeroFacingData] = useState({
    data: [],
    storeLength: 0,
  });

  let lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - 1);
  lastDate = moment(lastDate).format("YYYY-MM-DD");
  let currentDate = new Date();
  currentDate = moment(currentDate).format("YYYY-MM-DD");
  if (
    cookies.get("osa_endDate") &&
    cookies.get("osa_endDate") !== "" &&
    cookies.get("osa_endDate") !== "undefined"
  ) {
    currentDate = cookies.get("osa_endDate");
  } else {
    let currentDate = new Date();
    cookies.set("osa_endDate", currentDate.toISOString().split("T")[0]);
  }

  if (
    cookies.get("osa_startDate") &&
    cookies.get("osa_startDate") !== "" &&
    cookies.get("osa_startDate") !== "undefined"
  ) {
    lastDate = cookies.get("osa_startDate");
  } else {
    let lastDate = new Date();
    lastDate.setMonth(lastDate.getMonth() - 1);
    cookies.set("osa_startDate", lastDate.toISOString().split("T")[0]);
  }

  const onHandleFilterChanged = async (data) => {
    var strBrand = "";
    if (
      data.BRAND_LIST &&
      data.BRAND_LIST.value &&
      data.BRAND_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.BRAND_LIST.value.length; i++) {
        strBrand = strBrand + data.BRAND_LIST.value[i].id + ",";
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    } else {
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    }
    cookies.set("so_osa_brand", strBrand);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("so_osa_retailer", strRetailer);

    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("so_osa_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    cookies.set("so_osa_region", strRegion);

    if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      cookies.set("so_osa_includePromoProducts", !promotion[0].isChecked);
    } else cookies.set("so_osa_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      cookies.set("so_osa_includeSecondaryDisplay", secondary[0].isChecked);
    } else cookies.set("so_osa_includeSecondaryDisplay", false);

    setFilterData(data);
    convertDataForTags(data);

    await Promise.all([
      refreshOnShelfAvailability(),
      refreshOsaByRetailer(),
      refreshSKUWithZeroFacing(),
      //refreshOsaByRetailerWithZeroFacing(),
    ]);
  };

  const handleRemoveTag = async (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "BRAND_LIST") {
      let brand = cookies.get("so_osa_brand").split(",");
      brand = brand.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("so_osa_brand", brand.join());
      if (brand && brand.length <= 0) {
        let strBrand = "";
        const brands = JSON.parse(localStorage.getItem("brands"));
        if (brands) {
          forEach(brands, (item) => {
            strBrand = strBrand + item.brand_id + ",";
          });
        }
        strBrand = strBrand.substring(0, strBrand.length - 1);
        cookies.set("so_osa_brand", strBrand);
      }
    }
    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("so_osa_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("so_osa_retailer", retailer.join());
      if (retailer && retailer.length <= 0) {
        let strRetailer = "";
        const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
        cookies.set("so_osa_retailer", strRetailer);
      }
    }
    if (item.key === "COUNTRY_LIST") {
      let country = cookies.get("so_osa_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("so_osa_country", country.join());
      if (country && country.length <= 0) {
        let strCountry = "";
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
        cookies.set("so_osa_country", strCountry);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = cookies.get("so_osa_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("so_osa_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("brands"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        cookies.set("so_osa_region", strRegion);
      }
    }
    if (item.key === "PROMO_SELECTION") {
      cookies.set("so_osa_includePromoProducts", true);
    }
    if (item.key === "SECONDARY_DISPLAY_SELECTION") {
      cookies.set("so_osa_includeSecondaryDisplay", false);
    }

    await Promise.all([
      refreshOnShelfAvailability(),
      refreshOsaByRetailer(),
      refreshSKUWithZeroFacing(),
      //refreshOsaByRetailerWithZeroFacing(),
    ]);
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const fetchOnShelfAvailability = () => {
    const loaderId = "se_fetchosa";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getonshelfavailability?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&countries=${cookies.get(
      "so_osa_country"
    )}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setTotalOsa(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const refreshOnShelfAvailability = () => {
    const loaderId = "se_fetchosa";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getonshelfavailability?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&brands=${cookies.get(
      "so_osa_brand"
    )}&countries=${cookies.get("so_osa_country")}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setTotalOsa(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const fetchOsaByRetailer = () => {
    const loaderId = "se_fetchosaretailer";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getosabyretailer?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&countries=${cookies.get(
      "so_osa_country"
    )}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setOsaData(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const refreshOsaByRetailer = () => {
    const loaderId = "se_fetchosaretailer";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getosabyretailer?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&brands=${cookies.get(
      "so_osa_brand"
    )}&countries=${cookies.get("so_osa_country")}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setOsaData(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const fetchSKUWithZeroFacing = () => {
    const loaderId = "se_fetchskuwithzerofacing";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getonshelfavailabilitybystorewithzerofacing?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&countries=${cookies.get(
      "so_osa_country"
    )}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        result.storeLength = result.data.length;
        setZeroFacingData(result);
        //setSKUZeroFacingData(result);
        let arrTruncate = [];
        let arrProduct = [];
        let arrResult = [];
        let arrStore = [];
        let arrAuditDate = [];
        for (let i = 0; i < result.data.length; i++) {
          for (let j = 0; j < result.data[i].product.length; j++) {
            arrProduct.push(result.data[i].product[j]);
            arrStore.push(result.data[i].store);
            arrAuditDate.push(result.data[i].date[j]);
          }
        }
        arrTruncate = arrProduct.reduce((unique, item) => {
          return unique.includes(item) ? unique : [...unique, item];
        }, []);
        for (let i = 0; i < arrTruncate.length; i++) {
          var sum = 0;
          let arrSKUStore = [];
          let arrSKUAuditDate = [];
          for (let j = 0; j < arrProduct.length; j++) {
            if (arrTruncate[i] === arrProduct[j]) 
            {
              arrSKUStore.push(arrStore[j]);
              arrSKUAuditDate.push(arrAuditDate[j]);
            }
          }
          arrResult.push({
            product: arrTruncate[i],
            num_store: arrSKUStore.length,
            store: arrSKUStore,
            audit_date: arrSKUAuditDate,
          });
        }
        //setSKUZeroFacingTruncateData({ data: arrTruncate });
        removeLoader(loaderId);
        setSKUZeroFacingData({ data: arrResult, skuLength: arrResult.length });
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const refreshSKUWithZeroFacing = () => {
    const loaderId = "se_fetchskuwithzerofacing";
    addLoader(loaderId);
    console.log("refreshSKUWithZeroFacing");
    const url = `${BACKEND_URL_AZURE}/getonshelfavailabilitybystorewithzerofacing?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&brands=${cookies.get(
      "so_osa_brand"
    )}&countries=${cookies.get("so_osa_country")}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        result.storeLength = result.data.length;
        setZeroFacingData(result);
        //setSKUZeroFacingData(result);
        let arrTruncate = [];
        let arrProduct = [];
        let arrResult = [];
        let arrStore = [];
         let arrAuditDate = [];
        for (let i = 0; i < result.data.length; i++) {
          for (let j = 0; j < result.data[i].product.length; j++) {
            arrProduct.push(result.data[i].product[j]);
            arrStore.push(result.data[i].store);
            arrAuditDate.push(result.data[i].date[j]);
          }
        }
        arrTruncate = arrProduct.reduce((unique, item) => {
          return unique.includes(item) ? unique : [...unique, item];
        }, []);
        for (let i = 0; i < arrTruncate.length; i++) {
          var sum = 0;
          let arrSKUStore = [];
          let arrSKUAuditDate = [];
          for (let j = 0; j < arrProduct.length; j++) {
            if (arrTruncate[i] === arrProduct[j])
            {
            arrSKUStore.push(arrStore[j]);
            arrSKUAuditDate.push(arrAuditDate[j]);
            }
          }
          arrResult.push({
            product: arrTruncate[i],
            num_store: arrSKUStore.length,
            store: arrSKUStore,
            audit_date: arrSKUAuditDate,
          });
        }
        //setSKUZeroFacingTruncateData({ data: arrTruncate });
        removeLoader(loaderId);
        setSKUZeroFacingData({ data: arrResult, skuLength: arrResult.length });
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  /*const fetchOsaByRetailerWithZeroFacing = () => {
    const loaderId = "se_fetchosazerofacing";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getonshelfavailabilitybystorewithzerofacing?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&countries=${cookies.get(
      "so_osa_country"
    )}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        result.storeLength = result.data.length;
        setZeroFacingData(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

 const refreshOsaByRetailerWithZeroFacing = () => {
    const loaderId = "se_fetchosazerofacing";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/getonshelfavailabilitybystorewithzerofacing?startDate=${cookies.get(
      "osa_startDate"
    )}&endDate=${cookies.get("osa_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&retailers=${cookies.get("so_osa_retailer")}&brands=${cookies.get(
      "so_osa_brand"
    )}&countries=${cookies.get("so_osa_country")}&regions=${cookies.get(
      "so_osa_region"
    )}&includePromoProducts=${cookies.get(
      "so_osa_includePromoProducts"
    )}&includeSecondaryDisplay=${cookies.get(
      "so_osa_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        result.storeLength = result.data.length;
        setZeroFacingData(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };*/

  useEffect(() => {
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    cookies.set("so_osa_country", strCountry);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    cookies.set("so_osa_region", strRegion);

    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    cookies.set("so_osa_retailer", strRetailer);
    //end get all countries & regions
    if (
      cookies.get("so_osa_includePromoProducts") === undefined ||
      cookies.get("so_osa_includePromoProducts") === "" ||
      cookies.get("so_osa_includePromoProducts") === "undefined"
    ) {
      cookies.set("so_osa_includePromoProducts", true);
    }
    if (
      cookies.get("so_osa_includeSecondaryDisplay") === undefined ||
      cookies.get("so_osa_includeSecondaryDisplay") === "" ||
      cookies.get("so_osa_includeSecondaryDisplay") === "undefined"
    ) {
      cookies.set("so_osa_includeSecondaryDisplay", false);
    }
    fetchOnShelfAvailability();
    fetchOsaByRetailer();
    fetchSKUWithZeroFacing();
    //fetchOsaByRetailerWithZeroFacing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-100">
          <div className="Team-Activity-Overview-wp">
            <div className="title-wp">
              <div className="title-wp">On-Shelf Availability</div>
            </div>
            <div className="condition-values-wp">
              <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
            </div>
            <div className="control-btns">
              <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
                <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
                <RangePicker
                  showTime
                  format={"YYYY-MM-DD"}
                  onCalendarChange={async (range, dateString, info) => {
                    let startDate = moment(range[0]).format("YYYY-MM-DD");
                    let endDate = moment(range[1]).format("YYYY-MM-DD");

                    //if (info.range === "end") {
                    //cookies.set("startDate", startDate);
                    //cookies.set("endDate", endDate);
                    cookies.set("osa_startDate", startDate);
                    cookies.set("osa_endDate", endDate);

                    // message.loading('Loading data...', 0);
                    await Promise.all([
                      fetchOnShelfAvailability(),
                      fetchOsaByRetailer(),
                      fetchSKUWithZeroFacing(),
                    ]);
                    //}
                  }}
                  defaultValue={[
                    moment(lastDate, dateFormat),
                    moment(currentDate, dateFormat),
                  ]}
                  allowClear={false}
                  suffixIcon={
                    <img
                      alt=""
                      style={{ marginTop: "3px" }}
                      src={ICON_ARROW_DOWN}
                    />
                  }
                />
              </div>
              <CustomFilter
                useFilterBtnForOverview={true}
                selectedData={filterData}
                filterKeys={[
                  CUSTOM_FILTER_KEYS.COUNTRY_LIST,
                  CUSTOM_FILTER_KEYS.REGION_LIST,
                  CUSTOM_FILTER_KEYS.RETAILER_LIST,
                  CUSTOM_FILTER_KEYS.BRAND_LIST,
                  CUSTOM_FILTER_KEYS.PROMOTION_LIST,
                  CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
                ]}
                onChange={(data) => {
                  onHandleFilterChanged(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <OnShelfAvailability
            data={totalOsa}
            //dataCompetitor={dataCompetitor}
          />
        </div>
        {/*<div className="col-25">
          <SpaceBuy />
        </div>*/}
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-40">
          <OSAByRetailer osaData={osaData} />
        </div>
        <div className="col-60">
          <SkuWithZeroFacings skuZeroFacingData={skuZeroFacingData} />
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-100">
          <StoresWithZeroFacing osaZeroFacingData={osaZeroFacingData} />
        </div>
      </div>
    </>
  );
};

export default OSAComponent;
