import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Alert,
  message,
} from "antd";
import React, { useState } from "react";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Main from "../screens/Main";
import Cookies from "universal-cookie";
import { BACKEND_URL, BACKEND_URL_AZURE } from "../constants/constants";
import authentication from "react-azure-b2c";
import { encode } from "base-64";
import loader from "../assets/img/loadingMilkyway.gif";

const FormItem = Form.Item;

const style = {
  maincontent: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    padding: 20,
    margin: 15,
    boxShadow: "1px 1px 5px 5px #eee",
  },
};

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    let headers = new Headers({
      Authorization:
        "Basic " +
        encode(
          process.env.REACT_APP_dashboardUser +
            ":" +
            process.env.REACT_APP_dashboardPassword
        ),
      "Content-Type": "application/json",
    });
    const cookies = new Cookies();
    const token = authentication.getAccessToken();
    if (token) {
      fetch(
        BACKEND_URL_AZURE +
          "/checkemail?email=" +
          token.idToken.claims.emails[0],
        {
          method: "GET",
          headers: headers,
        }
      )
        .then((res) => res.json())
        .then((result) => {
          cookies.set("user", result.name);
          cookies.set("email", result.email);
          cookies.set("manufacturer", result.client);
          cookies.set("client", result.client);
          cookies.set("manufacturer_name", result.manufacturer_name);
          cookies.set("uses_pricing_module", result.uses_pricing_module);
          //cookies.set("country", "Singapore");
          //cookies.set("currency", "SGD");

          localStorage.setItem("merchandisers", JSON.stringify(result.users));
          localStorage.setItem(
            "countries",
            JSON.stringify(result.countries_regions_stores)
          );
          localStorage.setItem("retailers", JSON.stringify(result.retailers));

          let regions = [];
          for (var i in result.countries_regions_stores) {
            for (var j in result.countries_regions_stores[i].regions) {
              regions.push({
                region_id:
                  result.countries_regions_stores[i].regions[j].region_id,
                region_name:
                  result.countries_regions_stores[i].regions[j].region_name,
              });
            }
          }
          localStorage.setItem("regions", JSON.stringify(regions));

          let stores = [];
          for (i in result.countries_regions_stores) {
            for (j in result.countries_regions_stores[i].regions) {
              for (var k in result.countries_regions_stores[i].regions[j]
                .stores) {
                stores.push({
                  store_id:
                    result.countries_regions_stores[i].regions[j].stores[k]
                      .store_id,
                  store_name:
                    result.countries_regions_stores[i].regions[j].stores[k]
                      .store_name,
                });
              }
            }
          }
          localStorage.setItem("stores", JSON.stringify(stores));
          cookies.set("store", stores[0].store_id);

          const tracked_list_url =
            BACKEND_URL_AZURE + "/gettrackedlist?manufacturer=" + result.client;
          fetch(tracked_list_url, {
            method: "GET",
            headers: headers,
          })
            .then((res1) => res1.json())
            .then((result1) => {
              /*localStorage.setItem(
                "tracked_list",
                JSON.stringify(result1.message)
              );*/
              let tracked_brand = [];
              let arrBrands = [];
              for (let d = 0; d < result1.message.length; d++) {
                if (arrBrands.includes(result1.message[d].brand_id) === false) {
                  arrBrands.push(result1.message[d].brand_id);
                  tracked_brand.push({
                    brand_id: result1.message[d].brand_id,
                    brand_name: result1.message[d].brand_name,
                  });
                }
              }
              localStorage.setItem("brands", JSON.stringify(tracked_brand));

              let tracked_product = [];
              let arrProducts = [];
              for (let d = 0; d < result1.message.length; d++) {
                if (
                  arrProducts.includes(result1.message[d].product_id) === false
                ) {
                  arrProducts.push(result1.message[d].product_id);
                  tracked_product.push({
                    product_id: result1.message[d].product_id,
                    product_name: result1.message[d].product_name,
                  });
                }
              }
              localStorage.setItem("products", JSON.stringify(tracked_product));

              let tracked_manufacturer = [];
              let arrManufacturer = [];
              for (let d = 0; d < result1.message.length; d++) {
                if (
                  arrManufacturer.includes(
                    result1.message[d].manufacturer_id
                  ) === false
                ) {
                  arrManufacturer.push(result1.message[d].manufacturer_id);
                  tracked_manufacturer.push({
                    manufacturer_id: result1.message[d].manufacturer_id,
                    manufacturer_name: result1.message[d].manufacturer_name,
                  });
                }
              }
              localStorage.setItem(
                "manufacturers",
                JSON.stringify(tracked_manufacturer)
              );

              let tracked_category = [];
              let arrCategory = [];
              for (let d = 0; d < result1.message.length; d++) {
                if (
                  arrCategory.includes(result1.message[d].category_id) === false
                ) {
                  arrCategory.push(result1.message[d].category_id);
                  tracked_category.push({
                    category_id: result1.message[d].category_id,
                    category_name: result1.message[d].category_name,
                  });
                }
              }
              localStorage.setItem(
                "categories",
                JSON.stringify(tracked_category)
              );

               let tracked_subcategory = [];
               let arrSubCategory = [];
               for (let d = 0; d < result1.message.length; d++) {
                 if (
                   arrSubCategory.includes(
                     result1.message[d].subcategory_id
                   ) === false
                 ) {
                   arrSubCategory.push(result1.message[d].subcategory_id);
                   tracked_subcategory.push({
                     subcategory_id: result1.message[d].subcategory_id,
                     subcategory_name: result1.message[d].subcategory_name,
                   });
                 }
               }
               localStorage.setItem(
                 "subcategories",
                 JSON.stringify(tracked_subcategory)
               );
            });

          const osa_list_url =
            BACKEND_URL_AZURE + "/getosalist?manufacturer=" + result.client;
          fetch(osa_list_url, {
            method: "GET",
            headers: headers,
          })
            .then((res1) => res1.json())
            .then((result1) => {
              localStorage.setItem("osa_list", JSON.stringify(result1.message));
            });
          this.props.history.push("./team-activity");
        })
        .catch((error) => {
          console.log("--------- Error --------", error);
        });
    }
  }

  render() {
    return <></>;
  }
}
