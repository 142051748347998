import React, { useState, useEffect } from "react";
import { message } from "antd";
import SpaceBuy from "../../components/SpaceBuy";
import NoOfNonCompliantStoresByKeyRetailer from "../../components/NoOfNonCompliantStoresByKeyRetailer";
import NoOfNonCompliantStoresByAccount from "../../components/NoOfNonCompliantStoresByAccount";
import OnShelfAvailability from "../../components/OnShelfAvailability";
import StoreExecutionOverview from "../../components/StoreExecutionOverview";
import OSAByRetailer from "../../components/OSAByRetailer";
import StoresWithZeroFacing from "../../components/StoresWithZeroFacing";
import SkuWithZeroFacings from "../../components/SkuWithZeroFacings";
import OSAKelloggVsCompetitorChart from "../../components/OSAKelloggVsCompetitorChart";
import "./style.scss";
import CustomFilter from "../../componentsShared/CustomFilter";
import { cloneDeep } from "lodash";
import FilterTags from "../../componentsShared/FilterTags";
import { Dropdown, Menu, Radio, DatePicker, Button } from "antd";
import Cookies from "universal-cookie";
import { CUSTOM_FILTER_KEYS, BACKEND_URL } from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
import moment from "moment";
import AvgOfFacingsByRetailer from "../../components/AvgOfFacingsByRetailer";
import AvgOfFacingsByBrand from "../../components/AvgOfFacingsByBrand";
import NoOfStoresByFacing from "../../components/NoOfStoresByFacing/NoOfStoresByFacing";
import TotalOfFacings from "../../components/TotalOfFacings/TotalOfFacings";
import StoreExecutionComponent from "../../components/StoreExecution/StoreExecution";
import OSAComponent from "../../components/OSAComponent/OSAComponent";
import LoaderComponent from "../../components/LoaderComponent";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();

var lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
var currentDate = new Date();

if (
  cookies.get("se_endDate") &&
  cookies.get("se_endDate") !== "" &&
  cookies.get("se_endDate") !== "undefined"
) {
  currentDate = cookies.get("se_endDate");
} else cookies.set("se_endDate", currentDate.toISOString().split("T")[0]);

if (
  cookies.get("se_startDate") &&
  cookies.get("se_startDate") !== "" &&
  cookies.get("se_startDate") !== "undefined"
) {
  lastDate = cookies.get("se_startDate");
} else cookies.set("se_startDate", lastDate.toISOString().split("T")[0]);

const StoreExecution = () => {
  const [periodFilter, setPeriodFilter] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [filterDataOsaVsCompetitor, setFilterDataOsaVsCompetitor] = useState(
    []
  );
  const [tagsData, setTagsData] = useState([]);
  const [manufacturer, setManufacturer] = useState();

  // store active loading state of the child components
  const [loaders, setLoaders] = useState([]);

  const removeLoader = (id) => {
    setLoaders((prevState) => prevState.filter((state) => state !== id));
  };

  const addLoader = (id) => {
    setLoaders((prevState) => [...prevState, id]);
  };

  useEffect(() => {
    if (
      !cookies.get("so_osa_includePromoProducts") ||
      cookies.get("so_osa_includePromoProducts") === "" ||
      cookies.get("so_osa_includePromoProducts") === "undefined"
    ) {
      cookies.set("so_osa_includePromoProducts", true);
    }

    if (
      !cookies.get("so_osa_includeSecondaryDisplay") ||
      cookies.get("so_osa_includeSecondaryDisplay") === "" ||
      cookies.get("so_osa_includeSecondaryDisplay") === "undefined"
    ) {
      cookies.set("so_osa_includeSecondaryDisplay", false);
    }
  }, []);

  return (
    <div className="team-av">
      <LoaderComponent show={loaders.length > 0} />
      <StoreExecutionComponent
        addLoader={addLoader}
        removeLoader={removeLoader}
      />

      <div className="separate-line mb-30" />

      <OSAComponent addLoader={addLoader} removeLoader={removeLoader} />

      <div className="separate-line mb-30" />

      <NoOfStoresByFacing addLoader={addLoader} removeLoader={removeLoader} />

      <div className="separate-line mb-30" />

      <AvgOfFacingsByRetailer
        addLoader={addLoader}
        removeLoader={removeLoader}
      />

      <div className="separate-line mb-30" />

      <AvgOfFacingsByBrand addLoader={addLoader} removeLoader={removeLoader} />

      <div className="separate-line mb-30" />

      <OSAKelloggVsCompetitorChart
        addLoader={addLoader}
        removeLoader={removeLoader}
      />

      <div className="separate-line mb-30" />

      <TotalOfFacings addLoader={addLoader} removeLoader={removeLoader} />
    </div>
  );
};
export default StoreExecution;
