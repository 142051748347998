import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Alert,
  message,
} from "antd";
import React, { useState } from "react";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Main from "../screens/Main";
import Cookies from "universal-cookie";
import { BACKEND_URL } from "../constants/constants";
import authentication from "react-azure-b2c";
import { encode } from "base-64";
import loader from "../assets/img/loadingMilkyway.gif";

const FormItem = Form.Item;

const style = {
  maincontent: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    padding: 20,
    margin: 15,
    boxShadow: "1px 1px 5px 5px #eee",
  },
};

export default class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    //cookies.set("user", null);
    //cookies.set("email", null);
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    localStorage.clear();
    authentication.signOut();
    this.props.history.push("./");
  }

  render() {
    return <></>;
  }
}
