import React, { useState, useEffect } from "react";
import { Divider, Table, message } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS, BACKEND_URL,BACKEND_URL_AZURE } from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";
import { useParams } from "react-router-dom";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const MyStoreProfile = ({ addLoader, removeLoader }) => {
  const [name, setName] = useState([]);
  const [address, setAddress] = useState([]);
  const [sale, setSale] = useState([]);
  const [lastaudit, setLastaudit] = useState([]);
  //const { store } = useParams();
  let [store, setStore] = useState(cookies.get("store"));

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function timer() {
    refreshMyStoreProfile();
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function refreshMyStoreProfile() {
    if (
      cookies.get("store") &&
      cookies.get("store") !== "" &&
      cookies.get("store") !== "undefined" &&
      store !== cookies.get("store")
    ) {
      // message.loading("Loading data...", 0);
      //await sleep(1000);
      store = cookies.get("store");
      var url = BACKEND_URL_AZURE + "/getstorebyaudit?store=" + cookies.get("store");

      // const loaderId = 'ms_refstoreprofileone';
      // addLoader(loaderId);
      fetch(url, { method: "GET", headers:headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          // removeLoader(loaderId);
          setName(result.name);
          setAddress(result.address);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
      if (
        cookies.get("ms_startDate") == undefined ||
        cookies.get("ms_startDate") === "" ||
        cookies.get("ms_startDate") === "undefined"
      ) {
        var startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ms_endDate") == undefined ||
        cookies.get("ms_endDate") === "" ||
        cookies.get("ms_endDate") === "undefined"
      ) {
        cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
      }

      // const loaderIdAudit = 'ms_lastauditstore';
      // addLoader(loaderIdAudit);
      var url1 =
        BACKEND_URL_AZURE +
        "/getlastauditbystore?store=" +
        cookies.get("store") +
        "&client=" +
        cookies.get("client") +
        "&startDate=" +
        cookies.get("ms_startDate") +
        "&endDate=" +
        cookies.get("ms_endDate");

      fetch(url1, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result1) => {
          // removeLoader(loaderIdAudit);
          if (result1) {
            setSale(result1.sale);
            //setLastaudit(result1[0].dateEnd.split("T")[0]);
            setLastaudit(result1.dateEnd);
          } else {
            setSale("");
            setLastaudit("");
          }
          // message.destroy();
        })
        .catch(function (error) {
          //console.log("-------- error ------- " + error);
        });
    }
  }

  useEffect(() => {
    setInterval(timer, 1000);
    const fetchMyStoreProfile = async () => {
      const loaderId = 'ms_mystoreprofile';
      //addLoader(loaderId);

      if (
        cookies.get("store") &&
        cookies.get("store") !== "" &&
        cookies.get("store") !== "undefined"
      ) {
        var url =
          BACKEND_URL_AZURE + "/getstorebyaudit?store=" + cookies.get("store");
        fetch(url, { method: "GET", headers: headers })
          .then(function (response) {
            return response.json();
          })
          .then(async (result) => {
            setName(result.name);
            setAddress(result.address);
          })
          .catch(function (error) {
            console.log("-------- error ------- " + error);
          });
        if (
          cookies.get("ms_startDate") == undefined ||
          cookies.get("ms_startDate") === "" ||
          cookies.get("ms_startDate") === "undefined"
        ) {
          var startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          cookies.set("ms_startDate", startDate.toISOString().split("T")[0]);
        }
        if (
          cookies.get("ms_endDate") == undefined ||
          cookies.get("ms_endDate") === "" ||
          cookies.get("ms_endDate") === "undefined"
        ) {
          cookies.set("ms_endDate", new Date().toISOString().split("T")[0]);
        }
        var url1 =
          BACKEND_URL_AZURE +
          "/getlastauditbystore?store=" +
          cookies.get("store") +
          "&client=" +
          cookies.get("client") +
          "&startDate=" +
          cookies.get("ms_startDate") +
          "&endDate=" +
          cookies.get("ms_endDate");

        fetch(url1, { method: "GET", headers: headers })
          .then(function (response) {
            return response.json();
          })
          .then(async (result1) => {
            //removeLoader(loaderId);
            if (result1) {
              setSale(result1.sale);
              setLastaudit(
                result1.dateEnd
              );
            } else {
              setSale("");
              setLastaudit("");
            }
          })
          .catch(function (error) {
            console.log("-------- error ------- " + error);
          });
      }
    };
    fetchMyStoreProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader title="Profile" hideControlButtons={true} />
      <div className="separate-line" />
      <div style={{ fontSize: 14, fontWeight: 500, color: "#000" }}>
        Store: {name}
        <Divider />
        Store Address: {address}
        <Divider />
        Merchandiser : {sale}
        <Divider />
        Last Audit : {lastaudit}
        <Divider />
      </div>
    </div>
  );
};

export default MyStoreProfile;
