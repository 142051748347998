import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";

const columns = [
  {
    title: "# of Facings",
    dataIndex: "facing",
    width: "30%",
    align: "center",
    key: "facing",
  },
  {
    title: "Stores",
    dataIndex: "stores",
    width: "70%",
    key: "stores",
    render: (stores) => (
      <>
        {stores.map((store) => (
          <div>{store}</div>
        ))}
      </>
    ),
  },
];

const tableColumns = [
  {
    title: "No. of Facings",
    dataIndex: "facing",
    width: "30%",
    align: "center",
    key: "facing",
  },
  {
    title: "Stores",
    dataIndex: "stores",
    width: "70%",
    key: "stores",
    render: (stores) => {
      stores = stores.slice(0, 5);
      return stores.map((store) => <div>{store}</div>);
    },
  },
];

const NoOfStoresByFacings = (props) => {
  const tableData = props.data.slice(0, 1);
  const modalData = props.data;
  const [showModal, setShowModal] = useState(false);
  const [modalConfigs, setModalTableConfig] = useState([]);
  const [tableConfigs, setTableConfig] = useState([]);

  useEffect(() => {
    setModalTableConfig(columns);
    setTableConfig(tableColumns);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="Stores by No. of Facing"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={tableData}
        isPagination={true}
      />
      <Modal
        title="Stores by No. of Facing"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
        footer={null}
      >
        <ReportTable
          columns={modalConfigs}
          dataSource={modalData}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton onClick={() => setShowModal(true)} title="View all" />
      </div>
    </div>
  );
};

export default NoOfStoresByFacings;
