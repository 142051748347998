import React, { useState, useEffect } from "react";
import { forEach, cloneDeep, find } from "lodash";
import RegionsList from "./data.json";
import "./style.scss";

const PromoSelection = (props) => {
  const { onChange } = props;
  const [regionData, setRegionsData] = useState([]);

  useEffect(() => {
    generateRegionsData(RegionsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateRegionsData = (data) => {
    let Regions = [];
    forEach(data, (sItem) => {
      Regions.push({
        id: sItem.id,
        label: sItem.label,
        value: sItem.value,
        isChecked: false,
      });
    });
    setRegionsData(Regions);
  };

  const onSelectRegion = (mItem) => {
    let clonedData = cloneDeep(regionData);

    let matched = find(clonedData, { id: mItem.id });
    matched.isChecked = true;

    clonedData = clonedData.map(data => {
        if(data.id === mItem.id) {
            data = matched;
        } else {
            data.isChecked = false;
        }
        return data;
    })
    setRegionsData(clonedData);
    onChange(matched);
  };

  const handleRadioChange = (event, data) => {
    onSelectRegion(data);
  };

  return (
    <div className="custom-table-RegionsSelection">
      <div className="RegionsSelection-wp">
        <div className="MultiCheckboxSelection-wp">
        {regionData &&
          regionData.map((data) => (
            <div className="customCheckbox-wp">
              <label class="custom-checkbox-item">
                <input
                  type="radio"
                  value={data.id}
                  onChange={(e) => handleRadioChange(e, data)}
                  name="promo"
                />
                <span />
                <div>{data.label}</div>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

PromoSelection.defaultProps = {
  selectedItems: [{ id: "", label: "", isChecked: false }],
  onChange: (value) => {},
};

export default PromoSelection;
