import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as AuthDataManager from "../../services/authDataManager";
import { useAuthState } from "../../providers/authProvider";
import { isTimeExpired } from '../../utils/utilitiesService';
import "../layoutStyles/loginLayout.scss";

export default function LoginLayout(props) {
    const history = useHistory();
    const { isAuthenticated } = useAuthState();

    useEffect(() => {
        if (!isAuthenticated) return;

        const { token } = AuthDataManager.getAuthData();
        if (isTimeExpired(token)) {
            return;
        }

        if (history.push) {
            history.push("./");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname]);


    return (
        <div className="login-layout-container">
            {props.children}
        </div>
    );
}