import React, { useState, useEffect } from "react";
import HeaderStoreAuditsChart from "../../componentsShared/HeaderStoreAuditsChart";
import { Column } from "@ant-design/charts";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});
const CompletedStoreAuditsChart = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState([
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
    { month: "", numAudit: 0 },
  ]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month] = useState(new Date().getMonth() + 1);
  const [filterData, setFilterData] = useState([]);

  //get filter data
  function getDataByFilter(country, region) {
    const loaderId = "ta_store_audit_chart_data_by_filter";
    addLoader(loaderId);
    var strCountry = "";
    if (country && country.length > 0) {
      for (let i = 0; i < country.length; i++) {
        strCountry = strCountry + country[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }

    var strRegion = "";
    if (region && region.length > 0) {
      for (let i = 0; i < region.length; i++) {
        strRegion = strRegion + region[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }

    var url =
      BACKEND_URL_AZURE +
      "/getauditbyclient?client=" +
      cookies.get("client") +
      "&countries=" +
      strCountry +
      "&regions=" +
      strRegion +
      "&month=" +
      month +
      "&year=" +
      year;
    fetch(url, { method: "GET", headers: headers })
      .then(function (response) {
        return response.json();
      })
      .then(async (result) => {
        removeLoader(loaderId);
        setData(result.message);
      })
      .catch(function (error) {
        console.log("-------- error ------- " + error);
      });
  }
  //end get filter data
  //get filter data
  function getDataByYear(year) {
    const loaderId = "ta_store_audit_chart_data_by_year";
    addLoader(loaderId);
    const url1 =
      BACKEND_URL_AZURE +
      "/getauditbyclient?client=" +
      cookies.get("client") +
      "&year=" +
      year;

    fetch(url1, { method: "GET", headers: headers })
      .then(function (response) {
        return response.json();
      })
      .then(async (result) => {
        removeLoader(loaderId);
        setData(result);
      })
      .catch(function (error) {
        console.log("-------- error ------- " + error);
      });
  }
  //end get filter data
  //get data
  useEffect(() => {
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    //end get all countries & regions
    const fetchData3 = async () => {
      const loaderId = "ta_store_audit_chart_data3";
      addLoader(loaderId);
      var url =
        BACKEND_URL_AZURE +
        "/getauditbyclient?client=" +
        cookies.get("client") +
        "&countries=" +
        strCountry +
        "&regions=" +
        strRegion +
        "&month=" +
        month +
        "&year=" +
        year;

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          removeLoader(loaderId);
          setData(result.message);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    };
    fetchData3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data_sa = [
    {
      Month: data[11].month,
      "No. of stores audited": data[11].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[10].month,
      "No. of stores audited": data[10].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[9].month,
      "No. of stores audited": data[9].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[8].month,
      "No. of stores audited": data[8].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[7].month,
      "No. of stores audited": data[7].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[6].month,
      "No. of stores audited": data[6].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[5].month,
      "No. of stores audited": data[5].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[4].month,
      "No. of stores audited": data[4].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[3].month,
      "No. of stores audited": data[3].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[2].month,
      "No. of stores audited": data[2].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[1].month,
      "No. of stores audited": data[1].numAudit,
      category: "No. of stores audited",
    },
    {
      Month: data[0].month,
      "No. of stores audited": data[0].numAudit,
      category: "No. of stores audited",
    },
  ];

  const config_sa = {
    data: data_sa,
    title: {
      visible: false,
      text: "Promotion Frequency",
      style: {
        fontSize: 15,
      },
    },
    legend: {
      position: "top",
      visible: true,
    },
    xField: "Month",
    yField: "No. of stores audited",
    seriesField: "category",
    smooth: "false",
    responsive: true,
    //color: ["#4A5DE8"],
    color: [
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
    ],
    yAxis: {
      title: {
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
    },
    xAxis: {
      title: {
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      label: {
        style: {
          fill: "#000",
          fontSize: 14,
          fontWeight: "bold",
        },
      },
    },
    label: {
      visible: false,
      style: {
        fill: "#000",
        fontSize: 14,
        //fontWeight: 600,
      },
    },
    tooltip: {
      style: {
        fill: "#000",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
  };

  return (
    <div className="Completed-Store-Audits-Chart-wp">
      <HeaderStoreAuditsChart
        title="No. of Completed Store Audits"
        filterKeys={[
          CUSTOM_FILTER_KEYS.COUNTRY_LIST,
          CUSTOM_FILTER_KEYS.REGION_LIST,
        ]}
        onFilterChanged={(filters) => {
          getDataByFilter(
            filters.COUNTRY_LIST.value,
            filters.REGION_LIST.value
          );
          setFilterData(filters);
        }}
        onYearChanged={(filters) => {
          setYear(filters);
          getDataByYear(filters);
        }}
      />
      <div className="separate-line" />
      <Column {...config_sa} />
    </div>
  );
};

export default CompletedStoreAuditsChart;
