import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import MultiCheckboxSelection from "../MultiCheckboxSelection";
import "./style.scss";
import { findAllByDisplayValue } from "@testing-library/react";

const BrandSelection = (props) => {
  const { defaultValue, onChange, isPlanogram=false } = props;
  const [regionData, setRegionsData] = useState([]);
  const trackedBrands = JSON.parse(localStorage.getItem("brands"));
  const planogramBrands = JSON.parse(localStorage.getItem("brands"));

  useEffect(() => {
    if (isPlanogram) {
      generateRegionsData(planogramBrands);
    } else {
      generateRegionsData(trackedBrands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    combineRegionsWithOutsideData(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const generateRegionsData = (data) => {
    let Brands = [];
    forEach(data, (sItem) => {
      Brands.push({
        id: sItem.brand_id,
        label: sItem.brand_name,
        isChecked: false,
        className: "custom-checkbox-item",
      });
    });
    setRegionsData(Brands);
    //setRegionsData(Brands);
  };

  const combineRegionsWithOutsideData = (items) => {
    let Brands = [];
    if (isPlanogram) {
      forEach(planogramBrands, (sItem) => {
        Brands.push({
          id: sItem.brand_id,
          label: sItem.brand_name,
          isChecked: false,
          className: "custom-checkbox-item",
        });
      });
    } else {
      forEach(trackedBrands, (sItem) => {
        Brands.push({
          id: sItem.brand_id,
          label: sItem.brand_name,
          isChecked: false,
        });
      });
    }
    let clonedData = cloneDeep(Brands);
    //let clonedData = cloneDeep(Brands);

    forEach(clonedData, (sItem) => {
      const matched = find(items, { id: sItem.id });
      sItem.isChecked = !isEmpty(matched) ? matched.isChecked : false;
    });

    setRegionsData(clonedData);
  };

  const onSelectRegion = (mItem, isChecked) => {
    let clonedData = cloneDeep(regionData);

    let matched = find(clonedData, { id: mItem.id });
    if (matched) {
      matched.isChecked = isChecked;
    }
    setRegionsData(clonedData);

    const selectedItems = filter(clonedData, { isChecked: true });
    onChange(selectedItems);
  };

  const onSelectAll = (isChecked) => {
    if (isChecked===true) {
      let clonedData = cloneDeep(regionData);
      forEach(clonedData, (sItem) => {
        sItem.isChecked = isChecked;
      });
      setRegionsData(clonedData);
      onChange(clonedData);
    }
    else{
      let clonedData = cloneDeep(null);
      onChange(clonedData);
    }
  };

  return (
    <div className="custom-table-StoresSelection">
      <div className="StoresSelection-wp">
        <MultiCheckboxSelection
          data={regionData}
          onCheckItem={onSelectRegion}
          onCheckAll={onSelectAll}
        />
      </div>
    </div>
  );
};

BrandSelection.defaultProps = {
  selectedItems: [{ id: "", label: "", isChecked: false }],
  onChange: (value) => {},
  isPlanogram: false,
};

export default BrandSelection;
