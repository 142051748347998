
import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";

const columns = [
  {
    title: "Retailer",
    dataIndex: "name",
    width: "50%",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    showSorterTooltip: false,
  },
  {
    title: "No. of Facings",
    dataIndex: "avgFacingCount",
    width: "50%",
    align: "center",
    key: "avgFacingCount",
    sorter: (a, b) => a.avgFacingCount - b.avgFacingCount,
    showSorterTooltip: false,
  },
];

const NoOfFacingsByRetailer = (props) => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="Average No. of facing by retailer"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={props.data}
        isPagination={true}
      />
      <Modal
        title="Average No. of facing by retailer"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={props.data}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton onClick={() => setShowModal(true)} title="View all" />
      </div>
    </div>
  );
};

export default NoOfFacingsByRetailer;
