import React, { useState } from "react";
import "../assets/css/App.css";
import { Helmet } from "react-helmet";
import {
  Tabs,
  Menu,
  Dropdown,
  Layout,
} from "antd";
import { Row, Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ProductDetection from '../components/ProductDetection';
// import PriceDetection from '../components/PriceDetection';


const Demo = () => {
  const { Header, Content } = Layout;
  const { TabPane } = Tabs;
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a rel="noopener noreferrer" href="/">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  function callback(key) {
    console.log(key);
    setSelectedTab(key);
  }

  const [selectedTab, setSelectedTab] = useState("1");

  return (
    <Layout>
      <Helmet>
        <title>MilkywayAI - Demo</title>
      </Helmet>
      <Header>
        <Row>
          <Col span={12}>
            <div className="logo">
              <img
                style={{ width: "96px" }}
                src={require("../assets/img/logo.png")}
                alt="logo"
              ></img>
              <font style={{ marginLeft: 30 }}>Demo</font>
            </div>
          </Col>
          <Col span={9}></Col>
          <Col span={3} style={{ float: "right" }}>
            <Dropdown overlay={menu} style={{ float: "right" }}>
              <a href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Demo InstaShelf <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row>
          <Col span={24} style={{ padding: 10 }}>
            <Tabs
              centered={false}
              //tabBarGutter={35}
              defaultActiveKey="1"
              onChange={callback}
            >
              <TabPane
                tab={
                  <div style={{ width: window.innerWidth / 6 }}>
                    Product Detection
                  </div>
                }
                key="1"
              >
                {selectedTab === "1" && <ProductDetection />}
              </TabPane>
              {/* <TabPane
                tab={
                  <div style={{ width: window.innerWidth / 6 }}>
                    Price Detection
                  </div>
                }
                key="2"
              >
                {selectedTab === "2" && <PriceDetection />}
              </TabPane> */}

            </Tabs>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
export default Demo;
