
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { cloneDeep } from "lodash";
import { Button, DatePicker } from 'antd';
import CustomFilter from "../CustomFilter";
import FilterTags from "../FilterTags";
import "./style.scss";

import * as filterDataHanler from "../../utils/filterDataHanler";

const ICON_FILTER = require("../../assets/img/icons/filter-icon.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");

const HeaderStoreAuditsChart = (props) => {
    const {
      title,
      onClickExport,
      showExportLoading,
      filterKeys,
      onFilterChanged,
      onYearChanged,
    } = props;

    const [filterData, setFilterData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
const [year, setYear] = useState(new Date().getFullYear());

    const onHandleFilterChanged = (data) => {
      onFilterChanged(data);
        setFilterData(data);
        convertDataForTags(data);
    }

     const onHandleYearChanged = (data) => {
       onYearChanged(data);
       //setYear(data);
     };

    const convertDataForTags = (data) => {
        setTagsData(filterDataHanler.convertDataForTags(data));
    }

    const handleRemoveTag = (item) => {
        const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
        onFilterChanged(data);
        setFilterData(data);
        convertDataForTags(data);
    }

    return (
      <div className="HeaderStoreAuditsChart">
        <div className="hd-left-col">
          <div className="title-value">{title || ""}</div>
        </div>
        <div className="hd-middle-col">
          <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
        </div>
        <div className="hd-right-col">
          {/*<div className="custom-calendar-wp">
            <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
            <DatePicker
              allowClear={false}
              onChange={(data) => {
                onHandleYearChanged(data.year());
              }}
              picker="year"
              suffixIcon={<img alt="" src={ICON_ARROW_DOWN} />}
              disabledDate={(d) => !d || d.isAfter("2021")}
            />
            </div>*/}
          <CustomFilter
            selectedData={filterData}
            filterKeys={filterKeys}
            onChange={(data) => {
              onHandleFilterChanged(data);
            }}
          />
        </div>
      </div>
    );
}

HeaderStoreAuditsChart.propTypes = {
    title: PropTypes.string,
    onClickFilter: PropTypes.func,
    onClickExport: PropTypes.func,
    showFilterLoading: PropTypes.bool,
    showExportLoading: PropTypes.bool,
}

export default HeaderStoreAuditsChart;
