import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { Button } from "antd";
import { isEmpty, cloneDeep } from "lodash";

import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import StoresSelection from "../StoresSelection";
import RegionSelection from "../RegionSelection";
import ChannelSelection from "../ChannelSelection";
import CountryCodesDropdown from "../CountryCodesDropdown";
import ManufacturerSelection from "../ManufacturerSelection";
import RetailerSelection from "../RetailerSelection";
import SalesRepSelection from "../SalesRepSelection";
import ProductCategorySelection from "../ProductCategorySelection";
import ProductSCategorySelection from "../ProductSCategorySelection";
import BrandSelection from "../BrandSelection";
import PackSizeSelection from "../PackSizeSelection";
import FlavourSelection from "../FlavourSelection";
import ProductionSelection from "../ProductSelection";
import PromotionSelection from "../PromotionSelection";
import PlacementTypeSelection from "../PlacementTypeSelection";
import MerchandiserSelection from "../MerchandiserSelection";
import OsaBrandSelection from "../OsaBrandSelection";
import TrackedProductSelection from "../TrackedProductSelection";
import SRPProductSelection from "../SRPProductSelection";
import SecondaryPlacementTypeSelection from "../SecondaryPlacementTypeSelection";
import SecondaryDisplaySelection from "../SecondaryDisplaySelection";
import CountrySelection from "../CountrySelection";
import SingleCountrySelection from "../SingleCountrySelection";
import ProductSubCategorySelection from "../ProductSubCategorySelection";

import "./style.scss";
import PromoSelection from "../PromoSelection";
import SingleProductSelection from "../SingleProductSelection";

const ICON_FILTER = require("../../assets/img/icons/filter-icon.svg");
const ICON_FILTER_FOR_OVERVIEW = require("../../assets/img/icons/filter-icon-black.svg");

export const PopperContext = React.createContext();

var documentListener = null;

const ClickAwayListener = ({ onClickAway, children, classNameRef }) => {
  const containerRef = useRef();
  const insideReactTree = useRef(false);

  useEffect(() => {
    documentListener = document.body.addEventListener("click", (e) => {
      if (
        !insideReactTree.current &&
        containerRef &&
        containerRef.current &&
        containerRef.current.contains &&
        e &&
        e.target &&
        !containerRef.current.contains(e.target)
      ) {
        onClickAway();
      }
      insideReactTree.current = false;
    });
    return () => {
      document.body.removeEventListener("click", documentListener);
    };
  }, [onClickAway]);
  return (
    <div
      className={classNameRef || ""}
      ref={containerRef}
      onClick={() => {
        insideReactTree.current = true;
      }}
    >
      {children}
    </div>
  );
};

const CustomFilter = (props) => {
  const {
    filterKeys,
    selectedData,
    onChange,
    useFilterBtnForOverview = false,
    isPlanogram,
  } = props;
  const [filterData, setFilterData] = useState({});

  const [activeSubPopoverKey, setActiveSubPopoverKey] = useState(null);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const popper = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const portalRef = useRef();

  const contextProps = {
    popperEl: popperElement,
    openPopup: (target) => setReferenceElement(target),
    hidePopup: () => setReferenceElement(null),
    portalRef,
  };

  useEffect(() => {
    // initCustomFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initCustomFilterData = () => {
    let config = {};
    filterKeys.forEach((iKey) => {
      config[iKey] = { key: iKey, value: [] };
    });
    setFilterData(config);
  };

  const onHandleFilterDataChange = (key, value) => {
    const clonedData = cloneDeep(filterData);
    let matchedData = clonedData[key];
    if (!isEmpty(matchedData)) {
      matchedData.value = value;
    }

    setFilterData(clonedData);
  };

  // const isDisableApplyBtn = () => {
  //     if (isEmpty(filterData)) return true;

  //     let isDisabled = true;
  //     forIn(filterData, (value, key) => {
  //         if (!isEmpty(value.value)) {
  //             isDisabled = false;
  //         }
  //     })
  //     return isDisabled;
  // }

  return (
    <PopperContext.Provider value={contextProps}>
      <ClickAwayListener
        onClickAway={() => {
          if (referenceElement !== null) {
            setReferenceElement(null);
          }
        }}
      >
        <div className="filter-team-activity-wp">
          <Button
            className={`${
              useFilterBtnForOverview ? "btn-item control-btn" : "hd-btn"
            }`}
            onClick={(e) => {
              setReferenceElement(e.target);
              setActiveSubPopoverKey(null);

              if (!isEmpty(selectedData)) {
                setFilterData(cloneDeep(selectedData));
              } else {
                initCustomFilterData();
              }
              //console.log("FilterBtnClick #######", selectedData);
            }}
          >
            <img
              alt="icon-btn"
              src={
                useFilterBtnForOverview ? ICON_FILTER_FOR_OVERVIEW : ICON_FILTER
              }
              className="icon-btn filter-icon"
            />
            {useFilterBtnForOverview && <span>Filters</span>}
          </Button>
          {referenceElement && (
            <React.Fragment>
              <div
                className="main-filter-wp"
                ref={setPopperElement}
                style={{
                  ...popper.styles.popper,
                  display: referenceElement ? "block" : "none",
                }}
                {...popper.attributes.popper}
              >
                <div className="m-filter-inner-wp">
                  {!isEmpty(filterData) && (
                    <div className="m-filter-content">
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN}
                          filterKey={CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN}
                          label="Country"
                          headerSubLabel="Select Country(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.COUNTRY_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.COUNTRY_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.COUNTRY_LIST}
                          label={"Country"}
                          headerSubLabel="Select Country(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.COUNTRY_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION}
                          filterKey={
                            CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION
                          }
                          label={"Country"}
                          headerSubLabel="Select Country(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[
                              CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION
                            ].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.REGION_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.REGION_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.REGION_LIST}
                          label={"Region"}
                          headerSubLabel="Select Region(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.REGION_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.CHANNEL_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.CHANNEL_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.CHANNEL_LIST}
                          label={"Channel"}
                          headerSubLabel="Select Channel(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.CHANNEL_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.MANUFACTURER_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.MANUFACTURER_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.MANUFACTURER_LIST}
                          label={"Manufacturer"}
                          headerSubLabel="Select Manufacturer(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.MANUFACTURER_LIST]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}

                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.RETAILER_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.RETAILER_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.RETAILER_LIST}
                          label={"Retailer"}
                          headerSubLabel="Select Retailer(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.RETAILER_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}

                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.STORES_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.STORES_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.STORES_LIST}
                          label={"Store"}
                          headerSubLabel="Select Store(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.STORES_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.SALES_REP_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.SALES_REP_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.SALES_REP_LIST}
                          label={"Sales Rep"}
                          headerSubLabel="Select Sales Rep(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.SALES_REP_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.MERCHANDISER_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.MERCHANDISER_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.MERCHANDISER_LIST}
                          label={"Merchandiser"}
                          headerSubLabel="Select Merchandiser(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.MERCHANDISER_LIST]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST}
                          label={"Product Category"}
                          headerSubLabel="Select Product Category(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST}
                          label={"Sub Category"}
                          headerSubLabel="Select Sub Category(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.BRAND_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.BRAND_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.BRAND_LIST}
                          label={"Brand"}
                          headerSubLabel="Select Brand(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.BRAND_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                          isPlanogram={isPlanogram}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.OSA_BRAND_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.OSA_BRAND_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.OSA_BRAND_LIST}
                          label={"Brand"}
                          headerSubLabel="Select Brand(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.OSA_BRAND_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.PACK_SIZE_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.PACK_SIZE_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.PACK_SIZE_LIST}
                          label={"Pack Size"}
                          headerSubLabel="Select Pack Size(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.PACK_SIZE_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.FLAVOUR_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.FLAVOUR_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.FLAVOUR_LIST}
                          label={"Flavour"}
                          headerSubLabel="Select Flavour(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.FLAVOUR_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.PRODUCT_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.PRODUCT_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.PRODUCT_LIST}
                          label={"Product"}
                          headerSubLabel="Select Product(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.PRODUCT_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST}
                          label={"Product"}
                          headerSubLabel="Select Product(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST}
                          label={"Product"}
                          headerSubLabel="Select Product(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION}
                          filterKey={
                            CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION
                          }
                          label={"Product"}
                          headerSubLabel="Select Product(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[
                              CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION
                            ].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(CUSTOM_FILTER_KEYS.PROMOTION_LIST) !==
                        -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.PROMOTION_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.PROMOTION_LIST}
                          label={"Promotion"}
                          headerSubLabel="Select Option"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.PROMOTION_LIST].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION}
                          filterKey={
                            CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION
                          }
                          label={"Secondary Display"}
                          headerSubLabel="Select Option"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[
                              CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION
                            ].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST}
                          filterKey={CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST}
                          label={"Placement Type"}
                          headerSubLabel="Select Placement Type(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST]
                              .value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST}
                          filterKey={
                            CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST
                          }
                          label={"Placement Type"}
                          headerSubLabel="Select Placement Type(s)"
                          activePopover={activeSubPopoverKey}
                          defautlValue={
                            filterData[
                              CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST
                            ].value
                          }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                      {filterKeys.indexOf(
                        CUSTOM_FILTER_KEYS.PROMO_SELECTION
                      ) !== -1 && (
                        <ChildPopperItem
                          key={CUSTOM_FILTER_KEYS.PROMO_SELECTION}
                          filterKey={CUSTOM_FILTER_KEYS.PROMO_SELECTION}
                          label={"Promo"}
                          headerSubLabel="Toggle Promo Products"
                          activePopover={activeSubPopoverKey}
                          // defautlValue={
                          //   filterData[CUSTOM_FILTER_KEYS.PROMO_SELECTION]
                          //     .value
                          // }
                          onClick={(subKey) => setActiveSubPopoverKey(subKey)}
                          onChange={onHandleFilterDataChange}
                        />
                      )}
                    </div>
                  )}
                  <div className="apply-btn-wp">
                    <Button
                      className="apply-btn"
                      onClick={() => {
                        //console.log("Apply filter: #######", filterData);
                        onChange(filterData);
                        setReferenceElement(null);
                        setActiveSubPopoverKey(null);
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
              <div ref={portalRef} className="sub-poper-wp"></div>
            </React.Fragment>
          )}
        </div>
      </ClickAwayListener>
    </PopperContext.Provider>
  );
};

export default CustomFilter;

export const ChildPopperItem = ({
  children,
  filterKey,
  defautlValue,
  onClick,
  onChange,
  label,
  headerSubLabel,
  activePopover,
  isPlanogram,
  ...rest
}) => {
  const popper = useContext(PopperContext);
  const [referenceSubElement, setSubReferenceElement] = useState(null);
  const [subPopperElement, setSubPopperElement] = useState(null);
  const subPopper = usePopper(referenceSubElement, subPopperElement, {
    placement: "right-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(defautlValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defautlValue]);

  const onHandleValueChanged = (key, value) => {
    setSelectedValue(value);
    onChange(key, value);
  };

  return (
    <ClickAwayListener
      classNameRef="main-mn-item-wp"
      onClickAway={() => {
        if (referenceSubElement !== null) {
          setSubReferenceElement(null);
        }
      }}
    >
      <button
        className={`filter-mn-item ${
          activePopover === filterKey ? "is-selected-menu" : ""
        }`}
        onClick={() => {
          setSubReferenceElement(popper.popperEl);
          if (onClick) onClick(filterKey);
        }}
      >
        <div className={`mn-item-wp`}>
          <div className="ic-menu-arrow-icon" />
          <div>{label}</div>
        </div>
        {popper.portalRef.current &&
          ReactDOM.createPortal(
            <div
              width={300}
              ref={setSubPopperElement}
              style={{
                ...subPopper.styles.popper,
                display: referenceSubElement ? "block" : "none",
              }}
              {...subPopper.attributes.popper}
              onMouseDown={(e) => e.stopPropagation()}
              className="sub-popper-content-wp"
            >
              <div className="header-sub-label">{headerSubLabel}</div>
              {filterKey === CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN && (
                <CountryCodesDropdown
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.REGION_LIST && (
                <RegionSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(CUSTOM_FILTER_KEYS.REGION_LIST, value);
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.CHANNEL_LIST && (
                <ChannelSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.CHANNEL_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.MANUFACTURER_LIST && (
                <ManufacturerSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.MANUFACTURER_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.RETAILER_LIST && (
                <RetailerSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.RETAILER_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.COUNTRY_LIST && (
                <CountrySelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.COUNTRY_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION && (
                <SingleCountrySelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.STORES_LIST && (
                <StoresSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(CUSTOM_FILTER_KEYS.STORES_LIST, value);
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.SALES_REP_LIST && (
                <SalesRepSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.SALES_REP_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.MERCHANDISER_LIST && (
                <SalesRepSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.MERCHANDISER_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST && (
                <ProductCategorySelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST && (
                <ProductSubCategorySelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.BRAND_LIST && (
                <BrandSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(CUSTOM_FILTER_KEYS.BRAND_LIST, value);
                  }}
                  isPlanogram={isPlanogram}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.OSA_BRAND_LIST && (
                <OsaBrandSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.OSA_BRAND_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.PACK_SIZE_LIST && (
                <PackSizeSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.PACK_SIZE_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.FLAVOUR_LIST && (
                <FlavourSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.FLAVOUR_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.PRODUCT_LIST && (
                <ProductionSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.PRODUCT_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST && (
                <TrackedProductSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION && (
                <SingleProductSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST && (
                <SRPProductSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.PROMOTION_LIST && (
                <PromotionSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.PROMOTION_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST && (
                <PlacementTypeSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey ===
                CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST && (
                <SecondaryPlacementTypeSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION && (
                <SecondaryDisplaySelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
                      value
                    );
                  }}
                />
              )}
              {filterKey === CUSTOM_FILTER_KEYS.PROMO_SELECTION && (
                <PromoSelection
                  defaultValue={selectedValue}
                  onChange={(value) => {
                    onHandleValueChanged(
                      CUSTOM_FILTER_KEYS.PROMO_SELECTION,
                      value
                    );
                  }}
                />
              )}
            </div>,
            popper.portalRef.current
          )}
      </button>
    </ClickAwayListener>
  );
};
