import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, message } from "antd";
import TotalOfFacingsChart from "../TotalOfFacingsChart";
import Cookies from "universal-cookie";
import FilterTags from "../../componentsShared/FilterTags";
import {
  BACKEND_URL,
  BACKEND_URL_AZURE,
  CUSTOM_FILTER_KEYS,
} from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
import CustomFilter from "../../componentsShared/CustomFilter";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format("YYYY-MM-DD");
let currentDate = new Date();
currentDate = moment(currentDate).format("YYYY-MM-DD");

const TotalOfFacings = ({ addLoader, removeLoader }) => {
  const [tagsData, setTagsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [facingData, setFacingData] = useState([]);

  if (
    cookies.get("tr_startDate") &&
    cookies.get("tr_startDate") !== "" &&
    cookies.get("tr_startDate") !== "undefined"
  ) {
    lastDate = cookies.get("tr_startDate");
  }
  if (
    cookies.get("tr_endDate") &&
    cookies.get("tr_endDate") !== "" &&
    cookies.get("tr_endDate") !== "undefined"
  ) {
    currentDate = cookies.get("tr_endDate");
  }

  if (!cookies.get("tr_startDate") || !cookies.get("tr_endDate")) {
    cookies.set("tr_startDate", lastDate);
    cookies.set("tr_endDate", currentDate);
  }

  const fetchFacing = async () => {
    const loaderId = "se_totalfacing";
    addLoader(loaderId);

    const url = `${BACKEND_URL_AZURE}/gettotalfacing?manufacturer=${cookies.get(
      "manufacturer"
    )}&regions=${cookies.get("tr_region")}&countries=${cookies.get(
      "tr_country"
    )}&retailers=${cookies.get("tr_retailer")}&categories=${cookies.get(
      "tr_category"
    )}&brands=${cookies.get("tr_brand")}&includePromoProducts=${cookies.get(
      "tr_includePromoProducts"
    )}&subcategory=${cookies.get(
      "tr_subcategory"
    )}&includeSecondaryDisplay=${cookies.get("tr_includeSecondaryDisplay")}`;

    await fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setFacingData(result.message);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const onHandleFilterChanged = (data) => {
    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("tr_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    cookies.set("tr_region", strRegion);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("tr_retailer", strRetailer);

    var strCategory = "";
    if (
      data.PRODUCT_CATEGORY_LIST &&
      data.PRODUCT_CATEGORY_LIST.value &&
      data.PRODUCT_CATEGORY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.PRODUCT_CATEGORY_LIST.value.length; i++) {
        strCategory =
          strCategory + data.PRODUCT_CATEGORY_LIST.value[i].id + ",";
      }
      strCategory = strCategory.substring(0, strCategory.length - 1);
    } else {
      const retailers = JSON.parse(localStorage.getItem("categories"));
      if (retailers) {
        forEach(retailers, (item) => {
          strCategory = strCategory + item.category_id + ",";
        });
      }
      strCategory = strCategory.substring(0, strCategory.length - 1);
    }
    cookies.set("tr_category", strCategory);

    var strSubCategory = "";
    if (
      data.SUB_CATEGORY_LIST &&
      data.SUB_CATEGORY_LIST.value &&
      data.SUB_CATEGORY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.SUB_CATEGORY_LIST.value.length; i++) {
        strSubCategory =
          strSubCategory + data.SUB_CATEGORY_LIST.value[i].id + ",";
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);
    } else {
      /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
      if (subcategories) {
        forEach(subcategories, (item) => {
          strSubCategory = strSubCategory + item.subcategory_id + ",";
        });
      }
      strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
      strSubCategory = "";
    }
    cookies.set("tr_subcategory", strSubCategory);

    var strBrand = "";
    if (
      data.BRAND_LIST &&
      data.BRAND_LIST.value &&
      data.BRAND_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.BRAND_LIST.value.length; i++) {
        strBrand = strBrand + data.BRAND_LIST.value[i].id + ",";
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    } else {
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
    }
    cookies.set("tr_brand", strBrand);

    if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      cookies.set("tr_includePromoProducts", !promotion[0].isChecked);
    } else cookies.set("tr_includePromoProducts", true);

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      cookies.set("tr_includeSecondaryDisplay", secondary[0].isChecked);
    } else cookies.set("tr_includeSecondaryDisplay", false);

    setFilterData(data);
    convertDataForTags(data);

    fetchFacing();
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "COUNTRY_LIST") {
      let country = cookies.get("tr_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("tr_country", country.join());
      if (country && country.length<=0)
      {
         let strCountry = "";
         const countries = JSON.parse(localStorage.getItem("countries"));
         if (countries) {
           forEach(countries, (item) => {
             strCountry = strCountry + item.country_id + ",";
           });
         }
         strCountry = strCountry.substring(0, strCountry.length - 1);
         cookies.set("tr_country", strCountry);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = cookies.get("tr_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("tr_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("brands"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        cookies.set("tr_region", strRegion);
      }
    }
    if (item.key === "BRAND_LIST") {
      let brand = cookies.get("tr_brand").split(",");
      brand = brand.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("tr_brand", brand.join());
       if (brand && brand.length <= 0) {
         let strBrand = "";
         const brands = JSON.parse(localStorage.getItem("brands"));
         if (brands) {
           forEach(brands, (item) => {
             strBrand = strBrand + item.brand_id + ",";
           });
         }
         strBrand = strBrand.substring(0, strBrand.length - 1);
         cookies.set("tr_brand", strBrand);
       }
    }
    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("tr_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("tr_retailer", retailer.join());
      if (retailer && retailer.length <= 0) {
        let strRetailer = "";
        const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
        cookies.set("tr_retailer", strRetailer);
      }
    }
    if (item.key === "PRODUCT_CATEGORY_LIST") {
      let category = cookies.get("tr_category").split(",");
      category = category.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("tr_category", category.join());
      if (category && category.length <= 0) {
        let strCategory = "";
        const categories = JSON.parse(localStorage.getItem("categories"));
        if (categories) {
          forEach(categories, (item) => {
            strCategory = strCategory + item.category_id + ",";
          });
        }
        strCategory = strCategory.substring(0, strCategory.length - 1);
        cookies.set("tr_category", strCategory);
      }
    }
    if (item.key === "SUB_CATEGORY_LIST") {
      let subcategory = cookies.get("tr_subcategory").split(",");
      subcategory = subcategory.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("tr_subcategory", subcategory.join());
      if (subcategory && subcategory.length <= 0) {
        let strSubCategory = "";
        /*const subcategories = JSON.parse(localStorage.getItem("subcategories"));
        if (subcategories) {
          forEach(subcategories, (item) => {
            strSubCategory = strSubCategory + item.subcategory_id + ",";
          });
        }
        strSubCategory = strSubCategory.substring(0, strSubCategory.length - 1);*/
        cookies.set("tr_subcategory", strSubCategory);
      }
    }
    if (item.key === "PROMO_SELECTION") {
      cookies.set("tr_includePromoProducts", true);
    }
    if (item.key === "SECONDARY_DISPLAY_SELECTION") {
      cookies.set("tr_includeSecondaryDisplay", false);
    }
    fetchFacing();
  };

  useEffect(() => {
    const loaderId = "se_totalfacing";
    addLoader(loaderId);

    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);

    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    //end get all countries & regions

    const url = `${BACKEND_URL_AZURE}/gettotalfacing?manufacturer=${cookies.get(
      "manufacturer"
    )}&countries=${strCountry}&regions=${strRegion}&retailers=${strRetailer}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setFacingData(result.message);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-100">
          <div className="Team-Activity-Overview-wp">
            <div className="title-wp">
              <div className="title-wp">Total No. of Facings</div>
            </div>
            <div className="condition-values-wp">
              <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
            </div>
            <div className="control-btns">
              <CustomFilter
                useFilterBtnForOverview={true}
                selectedData={filterData}
                filterKeys={[
                  CUSTOM_FILTER_KEYS.COUNTRY_LIST,
                  CUSTOM_FILTER_KEYS.REGION_LIST,
                  CUSTOM_FILTER_KEYS.RETAILER_LIST,
                  CUSTOM_FILTER_KEYS.PRODUCT_CATEGORY_LIST,
                  CUSTOM_FILTER_KEYS.SUB_CATEGORY_LIST,
                  CUSTOM_FILTER_KEYS.BRAND_LIST,
                  CUSTOM_FILTER_KEYS.PROMOTION_LIST,
                  CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
                ]}
                onChange={(data) => {
                  onHandleFilterChanged(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="separate-line mb-30" />
      <div className="row">
        <div className="col-100">
          <TotalOfFacingsChart facingData={facingData} />
        </div>
      </div>
    </>
  );
};

export default TotalOfFacings;
