import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";
import ExportJsonExcel from "js-export-excel";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const columns = [
  {
    title: "Store",
    dataIndex: "store",
    width: "25%",
    key: "store",
    sorter: (a, b) => a.store.localeCompare(b.store),
    showSorterTooltip: false,
  },
  {
    title: "Merchandiser",
    dataIndex: "merchandiser",
    width: "25%",
    key: "merchandiser",
    sorter: (a, b) => a.merchandiser.localeCompare(b.merchandiser),
    showSorterTooltip: false,
  },
  {
    title: "Product",
    dataIndex: "product",
    width: "30%",
    align: "left",
    key: "product",
    render: (products) => {
      products = products.slice(0, 2);
      return products.map((product, index) => (
        <div style={{ marginTop: 10 }}>
          {index + 1}. {product}
        </div>
      ));
    },
  },
  {
    title: "Total of products",
    dataIndex: "product",
    width: "20%",
    align: "center",
    key: "product",
    sorter: (a, b) => Number(a.product.length) - Number(b.product.length),
    showSorterTooltip: false,
    render: (products) => {
      return products.length;
    },
  },
];

const modalColumns = [
  {
    title: "Store",
    dataIndex: "store",
    width: "25%",
    key: "store",
    sorter: (a, b) => a.store.localeCompare(b.store),
    showSorterTooltip: false,
  },
  {
    title: "Merchandiser",
    dataIndex: "merchandiser",
    width: "25%",
    key: "merchandiser",
    sorter: (a, b) => a.merchandiser.localeCompare(b.merchandiser),
    showSorterTooltip: false,
  },
  {
    title: "Product",
    dataIndex: "product",
    width: "50%",
    align: "left",
    key: "product",
    render: (product) => (
      <>
        {product.map((item, index) => (
          <div style={{ marginTop: 10 }}>
            {index + 1}. {item}
          </div>
        ))}
      </>
    ),
  },
];

const StoresWithZeroFacing = ({ osaZeroFacingData }) => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [modalConfigs, setModalConfigs] = useState([]);
  const [visible, setVisible] = useState(false);

  function exportToExcel() {
    var option = {};
    option.fileName =
      "Stores with Zero Facing Products (" +
      cookies.get("osa_startDate") +
      " _ " +
      cookies.get("osa_endDate") +
      ")";
      var arrData = [];
      for (var i = 0; i < osaZeroFacingData.data.length; i++) {
        for (var j = 0; j < osaZeroFacingData.data[i].product.length; j++) {
          if (j > 0) {
            arrData.push({
              store: "",
              merchandiser: "",
              audit_date: osaZeroFacingData.data[i].date[j].replace("GMT", ""),
              product: osaZeroFacingData.data[i].product[j],
              num_facing: "",
            });
          } else {
            arrData.push({
              store: osaZeroFacingData.data[i].store,
              merchandiser: osaZeroFacingData.data[i].merchandiser,
              audit_date: osaZeroFacingData.data[i].date[j].replace("GMT", ""),
              product: osaZeroFacingData.data[i].product[j],
              num_facing: osaZeroFacingData.data[i].product.length,
            });
          }
        }
      }
    option.datas = [
      {
        sheetData: arrData,
        sheetName: "sheet",
        sheetHeader: [
          "Store",
          "Merchandiser",
          "Audit Date",
          "Product",
          "Total Number of Products with Zero Facing",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  useEffect(() => {
    setTableConfigs(columns);
    setModalConfigs(modalColumns);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title={`Stores with Zero Facing Products (Total ${osaZeroFacingData.storeLength} Stores)`}
        hideControlButtons={false}
        hideFilters={true}
        onClickExport={() => {
          exportToExcel();
        }}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={osaZeroFacingData.data}
        isPagination={true}
      />
      <Modal
        title="Stores with Zero Facing"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={modalConfigs}
          dataSource={osaZeroFacingData.data}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton title="View all" onClick={() => setVisible(true)} />
      </div>
    </div>
  );
};

export default StoresWithZeroFacing;
