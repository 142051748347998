import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import "./style.scss";
import Cookies from "universal-cookie";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const ICON_DECREASE = require("../../assets/img/icons/ic-increase-green.svg");
const ICON_INCREASE = require("../../assets/img/icons/ic-increase-green.svg");

const cookies = new Cookies();

const TotalAuditsCompleted = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState({
    stores: 0,
    completedStores: 0,
  });

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  function getNumWeeksFromDateRange(startDate, endDate) {
    var lastSunday = new Date(endDate);
    if (lastSunday.getDay() > 0) {
      lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());
    }
    var firstMonday = new Date(startDate);
    if (firstMonday.getDay() != 1) {
      firstMonday.setDate(firstMonday.getDate() + firstMonday.getDay());
    }
    return Math.round(datediff(firstMonday, lastSunday) / 7);
  }

  useEffect(() => {
    let headers = new Headers({
      Authorization:
        "Basic " +
        encode(
          process.env.REACT_APP_dashboardUser +
            ":" +
            process.env.REACT_APP_dashboardPassword
        ),
      "Content-Type": "application/json",
    });
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);
    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    //end get all countries & regions
    //get data
    var totalCompleted = 0;
    var totalAccountable = 0;
    const fetchData1 = async () => {
      const loaderId = "ta_total_audits_completed";
      addLoader(loaderId);
      //get data
      if (
        cookies.get("ta_startDate") === undefined ||
        cookies.get("ta_startDate") === "" ||
        cookies.get("ta_startDate") === "undefined"
      ) {
        var startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ta_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ta_endDate") === undefined ||
        cookies.get("ta_endDate") === "" ||
        cookies.get("ta_endDate") === "undefined"
      ) {
        cookies.set("ta_endDate", new Date().toISOString().split("T")[0]);
      }
      var numWeek = getNumWeeksFromDateRange(
        cookies.get("ta_startDate"),
        cookies.get("ta_endDate")
      );
      if (numWeek < 0) numWeek = 0;
      var url =
        BACKEND_URL_AZURE +
        "/getauditscompletedbydate?client=" +
        cookies.get("client") +
        "&start=" +
        cookies.get("ta_startDate") +
        "&end=" +
        cookies.get("ta_endDate") +
        "&countries=" +
        strCountry +
        "&regions=" +
        strRegion;

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          const results = result.message;
          if (results) {
            for (var i = 0; i < results.length; i++) {
              totalCompleted += results[i].auditsCompleted;
              totalAccountable += results[i].auditsExpected;
            }
            let percent = 0;
            if (totalAccountable > 0) {
              percent = Math.round((totalCompleted / totalAccountable) * 100);
            }
            setData({
              percent: percent,
              stores: totalAccountable,
              completedStores: totalCompleted,
              isIncrease: true,
              increasePercent: 10,
            });
          }
          removeLoader(loaderId);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
      //end get data
      //setData(auditData);
    };
    fetchData1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Total-Audits-Completed-wp">
      <ReportTableHeader
        title="Total Audits Completed"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <div className="content-wp">
        <Progress
          type="circle"
          percent={data.percent}
          strokeWidth={10}
          strokeColor={data.percent > 80 ? "#87d068" : "#E5305B"}
          format={() =>
            data.percent > 80 ? (
              <div style={{ color: "#87d068" }}>{data.percent}%</div>
            ) : (
              <div style={{ color: "#E5305B" }}>{data.percent}%</div>
            )
          }
          width={150}
        />
        <div className="summary-completed-stores">{`${data.completedStores}/${data.stores} Store Audits Completed`}</div>
        {/*<div className="summary-detail">
                    {data.isIncrease
                        ? <img alt="" className="period-icon" src={ICON_INCREASE} />
                        : <img alt="" className="period-icon" src={ICON_DECREASE} />
                    }
                    <div className="last-period">
                        {`${data.isIncrease ? '+' : '-'}${data.increasePercent}% vs last week`}
                  </div>
                </div>*/}
      </div>
    </div>
  );
};

export default TotalAuditsCompleted;
