import React, { useState, useEffect } from "react";
import ReportTable1 from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import "./style.scss";
import Cookies from "universal-cookie";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base-64";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

const columns = [
  {
    title: "Store Name",
    dataIndex: "store",
    width: "50%",
    key: "store",
    sorter: (a, b) => a.store.localeCompare(b.store),
    showSorterTooltip: false,
  },
  {
    title: "Merchandiser",
    dataIndex: "merchandiser",
    width: "30%",
    align: "left",
    key: "merchandiser",
    sorter: (a, b) => a.merchandiser.localeCompare(b.merchandiser),
    showSorterTooltip: false,
  },
  {
    title: "Overdue by",
    dataIndex: "overdue",
    key: "overdue",
    align: "center",
    width: "20%",
    sorter: (a, b) =>
      Number(a.overdue.split(" ")[0].replace("-", 0)) -
      Number(b.overdue.split(" ")[0].replace("-", 0)),
    showSorterTooltip: false,
  },
];

const OverdueStoreAudits = ({ removeLoader, addLoader }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);

  function exportToExcel() {
    var option = {};
    option.fileName = "Overdue Store Audits";
    option.datas = [
      {
        sheetData: data,
        sheetName: "sheet",
        sheetHeader: ["Store Name", "Sale Reps", "Overdue by"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  //get filter data
  function getDataByFilter(country, region, retailer, sale_rep) {
    const fetchData23 = async () => {
      const loaderId = "ta_overdue_store_data23";
      addLoader(loaderId);
      var strCountry = "";
      if (country && country.length > 0) {
        for (let i = 0; i < country.length; i++) {
          strCountry = strCountry + country[i].id + ",";
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
      } else {
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
      }

      var strRegion = "";
      if (region && region.length > 0) {
        for (let i = 0; i < region.length; i++) {
          strRegion = strRegion + region[i].id + ",";
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
      } else {
        const regions = JSON.parse(localStorage.getItem("regions"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
      }

      var strRetailer = "";
      if (retailer && retailer.length > 0) {
        for (let i = 0; i < retailer.length; i++) {
          strRetailer = strRetailer + retailer[i].id + ",";
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
      } else {
        const retailers = JSON.parse(localStorage.getItem("retailers"));
        if (retailers) {
          forEach(retailers, (item) => {
            strRetailer = strRetailer + item.retailer_id + ",";
          });
        }
        strRetailer = strRetailer.substring(0, strRetailer.length - 1);
      }

      var strSalesRep = "";
      if (sale_rep && sale_rep.length > 0) {
        for (let i = 0; i < sale_rep.length; i++) {
          strSalesRep = strSalesRep + sale_rep[i].id + ",";
        }
        strSalesRep = strSalesRep.substring(0, strSalesRep.length - 1);
      } else {
        const sales_reps = JSON.parse(localStorage.getItem("merchandisers"));
        if (sales_reps) {
          forEach(sales_reps, (item) => {
            strSalesRep = strSalesRep + item.user_id + ",";
          });
        }
        strSalesRep = strSalesRep.substring(0, strSalesRep.length - 1);
      }

      
      var url =
        BACKEND_URL_AZURE +
        "/getoverdueauditsbyfilter?client=" +
        cookies.get("client") +
        "&startDate=" +
        cookies.get("ta_startDate") +
        "&endDate=" +
        cookies.get("ta_endDate") +
        "&retailer=" +
        strRetailer +
        "&sale_rep=" +
        strSalesRep +
        "&countries=" +
        strCountry +
        "&regions=" +
        strRegion;
      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          const results = result.message;
          var overdueAudit = [];
          for (var i = 0; i < results.length; i++) {
            if (results[i].overdue >= 7 || results[i].overdue === "-") {
              if (results[i].overdue > 1)
                overdueAudit.push({
                  store: results[i].store,
                  merchandiser: results[i].merchandiser
                    .split("@")[0]
                    .split(".")[0],
                  overdue: results[i].overdue + " days",
                });
              else
                overdueAudit.push({
                  store: results[i].store,
                  merchandiser: results[i].merchandiser
                    .split("@")[0]
                    .split(".")[0],
                  overdue: results[i].overdue + " day",
                });
            }
          }
          removeLoader(loaderId);
          setData(overdueAudit);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
    };
    fetchData23();
  }

  useEffect(() => {
    setTableConfigs(columns);
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);

    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);

    let strSalesRep = "";
    const sales_reps = JSON.parse(localStorage.getItem("merchandisers"));
    if (sales_reps) {
      forEach(sales_reps, (item) => {
        strSalesRep = strSalesRep + item.user_id + ",";
      });
    }
    strSalesRep = strSalesRep.substring(0, strSalesRep.length - 1);
    //end get all countries & regions
    const fetchData22 = async () => {
      const loaderId = "ta_overdue_store_audit_data22";
      addLoader(loaderId);
      //get data

      if (
        cookies.get("ta_startDate") === undefined ||
        cookies.get("ta_startDate") === "" ||
        cookies.get("ta_startDate") === "undefined"
      ) {
        var startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        cookies.set("ta_startDate", startDate.toISOString().split("T")[0]);
      }
      if (
        cookies.get("ta_endDate") === undefined ||
        cookies.get("ta_endDate") === "" ||
        cookies.get("ta_endDate") === "undefined"
      ) {
        cookies.set("ta_endDate", new Date().toISOString().split("T")[0]);
      }
      var url =
        BACKEND_URL_AZURE +
        "/getoverdueauditsbyfilter?client=" +
        cookies.get("client") +
        "&startDate=" +
        cookies.get("ta_startDate") +
        "&endDate=" +
        cookies.get("ta_endDate") +
        "&retailer=" +
        strRetailer +
        "&sale_rep=" +
        strSalesRep +
        "&countries=" +
        strCountry +
        "&regions=" +
        strRegion;

      fetch(url, { method: "GET", headers: headers })
        .then(function (response) {
          return response.json();
        })
        .then(async (result) => {
          const results = result.message;
          var overdueAudit = [];
          if (results)
            for (var i = 0; i < results.length; i++) {
              if (results[i].overdue >= 7 || results[i].overdue === "-") {
                if (results[i].overdue > 1)
                  overdueAudit.push({
                    store: results[i].store,
                    merchandiser: results[i].merchandiser
                      .split("@")[0]
                      .split(".")[0],
                    overdue: results[i].overdue + " days",
                  });
                else
                  overdueAudit.push({
                    store: results[i].store,
                    merchandiser: results[i].merchandiser
                      .split("@")[0]
                      .split(".")[0],
                    overdue: results[i].overdue + " day",
                  });
              }
            }
          removeLoader(loaderId);
          setData(overdueAudit);
        })
        .catch(function (error) {
          console.log("-------- error ------- " + error);
        });
      //end get data
    };
    fetchData22();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="Audits Pending"
        filterKeys={[
          CUSTOM_FILTER_KEYS.COUNTRY_LIST,
          CUSTOM_FILTER_KEYS.REGION_LIST,
          CUSTOM_FILTER_KEYS.RETAILER_LIST,
          CUSTOM_FILTER_KEYS.SALES_REP_LIST,
        ]}
        onFilterChanged={(filters) => {
          getDataByFilter(
            filters.COUNTRY_LIST.value,
            filters.REGION_LIST.value,
            filters.RETAILER_LIST.value,
            filters.SALES_REP_LIST.value
          );
          setFilterData(filters);
        }}
        onClickExport={() => {
          exportToExcel();
        }}
      />
      <div className="separate-line" />
      <ReportTable1
        columns={tableConfigs}
        dataSource={data}
        isPagination={true}
      />
      {/*<div className="view-all-btn-wp">
          <ViewAllButton title="View all" />
        </div>*/}
    </div>
  );
};

export default OverdueStoreAudits;
