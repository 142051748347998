import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Table } from "antd";
import "./style.scss";
import FlavourSelection from "../FlavourSelection";

const ReportTable = (props) => {
  const { isPagination, columns, dataSource, showHeader, count } = props;

  return (
    <div className="ReportTable">
      <Table
        columns={columns}
        showHeader={showHeader}
        dataSource={dataSource}
        pagination={
          isPagination
            ? {
                defaultPageSize: count,
                showSizeChanger: false,
              }
            : false
        }
      />
    </div>
  );
};

ReportTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  isPagination: PropTypes.bool,
  showHeader: PropTypes.bool,
  count: PropTypes.number,
};

ReportTable.defaultProps = {
  count: 5,
};

export default ReportTable;
