import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, message } from "antd";
import NoOfStoresByFacings from "../NoOfStoresByFacings";
import NoOfStoresByFacingsChart from "../NoOfStoresByFacingsChart";
import Cookies from "universal-cookie";
import FilterTags from "../../componentsShared/FilterTags";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import {
  BACKEND_URL,
  CUSTOM_FILTER_KEYS,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";
import CustomFilter from "../../componentsShared/CustomFilter";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format("YYYY-MM-DD");
let currentDate = new Date();
currentDate = moment(currentDate).format("YYYY-MM-DD");

const NoOfStoresByFacing = ({ addLoader, removeLoader }) => {
  const [tagsData, setTagsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [facingData, setFacingData] = useState({ chart: [], table: [] });

  if (
    localStorage.getItem("sr_startDate") &&
    localStorage.getItem("sr_startDate") !== "" &&
    localStorage.getItem("sr_startDate") !== "undefined"
  ) {
    lastDate = localStorage.getItem("sr_startDate");
  }
  if (
    localStorage.getItem("sr_endDate") &&
    localStorage.getItem("sr_endDate") !== "" &&
    localStorage.getItem("sr_endDate") !== "undefined"
  ) {
    currentDate = localStorage.getItem("sr_endDate");
  }

  if (
    !localStorage.getItem("sr_startDate") ||
    !localStorage.getItem("sr_endDate")
  ) {
    localStorage.setItem("sr_startDate", lastDate);
    localStorage.setItem("sr_endDate", currentDate);
  }

  const fetchFacing = () => {
    const loaderId = "se_fetchfacingstores";
    addLoader(loaderId);

    if (
      localStorage.getItem("sr_manufacturer") === undefined ||
      localStorage.getItem("sr_manufacturer") === "undefined" ||
      localStorage.getItem("sr_manufacturer") === "" ||
      localStorage.getItem("sr_manufacturer") === null
    ) {
      /*var strManufacturer = "";
      const manufacturers = JSON.parse(localStorage.getItem("manufacturers"));
      if (manufacturers) {
        forEach(manufacturers, (item) => {
          strManufacturer = strManufacturer + item.manufacturer_id + ",";
        });
      }
      strManufacturer = strManufacturer.substring(
        0,
        strManufacturer.length - 1
      );
      localStorage.setItem("sr_manufacturer", strManufacturer);*/
      localStorage.setItem("sr_manufacturer", "");
    }

    if (
      localStorage.getItem("sr_brand") === undefined ||
      localStorage.getItem("sr_brand") === "undefined" ||
      localStorage.getItem("sr_brand") === "" ||
      localStorage.getItem("sr_brand") === null
    ) {
      /*var strBrand = "";
      const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
      localStorage.setItem("sr_brand", strBrand);*/
      localStorage.setItem("sr_brand", "");
    }

    if (
      localStorage.getItem("sr_country") === undefined ||
      localStorage.getItem("sr_country") === "undefined" ||
      localStorage.getItem("sr_country") === "" ||
      localStorage.getItem("sr_country") === null
    ) {
      var strCountry = "";
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
      localStorage.setItem("sr_country", strCountry);
    }

    if (
      localStorage.getItem("sr_region") === undefined ||
      localStorage.getItem("sr_region") === "undefined" ||
      localStorage.getItem("sr_region") === "" ||
      localStorage.getItem("sr_region") === null
    ) {
      var strRegion = "";
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
      localStorage.setItem("sr_region", strRegion);
    }

    if (
      localStorage.getItem("sr_product") === undefined ||
      localStorage.getItem("sr_product") === "undefined" ||
      localStorage.getItem("sr_product") === "" ||
      localStorage.getItem("sr_product") === null
    ) {
      /*var strProduct = "";
      const products = JSON.parse(localStorage.getItem("products"));
      if (products) {
        forEach(products, (item) => {
          strProduct = strProduct + item.product_id + ",";
        });
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);
      localStorage.setItem("sr_product", strProduct);*/
      localStorage.setItem("sr_product", "");
    }

    if (
      localStorage.getItem("sr_includePromoProducts") === undefined ||
      localStorage.getItem("sr_includePromoProducts") === "undefined" ||
      localStorage.getItem("sr_includePromoProducts") === "" ||
      localStorage.getItem("sr_includePromoProducts") === null
    ) {
      localStorage.setItem("sr_includePromoProducts", true);
    }

    if (
      localStorage.getItem("sr_includeSecondaryDisplay") === undefined ||
      localStorage.getItem("sr_includeSecondaryDisplay") === "undefined" ||
      localStorage.getItem("sr_includeSecondaryDisplay") === "" ||
      localStorage.getItem("sr_includeSecondaryDisplay") === null
    ) {
      localStorage.setItem("sr_includeSecondaryDisplay", false);
    }

    const url = `${BACKEND_URL_AZURE}/getstoresbyfacing?startDate=${localStorage.getItem(
      "sr_startDate"
    )}&endDate=${localStorage.getItem("sr_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&manufacturer_filter=${localStorage.getItem(
      "sr_manufacturer"
    )}&brands=${localStorage.getItem(
      "sr_brand"
    )}&countries=${localStorage.getItem(
      "sr_country"
    )}&products=${localStorage.getItem(
      "sr_product"
    )}&regions=${localStorage.getItem(
      "sr_region"
    )}&includePromoProducts=${localStorage.getItem(
      "sr_includePromoProducts"
    )}&includeSecondaryDisplay=${localStorage.getItem(
      "sr_includeSecondaryDisplay"
    )}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setFacingData(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const onHandleFilterChanged = (data) => {
    var strBrand = "";
    if (
      data.BRAND_LIST &&
      data.BRAND_LIST.value &&
      data.BRAND_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.BRAND_LIST.value.length; i++) {
        strBrand = strBrand + data.BRAND_LIST.value[i].id + ",";
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);
      localStorage.setItem("sr_brand", strBrand);
    } else {
      /*const brands = JSON.parse(localStorage.getItem("brands"));
      if (brands) {
        forEach(brands, (item) => {
          strBrand = strBrand + item.brand_id + ",";
        });
      }
      strBrand = strBrand.substring(0, strBrand.length - 1);*/
      localStorage.setItem("sr_brand", "");
    }

    var strManufacturer = "";
    if (
      data.MANUFACTURER_LIST &&
      data.MANUFACTURER_LIST.value &&
      data.MANUFACTURER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.MANUFACTURER_LIST.value.length; i++) {
        strManufacturer =
          strManufacturer + data.MANUFACTURER_LIST.value[i].id + ",";
      }
      strManufacturer = strManufacturer.substring(
        0,
        strManufacturer.length - 1
      );
      localStorage.setItem("sr_manufacturer", strManufacturer);
    } else {
      /*const manufacturers = JSON.parse(localStorage.getItem("manufacturers"));
      if (manufacturers) {
        forEach(manufacturers, (item) => {
          strManufacturer = strManufacturer + item.manufacturer_id + ",";
        });
      }
      strManufacturer = strManufacturer.substring(
        0,
        strManufacturer.length - 1
      );*/
      localStorage.setItem("sr_manufacturer", "");
    }

    var strCountry = "";
    if (
      data.COUNTRY_LIST &&
      data.COUNTRY_LIST.value &&
      data.COUNTRY_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.COUNTRY_LIST.value.length; i++) {
        strCountry = strCountry + data.COUNTRY_LIST.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    } else {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    localStorage.setItem("sr_country", strCountry);

    var strRegion = "";
    if (
      data.REGION_LIST &&
      data.REGION_LIST.value &&
      data.REGION_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.REGION_LIST.value.length; i++) {
        strRegion = strRegion + data.REGION_LIST.value[i].id + ",";
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    } else {
      const regions = JSON.parse(localStorage.getItem("regions"));
      if (regions) {
        forEach(regions, (item) => {
          strRegion = strRegion + item.region_id + ",";
        });
      }
      strRegion = strRegion.substring(0, strRegion.length - 1);
    }
    localStorage.setItem("sr_region", strRegion);
    
if (
      data.PROMOTION_LIST.value &&
      data.PROMOTION_LIST.value !== "null" &&
      data.PROMOTION_LIST.value !== "undefined" &&
      data.PROMOTION_LIST.value.length > 0
    ) {
      const promotion = data.PROMOTION_LIST.value;
      localStorage.setItem("sr_includePromoProducts", !promotion[0].isChecked);
    } else localStorage.setItem("sr_includePromoProducts", true);

    var strProduct = "";
    if (
      data.TRACKED_PRODUCT_LIST &&
      data.TRACKED_PRODUCT_LIST.value &&
      data.TRACKED_PRODUCT_LIST.value.length > 0
    ) {
      if (
        data.TRACKED_PRODUCT_LIST.value.length ===
        JSON.parse(localStorage.getItem("products")).length
      )
        localStorage.setItem("sr_product", "");
      else {
        for (let i = 0; i < data.TRACKED_PRODUCT_LIST.value.length; i++) {
          strProduct = strProduct + data.TRACKED_PRODUCT_LIST.value[i].id + ",";
        }
        strProduct = strProduct.substring(0, strProduct.length - 1);
        localStorage.setItem("sr_product", strProduct);
      }
    } else {
      const products = JSON.parse(localStorage.getItem("products"));
      if (products) {
        forEach(products, (item) => {
          strProduct = strProduct + item.product_id + ",";
        });
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);
      localStorage.setItem("sr_product", "");
    }

    if (
      data.SECONDARY_DISPLAY_SELECTION.value &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "null" &&
      data.SECONDARY_DISPLAY_SELECTION.value !== "undefined" &&
      data.SECONDARY_DISPLAY_SELECTION.value.length > 0
    ) {
      const secondary = data.SECONDARY_DISPLAY_SELECTION.value;
      localStorage.setItem(
        "sr_includeSecondaryDisplay",
        secondary[0].isChecked
      );
    } else localStorage.setItem("sr_includeSecondaryDisplay", false);

    setFilterData(data);
    convertDataForTags(data);
    fetchFacing();
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "BRAND_LIST") {
      let brand = localStorage.getItem("sr_brand").split(",");
      brand = brand.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("sr_brand", brand.join());
      if (brand && brand.length <= 0) {
        /*let strBrand = "";
        const brands = JSON.parse(localStorage.getItem("brands"));
        if (brands) {
          forEach(brands, (item) => {
            strBrand = strBrand + item.brand_id + ",";
          });
        }
        strBrand = strBrand.substring(0, strBrand.length - 1);
        localStorage.setItem("sr_brand", strBrand);*/
        localStorage.setItem("sr_brand", "");
      }
    }
    if (item.key === "MANUFACTURER_LIST") {
      let manufacturer = localStorage.getItem("sr_manufacturer").split(",");
      manufacturer = manufacturer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      localStorage.setItem("sr_manufacturer", manufacturer.join());
      if (manufacturer && manufacturer.length <= 0) {
        /*let strManufacturer = "";
        const manufacturers = JSON.parse(localStorage.getItem("manufacturers"));
        if (manufacturers) {
          forEach(manufacturers, (item) => {
            strManufacturer = strManufacturer + item.manufacturer_id + ",";
          });
        }
        strManufacturer = strManufacturer.substring(
          0,
          strManufacturer.length - 1
        );
        localStorage.setItem("sr_manufacturer", strManufacturer);*/
        localStorage.setItem("sr_manufacturer", "");
      }
    }
    if (item.key === "COUNTRY_LIST") {
      let country = localStorage.getItem("sr_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("sr_country", country.join());
      if (country && country.length <= 0) {
        let strCountry = "";
        const countries = JSON.parse(localStorage.getItem("countries"));
        if (countries) {
          forEach(countries, (item) => {
            strCountry = strCountry + item.country_id + ",";
          });
        }
        strCountry = strCountry.substring(0, strCountry.length - 1);
        localStorage.setItem("sr_country", strCountry);
      }
    }
    if (item.key === "REGION_LIST") {
      let region = localStorage.getItem("sr_region").split(",");
      region = region.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("sr_region", region.join());
      if (region && region.length <= 0) {
        let strRegion = "";
        const regions = JSON.parse(localStorage.getItem("brands"));
        if (regions) {
          forEach(regions, (item) => {
            strRegion = strRegion + item.region_id + ",";
          });
        }
        strRegion = strRegion.substring(0, strRegion.length - 1);
        localStorage.setItem("sr_region", strRegion);
      }
    }
    if (item.key === "PROMO_SELECTION") {
      localStorage.setItem("sr_includePromoProducts", true);
    }
    if (item.key === "TRACKED_PRODUCT_LIST") {
      let product = localStorage.getItem("sr_product").split(",");
      product = product.filter((id) => String(id) !== String(item.checkboxId));
      localStorage.setItem("sr_product", product.join());
      if (product && product.length <= 0) {
        /*let strProduct = "";
        const products = JSON.parse(localStorage.getItem("products"));
        if (products) {
          forEach(products, (item) => {
            strProduct = strProduct + item.product_id + ",";
          });
        }
        strProduct = strProduct.substring(0, strProduct.length - 1);
        localStorage.setItem("sr_product", strProduct);*/
        localStorage.setItem("sr_product", "");
      }
    }
    if (item.key === "SECONDARY_DISPLAY_SELECTION") {
      localStorage.setItem("sr_includeSecondaryDisplay", false);
    }

    fetchFacing();
  };

  useEffect(() => {
    const loaderId = "se_fetchfacingstores";
    addLoader(loaderId);
    //get all countries & regions
    let strCountry = "";
    const countries = JSON.parse(localStorage.getItem("countries"));
    if (countries) {
      forEach(countries, (item) => {
        strCountry = strCountry + item.country_id + ",";
      });
    }
    strCountry = strCountry.substring(0, strCountry.length - 1);

    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    //end get all countries & regions
    const url = `${BACKEND_URL_AZURE}/getstoresbyfacing?startDate=${localStorage.getItem(
      "sr_startDate"
    )}&endDate=${localStorage.getItem("sr_endDate")}&manufacturer=${cookies.get(
      "manufacturer"
    )}&countries=${strCountry}&regions=${strRegion}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setFacingData(result);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-100">
          <div className="Team-Activity-Overview-wp">
            <div className="title-wp">
              <div className="title-wp">No. of Stores by Facings</div>
            </div>
            <div className="condition-values-wp">
              <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
            </div>
            <div className="control-btns">
              <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
                <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
                <RangePicker
                  showTime
                  format={"YYYY-MM-DD"}
                  onCalendarChange={(range, dateString, info) => {
                    let startDate = moment(range[0]).format("YYYY-MM-DD");
                    let endDate = moment(range[1]).format("YYYY-MM-DD");

                    //if (info.range === "end") {
                    localStorage.setItem("sr_startDate", startDate);
                    localStorage.setItem("sr_endDate", endDate);

                    // message.loading("loading data...", 0);
                    fetchFacing();
                    //}
                  }}
                  defaultValue={[
                    moment(lastDate, dateFormat),
                    moment(currentDate, dateFormat),
                  ]}
                  allowClear={false}
                  suffixIcon={
                    <img
                      alt=""
                      style={{ marginTop: "3px" }}
                      src={ICON_ARROW_DOWN}
                    />
                  }
                />
              </div>
              <CustomFilter
                useFilterBtnForOverview={true}
                selectedData={filterData}
                filterKeys={[
                  CUSTOM_FILTER_KEYS.COUNTRY_LIST,
                  CUSTOM_FILTER_KEYS.REGION_LIST,
                  CUSTOM_FILTER_KEYS.MANUFACTURER_LIST,
                  CUSTOM_FILTER_KEYS.BRAND_LIST,
                  CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST,
                  CUSTOM_FILTER_KEYS.PROMOTION_LIST,
                  CUSTOM_FILTER_KEYS.SECONDARY_DISPLAY_SELECTION,
                ]}
                onChange={(data) => {
                  onHandleFilterChanged(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="separate-line mb-30" />

      <div className="row">
        <div className="col-75">
          <NoOfStoresByFacingsChart facingData={facingData.chart} />
        </div>
        <div className="col-25">
          <NoOfStoresByFacings data={facingData.table} />
        </div>
      </div>
    </>
  );
};

export default NoOfStoresByFacing;
