import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from 'moment';
import Cookies from "universal-cookie";
import ReportTable from "../../componentsShared/ReportTable";
import { BACKEND_URL, CUSTOM_FILTER_KEYS,BACKEND_URL_AZURE } from "../../constants/constants";
import "./style.scss";
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import * as filterDataHanler from "../../utils/filterDataHanler";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const cookies = new Cookies();
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format(dateFormat);
let currentDate = new Date();
currentDate = moment(currentDate).format(dateFormat);

const columns = [
  {
    title: "Product",
    dataIndex: "product",
    width: "25%",
    key: "product",
    sorter: (a, b) => a.product.localeCompare(b.product),
    showSorterTooltip: false,
  },
  {
    title: "Retailer",
    dataIndex: "retailer",
    width: "20%",
    key: "retailer",
    sorter: (a, b) => a.retailer.localeCompare(b.retailer),
    showSorterTooltip: false,
  },
  {
    title: "Promotions",
    dataIndex: "promotions",
    width: "25%",
    align: "center",
    key: "promotions",
    render: (val) =>
      val.map((item) => <div style={{ marginTop: 5 }}>{item}</div>),
  },
  {
    title: "Promotion Period ( Weeks)",
    dataIndex: "promotionPeriod",
    width: "15%",
    key: "promotionPeriod",
    sorter: (a, b) => Number(b.promotionPeriod) - Number(a.promotionPeriod),
    showSorterTooltip: false,
  },
  {
    title: "Depth (Variance from Base Price, %)",
    dataIndex: "promotionDepthCount",
    width: "15%",
    align: "center",
    key: "promotionDepthCount",
    sorter: (a, b) =>
      Number(b.promotionDepthCount) - Number(a.promotionDepthCount),
    showSorterTooltip: false,
  },
];

const PromotionFrequencyAndDepth = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);
  const [tagsData, setTagsData] = useState([]);

  if (
    cookies.get("pf_startDate") &&
    cookies.get("pf_startDate") !== "" &&
    cookies.get("pf_startDate") !== "undefined"
  ) {
    lastDate = cookies.get("pf_startDate");
  } else {
    cookies.set("pf_startDate", lastDate);
  }
  if (
    cookies.get("pf_endDate") &&
    cookies.get("pf_endDate") !== "" &&
    cookies.get("pf_endDate") !== "undefined"
  ) {
    currentDate = cookies.get("pf_endDate");
  } else {
    cookies.set("pf_endDate", currentDate);
  }

  const onHandleFilterChanged = (data) => {
var strRetailer = "";
if (
  data.RETAILER_LIST &&
  data.RETAILER_LIST.value &&
  data.RETAILER_LIST.value.length > 0
) {
  for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
    strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
  }
  strRetailer = strRetailer.substring(0, strRetailer.length - 1);
} else {
  const retailers = JSON.parse(localStorage.getItem("retailers"));
  if (retailers) {
    forEach(retailers, (item) => {
      strRetailer = strRetailer + item.retailer_id + ",";
    });
  }
  strRetailer = strRetailer.substring(0, strRetailer.length - 1);
}
cookies.set("pf_retailer", strRetailer);

    setFilterData(data);
    convertDataForTags(data);

    fetchPromotionFrequencyAndDepth();
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);
    
if (item.key === "RETAILER_LIST") {
  let retailer = cookies.get("pf_retailer").split(",");
  retailer = retailer.filter((id) => String(id) !== String(item.checkboxId));
  cookies.set("pf_retailer", retailer.join());
  if (retailer && retailer.length <= 0) {
    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    cookies.set("pf_retailer", strRetailer);
  }
}

    fetchPromotionFrequencyAndDepth();
  };

  const fetchPromotionFrequencyAndDepth = () => {
    const loaderId = 'ca_promotionfrequencyanddepth';
    addLoader(loaderId);
    const manufacturer = cookies.get("manufacturer");
    const retailer = cookies.get("pf_retailer");
    const startDate = cookies.get("pf_startDate");
    const endDate = cookies.get("pf_endDate");

    const url = `${BACKEND_URL_AZURE}/getpromodepthandfrequency?manufacturer=${manufacturer}&countries=${cookies.get(
      "ca_country"
    )}&regions=${cookies.get(
      "ca_region"
    )}&retailers=${retailer}&startDate=${startDate}&endDate=${endDate}`;

    fetch(url, { method: 'GET',headers:headers })
      .then(res => res.json())
      .then(data => {
        removeLoader(loaderId);
        setData(data.message);
      })
      .catch(err => console.log('-------- Error --------', err));
  }

  useEffect(() => {
    setTableConfigs(columns);
    let strRetailer = "";
    const retailers = JSON.parse(localStorage.getItem("retailers"));
    if (retailers) {
      forEach(retailers, (item) => {
        strRetailer = strRetailer + item.retailer_id + ",";
      });
    }
    strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    cookies.set("pf_retailer", strRetailer);
    fetchPromotionFrequencyAndDepth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Completed-Store-Audits-Chart-wp">
      <div className="HeaderStoreAuditsChart">
        <div className="hd-left-col">
          <div className="title-value">Price Promotion Frequency and Depth</div>
        </div>
        <div className="hd-middle-col">
          <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
        </div>
        <div className="hd-right-col">
          <div className="custom-calendar-wp">
            <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
            <RangePicker
              showTime
              format={"YYYY-MM-DD"}
              onCalendarChange={(range, dateString, info) => {
                let startDate = moment(range[0]).format("YYYY-MM-DD");
                let endDate = moment(range[1]).format("YYYY-MM-DD");

                cookies.set("pf_startDate", startDate);
                cookies.set("pf_endDate", endDate);

                fetchPromotionFrequencyAndDepth();
              }}
              defaultValue={[
                moment(lastDate, dateFormat),
                moment(currentDate, dateFormat),
              ]}
              allowClear={false}
              suffixIcon={
                <img
                  alt=""
                  style={{ marginTop: "3px" }}
                  src={ICON_ARROW_DOWN}
                />
              }
            />
          </div>
          <CustomFilter
            selectedData={filterData}
            filterKeys={[
              CUSTOM_FILTER_KEYS.RETAILER_LIST,
              //CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST,
            ]}
            onChange={(data) => {
              onHandleFilterChanged(data);
            }}
          />
        </div>
      </div>
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={data}
        isPagination={true}
      />
    </div>
  );
};

export default PromotionFrequencyAndDepth;
