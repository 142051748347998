import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Table, Row, Col, Image, Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS, BACKEND_URL } from "../../constants/constants";
import Cookies from "universal-cookie";

import "./style.scss";

const cookies = new Cookies();

const ImageGalleryPriceScans = (props) => {
  const [img, setImg] = useState([]);
  const [visible, setVisible] = useState(false);
  const [displayPriceScans, setdisplayPriceScans] = useState("none");
  const [imageURL, setImageURL] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  let [store, setStore] = useState(cookies.get("ig_store"));

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  useEffect(() => {
    if (
      cookies.get("ig_pricescans") &&
      cookies.get("ig_pricescans")=== "true"
    )
      setdisplayPriceScans("");
    const fetchPriceScans = async () => {
      let arrImg = [];
      if (
        cookies.get("ig_store") &&
        cookies.get("ig_store") !== "" &&
        cookies.get("ig_store") !== "undefined"
      ) {
        if (
          cookies.get("ig_startDate") === undefined ||
          cookies.get("ig_startDate") === "" ||
          cookies.get("ig_startDate") === "undefined"
        ) {
          let startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          cookies.set("ig_startDate", startDate.toISOString().split("T")[0]);
        }
        if (
          cookies.get("ig_endDate") === undefined ||
          cookies.get("ig_endDate") === "" ||
          cookies.get("ig_endDate") === "undefined"
        ) {
          cookies.set("ig_endDate", new Date().toISOString().split("T")[0]);
        }
        let url =
          BACKEND_URL +
          "/getallpricescansbystore?store=" +
          cookies.get("ig_store") +
          "&client=" +
          cookies.get("client") +
          "&startDate=" +
          cookies.get("ig_startDate") +
          "&endDate=" +
          cookies.get("ig_endDate");

        fetch(url, { method: "GET" })
          .then(function (response) {
            return response.json();
          })
          .then(async (result) => {
            for (let i = 0; i < result.length; i++) {
              if (result[i].images) {
                for (let j = 0; j < result[i].images.length; j++) {
                    arrImg.push({
                      img: result[i].images[j],
                      date:
                        result[i].dateEnd.split("T")[0].split("-")[2] +
                        " " +
                        monthNames[
                          parseInt(
                            result[i].dateEnd.split("T")[0].split("-")[1]
                          ) - 1
                        ] +
                        " " +
                        result[i].dateEnd
                          .split("T")[0]
                          .split("-")[0]
                          .substr(2, 2),
                    });
                
              }
            }
          }
            setImg(arrImg);
          
          })
          .catch(function (error) {
            console.log("-------- error ------- " + error);
          });
      }
    };
    fetchPriceScans();
  }, []);

  const showModal = (img) => {
    setImageURL(img);
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <div
      className="OverdueStoreAudits-wp"
      style={{ display: displayPriceScans }}
    >
      <Modal
        title="Image"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1080}
        bodyStyle={{ height: 1000 }}
        style={{ marginTop: -90 }}
      >
        <img src={imageURL} style={{ borderRadius: 7 }} width="1024" />
      </Modal>
      <Row>
        <Col span={24}>
          <div>
            <div
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Price Scans
              <br />
              <br />
            </div>
            <Row>
              {img.map((item, index) => {
                return (
                  <Col span={4} style={{ padding: 10 }} key={index}>
                    <img
                      src={item.img}
                      style={{ width: 200, borderRadius: 7 }}
                      onClick={() => showModal(item.img)}
                    />
                    <br />
                    <br />
                    Audit Date: {item.date.split("T")[0]}
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImageGalleryPriceScans;
