//API
export const SUCCESS_STATUS_CODE = [200, 201, 202, 203];
export const EXPIRED_STATUS_CODE = [403, 401];

export const LOGGED_USER = 'LOGGED_USER';
export const TOKEN = 'TOKEN';
export const REFRESS_TOKEN = 'REFRESS_TOKEN';
export const BACKEND_URL = "https://cpgwebapps-dot-serious-energy-259513.appspot.com";
export const BACKEND_URL_AZURE = "https://instashelf-dashboard-backend.azurewebsites.net";

export const CUSTOM_FILTER_KEYS = {
  COUNTRY_CODE_DROPDOWN: "COUNTRY_CODE_DROPDOWN",
  COUNTRY_LIST: "COUNTRY_LIST",
  SINGLE_COUNTRY_SELECTION: "SINGLE_COUNTRY_SELECTION",
  REGION_LIST: "REGION_LIST",
  CHANNEL_LIST: "CHANNEL_LIST",
  MANUFACTURER_LIST: "MANUFACTURER_LIST",
  RETAILER_LIST: "RETAILER_LIST",
  SALES_REP_LIST: "SALES_REP_LIST",
  PRODUCT_CATEGORY_LIST: "PRODUCT_CATEGORY_LIST",
  SUB_CATEGORY_LIST: "SUB_CATEGORY_LIST",
  BRAND_LIST: "BRAND_LIST",
  PACK_SIZE_LIST: "PACK_SIZE_LIST",
  FLAVOUR_LIST: "FLAVOUR_LIST",
  PRODUCT_LIST: "PRODUCT_LIST",
  PROMOTION_LIST: "PROMOTION_LIST",
  PLACEMENT_TYPE_LIST: "PLACEMENT_TYPE_LIST",
  STORES_LIST: "STORES_LIST",
  MERCHANDISER_LIST: "MERCHANDISER_LIST",
  OSA_BRAND_LIST: "OSA_BRAND_LIST",
  PROMO_SELECTION: "PROMO_SELECTION",
  TRACKED_PRODUCT_LIST: "TRACKED_PRODUCT_LIST",
  SRP_PRODUCT_LIST: "SRP_PRODUCT_LIST",
  SECONDARY_PLACEMENT_TYPE_LIST: "SECONDARY_PLACEMENT_TYPE_LIST",
  SINGLE_PRODUCT_SELECTION: "SINGLE_PRODUCT_SELECTION",
  SECONDARY_DISPLAY_SELECTION: "SECONDARY_DISPLAY_SELECTION",
};