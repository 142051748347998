import React, { useState, useEffect } from "react";
import HeaderStoreAuditsPeriodChart from "../../componentsShared/HeaderStoreAuditsPeriodChart";
import { Column, GroupedColumn, Line } from "@ant-design/charts";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";
import * as filterDataHanler from "../../utils/filterDataHanler";
import FilterTags from "../../componentsShared/FilterTags";
import CustomFilter from "../../componentsShared/CustomFilter";
import moment from "moment";
import { DatePicker, Modal, message } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const columns = [
  {
    title: "SKU",
    dataIndex: "name",
    width: "50%",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    showSorterTooltip: false,
  },
  {
    title: "Variance (%)",
    dataIndex: "variance",
    width: "50%",
    align: "center",
    key: "variance",
    sorter: (a, b) => Number(b.variance) - Number(a.variance),
    showSorterTooltip: false,
  },
];

let lastDate = new Date();
lastDate.setYear(lastDate.getFullYear() - 1);
lastDate = moment(lastDate).format(dateFormat);
let currentDate = new Date();
currentDate = moment(currentDate).format(dateFormat);

const VarianceFromMSRPChart = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  // const [tableConfigs, setTableConfigs] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tableConfigs, setTableConfigs] = useState([]);
  const [rspVariance, setRspVariance] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
  }, []);

  //get data
  const onHandleFilterChanged = (data) => {
    var strCountry = "";
    if (
      data.SINGLE_COUNTRY_SELECTION &&
      data.SINGLE_COUNTRY_SELECTION.value &&
      data.SINGLE_COUNTRY_SELECTION.value.length > 0
    ) {
      for (let i = 0; i < data.SINGLE_COUNTRY_SELECTION.value.length; i++) {
        strCountry =
          strCountry + data.SINGLE_COUNTRY_SELECTION.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("srp_country", strCountry);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("srp_retailer", strRetailer);

    var strProduct = "";
    if (
      data.SRP_PRODUCT_LIST &&
      data.SRP_PRODUCT_LIST.value &&
      data.SRP_PRODUCT_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.SRP_PRODUCT_LIST.value.length; i++) {
        strProduct = strProduct + data.SRP_PRODUCT_LIST.value[i].id + ",";
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);
    }
    cookies.set("srp_product", strProduct);

    setFilterData(data);
    convertDataForTags(data);
    if (
      data.SINGLE_COUNTRY_SELECTION &&
      data.SINGLE_COUNTRY_SELECTION.value.length > 0 &&
      data.SINGLE_COUNTRY_SELECTION.value &&
      data.SINGLE_COUNTRY_SELECTION.value !== "null" &&
      data.SINGLE_COUNTRY_SELECTION.value !== "undefined" &&
      data.SRP_PRODUCT_LIST &&
      data.SRP_PRODUCT_LIST.value.length > 0 &&
      data.SRP_PRODUCT_LIST.value &&
      data.SRP_PRODUCT_LIST.value !== "null" &&
      data.SRP_PRODUCT_LIST.value !== "undefined" &&
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value.length > 0 &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value !== "null" &&
      data.RETAILER_LIST.value !== "undefined"
    ) {
      fetchSRP();
    } else message.error("Please select country, retailer and product", 2);
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "SINGLE_COUNTRY_SELECTION") {
      let country = cookies.get("srp_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("srp_country", country.join());
    }

    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("srp_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("srp_retailer", retailer.join());
    }

    if (item.key === "SRP_PRODUCT_LIST") {
      let product = cookies.get("srp_product").split(",");
      product = product.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("srp_product", product.join());
    }
    if (
      data.SINGLE_COUNTRY_SELECTION &&
      data.SINGLE_COUNTRY_SELECTION.value.length > 0 &&
      data.SINGLE_COUNTRY_SELECTION.value &&
      data.SINGLE_COUNTRY_SELECTION.value !== "null" &&
      data.SINGLE_COUNTRY_SELECTION.value !== "undefined" &&
      data.SRP_PRODUCT_LIST &&
      data.SRP_PRODUCT_LIST.value.length > 0 &&
      data.SRP_PRODUCT_LIST.value &&
      data.SRP_PRODUCT_LIST.value !== "null" &&
      data.SRP_PRODUCT_LIST.value !== "undefined" &&
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value.length > 0 &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value !== "null" &&
      data.RETAILER_LIST.value !== "undefined"
    ) {
      fetchSRP();
    } else message.error("Please select country, retailer and product", 2);
  };

  const fetchSRP = () => {
    const loaderId = "ca_fetchsrpnow";
    addLoader(loaderId);
    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    let url = "";
    url = `${BACKEND_URL_AZURE}/getrsp?manufacturer=${cookies.get(
      "manufacturer"
    )}&countries=${cookies.get(
      "srp_country"
    )}&regions=${strRegion}&retailers=${cookies.get(
      "srp_retailer"
    )}&product=${cookies.get("srp_product")}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        const reversedResult = [...result.message].reverse();
        setData(reversedResult);
        cookies.set("srp_currency", result.currency);
      })
      .catch((error) => {
        console.log("--------- Error --------", error);
      });
  };

  const fetchRspVariance = () => {
    const loaderId = "ca_fetchrspvariance";
    addLoader(loaderId);
    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    const manufacturer = cookies.get("manufacturer");
    //const startDate = moment().startOf("year").format("YYYY-MM-DD");
    //const endDate = moment().format("YYYY-MM-DD");
    const startDate = cookies.get("srp_startDate");
    const endDate = cookies.get("srp_endDate");

    let strCountry = "";
    if (
      cookies.get("srp_country") === null ||
      cookies.get("srp_country") === undefined ||
      cookies.get("srp_country") === "undefined"
    ) {
      const countries = JSON.parse(localStorage.getItem("countries"));
      if (countries) {
        forEach(countries, (item) => {
          strCountry = strCountry + item.country_id + ",";
        });
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }

    let strRetailer = "";
    if (
      cookies.get("srp_retailer") === null ||
      cookies.get("srp_retailer") === undefined ||
      cookies.get("srp_retailer") === "undefined"
    ) {
      const retailers = JSON.parse(localStorage.getItem("retailers"));
      if (retailers) {
        forEach(retailers, (item) => {
          strRetailer = strRetailer + item.retailer_id + ",";
        });
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }

    let url;
    if (strCountry === "" && strRetailer === "")
      url = `${BACKEND_URL_AZURE}/getvariancersp?manufacturer=${manufacturer}&startDate=${startDate}&endDate=${endDate}&countries=${cookies.get(
        "srp_country"
      )}&regions=${strRegion}&retailers=${cookies.get("srp_retailer")}`;
    else
      url = `${BACKEND_URL_AZURE}/getvariancersp?manufacturer=${manufacturer}&startDate=${startDate}&endDate=${endDate}&countries=${strCountry}&regions=${strRegion}&retailers=${strRetailer}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((data) => {
        removeLoader(loaderId);
        setRspVariance(data.message);
      })
      .catch((error) => console.log("------- Error ------", error));
  };

  useEffect(() => {
    if (
      cookies.get("srp_startDate") &&
      cookies.get("srp_startDate") !== "" &&
      cookies.get("srp_startDate") !== "undefined"
    ) {
      lastDate = cookies.get("srp_startDate");
    }

    if (
      cookies.get("srp_endDate") &&
      cookies.get("srp_endDate") !== "" &&
      cookies.get("srp_endDate") !== "undefined"
    ) {
      currentDate = cookies.get("srp_endDate");
    }

    if (!cookies.get("srp_startDate") || !cookies.get("srp_endDate")) {
      cookies.set("srp_startDate", lastDate);
      cookies.set("srp_endDate", currentDate);
    }

    fetchRspVariance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //end get data

  const data_sa = [
    { Month: "Jan", "Shelf Price": 0, category: "SRP" },
    { Month: "Feb", "Shelf Price": 0, category: "SRP" },
    { Month: "Mar", "Shelf Price": 0, category: "SRP" },
    { Month: "Apr", "Shelf Price": 0, category: "SRP" },
    {
      Month: "May",
      "Shelf Price": 0,
      category: "SRP",
    },
    { Month: "Jun", "Shelf Price": 0, category: "SRP" },
    { Month: "Jul", "Shelf Price": 0, category: "SRP" },
    { Month: "Aug", "Shelf Price": 0, category: "SRP" },
    {
      Month: "Sep",
      "Shelf Price": 0,
      category: "SRP",
    },
    {
      Month: "Oct",
      "Shelf Price": 0,
      category: "SRP",
    },
    {
      Month: "Nov",
      "Shelf Price": 0,
      category: "SRP",
    },
    {
      Month: "Dec",
      "Shelf Price": 0,
      category: "SRP",
    },
    { Month: "Jan", "Shelf Price": 0, category: "Min Price" },
    { Month: "Feb", "Shelf Price": 0, category: "Min Price" },
    { Month: "Mar", "Shelf Price": 0, category: "Min Price" },
    { Month: "Apr", "Shelf Price": 0, category: "Min Price" },
    {
      Month: "May",
      "Shelf Price": 0,
      category: "Min Price",
    },
    { Month: "Jun", "Shelf Price": 0, category: "Min Price" },
    { Month: "Jul", "Shelf Price": 0, category: "Min Price" },
    { Month: "Aug", "Shelf Price": 0, category: "Min Price" },
    {
      Month: "Sep",
      "Shelf Price": 0,
      category: "Min Price",
    },
    {
      Month: "Oct",
      "Shelf Price": 0,
      category: "Min Price",
    },
    {
      Month: "Nov",
      "Shelf Price": 0,
      category: "Min Price",
    },
    {
      Month: "Dec",
      "Shelf Price": 0,
      category: "Min Price",
    },
    { Month: "Jan", "Shelf Price": 0, category: "Max Price" },
    { Month: "Feb", "Shelf Price": 0, category: "Max Price" },
    { Month: "Mar", "Shelf Price": 0, category: "Max Price" },
    { Month: "Apr", "Shelf Price": 0, category: "Max Price" },
    {
      Month: "May",
      "Shelf Price": 0,
      category: "Max Price",
    },
    { Month: "Jun", "Shelf Price": 0, category: "Max Price" },
    { Month: "Jul", "Shelf Price": 0, category: "Max Price" },
    { Month: "Aug", "Shelf Price": 0, category: "Max Price" },
    {
      Month: "Sep",
      "Shelf Price": 0,
      category: "Max Price",
    },
    {
      Month: "Oct",
      "Shelf Price": 0,
      category: "Max Price",
    },
    {
      Month: "Nov",
      "Shelf Price": 0,
      category: "Max Price",
    },
    {
      Month: "Dec",
      "Shelf Price": 0,
      category: "Max Price",
    },
  ];

  function unquote(string) {
    return string && string.replace(/^['"]|['"]$/g, "");
  }

  const config_sa = {
    data: data,
    title: {
      visible: false,
      text: "Shelf Price",
      style: {
        fontSize: 12,
        fontWeight: "bold",
      },
    },
    legend: {
      position: "top",
      visible: true,
    },
    xField: "Month",
    yField: "Shelf Price",
    seriesField: "category",
    //smooth: "false",
    //responsive: true,
    //color: ["#4A5DE8", "#43BD8B", "#DE78B5", "#3282b8"],
    color: [
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
    ],
    yAxis: {
      nice: true,
      title: {
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
    },
    xAxis: {
      title: {
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      label: {
        visible: true,
        style: {
          fill: "#000",
          fontSize: 12,
          fontWeight: "bold",
          //opacity: 0.6,
        },
      },
    },
    label: {
      visible: true,
      style: {
        fill: "#000",
        fontSize: 12,
        fontWeight: "bold",
        //opacity: 0.6,
      },
    },
  };

  return (
    <div className="row">
      <div
        className="col-70"
        style={{
          display:
            cookies.get("uses_pricing_module") === true ||
            cookies.get("uses_pricing_module") === "true"
              ? "block"
              : "none",
        }}
      >
        <div className="Completed-Store-Audits-Chart-wp">
          <div className="HeaderStoreAuditsChart">
            <div className="hd-left-col">
              <div className="title-value">Variance from RSP</div>
            </div>
            <div className="hd-middle-col">
              <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
            </div>
            <div className="hd-right-col">
              <CustomFilter
                selectedData={filterData}
                filterKeys={[
                  CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION,
                  CUSTOM_FILTER_KEYS.RETAILER_LIST,
                  CUSTOM_FILTER_KEYS.SRP_PRODUCT_LIST,
                ]}
                onChange={(data) => {
                  onHandleFilterChanged(data);
                }}
              />
            </div>
          </div>
          <div className="separate-line" />
          <Line {...config_sa} />
        </div>
      </div>
      <div
        className="col-30"
        style={{
          display:
            cookies.get("uses_pricing_module") === true ||
            cookies.get("uses_pricing_module") === "true"
              ? "block"
              : "none",
        }}
      >
        <div className="OverdueStoreAudits-wp">
          <ReportTableHeader
            title="Top Variance from RSP"
            hideControlButtons={true}
          />
          <div className="HeaderStoreAuditsChart hd-right-col">
            <div className="custom-calendar-wp">
              <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
              <RangePicker
                showTime
                format={"YYYY-MM-DD"}
                onCalendarChange={(range, dateString, info) => {
                  let startDate = moment(range[0]).format("YYYY-MM-DD");
                  let endDate = moment(range[1]).format("YYYY-MM-DD");

                  //if (info.range === "end") {
                  cookies.set("srp_startDate", startDate);
                  cookies.set("srp_endDate", endDate);
                  fetchRspVariance();
                  //fetchProdutPricingComparison();
                  //}
                }}
                defaultValue={[
                  moment(lastDate, dateFormat),
                  moment(currentDate, dateFormat),
                ]}
                allowClear={false}
                suffixIcon={
                  <img
                    alt=""
                    style={{ marginTop: "3px" }}
                    src={ICON_ARROW_DOWN}
                  />
                }
              />
            </div>
          </div>
          <div className="separate-line" />
          <ReportTable
            columns={tableConfigs}
            dataSource={rspVariance}
            isPagination={true}
          />
          <Modal
            title="Top Variance from RSP"
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            footer={null}
            width={650}
          >
            <ReportTable columns={tableConfigs} dataSource={rspVariance} />
          </Modal>
          <div className="view-all-btn-wp">
            <ViewAllButton title="View all" onClick={() => setVisible(true)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VarianceFromMSRPChart;
