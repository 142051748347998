import React from "react";
import loader from '../assets/img/loadingMilkyway.gif';

const LoaderComponent = ({ show }) => (
  <div className={`loader__container ${show ? 'loader__animate' : 'loader__remove_animate'}`}>
    <div className="loader__item">
        <img src={loader} alt="Loading..." />
    </div>
  </div>
);

export default LoaderComponent;
