import React, { useState, useEffect } from "react";
import HeaderStoreAuditsPeriodChart from "../../componentsShared/HeaderStoreAuditsPeriodChart";
import { Column, GroupedColumn, Line } from "@ant-design/charts";
import {
  CUSTOM_FILTER_KEYS,
  BACKEND_URL,
  BACKEND_URL_AZURE,
} from "../../constants/constants";
import Cookies from "universal-cookie";
import "./style.scss";
import * as filterDataHanler from "../../utils/filterDataHanler";
import FilterTags from "../../componentsShared/FilterTags";
import CustomFilter from "../../componentsShared/CustomFilter";
import { message, DatePicker } from "antd";
import moment from "moment";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const cookies = new Cookies();
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format(dateFormat);
let currentDate = new Date();
currentDate = moment(currentDate).format(dateFormat);

const KelloggsVsCompetitorPromotionFrequencyChart = ({
  addLoader,
  removeLoader,
}) => {
  const [data, setData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [manufacturer, setManufacturer] = useState();

  if (
    cookies.get("pc_startDate") &&
    cookies.get("pc_startDate") !== "" &&
    cookies.get("pc_startDate") !== "undefined"
  ) {
    lastDate = cookies.get("pc_startDate");
  }
  if (
    cookies.get("pc_endDate") &&
    cookies.get("pc_endDate") !== "" &&
    cookies.get("pc_endDate") !== "undefined"
  ) {
    currentDate = cookies.get("pc_endDate");
  }

  if (!cookies.get("pc_startDate") || !cookies.get("pc_endDate")) {
    cookies.set("pc_startDate", lastDate);
    cookies.set("pc_endDate", currentDate);
  }

  const fetchProdutPricingComparison = () => {
    const loaderId = "kc_fetchproductpricingfrequency";
    addLoader(loaderId);
    let strRegion = "";
    const regions = JSON.parse(localStorage.getItem("regions"));
    if (regions) {
      forEach(regions, (item) => {
        strRegion = strRegion + item.region_id + ",";
      });
    }
    strRegion = strRegion.substring(0, strRegion.length - 1);
    const url = `${BACKEND_URL_AZURE}/getproductpricingcomparison?manufacturer=${cookies.get(
      "manufacturer"
    )}&countries=${cookies.get(
      "pc_country"
    )}&regions=${strRegion}&retailers=${cookies.get(
      "pc_retailer"
    )}&products=${cookies.get("pc_product")}&startDate=${cookies.get(
      "pc_startDate"
    )}&endDate=${cookies.get("pc_endDate")}`;

    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((result) => {
        removeLoader(loaderId);
        setData(result.message);
        cookies.set("pc_currency", result.currency);
      })
      .catch((err) => console.log("------- Error -------", err));
  };

  const onHandleFilterChanged = (data) => {
    var strCountry = "";
    if (
      data.SINGLE_COUNTRY_SELECTION &&
      data.SINGLE_COUNTRY_SELECTION.value &&
      data.SINGLE_COUNTRY_SELECTION.value.length > 0
    ) {
      for (let i = 0; i < data.SINGLE_COUNTRY_SELECTION.value.length; i++) {
        strCountry =
          strCountry + data.SINGLE_COUNTRY_SELECTION.value[i].id + ",";
      }
      strCountry = strCountry.substring(0, strCountry.length - 1);
    }
    cookies.set("pc_country", strCountry);

    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("pc_retailer", strRetailer);

    var strProduct = "";
    if (
      data.TRACKED_PRODUCT_LIST &&
      data.TRACKED_PRODUCT_LIST.value &&
      data.TRACKED_PRODUCT_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.TRACKED_PRODUCT_LIST.value.length; i++) {
        strProduct = strProduct + data.TRACKED_PRODUCT_LIST.value[i].id + ",";
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);
    }
    cookies.set("pc_product", strProduct);

    setFilterData(data);
    convertDataForTags(data);
    if (
      data.SINGLE_COUNTRY_SELECTION &&
      data.SINGLE_COUNTRY_SELECTION.value.length > 0 &&
      data.SINGLE_COUNTRY_SELECTION.value &&
      data.SINGLE_COUNTRY_SELECTION.value !== "null" &&
      data.SINGLE_COUNTRY_SELECTION.value !== "undefined" &&
      data.TRACKED_PRODUCT_LIST &&
      data.TRACKED_PRODUCT_LIST.value.length > 0 &&
      data.TRACKED_PRODUCT_LIST.value &&
      data.TRACKED_PRODUCT_LIST.value !== "null" &&
      data.TRACKED_PRODUCT_LIST.value !== "undefined" &&
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value.length > 0 &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value !== "null" &&
      data.RETAILER_LIST.value !== "undefined"
    ) {
      fetchProdutPricingComparison();
    } else message.error("Please select country, retailer and product", 2);
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    // message.destroy();
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "SINGLE_COUNTRY_SELECTION") {
      let country = cookies.get("pc_country").split(",");
      country = country.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("pc_country", country.join());
    }

    if (item.key === "RETAILER_LIST") {
      let retailer = cookies.get("pc_retailer").split(",");
      retailer = retailer.filter(
        (id) => String(id) !== String(item.checkboxId)
      );
      cookies.set("pc_retailer", retailer.join());
    }

    if (item.key === "TRACKED_PRODUCT_LIST") {
      let product = cookies.get("pc_product").split(",");
      product = product.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("pc_product", product.join());
    }

    if (
      data.SINGLE_COUNTRY_SELECTION &&
      data.SINGLE_COUNTRY_SELECTION.value.length > 0 &&
      data.SINGLE_COUNTRY_SELECTION.value &&
      data.SINGLE_COUNTRY_SELECTION.value !== "null" &&
      data.SINGLE_COUNTRY_SELECTION.value !== "undefined" &&
      data.TRACKED_PRODUCT_LIST &&
      data.TRACKED_PRODUCT_LIST.value.length > 0 &&
      data.TRACKED_PRODUCT_LIST.value &&
      data.TRACKED_PRODUCT_LIST.value !== "null" &&
      data.TRACKED_PRODUCT_LIST.value !== "undefined" &&
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value.length > 0 &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value !== "null" &&
      data.RETAILER_LIST.value !== "undefined"
    ) {
      fetchProdutPricingComparison();
    } else message.error("Please select country, retailer and product", 2);
    //fetchProdutPricingComparison();
  };

  //get data
  useEffect(() => {
    //setData();
  }, []);
  //end get data

  // eslint-disable-next-line no-useless-concat
  let yAxis = "Shelf Price" + " (" + cookies.get("pc_currency") + ")";

  const config_sa = {
    data: data,
    title: {
      visible: false,
      text: "Shelf Price",
      style: {
        fontSize: 12,
        //fontWeight: "bold",
      },
    },
    legend: {
      position: "top",
      visible: true,
    },
    xField: "Month",
    yField: "Shelf Price",
    seriesField: "category",
    //smooth: "true",
    responsive: true,
    //color: ["#4A5DE8", "#43BD8B", "#FAA219", "#3282b8"],
    color: [
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
    ],
    yAxis: {
      nice: true,
      title: {
        text: yAxis,
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
    },
    xAxis: {
      title: {
        visible: false,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      label: {
        visible: true,
        style: {
          fill: "#000",
          fontSize: 12,
          fontWeight: "bold",
          //opacity: 0.6,
        },
      },
    },
    label: {
      visible: true,
      style: {
        fill: "#000",
        fontSize: 12,
        fontWeight: "bold",
        //opacity: 0.6,
      },
    },
  };

  return (
    <div className="Completed-Store-Audits-Chart-wp">
      <div className="HeaderStoreAuditsChart">
        <div className="hd-left-col">
          <div className="title-value">Product Pricing Comparison</div>
        </div>
        <div className="hd-middle-col">
          <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
        </div>
        <div className="hd-right-col">
          <div className="custom-calendar-wp">
            <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
            <RangePicker
              showTime
              format={"YYYY-MM-DD"}
              onCalendarChange={(range, dateString, info) => {
                let startDate = moment(range[0]).format("YYYY-MM-DD");
                let endDate = moment(range[1]).format("YYYY-MM-DD");

                //if (info.range === "end") {
                cookies.set("pc_startDate", startDate);
                cookies.set("pc_endDate", endDate);
                // message.loading("Loading data...", 0);
                fetchProdutPricingComparison();
                //}
              }}
              defaultValue={[
                moment(lastDate, dateFormat),
                moment(currentDate, dateFormat),
              ]}
              allowClear={false}
              suffixIcon={
                <img
                  alt=""
                  style={{ marginTop: "3px" }}
                  src={ICON_ARROW_DOWN}
                />
              }
            />
          </div>
          <CustomFilter
            selectedData={filterData}
            filterKeys={[
              CUSTOM_FILTER_KEYS.SINGLE_COUNTRY_SELECTION,
              CUSTOM_FILTER_KEYS.RETAILER_LIST,
              CUSTOM_FILTER_KEYS.TRACKED_PRODUCT_LIST,
            ]}
            onChange={(data) => {
              onHandleFilterChanged(data);
            }}
          />
        </div>
      </div>
      <div className="separate-line" />
      <Line {...config_sa} />
    </div>
  );
};

export default KelloggsVsCompetitorPromotionFrequencyChart;
