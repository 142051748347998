
import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Modal, Table } from 'antd';
import Cookies from "universal-cookie";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS, BACKEND_URL } from "../../constants/constants";
import "./style.scss";

const cookies = new Cookies();

const columns = [
  {
    title: "Retailer",
    dataIndex: "retailer",
    width: "33.33%",
    key: "retailer",
    sorter: (a, b) => a.retailer.localeCompare(b.retailer),
    showSorterTooltip: false,
  },
  {
    title: "No. of Stores",
    dataIndex: "stores",
    width: "33.33%",
    align: "center",
    key: "stores",
    sorter: (a, b) => a.stores - b.stores,
    showSorterTooltip: false,
  },
  /*{
    title: "Space Buy ($)",
    dataIndex: "overdueBy",
    key: "overdueBy",
    align: "center",
    width: "33.33%",
    // render: (text, record) => (
    //   <span>{`${record.overdueBy} ${
    //     record.overdueBy > 1 ? "days" : "day"
    //   }`}</span>
    // ),
  },*/
];

const auditData = [
    /*{
        auditor: 'Sally',
        store: 'Store A',
        overdueBy: 5,
    },
    {
        auditor: 'John',
        store: 'Store B',
        overdueBy: 4,
    },
    {
        auditor: 'Adrian',
        store: 'Store C',
        overdueBy: 3,
    },
    {
        auditor: 'Gary',
        store: 'Store D',
        overdueBy: 2,
    },*/
];

const NoOfNonCompliantStoresByKeyRetailer = (props) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tableConfigs, setTableConfigs] = useState([]);

  // const fetchBaseKpi = () => {
  //   if (
  //     cookies.get("store") &&
  //     cookies.get("store") !== "" &&
  //     cookies.get("store") !== "undefined"
  //   ) {
  //     const url = `${BACKEND_URL}/getnoncompliantstoresbykeyretailer?manufacturer=${cookies.get(
  //       "manufacturer"
  //     )}&startDate=${cookies.get("startDate")}&endDate=${cookies.get(
  //       "endDate"
  //     )}&country=${cookies.get("country")}`;

  //     fetch(url, { method: "GET" })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         setData(result);
  //       })
  //       .catch((err) => console.log("------ Error ------", err));
  //   }
  // };

  useEffect(() => {
    // if (
    //   cookies.get("startDate") === undefined ||
    //   cookies.get("startDate") === "" ||
    //   cookies.get("startDate") === "undefined"
    // ) {
    //   let startDate = new Date();
    //   startDate.setMonth(startDate.getMonth() - 1);
    //   cookies.set("startDate", startDate);
    // }

    // if (
    //   cookies.get("endDate") === undefined ||
    //   cookies.get("endDate") === "" ||
    //   cookies.get("endDate") === "undefined"
    // ) {
    //   cookies.set("endDate", new Date());
    // }
    setTableConfigs(columns);
    // setData(auditData);
    // fetchBaseKpi();
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="No. of Non-Compliant Stores by Key Retailer"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={props.nonCompliant}
        isPagination={true}
      />
      <Modal
        title="No. of Non-Compliant Stores by Key Retailer"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={props.nonCompliant}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton onClick={() => setVisible(true)} title="View all" />
      </div>
    </div>
  );
};

export default NoOfNonCompliantStoresByKeyRetailer;
