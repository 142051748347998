import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import { BACKEND_URL } from "../../constants/constants";
import "./style.scss";
import Cookies from "universal-cookie";

const ICON_DECREASE = require("../../assets/img/icons/ic-decrease-red.svg");
const ICON_INCREASE = require("../../assets/img/icons/ic-increase-green.svg");

const cookies = new Cookies();

const PlanogramCompliance = (props) => {
  const [data, setData] = useState({});

  // const fetchPlanogram = () => {
  //   if (
  //     cookies.get("store") &&
  //     cookies.get("store") !== "" &&
  //     cookies.get("store") !== "undefined"
  //   ) {
  //     const store = cookies.get('store');

  //     if (
  //       cookies.get("startDate") === undefined ||
  //       cookies.get("startDate") === "" ||
  //       cookies.get("startDate") === "undefined"
  //     ) {
  //       var startDate = new Date();
  //       startDate.setMonth(startDate.getMonth() - 1);
  //       cookies.set("startDate", startDate);
  //     }
  //     if (
  //       cookies.get("endDate") === undefined ||
  //       cookies.get("endDate") === "" ||
  //       cookies.get("endDate") === "undefined"
  //     ) {
  //       cookies.set("endDate", new Date());
  //     }

  //     const url = `${BACKEND_URL}/getplanogramcompliancebystore?store=${store}&manufacturer=${cookies.get('manufacturer')}&startDate=${cookies.get("startDate")}&endDate=${cookies.get("endDate")}`;

  //     fetch(url, { method: "GET" })
  //       .then(function (response) {
  //         return response.json();
  //       })
  //       .then(async (result) => {
  //         setData({
  //           percent: result.current,
  //           stores: 0,
  //           completedStores: 0,
  //           isIncrease: false,
  //           increasePercent: 0,
  //         })
  //       })
  //       .catch(function (error) {
  //         console.log("-------- error ------- " + error);
  //       });
  //   }
  // }

  // useEffect(() => {
  //   // fetchPlanogram()
  // }, []);

  return (
    <div className="Total-Audits-Completed-wp">
      <div className="row">
        <div className="col-25">
          <Progress
            type="circle"
            percent={props.planogramData.complianceScore}
            strokeWidth={10}
            strokeColor={
              props.planogramData.complianceScore >
              props.planogramData.targetCompliance
                ? "#87d068"
                : "#E5305B"
            }
            format={() =>
              props.planogramData.complianceScore >
              props.planogramData.targetCompliance ? (
                <div style={{ color: "#87d068" }}>
                  {props.planogramData.complianceScore}%
                </div>
              ) : (
                <div style={{ color: "#E5305B" }}>
                  {props.planogramData.complianceScore}%
                </div>
              )
            }
            width={80}
          />
        </div>
        <div className="col-75">
          <div className="summary-completed-stores">Planogram Compliance</div>
          <div className="summary-detail">
            &nbsp;
            {/*{data.isIncrease ? (
              <img alt="" className="period-icon" src={ICON_INCREASE} />
            ) : (
              <img alt="" className="period-icon" src={ICON_DECREASE} />
            )}
            <div className="last-period">
              {`${data.isIncrease ? "+" : "-"}${
                data.increasePercent
              }% vs last week`}
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanogramCompliance;
