
import React, { useState, useEffect } from "react";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import "./style.scss";

const ICON_DECREASE = require("../../assets/img/icons/ic-decrease-red.svg")
const ICON_INCREASE = require("../../assets/img/icons/ic-increase-green.svg")

const columns = [
    {
        title: 'Region',
        dataIndex: 'region',
        width: '33.33%',
        key: 'region',
    },
    {
        title: 'Actual Vs Plan ( %, Stores)',
        dataIndex: 'actualPlanStore',
        width: '33.33%',
        align: 'center',
        key: 'actualPlanStore',
    },
    {
        title: 'Vs Last Period (%)',
        dataIndex: 'lastPeriod',
        key: 'lastPeriod',
        align: 'center',
        width: '33.33%',
        render: (text, record) => (
            <div className="period-wp">
                <div className={`period-content ${record.isIncrease ? 'is-increased' : ''}`}>
                    {record.isIncrease
                        ? <img alt="" className="period-icon" src={ICON_INCREASE} />
                        : <img alt="" className="period-icon" src={ICON_DECREASE} />
                    }
                    <div className="last-period">{record.lastPeriod}</div>
                </div>
            </div>
        ),
    },
];

const auditData = [
    /*{
        region: 'Central',
        actualPlanStore: '89% (5/10)',
        lastPeriod: '5%',
        isIncrease: true,
    },
    {
        region: 'South',
        actualPlanStore: '79% (5/10)',
        lastPeriod: '-10%',
        isIncrease: false,
    },
    {
        region: 'North',
        actualPlanStore: '79% (5/10)',
        lastPeriod: '-10%',
        isIncrease: false,
    },
    {
        region: 'East',
        actualPlanStore: '79% (5/10)',
        lastPeriod: '-10%',
        isIncrease: false,
    },*/
];

const SalesRepPerformanceRanking = (props) => {
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [tableConfigs, setTableConfigs] = useState([]);

    useEffect(() => {
        setTableConfigs(columns);
        setData(auditData);
    }, []);

    return (
      <div className="SalesRepPerformanceRanking-wp">
        <ReportTableHeader
          title="Merchandiser Performance Ranking"
          filterKeys={[CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN]}
          onFilterChanged={(filters) => {
            setFilterData(filters);
            console.log(
              "SalesRepPerformanceRanking: onFilterChanged:",
              filters
            );
          }}
          hideControlButtons={true}
        />
        <div className="separate-line" />
        <ReportTable
          columns={tableConfigs}
          dataSource={data}
          isPagination={true}
          showHeader={false}
        />
        <div className="view-all-btn-wp">
          <ViewAllButton title="View all" disabled={true} />
        </div>
      </div>
    );
}

export default SalesRepPerformanceRanking;
