import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import { Dropdown, Menu, Radio, DatePicker, Button, message } from "antd";
import CustomFilter from "../../componentsShared/CustomFilter";
import FilterTags from "../../componentsShared/FilterTags";
import Cookies from "universal-cookie";
import "./style.scss";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import * as filterDataHanler from "../../utils/filterDataHanler";

const ICON_EXPORT_EXCEL = require("../../assets/img/icons/ic-export-black.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const cookies = new Cookies();

var lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
var currentDate = new Date();

if (
  cookies.get("ig_startDate") &&
  cookies.get("ig_startDate") !== "" &&
  cookies.get("ig_startDate") !== "undefined"
) {
  currentDate = cookies.get("ig_startDate");
}
if (
  cookies.get("ig_endDate") &&
  cookies.get("ig_endDate") !== "" &&
  cookies.get("ig_endDate") !== "undefined"
) {
  lastDate = cookies.get("ig_endDate");
}

function printDocument() {
  message.loading("Exporting PDF...", 0);
  const input = document.querySelector("#divToPrint");
  html2canvas(
    input,
    { useCORS: true },
    {
      width: document.getElementById("divToPrint").scrollWidth,
      height: document.getElementById("divToPrint").scrollHeight,
    }
  ).then((canvas) => {
    canvas.crossOrigin = "Anonymous";
    const imgData = canvas.toDataURL("image/png");
    //document.querySelector("#divToPrint").appendChild(canvas);
    const pdf = new jsPDF();
    pdf.addImage(
      imgData,
      "png",
      0,
      0,
      document.getElementById("divToPrint").offsetWidth / 9,
      document.getElementById("divToPrint").offsetHeight / 9
    );
    pdf.save(new Date().valueOf() + ".pdf");
    message.destroy();
  });
}

const ImageGalleryOverview = (props) => {
  // const [data, setData] = useState([]);
  // const [tableConfigs, setTableConfigs] = useState([]);
  const [periodFilter, setPeriodFilter] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [sDate, setSDate] = useState([]);
  const [eDate, setEDate] = useState([]);

  useEffect(() => {
    if (cookies.get("email") == null) {
      document.location.href = "/";
    }
    cookies.set("pr_brand", "");
    cookies.set("retailer", "");
    cookies.set("pc_retailer", "");
    cookies.set("pc_product", "");
    setSDate();
  }, []);

  const exportMenus = (
    <Menu>
      <Menu.Item key="pdf" onClick={printDocument}>
        PDF
      </Menu.Item>
      {/*<Menu.Item key="1">Excel</Menu.Item>*/}
    </Menu>
  );

  const exportPDF = () => {
    //console.log(filterData);
    //alert(filterData.STORES_LIST.value.length);
  };

  const handlePeriodFilterChange = (e) => {
    setPeriodFilter(e.target.value);
  };

  const onHandleFilterChanged = (data) => {
    var strRetailer = "";
    if (
      data.RETAILER_LIST &&
      data.RETAILER_LIST.value &&
      data.RETAILER_LIST.value.length > 0
    ) {
      for (let i = 0; i < data.RETAILER_LIST.value.length; i++) {
        strRetailer = strRetailer + data.RETAILER_LIST.value[i].id + ",";
      }
      strRetailer = strRetailer.substring(0, strRetailer.length - 1);
    }
    cookies.set("ig_retailer", strRetailer);
    /*if (
      data.STORES_LIST.value &&
      data.STORES_LIST.value.length > 0 &&
      data.STORES_LIST.value !== undefined &&
      data.STORES_LIST.value !== "undefined"
    )
      cookies.set("ig_store", data.STORES_LIST.value[0].id);*/
    setFilterData(data);
    //convertDataForTags(data);
    /*cookies.set(
      "ig_pricescans",
      document.getElementById("chkPriceScan").checked
    );*/
    document.location.href = "secondary-display";
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);
  };

  return (
    <div className="Team-Activity-Overview-wp">
      <div className="title-wp" style={{ width: "100px" }}>
        <div className="title-wp">Secondary Display</div>
      </div>
      <div className="condition-values-wp">
        <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
      </div>
      <div className="control-btns" style={{ width: "700px" }}>
        {/*<Radio.Group
            className="period-filter-wp"
            value={periodFilter}
            onChange={handlePeriodFilterChange}
          >
            <Radio.Button value="monthly">Monthly</Radio.Button>
            <Radio.Button value="quarterly">Quarterly</Radio.Button>
            <Radio.Button value="yearly">Yearly</Radio.Button>
          </Radio.Group>
          <CustomFilter
            useFilterBtnForOverview={true}
            selectedData={filterData}
            filterKeys={[
              CUSTOM_FILTER_KEYS.COUNTRY_CODE_DROPDOWN,
              CUSTOM_FILTER_KEYS.STORES_LIST,
              CUSTOM_FILTER_KEYS.PLACEMENT_TYPE_LIST
            ]}
            onChange={(data) => {
              console.log("TeamActivityOverview ####", data);
              onHandleFilterChanged(data);
            }}
          />*/}
        <div className="custom-calendar-wp" style={{ marginRight: 0 }}>
          <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
          {/*<DatePicker
              allowClear={false}
              onChange={(data) => {}}
              
              suffixIcon={<img alt="" src={ICON_ARROW_DOWN} />}
              disabledDate={(d) => !d || d.isAfter("2021")}
            />*/}
          <RangePicker
            showTime
            format={"YYYY-MM-DD"}
            onChange={(range) => {
              //console.log(new Date(range[0]._d).getMonth());
            }}
            /*renderExtraFooter={() => (
                <Button
                  onClick={() => {
                    document.location.href = "my-stores";
                  }}
                  style={{ float: "right", margin: 10 }}
                >
                  OK
                </Button>
                )}*/
            onCalendarChange={(range) => {
              if (range[0] && range[1]) {
                var startDate = new Date(range[0]._d + "EDT")
                  .toISOString()
                  .split("T")[0];
                var endDate = new Date(range[1]._d + "EDT")
                  .toISOString()
                  .split("T")[0];
                //if (
                //  startDate !== cookies.get("startDate") &&
                //  endDate !== cookies.get("endDate")
                //) {
                cookies.set("ig_startDate", startDate);
                cookies.set("ig_endDate", endDate);
                //cookies.set("startDate", startDate);
                //cookies.set("endDate", endDate);
                document.location.href = "secondary-display";
                //}
              }
            }}
            //picker="year"
            defaultValue={[
              moment(lastDate, dateFormat),
              moment(currentDate, dateFormat),
            ]}
            allowClear={false}
            suffixIcon={
              <img alt="" style={{ marginTop: "3px" }} src={ICON_ARROW_DOWN} />
            }
          />
        </div>
        <CustomFilter
          useFilterBtnForOverview={true}
          selectedData={filterData}
          filterKeys={[
            //CUSTOM_FILTER_KEYS.STORES_LIST,
            CUSTOM_FILTER_KEYS.RETAILER_LIST,
            //CUSTOM_FILTER_KEYS.SECONDARY_PLACEMENT_TYPE_LIST,
          ]}
          onChange={(data) => {
            onHandleFilterChanged(data);
          }}
        />
        <Dropdown
          className="btn-item export-dropdown"
          overlay={exportMenus}
          trigger={["click"]}
        >
          <div>
            <img
              alt="icon-btn"
              className="arrow-icon"
              src={ICON_EXPORT_EXCEL}
            />
            <span>Export As</span>
            <img alt="" className="arrow-down-ic" src={ICON_ARROW_DOWN} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default ImageGalleryOverview;
