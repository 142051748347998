import React, { useState, useEffect } from "react";
import moment from 'moment';
import { message, DatePicker } from "antd";
import HeaderStoreAuditsPeriodChart from "../../componentsShared/HeaderStoreAuditsPeriodChart";
import { Column, GroupedColumn } from "@ant-design/charts";
import { BACKEND_URL, CUSTOM_FILTER_KEYS ,BACKEND_URL_AZURE} from "../../constants/constants";
import Cookies from "universal-cookie";
import { isEmpty, forEach, cloneDeep, find, filter } from "lodash";
import "./style.scss";
import FilterTags from "../../componentsShared/FilterTags";
import CustomFilter from "../../componentsShared/CustomFilter";
import * as filterDataHanler from "../../utils/filterDataHanler";
import { encode } from "base-64";

const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");

const cookies = new Cookies();
let headers = new Headers({
  Authorization:
    "Basic " +
    encode(
      process.env.REACT_APP_dashboardUser +
        ":" +
        process.env.REACT_APP_dashboardPassword
    ),
  "Content-Type": "application/json",
});
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 12);
lastDate = moment(lastDate).format(dateFormat);
let currentDate = new Date();
currentDate = moment(currentDate).format(dateFormat);

const FairShareOfShelfChart = ({ addLoader, removeLoader }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);

  if (
    cookies.get("fs_startDate") &&
    cookies.get("fs_startDate") !== "" &&
    cookies.get("fs_startDate") !== "undefined"
  ) {
    lastDate = cookies.get("fs_startDate");
  } else {
    cookies.set("fs_startDate", lastDate);
  }
  if (
    cookies.get("fs_endDate") &&
    cookies.get("fs_endDate") !== "" &&
    cookies.get("fs_endDate") !== "undefined"
  ) {
    currentDate = cookies.get("fs_endDate");
  } else {
    cookies.set("fs_endDate", currentDate);
  }

  const data_sa = [
    {
      name: "Nestle",
      month: "Jan",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Feb",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Mar",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Apr",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "May",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Jun",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Jul",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Aug",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Sep",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Oct",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Nov",
      FSOS: 0,
    },
    {
      name: "Nestle",
      month: "Dec",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Jan",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Feb",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Mar",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Apr",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "May",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Jun",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Jul",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Aug",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Sep",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Oct",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Nov",
      FSOS: 0,
    },
    {
      name: "Kelloggs",
      month: "Dec",
      FSOS: 0,
    },
  ];

  const config_sa = {
    title: {
      visible: false,
      text: "FSOS",
    },
    forceFit: true,
    data: data,
    xField: "month",
    yField: "value",
    // yAxis: { min: 0 },
    label: { visible: false },
    isGroup: true,
    // isStack: true,
    // seriesField: "type",
    groupField: "type",
    color: [
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
      "#2b9ddb",
      "#7cfac3",
      "#00ab84",
      "#394856",
      "#e57364",
      "#4e79a7",
      "#59a14f",
      "#9c755f",
      "#f28e2b",
      "#edc948",
      "#bab0ac",
      "#e15759",
      "#b07aa1",
      "#76b7b2",
      "#ff9da7",
    ],
    yAxis: {
      title: {
        text: "Value (%)",
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
    },
    xAxis: {
      title: {
        text: "Month",
        visible: true,
        style: {
          fill: "#4A5DE8",
          fontSize: 13,
          fontWeight: "bold",
        },
      },
      label: {
        style: {
          fill: "#000",
          fontSize: 14,
          fontWeight: "bold",
        },
      },
    },
  };

  const fetchFairShareOfShelfGraph = () => {
    const loaderId = 'ca_fshareofshelfchart';
    addLoader(loaderId);

    const manufacturer = cookies.get("manufacturer");
    const product = cookies.get("fs_product");
    const url = `${BACKEND_URL_AZURE}/getfairsos?manufacturer=${manufacturer}&countries=${cookies.get(
      "ca_country"
    )}&regions=${cookies.get("ca_region")}&product=${product}`;
    fetch(url, { method: "GET", headers: headers })
      .then((res) => res.json())
      .then((data) => {
        const reversedResult = [...data.message].reverse();
        removeLoader(loaderId);
        setData(reversedResult);
      })
      .catch((err) => console.log("-------- Error -------", err));
  }

  const onHandleFilterChanged = (data) => {
    var strProduct = "";
    if (
      data.SINGLE_PRODUCT_SELECTION &&
      data.SINGLE_PRODUCT_SELECTION.value &&
      data.SINGLE_PRODUCT_SELECTION.value.length > 0
    ) {
      for (let i = 0; i < data.SINGLE_PRODUCT_SELECTION.value.length; i++) {
        strProduct =
          strProduct + data.SINGLE_PRODUCT_SELECTION.value[i].id + ",";
      }
      strProduct = strProduct.substring(0, strProduct.length - 1);
    }
    cookies.set("fs_product", strProduct);

    setFilterData(data);
    convertDataForTags(data);

    if (
      data.SINGLE_PRODUCT_SELECTION &&
      data.SINGLE_PRODUCT_SELECTION.value.length > 0 &&
      data.SINGLE_PRODUCT_SELECTION.value &&
      data.SINGLE_PRODUCT_SELECTION.value !== "null" &&
      data.SINGLE_PRODUCT_SELECTION.value !== "undefined"
    ) {
      fetchFairShareOfShelfGraph();
    }
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);

    if (item.key === "SINGLE_PRODUCT_SELECTION") {
      let product = cookies.get("fs_product").split(",");
      product = product.filter((id) => String(id) !== String(item.checkboxId));
      cookies.set("fs_product", product);
    }
    
    if (
      data.SINGLE_PRODUCT_SELECTION &&
      data.SINGLE_PRODUCT_SELECTION.value.length > 0 &&
      data.SINGLE_PRODUCT_SELECTION.value &&
      data.SINGLE_PRODUCT_SELECTION.value !== "null" &&
      data.SINGLE_PRODUCT_SELECTION.value !== "undefined"
    ) {
      fetchFairShareOfShelfGraph();
    }
  };

  return (
    <div className="Completed-Store-Audits-Chart-wp">
      <div className="HeaderStoreAuditsChart">
        <div className="hd-left-col">
          <div className="title-value">
            Fair Share of Shelf (FSOS)
          </div>
        </div>
        <div className="hd-middle-col">
          <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
        </div>
        <div className="hd-right-col">
          {/*<div className="custom-calendar-wp">
            <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
            <RangePicker
              showTime
              format={"YYYY-MM-DD"}
              onCalendarChange={(range, dateString, info) => {
                let startDate = moment(range[0]).format("YYYY-MM-DD");
                let endDate = moment(range[1]).format("YYYY-MM-DD");

                //if (info.range === "end") {
                cookies.set("fs_startDate", startDate);
                cookies.set("fs_endDate", endDate);

                // message.loading("Loading data...", 0);
                fetchFairShareOfShelfGraph();
                //}
              }}
              defaultValue={[
                moment(lastDate, dateFormat),
                moment(currentDate, dateFormat),
              ]}
              allowClear={false}
              suffixIcon={
                <img
                  alt=""
                  style={{ marginTop: "3px" }}
                  src={ICON_ARROW_DOWN}
                />
              }
            />
            </div>*/}
          <CustomFilter
            selectedData={filterData}
            filterKeys={[
              //CUSTOM_FILTER_KEYS.RETAILER_LIST,
              CUSTOM_FILTER_KEYS.SINGLE_PRODUCT_SELECTION,
            ]}
            onChange={(data) => {
              onHandleFilterChanged(data);
            }}
          />
        </div>
      </div>
      <div className="separate-line" />
      <GroupedColumn {...config_sa} />
    </div>
  )
};

export default FairShareOfShelfChart;
