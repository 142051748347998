/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Table, Row, Col, Image, Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS, BACKEND_URL } from "../../constants/constants";
import Cookies from "universal-cookie";

import "./style.scss";

const cookies = new Cookies();

const ImageGalleryEndcapBays = ({ data }) => {
  const [img, setImg] = useState([]);
  const [visible, setVisible] = useState(false);
  const [displayEndcap, setDisplayEndcap] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  let [store, setStore] = useState(cookies.get("ig_store"));

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleData = (result) => {
    let arrImg = [];
    if (result) {
      for (let i = 0; i < result.length; i++) {
        let duplicate = false;
        if (i < result.length - 1) {
          if (
            result[i].store === result[i + 1].store &&
            new Date(result[i].dateEnd).toDateString() !==
              new Date(result[i + 1].dateEnd).toDateString()
          )
            duplicate = true;
        }
        if (result[i].processed_images) {
          for (let j = 0; j < result[i].processed_images.length; j++) {
            if (result[i].processed_bay_type[j] === "ENDCAP")
              arrImg.push({
                img: result[i].processed_images[j],
                store_name: result[i].storeName,
                bay: result[i].processed_bay_num[j],
                date: result[i].dateEnd,
              });
          }
        }
      }
    }
    setImg(arrImg);
  };

  useEffect(() => {
    if (
      cookies.get("ig_placement") &&
      cookies.get("ig_placement").indexOf("Endcap") === -1
    )
      setDisplayEndcap("none");

    handleData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const showModal = (img) => {
    setImageURL(img);
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <div className="OverdueStoreAudits-wp" style={{ display: displayEndcap }}>
      <Modal
        title="Image"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1080}
        bodyStyle={{ height: 1000 }}
        style={{ marginTop: -90 }}
      >
        <img src={imageURL} style={{ borderRadius: 7 }} width="1024" />
      </Modal>
      <Row>
        <Col span={24}>
          <div>
            <div
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#000",
              }}
            >
              End-caps
              <br />
              <br />
            </div>
            <Row>
              {img.map((item, index) => {
                return (
                  <Col span={4} style={{ padding: 10 }} key={index}>
                    <img
                      src={item.img}
                      style={{ width: 200, borderRadius: 7 }}
                      onClick={() => showModal(item.img)}
                    />
                    <br />
                    <br />
                    <font style={{ fontWeight: "bold" }}>
                      {item.store_name} - Bay {item.bay}
                    </font>
                    <br />
                    Audit Date: {item.date.replace("GMT", "")}
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImageGalleryEndcapBays;
