import React, { useEffect, useReducer, useMemo } from 'react';

import LoadingComponent from '../componentsShared/Loading';
import * as AuthDataManager from "../services/authDataManager";

import { EXPIRED_STATUS_CODE } from "../constants/constants";
import { isEmpty, isNull, isFunction } from "lodash";

const AppEventHandle = require("../appEvents/EventHandler");
const AuthContext = React.createContext();
let appEventSubscription = null;

function authReducer(state, action) {
    switch (action.type) {
        case 'IS_AUTHENTICATING': {
            return {
                ...state,
                status: 'pending',
                isAuthenticated: true,//TODO
                currentUser: {},
            };
        }
        case 'UNAUTHENTICATED': {
            return {
                ...state,
                status: 'failed',
                isAuthenticated: true,//TODO
                currentUser: {},
            };
        }
        case 'AUTH_SUCCESS': {
            return {
                ...state,
                status: 'done',
                isAuthenticated: true,
                currentUser: action.currentUser,
                error: '',
            };
        }
        case 'AUTH_FAILED': {
            return {
                ...state,
                status: 'failed',
                isAuthenticated: true,//TODO
                currentUser: {},
                error: action.error
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                status: 'failed',
                isAuthenticated: true,//TODO
                currentUser: {},
                error: '',
            };
        }
        case 'SET_CURRENT_USER': {
            return {
                ...state,
                currentUser: action.currentUser,
            };
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }
    }
}

const initialState = {
    status: 'pending',
    currentUser: {},
    isAuthenticated: true,//TODO
    error: '',
};

function logoutFn(dispatch) {
    AuthDataManager.clearAuthData();
    dispatch({ type: 'LOGOUT' });

    if (!isNull(appEventSubscription) && isFunction(appEventSubscription.unsubscribe)) {
        appEventSubscription.unsubscribe();
        appEventSubscription = null;
    }
}
function authenticateSuccessFn(dispatch, user) {
    subscribeEventFn(dispatch);
    dispatch({
        type: 'AUTH_SUCCESS',
        currentUser: user,
        isAuthenticated: true
    });
}
function subscribeEventFn(dispatch) {
    if (!isNull(appEventSubscription)) return;
    console.log("-------------Init subscribeEventFn ------------");
    appEventSubscription = AppEventHandle.subscribe("EXPIRED", data => {
        if (EXPIRED_STATUS_CODE.indexOf(data.code) !== -1) {
            logoutFn(dispatch);
        }
        console.log(
            `"anEvent", was published with this data: "${data.msg}"`
        );
    });
}

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const { status } = state;

    useEffect(() => {
        subscribeEventFn(dispatch);

        const { user, token } = AuthDataManager.getAuthData();
        if (!isEmpty(user) && !isEmpty(token)) {
                authenticateSuccessFn(dispatch, user);
        } else {
            dispatch({ type: 'UNAUTHENTICATED' });
        }
    }, []);

    const value = useMemo(() => [state, dispatch], [state]);

    return (
        <AuthContext.Provider value={value}>
            {status === 'pending'
                ? <LoadingComponent />
                : children
            }
        </AuthContext.Provider>
    )
}

function useAuthState() {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error(`useAuthState must be used within a AuthProvider`)
    }

    const [state, dispatch] = context;
    const logout = () => logoutFn(dispatch);
    const setAuthData = (user) => authenticateSuccessFn(dispatch, user);

    return {
        state,
        isAuthenticated: state.isAuthenticated,
        currentUser: state.currentUser,
        setAuthData,
        logout,
    };
}

export { AuthProvider, useAuthState }
