/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Pagination } from "antd";
import "./style.scss";

const OtherBays = ({ data }) => {
  const [img, setImg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [visible, setVisible] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [displayOther, setDisplayOther] = useState("");

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = img.slice(indexOfFirstItem, indexOfLastItem);

  const renderItems = currentItems.map((item, index) => {
    return (
      <Col span={4} style={{ padding: 10 }} key={index}>
        <img
          src={item.img}
          style={{ width: 200, borderRadius: 7 }}
          onClick={() => showModal(item.img)}
        />
        <br />
        <br />
        <font style={{ fontWeight: "bold" }}>Bay {item.bay}</font>
        <br />
        Audit Date: {item.date.replace("GMT", "")}
        <br />
        Auditor: {item.auditor}
      </Col>
    );
  });

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(img.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    let classActive = "";
    if (number === currentPage) {
      classActive = "active";
    }
    return (
      <li
        className={classActive}
        key={number}
        id={number}
        onClick={handleClick}
      >
        {number}
      </li>
    );
  });

  const handleData = (result) => {
    const arrImg = [];
    if (result) {
      for (var i = 0; i < result.length; i++) {
        if (result[i].processed_images) {
          for (var j = 0; j < result[i].processed_images.length; j++) {
            if (
              result[i].processed_bay_type[j] == "OTHERS" &&
              (result[i].processed_images_status[j] === "cv_done" ||
                result[i].processed_images_status[j] === "finalized")
            )
              arrImg.push({
                img: result[i].processed_images[j],
                bay: result[i].processed_bay_num[j],
                auditor: result[i].auditor,
                date: result[i].dateEnd,
              });
          }
        }
      }
    }
    // removeLoader(loaderId);
    if (arrImg.length <= 0) setDisplayOther("none");
    else setDisplayOther("");
    setImg(arrImg);
  };

  useEffect(() => {
    handleData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const showModal = (img) => {
    setImageURL(img);
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <div className="OverdueStoreAudits-wp" style={{ display: displayOther }}>
      <Modal
        title="Image"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1080}
        bodyStyle={{ height: 1000 }}
        style={{ marginTop: -90 }}
      >
        <img src={imageURL} style={{ borderRadius: 7 }} width="1024" />
      </Modal>
      <Row>
        <Col span={24}>
          <div>
            <div
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Others
              <br />
              <br />
            </div>
            <Row>{renderItems}</Row>
            <Row style={{ marginTop: 10 }}>
              <Col
                span={24}
                style={{
                  padding: 10,
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Pagination
                  onChange={handleChange}
                  defaultCurrent={1}
                  total={img.length}
                  showSizeChanger={false}
                  pageSize={6}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OtherBays;
