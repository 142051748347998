import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import ReportTable from "../../componentsShared/ReportTable";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import MyStoresPlanogramComplianceChart from "../MyStoresPlanogramComplianceChart";
import "./style.scss";

const columns = [
  {
    title: "Area of Non-Compliance",
    dataIndex: "reason",
    width: "60%",
    key: "reason",
    render: (val, obj) => {
      if(obj.nonCompliantSequenceData) {
        return obj.nonCompliantSequenceData.map(sequence => (
          <div style={{marginTop: 5}}>{sequence.name}</div>
        ))
      }
      if(obj.nonCompliantAdjacencyData) {
        return obj.nonCompliantAdjacencyData.map(adjacency => (
          <div style={{marginTop: 5}}>{adjacency.name}</div>
        ))
      }
      return obj.item;
    }
  },
  {
    title: "Reason",
    dataIndex: "reason",
    width: "40%",
    key: "reason",
    sorter: (a, b) => a.reason.localeCompare(b.reason),
    showSorterTooltip: false,
  },
];

const MyStoresPlanogramCompliance = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [tableConfigs, setTableConfigs] = useState([]);

  useEffect(() => {
    setTableConfigs(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <MyStoresPlanogramComplianceChart planogram={data.current} />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={data.nonCompliantProducts}
        isPagination={true}
      />
      <Modal
        title="Area of Non-Compliance"
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={null}
        width={700}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={data.nonCompliantProducts}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton onClick={() => setVisible(true)} title="View all" />
      </div>
    </div>
  );
};

export default MyStoresPlanogramCompliance;
