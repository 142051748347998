
import React from "react";
import PropTypes from 'prop-types';
import { Button } from 'antd';
import "./style.scss";

const ICON_ARROW_RIGHT = require("../../assets/img/icons/ic-arrow-right-blue.svg")

const ViewAllButton = (props) => {
    const { title, onClick, disabled } = props;

    return (
      <div className="ViewAllButton-wp">
        <Button disabled={disabled} className="view-all-btn" onClick={onClick}>
          <div className="text-value">{title}</div>
          <img alt="icon-btn" className="icon-btn" src={ICON_ARROW_RIGHT} />
        </Button>
      </div>
    );
}

ViewAllButton.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ViewAllButton;
