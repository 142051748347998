import React, { useState, useEffect } from 'react';
import { isEmpty, forEach, cloneDeep, find, filter } from 'lodash';
import RegionsList from "./data.json";
import MultiCheckboxSelection from "../MultiCheckboxSelection";
import { Tag, Input, Tooltip, Checkbox } from 'antd';
import './style.scss';

const FlavourSelection = (props) => {
  const { defaultValue, onChange } = props;
  const [regionData, setRegionsData] = useState([]);

  useEffect(() => {
    generateRegionsData(RegionsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    combineRegionsWithOutsideData(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const generateRegionsData = (data) => {
    let Regions = [];
    forEach(data, (sItem) => {
      Regions.push({
        id: sItem.id,
        label: sItem.value,
        isChecked: false,
      });
    });
    setRegionsData(Regions);
  };

  const combineRegionsWithOutsideData = (items) => {
    let Regions = [];
    forEach(RegionsList, (sItem) => {
      Regions.push({
        id: sItem.id,
        label: sItem.value,
        isChecked: false,
      });
    });

    let clonedData = cloneDeep(Regions);

    forEach(clonedData, (sItem) => {
      const matched = find(items, { id: sItem.id });
      sItem.isChecked = !isEmpty(matched) ? matched.isChecked : false;
    });

    setRegionsData(clonedData);
  };

  const onSelectRegion = (mItem, isChecked) => {
    let clonedData = cloneDeep(regionData);

    let matched = find(clonedData, { id: mItem.id });
    if (matched) {
      matched.isChecked = isChecked;
    }
    setRegionsData(clonedData);

    const selectedItems = filter(clonedData, { isChecked: true });
    onChange(selectedItems);
  };

  return (
    <div className="custom-table-RegionsSelection">
      <div className="RegionsSelection-wp">
        <MultiCheckboxSelection
          data={regionData}
          onCheckItem={onSelectRegion}
        />
      </div>
    </div>
  );
};

FlavourSelection.defaultProps = {
  selectedItems: [{ id: "", label: "", isChecked: false }],
  onChange: (value) => {},
};

export default FlavourSelection;