import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import { Button, DatePicker, Select } from "antd";
import CustomFilter from "../CustomFilter";
import FilterTags from "../FilterTags";
import Cookies from "universal-cookie";
import "./style.scss";
import moment from "moment";

import * as filterDataHanler from "../../utils/filterDataHanler";

const ICON_FILTER = require("../../assets/img/icons/filter-icon.svg");
const ICON_CALENDAR = require("../../assets/img/icons/ic-calendar.svg");
const ICON_ARROW_DOWN = require("../../assets/img/icons/ic-arrow-down-black.svg");
const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const cookies = new Cookies();
let lastDate = new Date();
lastDate.setMonth(lastDate.getMonth() - 1);
lastDate = moment(lastDate).format("YYYY-MM-DD");
let currentDate = new Date();
currentDate = moment(currentDate).format("YYYY-MM-DD");

const HeaderStoreAuditsChart = (props) => {
  const {
    title,
    onClickExport,
    showExportLoading,
    filterKeys,
    onFilterChanged,
  } = props;

  const [filterData, setFilterData] = useState([]);
  const [tagsData, setTagsData] = useState([]);

  const onHandleFilterChanged = (data) => {
    setFilterData(data);
    convertDataForTags(data);
  };

  const convertDataForTags = (data) => {
    setTagsData(filterDataHanler.convertDataForTags(data));
  };

  const handleRemoveTag = (item) => {
    const data = filterDataHanler.removeTag(cloneDeep(filterData), item);
    setFilterData(data);
    convertDataForTags(data);
  };

  return (
    <div className="HeaderStoreAuditsChart">
      <div className="hd-left-col">
        <div className="title-value">{title || ""}</div>
      </div>
      <div className="hd-middle-col">
        <FilterTags data={tagsData} onRemoveTag={handleRemoveTag} />
      </div>
      <div className="hd-right-col">
        <div className="custom-calendar-wp">
          <img className="calendar-icon" alt="" src={ICON_CALENDAR} />
          <RangePicker
            showTime
            format={"YYYY-MM-DD"}
            onChange={() => {}}
            defaultValue={[
              moment(lastDate, dateFormat),
              moment(currentDate, dateFormat),
            ]}
            suffixIcon={
              <img alt="" style={{ marginTop: "3px" }} src={ICON_ARROW_DOWN} />
            }
          />
        </div>
        <CustomFilter
          selectedData={filterData}
          filterKeys={filterKeys}
          onChange={(data) => {
            console.log("HeaderStoreAuditsChart ####", data);
            onHandleFilterChanged(data);
          }}
        />
      </div>
    </div>
  );
};

HeaderStoreAuditsChart.propTypes = {
  title: PropTypes.string,
  onClickFilter: PropTypes.func,
  onClickExport: PropTypes.func,
  showFilterLoading: PropTypes.bool,
  showExportLoading: PropTypes.bool,
};

export default HeaderStoreAuditsChart;
