
import React, { useState, useEffect } from "react";
import { Modal, Table } from 'antd';
import ReportTable from "../../componentsShared/ReportTable";
import ReportTableHeader from "../../componentsShared/ReportTableHeader";
import ViewAllButton from "../../componentsShared/ViewAllButton";
import { CUSTOM_FILTER_KEYS } from "../../constants/constants";
import "./style.scss";

const columns = [
  {
    title: "Brand",
    dataIndex: "brand",
    width: "50%",
    key: "brand",
    sorter: (a, b) => a.brand.localeCompare(b.brand),
    showSorterTooltip: false,
  },
  {
    title: "No. of Facings",
    dataIndex: "avgFacing",
    width: "50%",
    align: "center",
    key: "avgFacing",
    sorter: (a, b) => a.avgFacing - b.avgFacing,
    showSorterTooltip: false,
  },
];

const AvgOfFacingBySku = (props) => {
  const [tableConfigs, setTableConfigs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTableConfigs(columns);
  }, []);

  return (
    <div className="OverdueStoreAudits-wp">
      <ReportTableHeader
        title="Avg No. of Facing by Brand"
        hideControlButtons={true}
      />
      <div className="separate-line" />
      <ReportTable
        columns={tableConfigs}
        dataSource={props.data}
        isPagination={true}
      />
      <Modal
        title="Avg No. of Facing by Brand"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
        footer={null}
      >
        <ReportTable
          columns={tableConfigs}
          dataSource={props.data}
          isPagination={false}
        />
      </Modal>
      <div className="view-all-btn-wp">
        <ViewAllButton onClick={() => setShowModal(true)} title="View all" />
      </div>
    </div>
  );
};

export default AvgOfFacingBySku;
